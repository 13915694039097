import { Acl } from "../../interfaces/core.interface";

export enum SubscriberAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete', 
  Audit = 'audit'
}

export const SubscriberAcl = [
  {
    code: SubscriberAclCode.ListAll,
    title: 'subscriber.listAll.title',
    description: 'subscriber.listAll.subTitle',
  },
  {
    code: SubscriberAclCode.ListOwn,
    title: 'subscriber.listOwn.title',
    description: 'subscriber.listOwn.subTitle',
  },
  {
    code: SubscriberAclCode.Create,
    title: 'subscriber.create.title',
    description: 'subscriber.create.subTitle',
  },
  {
    code: SubscriberAclCode.Update,
    title: 'subscriber.update.title',
    description: 'subscriber.update.subTitle',
  },
  {
    code: SubscriberAclCode.Delete,
    title: 'subscriber.delete.title',
    description: 'subscriber.delete.subTitle',
  },
  {
    code: SubscriberAclCode.Audit,
    title: 'subscriber.audit.title',
    description: 'subscriber.audit.subTitle',
  },
] as Acl<SubscriberAclCode>;
