import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { Passenger } from '../../../entities/passenger.entity';
import { RequestAudit } from '../../../entities/request-audit.entity';
import { RequestCategory } from '../../../entities/request-category.entity';
import { Request, RequestStatus } from '../../../entities/request.entity';
import { User } from '../../../entities/user.entity';
import { IPaginationOptions, IPaginationResponse } from '../../../interfaces/paginate.interface';
import { Api, Endpoint } from '../../../services/api.service';

interface Props {
  selectedRequest: Request
  refresh: (e: any) => Promise<any>
}

export function SelectedItemContent({
  selectedRequest, refresh
}: Props) {
  const { t } = useTranslation(['forms', 'dashboard']);
  const [users, setUsers] = useState<any[]>([]);
  const [user, setUser] = useState<User>();
  const [requestAudit, setRequestAudit] = useState<any>([]);

  const dateFormate = (date: any) => {
    let d: any = new Date(date)
    return d.toLocaleString("en-US");
  }
  useEffect(() => { setUser(selectedRequest?.assignedTo as User) }, [selectedRequest])
  const onChange = async (e: any) => {
    console.log('This is Data:', e)
    setUser(e.ref);
  }

  const onAssign = async (e: any) => {
    e.preventDefault()
    if (selectedRequest && user) {
      const updateReq = await Api.patch<Request, { id: string, assignedTo: string, assignedAt: Date }>(Endpoint.REQUEST, { id: selectedRequest.id as string, assignedTo: user?.id as string, assignedAt: new Date() });
      console.log('Updated Request:', updateReq)
      refresh(true);
    }
  }

  const onCompletedBy = async (e: any) => {
    e.preventDefault()
    if (selectedRequest && user) {
      const updateReq = await Api.patch<Request, { id: string, completedBy: boolean, finalizedAt: Date }>(Endpoint.REQUEST, { id: selectedRequest.id as string, completedBy: true, finalizedAt: new Date() });
      console.log('Updated Request:', updateReq)
      refresh(true);
    }
  }

  const onRejectedBy = async (e: any) => {
    e.preventDefault()
    if (selectedRequest && user) {
      const updateReq = await Api.patch<Request, { id: string, rejectedBy: boolean, finalizedAt: Date }>(Endpoint.REQUEST, { id: selectedRequest.id as string, rejectedBy: true, finalizedAt: new Date() });
      console.log('Updated Request:', updateReq);
      refresh(true);
    }
  }

  useEffect(() => { init(); }, [])
  useEffect(() => { requestAduit(); }, [selectedRequest])


  const requestAduit = async () => {
    if (selectedRequest.id) {
      const requestAuditList = await Api.get<RequestAudit, { id: string }>(Endpoint.REQUEST_AUDIT, { id: selectedRequest.id });
      console.log('Passenger Data:', requestAuditList)
      setRequestAudit(requestAuditList);
    }
  }
  const init = async () => {
    const userList = await Api.get<IPaginationResponse<User>, IPaginationOptions>(Endpoint.USER_LIST);
    if (selectedRequest.id) {
      const requestAuditList = await Api.get<RequestAudit, { id: string }>(Endpoint.REQUEST_AUDIT, { id: selectedRequest.id });
      console.log('Passenger Data:', requestAuditList)
      setRequestAudit(requestAuditList);
    }
    setUsers(userList.items ? userList.items.map((i: any) => ({
      label: i.email,
      value: i.email,
      ref: i,
    })) : []
    );

    console.log('Passenger Data:', users)
  }

  const toSelectList = (data: User[]) => {
    const Final = data.map((user) => {
      return { value: user.email, label: user.email }
    })
  }

  return (
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={selectedRequest.code}
      >
        <ViewContentItems>
          {selectedRequest.message ? <ViewContentItem title={t("request.basicSection.message")}>
            {selectedRequest.message}
          </ViewContentItem> : null
          }<ViewContentItem title={t("request.basicSection.status")}>
            {selectedRequest.status}
          </ViewContentItem>
          <ViewContentItem title={t("request.basicSection.passenger")}>
            {(selectedRequest.passenger as Passenger)?.firstName}
            {' ' + (selectedRequest.passenger as Passenger)?.lastName}<br />
            {(selectedRequest.passenger as Passenger)?.phone}<br />
            {(selectedRequest.passenger as Passenger)?.email}
          </ViewContentItem>
          <ViewContentItem title={t("request.basicSection.category")}>
            {(selectedRequest.category as RequestCategory)?.title}
          </ViewContentItem>
          {selectedRequest.assignedBy ? <ViewContentItem title={t("request.basicSection.assignedBy")}>
            {(selectedRequest.assignedBy as User)?.email}
          </ViewContentItem> : null}
          {selectedRequest.assignedTo ? <ViewContentItem title={t("request.basicSection.assignedTo")}>
            {(selectedRequest.assignedTo as User)?.email}
          </ViewContentItem> : null}
          {selectedRequest.assignedAt ? <ViewContentItem title={t("request.basicSection.assignedAt")}>
            {dateFormate(selectedRequest.assignedAt)}
          </ViewContentItem> : null}
          {selectedRequest.completedBy ? <ViewContentItem title={t("request.basicSection.completedBy")}>
            {(selectedRequest.completedBy as User)?.email}
          </ViewContentItem> : null}
          {selectedRequest.rejectedBy ? <ViewContentItem title={t("request.basicSection.rejectedBy")}>
            {(selectedRequest.rejectedBy as User)?.email}
          </ViewContentItem> : null}
          {selectedRequest.finalizedAt ? <ViewContentItem title={t("request.basicSection.finalizedAt")}>
            {dateFormate(selectedRequest.finalizedAt)}
          </ViewContentItem> : null}
        </ViewContentItems>
      </ViewContent>
<div className='col-md-6'>
      {(selectedRequest.status == RequestStatus.Assigned || selectedRequest.status == RequestStatus.Direct || selectedRequest.status == RequestStatus.New) ?
        <ViewContent
          title={""}
          className='col-md-12'

        >
          <ViewContentItems>
            <ViewContentItem title={t("request.basicSection.user")}>
              <div style={{display:"flex"}}>
                <div>
                  <Select
                    placeholder={'Select Assignee'}
                    options={users}
                    value={user ? { value: user?.email, label: user?.email, ref: user } : null}
                    noOptionsMessage={() => "No items to show"}
                    onChange={(value) => onChange(value as any)}
                  />
                </div>
                <div>
                  <button style={{marginLeft:"15px"}} onClick={onAssign} className="btns btn-lg">Assign</button>
                </div>
              </div>
            </ViewContentItem>
            <div className='row'>
              <div className='col-md-6'>
                <button style={{ marginBottom: "10px", marginTop: "20px", borderRadius: '8px' }} onClick={onCompletedBy} className="btn btn-success btn-lg btn-block ">Mark as Completed</button>
              </div>
              <div className='col-md-6'>
                <button style={{ marginBottom: "10px", marginTop: "20px", borderRadius: '8px' }} onClick={onRejectedBy} className="btn btn-danger btn-lg btn-block">Mark as Rejected</button>
              </div>
            </div>
          </ViewContentItems>
        </ViewContent> : null}

      {Object.keys(requestAudit).length > 0 ? (
        <div style={{ marginTop: "10px" }}>
          <ViewContent title="Request History"
          className='col-md-12'>
            <div
              style={{
                maxHeight: "300px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Status</th>
                    <th scope="col">Reason</th>
                    <th scope="col">Source</th>
                    <th scope="col">Date And Time</th>
                  </tr>
                </thead>
                {requestAudit
                  ? requestAudit.map((audit: any, i: number) => {
                    return (
                      <tbody key={i}>
                        <tr>
                          <td>
                            {[RequestStatus.Assigned, RequestStatus.Direct, RequestStatus.New].includes(audit?.status?.toLowerCase())
                              ? (
                                <span className="badge badge-warning">
                                  {audit?.status}
                                </span>
                              ) : (" ")}

                            {[RequestStatus.PassengerRejected, RequestStatus.Rejected].includes(audit?.status?.toLowerCase()) ? (
                              <span className="badge badge-danger">
                                {audit?.status}
                              </span>
                            ) : ("")}

                            {[RequestStatus.Completed, RequestStatus.Completed].includes(audit?.status?.toLowerCase()) ? (
                              <span className="badge badge-success">
                                {audit?.status}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <span className="badge badge-success">
                              {audit?.reason}
                            </span>
                          </td>
                          <td>
                            <span className="badge badge-success">
                              {audit?.source}
                            </span>
                          </td>
                          <td>
                            <span>
                              {dateFormate(audit?.createTime)}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })
                  : ""}
              </table>
            </div>
          </ViewContent>
        </div>
      ) : (
        ""
      )}
</div>
    </ContentWrapper>
  );
}