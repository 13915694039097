import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../components/form/form.component";
import { FormHeader } from "../../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../../components/form/sections.component";
import { CorporateUser } from "../../../../entities/corporate-user.entity";
import { User } from "../../../../entities/user.entity";
import { FormMode } from "../../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../../enums/form.enum";
import { Endpoint } from '../../../../services/api.service';
import { ValidationError } from "../../../../types/error.interface";
import { CorporateBookingAclCode } from "../../acl";
import { CorporateBookingRoute } from "../../routes";
import { CorporateUserPostPersonal } from "./section/personal.component";

interface Props {
  style: FormStyle;
  cancel: Function;
  formMode: FormMode;
  entityId?: string;
  onAfterSave?: (entity: CorporateUser) => void;
}

/**
 * Add or update a user
 */
export function CorporateBookingPost({
  style, cancel, formMode, entityId, onAfterSave,
}: Props) {

  const { t } = useTranslation('main');
  const [value, setValue] = useState('')
  const [message, setMessage] = useState("");
  const { reset } = useForm();
  const onBeforeSave = async (mode: FormMode, form: CorporateUser) => {
    form.phone = value
    form.role = (form.role as any).name
  }

  const processData = (corporateUser: CorporateUser): CorporateUser => {
    setValue(corporateUser.phone);
    corporateUser.role = { value: corporateUser.role, name: corporateUser.role } as any
    return corporateUser
  }


  /**
   * Custom Header depending upon the type of form style
   * In case of containerized style, we're seperating headers in the seperate SectionsWrapper tags,
   * Otherwise if the form is supposed to be shown on full screen then don't use custom header, but rather
   * Use it seperately for each FormSections tag as shown below
   */
  const header = () =>
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader
            type={FormHeaderType.Title}
            title={t("entities.corporate_user")}
          />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Controls} />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={FormHeaderType.Title} title={t("entities.corporate_user")} />
    );


  return (
    <Form<CorporateUser>
      endpoint={Endpoint.CORPORATE_USER_ACCOUNT}
      onBeforeSave={onBeforeSave}
      processData={processData}
      returnLink={CorporateBookingRoute.User}
      onAfterSave={onAfterSave}
      cancel={cancel}
      formMode={formMode}
      entityId={entityId}
      className={style === FormStyle.Containerized && "ae-content-w"}
    >
      {/* Using the custom header in case of using containerized form */}
      {style === FormStyle.Containerized && header()}

      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
        {/* If not containerized, then use it normally */}
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Controls} title={t("mainMenu.corporateBooking.account")} />}
        {message !== "" ? <div className="alert alert-success btn-block" style={{ background: '#b0e7b0' }} role="alert">
          <h6>{message}</h6>
        </div> : ""}
        <FormSections>
          <CorporateUserPostPersonal onChange={setValue} value={value} />
        </FormSections>
      </SectionsWrapper>

    </Form>
  );
}
