import { RouteProps } from "react-router-dom";
import { DriverList } from "./list/list.component";
import { DriverPost } from "./post/post.component";

export enum CallRoute {
  List = '/call/list',
  Post = '/call/post',
}

export const CallRouteMap = [
  {
    path: CallRoute.List,
    component: DriverList,
  },
  {
    path: CallRoute.Post,
    component: DriverPost,
  },
] as RouteProps[];
