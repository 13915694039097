import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormRadio } from "../../../../components/form/input/radio.component";
import { FormSection } from "../../../../components/form/section.component";

export function FeatureRequest() {
  
  const { t } = useTranslation(['forms', 'main']);
  const formProps = useFormContext();

  return (
    <FormSection
      title=""
      subtitle=""
    >
        <FormInput
            name="title"
            label={ t("forms:common.title") }
            type="text"
            tabIndex={1}
            autoFocus
            validation={{ required: true }}
            { ...formProps }
        />
    
        <FormRadio
            name="type"
            label={ t("forms:feature.typeLabel")}
            options={[
                { label: t("main:entities.driver"), value: 'driver' },
                { label: t("main:entities.vehicle"), value: 'vehicle' },
            ]}
            tabIndex={2}
            validation={{ required: true }}
            { ...formProps }
        />
    </FormSection>
  );
  
}
