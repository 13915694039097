import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormSection } from "../../../../components/form/section.component";

export function DriverPostEmergency() {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  
  return (
    <FormSection
      title={ t("driver.emergencySection.title") }
      subtitle={ t("driver.emergencySection.subTitle") }
    >
      <div className="row">
        <FormInput
          label={ t("common.fullName") }
          name="emergencyName"
          type="text"
          className="col-sm-6"
          tabIndex={9}
          { ...formProps }
        />
        <FormInput
          label={ t("common.phone") }
          name="emergencyPhone"
          type="tel"
          className="col-sm-6"
          tabIndex={10}
          { ...formProps }
        />
        <FormInput
          label={ t("common.email") }
          name="emergencyEmail"
          type="email"
          className="col-sm-6"
          tabIndex={11}
          { ...formProps }
        />
      </div>
    </FormSection>
  );
  
}
