import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentWrapper, ViewContent } from '../../../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../../../components/view/item.component';
import { Currency } from '../../../../../entities/currency.entity';

interface Props {
  selectedCurrency: Currency
}

export function SelectedItemContent({
  selectedCurrency
}: Props) {

  const { t } = useTranslation('forms');

  const stats = [
    {
      "title": "Rides",
      "value": "0",
      "percentage": "0",
      "trend": "neutral",
    },
    {
      "title": "Earnings",
      "value": "$0",
      "percentage": "0",
      "trend": "neutral",
    },
    {
      "title": "Earnings per Ride",
      "value": "$0",
      "percentage": "0",
      "trend": "neutral",
    },
    {
      "title": "Shift Time",
      "value": "0 Hrs",
      "percentage": "0",
      "trend": "neutral",
    },
  ]

  return(
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={ selectedCurrency.name }
      >
        <ViewContentItems>
          <ViewContentItem title={ t('currency.basicSection.name') }>
            { selectedCurrency.name }
          </ViewContentItem>

          <ViewContentItem title={ t('currency.basicSection.code') }>
            { selectedCurrency.code }
          </ViewContentItem>
          <ViewContentItem title={ t('currency.basicSection.symbol') }>
            { selectedCurrency.symbol }
          </ViewContentItem>
          <ViewContentItem title={ t('currency.basicSection.subunits') }>
            { selectedCurrency.subunits }
          </ViewContentItem>
        </ViewContentItems>
      </ViewContent> 
    </ContentWrapper>
  );
}