import { IEntityBase, IEntityCreatedBy, IEntityTimestamp } from "../interfaces/entity.interface";
import { Role } from "./role.entity";

export enum UserStatus {
  Active = 'active',
  Pending = 'pending',
  Blocked = 'blocked',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
}

export interface User extends IEntityBase, IEntityTimestamp, IEntityCreatedBy {
  [key: string]: any; 
  firstName: string;
  lastName: string;
  gender: Gender;
  email: string;
  phone: string;
  role: Role | string; 
}
