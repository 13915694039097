export interface IValidationError {
  field: string;
  message: string;
}
export class ValidationError extends Error {
  field: string;
  message: string;
  constructor(error: IValidationError) {
    super('Validation error');
    this.field = error.field;
    this.message = error.message;
  }
}
