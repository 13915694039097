import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../../components/form/form.component";
import { FormHeader } from "../../../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../../../components/form/sections.component";
import { Currency } from "../../../../../entities/currency.entity";
import { FormMode } from "../../../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../../../enums/form.enum";
import { Endpoint } from '../../../../../services/api.service';
import { ValidationError } from "../../../../../types/error.interface";
import { SettingsRoute } from "../../../routes";
import { CurrencyPostBasic } from "./section/basic.component";

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: Currency) => void;
}

/**
 * Add or update a currency
 */
export function CurrencyPost({
  style, cancel, formMode, entityId, onAfterSave,
}: Props) {

  const { t } = useTranslation('main');

    /**
   * Custom Header
   */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Title } title={ t("entities.currency") } />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Controls } />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={ FormHeaderType.Title } title={ t("entities.currency") } />
    )
  );

  // const onBeforeSave = async (mode: FormMode, form: Currency) => {
  //   form.id = form.id || undefined;
  //   if(form.subunits<1){
  //     throw new ValidationError({
  //       field: "subunits",
  //       message: "Wrong Sub unit",
  //     });
      
  //     alert("Wrong subunit");
  //   } 
  // };

  /**
   * Render
   */
  return (
    <Form<Currency>
      endpoint={ Endpoint.SETTINGS_CURRENCIES }
      returnLink={ SettingsRoute.Currency }
      cancel={ cancel }
      formMode={ formMode }
      entityId={ entityId }
      onAfterSave={ onAfterSave }
      // onBeforeSave = {onBeforeSave}
      className={ style === FormStyle.Containerized && "ae-content-w" }
    >
      { style === FormStyle.Containerized && header() }
      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Title } title={ t("entities.currency") } /> }
        <FormSections>
          <CurrencyPostBasic />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Controls } /> }
      </SectionsWrapper>
    </Form>
  );
}
