import { RouteProps } from "react-router-dom";
import EmergencyCall from './handled/emergency.component';

export enum EmergencyRoute {
  Emergency = '/emergency',
}

export const EmergencyRouteMap = [
  {
    path: EmergencyRoute.Emergency,
    component: EmergencyCall,
  },
  //   {
  //     path: DriverRoute.Post,
  //     component: DriverPost,
  //   },
] as RouteProps[];
