import { sub } from "date-fns"

export interface StatsResponse {
    tripCount:number
    revenue: number
    distance: number
    rideTime:number
    tripCountPercentage: number
    revenuePercentage: number
    distancePercentage: number
    prevTripCount: number
    prevRevenue: number
    prevDistance: number
    prevRideTime:number
    rideTimePercentage: number
}

export type StatsType = "Rides" | "Shift Time" | "Distance" | "Earnings" | "Activity";

export enum StatsFilterPeriod {
    Today = 'today',
    Yesterday = 'yesterday',
    LastYesterday = 'last-yesterday',
    ThisWeek = 'this-week',
    LastWeek = 'last-week',
    WeekBeforeLast = 'week-before-last',
    ThisMonth = 'this-month',
    LastMonth = 'last-month',
    MonthBeforeLast = 'month-before-last',
    ThisYear = 'this-year',
    LastYear = 'last-year',
    Custom = "custom"
};

export interface StatsRequest {
    fleet?: string;
    group?: string;
    driver?: string;
    vehicle?: string;
    network?: string;
    operator?: string;
    endDate: string | Date;
    startDate: string | Date;
    timeline: StatsFilterPeriod;
}

export function getDatesForFilters(time: StatsFilterPeriod) {
    let startDate = new Date(), endDate = new Date();
    switch (time) {
        case StatsFilterPeriod.Today:
            break;
        case StatsFilterPeriod.Yesterday:
            startDate = sub(startDate, { days: 1 }); endDate = sub(endDate, { days: 1 });            
            break;
        case StatsFilterPeriod.ThisWeek:
            startDate = sub(startDate, { days: 7 });
            break;
        case StatsFilterPeriod.LastWeek:
            startDate = sub(startDate, { days: 14 }); endDate = sub(endDate, { days: 7 });
            break;
        case StatsFilterPeriod.ThisMonth:
            startDate = sub(startDate, { days: 30 });
            break;
        case StatsFilterPeriod.LastMonth:
            startDate = sub(startDate, { days: 60 }); endDate = sub(endDate, { days: 30 });
            break;
        case StatsFilterPeriod.ThisYear:
            const year = startDate.getFullYear();
            startDate = new Date(year, 0, 1);
            break;
    }

    return { startDate, endDate };
}