import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Request } from "../../../entities/request.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../enums/form.enum";
import { Endpoint } from '../../../services/api.service';
import { ValidationError } from "../../../types/error.interface";
import { RequestRoute } from "../routes";
import { RequestPostBasic } from "./section/basic.component";

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: Request) => void;
}

/**
 * Add or update a request
 */
export function RequestPost({
  style, cancel, formMode, entityId, onAfterSave,
}: Props) {

  const { t } = useTranslation('main');
  const [status, setStatus] = useState<any>();

  const onBeforeSave = (mode: FormMode, form: Request) => {
    form.status=(status as any)?.value;
    if(!form.assignedAt)
    form.assignedAt=null

    if(!form.finalizedAt)
    form.finalizedAt=null
    console.log('Final Data:',form)
  }

  const processData = (request: any): any => { 
    request.status={value:request.status,label:request.status};
    setStatus(request.status);
    return request
  }

    /**
   * Custom Header
   */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Title } title={ t("entities.request") } />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Controls } />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={ FormHeaderType.Title } title={ t("entities.request") } />
    )
  );

  /**
   * Render
   */
  return (
    <Form<Request>
      endpoint={ Endpoint.REQUEST }
      returnLink={ RequestRoute.List }
      cancel={ cancel }
      formMode={ formMode }
      entityId={ entityId }
      onAfterSave={ onAfterSave }
      onBeforeSave={onBeforeSave}
      processData={processData}
      className={ style === FormStyle.Containerized && "ae-content-w" }
    >
      { style === FormStyle.Containerized && header() }
      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Title } title={ t("entities.request") } /> }
        <FormSections>
          <RequestPostBasic onActionSelect = {setStatus}/>
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Controls } /> }
      </SectionsWrapper>
    </Form>
  );
}
