import { IEntityBase, IEntityCreatedBy, IEntityTimestamp } from "../interfaces/entity.interface";
import { Fare } from "./fare.entity";

export enum Day {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export interface FareAssignment extends IEntityBase, IEntityTimestamp, IEntityCreatedBy {
  [key: string]: any;
  fare?: Fare | string;
  day: Day;
  hour: number;
}

