import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormUpload } from "../../../../components/form/input/upload.component";
import { FormSection } from "../../../../components/form/section.component";


export function DriverPostLicense() {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  
  return (
    <FormSection
      title={ t("driver.licenseSection.title") }
      subtitle={ t("driver.licenseSection.subTitle") }
    >
      <div className="row">
        <FormInput
          label={ t("driver.licenseSection.licenseNumber") }
          name="licenseNumber"
          type="text"
          validation={{ required: true }}
          className="col-md-4 col-sm-12"
          tabIndex={20}
          { ...formProps }
        />
        <FormInput
          label={ t("driver.licenseSection.licenseExpiration") }
          name="licenseExpiryDate"
          type="date"
          validation={{ required: true }}
          className="col-md-4 col-sm-12"
          tabIndex={22}
          { ...formProps }
        />
      </div>
      <FormUpload
        label={ t("driver.licenseSection.licenseLabel") }
        name="newLicenseFiles"
        originalName="licenseFiles"
        description={ t("driver.licenseSection.licenseDescription") }
        validation={{ required: true }}
        tabIndex={23}
        { ...formProps }
      />
    </FormSection>
  );

}
