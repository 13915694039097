import Peer, { MediaConnection } from 'peerjs';
import { io, Socket } from "socket.io-client";
import { IceServers, IceServerResponse } from '../entities/ice.servers.entity';

export enum ACTIONS {
    CALL_ENDED = "call-ended",
    CONNECTING = "connecting",
    CONNECTED = "call-connected",
    INTERNET_DOWN = "internet-down",
    NO_EMERGENCY = "no-emergency",
    REQUIRED = 'required',
    SAVE_RECORDING = 'save-recording',
    SAVED = "saved",
    BUFFER_FAIL = "buffer-fail",
    ERROR_SAVE = "error-save",
    PLAY = "play"
}

enum EVENTS {
    EMERGENCY = "emergency",
    ADMIN_CONNECTION = "admin-connection",
    PEER_LOST = "peer-lost",
    RECONNECT = "reconnect",
    CHECK_CONNECTION = "check-connection",
    CALL_END = "emergency-end",
}

type DISPATCH = (actions: ACTIONS) => void
export class EmergencyService {
    private USER_ID: string;
    private DISPATCH: DISPATCH;
    private PEER: Peer | undefined = undefined;
    private PEER_ID: string | undefined = undefined;
    private SOCKET: Socket;
    private MEDIA_STREAM: MediaStream | undefined = undefined;
    private MEDIA_CONNECTION: MediaConnection | undefined = undefined;
    private REMOTE_STREAM: MediaStream | undefined = undefined;
    private ICE_SERVERS: IceServers;
    private RECONNECT: boolean = false;
    private RTC_URL: string;
    public WITH_STREAM: boolean = false;

    constructor(userID: string, dispatch: DISPATCH, IceServers: IceServers) {
        this.USER_ID = userID;
        this.DISPATCH = dispatch;
        this.ICE_SERVERS = IceServers;
        this.RTC_URL = process.env.REACT_APP_PEER_HOST as string;
        this.SOCKET = io(this.RTC_URL);
        
        this.SOCKET.on(EVENTS.PEER_LOST, ()=>{
            this.DISPATCH(ACTIONS.CONNECTING);
        });
        this.SOCKET.on(EVENTS.RECONNECT, async (remotePeer)=>{
            if (this.PEER) {
                const stream = await this.getMediaStream();
                this.MEDIA_CONNECTION = this.PEER.call(remotePeer, stream);
                this.MEDIA_CONNECTION.on("stream", (remoteStream)=>{
                    this.REMOTE_STREAM = remoteStream;
                    console.log("SENDING CONNECT FROM SOCKET");
                    this.DISPATCH(ACTIONS.CONNECTED);
                })
                this.DISPATCH(ACTIONS.CONNECTING);
            }
        });
        this.SOCKET.on(EVENTS.CALL_END, () => {
            this.REMOTE_STREAM = undefined;
            this.endCall(false);
        });
        this.AutoPeer();
    }
    private peerConfigs() {
        const config = {
            debug: 0,
            secure: false,
            host: this.RTC_URL,
            port: 3002,
            iceServers: this.ICE_SERVERS,
            path: '/peerjs/'
        };
        if (this.RTC_URL.includes("http://")) {
            config.secure = false;
            config.host = this.RTC_URL.replace("http://", "").replace(":3002","");
            config.port = 3002;
            config.debug = 3;
        }
        else {
            config.secure = true;
            config.host = this.RTC_URL.replace("https://", "");
            config.port = 443;
            config.debug = 0;
        }
        return config;
    }
    private sendEmergencyOrReconnect(peerID: string) {
        this.PEER_ID = peerID;
        if (this.RECONNECT) {
            this.SOCKET.emit(EVENTS.RECONNECT, { userID: this.USER_ID, type: "admin", peerID: peerID });
        }
        else {
            this.SOCKET.emit(EVENTS.ADMIN_CONNECTION, { userID: this.USER_ID, peerID: peerID });
        }
        this.DISPATCH(ACTIONS.CONNECTING);
    }
    private AutoPeer() {
        const config = this.peerConfigs();
        this.SOCKET.on('connect', () => {
            console.log("SOCKET CONNECTED!!!!");
            if (this.PEER) {
                if (this.PEER.disconnected) this.PEER.reconnect();
                console.log("PEER RECONNECTING = ",this.PEER.disconnected);
                this.sendEmergencyOrReconnect(this.PEER.id);
            }
            else {
                this.PEER = new Peer(undefined, config);
                this.PEER.on("open", (id) => {
                    console.log("PEER OPENED = ",id);
                    this.sendEmergencyOrReconnect(id);
                });
                this.PEER.on("error", (error)=>{
                    console.log("PEER ERROR = ",error.type);
                    switch(error.type) {
                        case 'disconnected':
                            this.DISPATCH(ACTIONS.INTERNET_DOWN);
                            break;
                        case 'network':
                            this.DISPATCH(ACTIONS.INTERNET_DOWN);
                            break;
                        case 'peer-unavailable':
                            this.REMOTE_STREAM = undefined;
                            this.DISPATCH(ACTIONS.CALL_ENDED);
                            break;
                        case 'server-error':
                            this.REMOTE_STREAM = undefined;
                            this.DISPATCH(ACTIONS.CALL_ENDED);
                            break;
                        case 'unavailable-id':
                            this.PEER = new Peer(undefined, config);
                            this.PEER.on('open', (id)=>{
                                this.PEER_ID = id;
                                this.sendEmergencyOrReconnect(id);
                            });
                            break;
                    }
                });
                this.PEER.on("call", async (mediaConnection) => {
                    this.MEDIA_CONNECTION = mediaConnection;
                    const stream = await this.getMediaStream();
                    this.MEDIA_CONNECTION.answer(stream);
                    this.MEDIA_CONNECTION.on("stream", (remoteStream)=>{
                        this.REMOTE_STREAM = remoteStream;
                        console.log("SENDING CONNECT FROM PEER");
                        this.DISPATCH(ACTIONS.CONNECTED);
                    });
                });
            }
        });
    }
    public getRemoteStream(): MediaStream | undefined {
        return this.REMOTE_STREAM;
    }
    private async getMediaStream(): Promise<MediaStream> {
        if (this.MEDIA_STREAM) return this.MEDIA_STREAM;
        else {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: false,
            });

            if (this.WITH_STREAM) {
                stream.getVideoTracks().forEach((track) => {
                    track.stop();
                    track.enabled = false;
                });
    
                stream.getAudioTracks().forEach((track) => {
                    track.enabled = false;
                });
            }

            this.MEDIA_STREAM = stream;
            return stream;
        }
    }
    public async endCall(send: boolean) {
        if (send) this.SOCKET.emit(EVENTS.CALL_END, { userID: this.USER_ID });
        this.MEDIA_CONNECTION?.close();
        this.PEER?.destroy();
        this.PEER_ID = undefined;
        this.PEER = undefined;
        if (this.MEDIA_STREAM) {
            this.MEDIA_STREAM.getTracks().forEach((track) => {
                track.enabled = false;
                track.stop();
            });
        }
        this.MEDIA_STREAM = undefined;
        this.DISPATCH(ACTIONS.CALL_ENDED);
        this.SOCKET.close();
    }
}
