import { ModuleName } from "../../enums/core.enum";
import { Module } from "../../interfaces/core.interface";
import { UserAcl, UserAclCode } from "./acl";
import { UserRouteMap } from './routes';

export const UserModule = {
  name: ModuleName.User,
  acl: UserAcl,
  routes: UserRouteMap,
} as Module<UserAclCode>;
