import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

interface ItemsProps {
  className?: any;
}

export function ViewContentItems({ className, children }: PropsWithChildren<ItemsProps>) {
  return (
    <div className={ classNames(className) }>
      <div className="ecommerce-customer-sub-info">
        { children }
      </div>
    </div>
  )
}

interface ItemProps {
  title: string;
  className?: any; 
}

export function ViewContentItem({ title, className, children }: PropsWithChildren<ItemProps>) {
  
  return (
    <div className={ classNames('ecc-sub-info-row', className) } style={{ whiteSpace: 'pre-line' }}>
      <div className="sub-info-label">{ title }</div>
      <div className="sub-info-value">{ children }</div>
    </div>
  );
}
