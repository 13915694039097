import classNames from 'classnames';
import React, { PropsWithChildren, useContext } from 'react';
import { FormPropsContext } from './form.component';

/* The props that the FormSections component expects to be passed to it */
interface Props {
    className?: any;
}

/* The FormSections component */
export function FormSections({
    children, className
}: PropsWithChildren<Props>) {
    /* The useContext hook provides the FormPropsContext to access the loading state of the form */
    const formContext = useContext(FormPropsContext);
    const { loading } = formContext;

    return (
        <>
            {/* If not loading, then show the form sections */}
            { !loading && (
                <div className={ classNames("element-box", className) }>
                    { children }
                </div>
            ) }
        </>
    );
}

/* The props that the SectionsWrapper expects to be provided to it */
/* className (optional): Needed for custom styling classes */
/* childClassName (optional): Needed for custom styling classes for the child wrapper */
interface Props {
    className?: any;
    childClassName?: any;
}

export function SectionsWrapper({
    children, className, childClassName
}: PropsWithChildren<Props>) {
    return(
        /* The primary div wrapper */
        <div className={ classNames("col-lg-6", className) }>

            {/* The children wrapper */}
            <div className={ classNames("element-wrapper", childClassName) }>
                { children }
            </div>
        </div>
    );
}