import React, { useEffect, useState } from "react"; 
import { Booking } from "../../../entities/booking.entity";
import { Currency } from "../../../entities/currency.entity";
import { Settings } from "../../../entities/settings.entity";
import { TripStatus } from "../../../entities/trip.entity";
import { Zone } from "../../../entities/zone.entity";
import { SettingsService } from "../../../services/settings.service";

export interface Activity {
  // route: string
  type: string,
  status:TripStatus,
  driverId:string,
  plate:string,
  tripCode:string,
  totalFare:string,
  // estimate: string
  timestamp: string,
  booking?:Booking,
  zone:string|Zone
}

interface Props {
  title: string
  activities?: Array<Activity> 
  color: string
  onclick: any
}

export function DashboardTripActivity(props: Props) {
  const [symbol, setSymbol] = useState<string | null>('')

  useEffect(()=>{
    const settings: Settings | undefined = SettingsService.getSetting();
    const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;
    setSymbol(currency)
    
  },[])

  const toSubUnit = (value: number, generalCurrency:Currency): string => {
   
    const settings: Settings | undefined = SettingsService.getSetting();
    // const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;
    const unit = generalCurrency ? (generalCurrency as Currency).subunits : 100;
    return `${generalCurrency.symbol} ${value / unit}`;
  }

  const onClick = (tripCode: string) => {
    // console.log("trip activ",props.activities);
    props.onclick(tripCode);
  }
  const dateFormate = (date: any) => {
    const settings: Settings | undefined = SettingsService.getSetting(); 

    let d:any = new Date(date)
    return d.toLocaleString(undefined, { timeZone: (settings as Settings)?.zone?.generalTimezone });
    
  }

  return (
    <div className="element-wrapper">
      <div className="element-box">
        <h6 className="element-header">
          <span className={ `badge badge-${ props.color }` }>{ props?.activities?.length }</span> &nbsp;
          { props.title } 
        </h6>
        <table className="table table-clean">
          <tbody>
          { props?.activities?.map(activity => (
           
            <tr  onClick={() => onClick(activity.tripCode)} className = "hovering">
            <td>
              <div className="value"><span className="badge badge-success">{`${activity?.plate}`}</span></div>
              <div> <span className="badge badge-primary">{activity.tripCode}</span></div>
              <span className="sub-value">{activity.type}</span>
            </td>
            <td className="text-right">
              <div className="value">{toSubUnit(parseInt(activity.totalFare),(activity.zone as Zone).generalCurrency)}</div>
              {/* <div className="value">{symbol}{activity.tripCode}</div> */}
              <span className="sub-value">{dateFormate(activity.timestamp)}</span>
            </td>
          </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
