import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../components/form/form.component";
import { FormHeader } from "../../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../../components/form/sections.component";
import { CorporateAccount } from "../../../../entities/corporate-account.entity";
import { User } from "../../../../entities/user.entity";
import { FormMode } from "../../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../../enums/form.enum";
import { Api, Endpoint } from '../../../../services/api.service';
import { ValidationError } from "../../../../types/error.interface";
import { CorporateBookingAclCode } from "../../acl";
import { CorporateBookingRoute } from "../../routes";
import { CorporateAccountPostPersonal  } from "./section/personal.component";

interface Props {
  style: FormStyle;
  cancel: Function;
  formMode: FormMode;
  entityId?: string;
  onAfterSave?: (entity: CorporateAccount) => void;
}
interface id {
  id: string | undefined;
  picId: string | undefined;
  fileType: 'logo';
}
/**
 * Add or update a user
 */

export const urlShow = React.createContext<boolean>(false);
export function CorporateBookingPost({
  style, cancel, formMode, entityId,onAfterSave,
}: Props) {

  const { t } = useTranslation('main');
  const [value, setValue] = useState('')
  const [message,setMessage] = useState("");
  const {reset} = useForm();
  const [corporateAccount, setCorporateAccount] = useState<CorporateAccount>();
  const [pic, setPic] = useState<boolean>(false);
  
  const onBeforeSave = async (mode: FormMode, form: CorporateAccount) => {
    setMessage("")
    if(form.contactPhone === null){
      throw new ValidationError({
        field: form.contactPhone,
        message: `phone number should not be empty!`,
      });
    }
    form.contactPhone = value

  }

  const processData = (corporateAccountForm: CorporateAccount): CorporateAccount => { 
    console.log("This is Phone:",corporateAccountForm)
    setValue(corporateAccountForm.contactPhone)
    setCorporateAccount(corporateAccountForm);
    return corporateAccountForm;
  }

  const FormEditingOnclick = async (e: any) => {
    setPic(true);
    console.log("corporateAccount img:",corporateAccount)
    if (corporateAccount?.logoFile?.id) {
      await Api.post<any, id>(Endpoint.CORPORATE_FILE_DELETE, {
        id: corporateAccount.id,
        picId: corporateAccount.logoFile?.id,
        fileType: 'logo',
      });
    }
  };
  /**
   * Custom Header depending upon the type of form style
   * In case of containerized style, we're seperating headers in the seperate SectionsWrapper tags,
   * Otherwise if the form is supposed to be shown on full screen then don't use custom header, but rather
   * Use it seperately for each FormSections tag as shown below
   */
   const header = () =>
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader
            type={FormHeaderType.Title}
            title={t("entities.corporate_account")}
          />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Controls} />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={FormHeaderType.Title} title={t("entities.corporate_account")} />
    );


  return (
    <Form<CorporateAccount>
      endpoint={Endpoint.CORPORATE_ACCOUNT}
      onBeforeSave={onBeforeSave}
      processData={processData}
      returnLink={CorporateBookingRoute.Account}
      fileEndpoint={Endpoint.CORPORATE_ACCOUNT_LOGO}
      onAfterSave={onAfterSave}
      cancel={cancel}
      formMode={formMode}
      entityId={entityId}
      className={style === FormStyle.Containerized && "ae-content-w"}
      formFiles={[ 
        { required: false, count: 1, originalName: 'logoFile', name: "newLogoFile", desc: t("mainMenu.corporateBooking.logoLabel") },
      ]}
    >
      {/* Using the custom header in case of using containerized form */}
      { style === FormStyle.Containerized && header()}

      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
        {/* If not containerized, then use it normally */}
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Controls} title={t("mainMenu.corporateBooking.account")} />}
        {message!==""?<div className="alert alert-success btn-block" style={{ background: '#b0e7b0' }} role="alert">
                        <h6>{message}</h6>
                    </div>:""}
        <FormSections>
          
        <urlShow.Provider value={pic}>
          <CorporateAccountPostPersonal corporateAccount={corporateAccount} FormEditingOnclick={FormEditingOnclick} onChange = {setValue} value = {value}/>
          </urlShow.Provider>
        </FormSections>
      </SectionsWrapper>

    </Form>
  );
}
