import { Acl } from "../../interfaces/core.interface";

export enum DriverAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Approve = 'approve',
  Block = 'block',
  Analytics = 'analytics',
  CreateRequiresConsent = 'create-requires-consent',
  UpdateRequiresConsent = 'update-requires-consent',
  Inspect = 'inspect',
  ManageInspection = 'manage-inspection',
  Audit = 'audit',
  Deposit = "deposit",
  ViewBalance = "view-balance",
  Monitor = "monitor",
  Import = 'import'
}

export const DriverAcl = [
  {
    code: DriverAclCode.ListAll,
    title: 'driver.listAll.title',
    description: 'driver.listAll.subTitle',
  },
  {
    code: DriverAclCode.ListOwn,
    title: 'driver.listOwn.title',
    description: 'driver.listOwn.subTitle',
  },
  {
    code: DriverAclCode.Create,
    title: 'driver.create.title',
    description: 'driver.create.subTitle',
  },
  {
    code: DriverAclCode.Update,
    title: 'driver.update.title',
    description: 'driver.update.subTitle',
  },
  {
    code: DriverAclCode.Delete,
    title: 'driver.delete.title',
    description: 'driver.delete.subTitle',
  },
  {
    code: DriverAclCode.Approve,
    title: 'driver.approve.title',
    description: 'driver.approve.subTitle',
  },
  {
    code: DriverAclCode.Block,
    title: 'driver.block.title',
    description: 'driver.block.subTitle',
  },
  {
    code: DriverAclCode.Analytics,
    title: 'driver.analytics.title',
    description: 'driver.analytics.subTitle',
  },
  {
    code: DriverAclCode.CreateRequiresConsent,
    title: 'driver.createRequiresConsent.title',
    description: 'driver.createRequiresConsent.subTitle',
  },
  {
    code: DriverAclCode.UpdateRequiresConsent,
    title: 'driver.updateRequiresConsent.title',
    description: 'driver.updateRequiresConsent.subTitle',
  },
  {
    code: DriverAclCode.Inspect,
    title: 'driver.inspect.title',
    description: 'driver.inspect.subTitle',
  },
  {
    code: DriverAclCode.ManageInspection,
    title: 'driver.manageInspection.title',
    description: 'driver.manageInspection.subTitle',
  },
  {
    code: DriverAclCode.Audit,
    title: 'driver.audit.title',
    description: 'driver.audit.subTitle',
  },
  {
    code: DriverAclCode.Import,
    title: 'driver.import.title',
    description: 'driver.import.subTitle',
  },

  {
    code: DriverAclCode.Deposit,
    title: 'driver.deposit.title',
    description: 'driver.deposit.subTitle',
  }, 
  {
    code: DriverAclCode.ViewBalance,
    title: 'driver.balance.title',
    description: 'driver.balance.subTitle',
  },
  {
    code: DriverAclCode.Monitor,
    title: 'driver.monitor.title',
    description: 'driver.monitor.subTitle',
  },
] as Acl<DriverAclCode>;
