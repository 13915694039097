import { RouteProps } from "react-router-dom";
import { ApiLogsList } from "./list/list.component";

export enum ApiLogsRoute {
  List = '/admin/apilogs',
}

export const ApiLogsRouteMap = [
  {
    path: ApiLogsRoute.List,
    component: ApiLogsList,
  },
] as RouteProps[];
