import { RouteProps } from "react-router-dom";
import { FeatureList } from "./list/list.component";
import { FeaturePost } from "./post/post.component";

export enum FeatureRoute {
  List = '/feature/list',
  Post = '/feature/post',
}

export const FeatureRouteMap = [
  {
    path: FeatureRoute.List,
    component: FeatureList,
  },
  {
    path: FeatureRoute.Post,
    component: FeaturePost,
  }
] as RouteProps[];
