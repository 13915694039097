import { RouteProps } from "react-router-dom";
import { RequestList } from "./list/list.component";
import { RequestPost } from "./post/post.component";

export enum RequestRoute {
  List = '/request/list',
  Post = '/request/post',
}

export const RequestRouteMap = [
  {
    path: RequestRoute.List,
    component: RequestList,
  },
  {
    path: RequestRoute.Post,
    component: RequestPost,
  },
] as RouteProps[];
