import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Article } from "../../../entities/article.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';
import { checkAssignment } from "../../../util/roleAssignment";
import { ArticlePost } from "../post/post.component";
import { SelectedItemContent } from "./content.component";


const groupFilters: GroupFilter[] = [
  { title: 'All', icon: 'phone-21' },
];

export function ArticleList(props: any) {
  const { t } = useTranslation('list');
  const { y: pageYOffset } = useWindowScroll();
  const [count, setCount] = useState<number>(1);
  const [search, setSearch] = useState<string>('')
  const [allCount, setAllCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [articles, setArticles] = useState<Article[] | undefined>(undefined);
  const [selectedArticle, setSelectedArticle] = useState<Article>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [size, setSize] = useState<number>()
  const [addButtonDisplay,setAddButtonDisplay] = useState<boolean>(false)

  useEffect(()=>{
    // check if given module and action is assigned to the role or not return boolean value
    setAddButtonDisplay(checkAssignment("article","list-all") || checkAssignment("fare","list-own") )
  },[])


  useEffect(() => {
    setSelectedArticle(props.location.state)
  }, [props.location.state])
  const refresh = useCallback(async () => {
    const group = activeGroupFilter?.title;
    const { items, totalItems } = await Api.get<IPaginationResponse<Article>, IPaginationOptions>(Endpoint.ARTICLE_LIST, { page: 0, limit, group, search });

    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    function sortFunction(a: any, b: any) {
      var dateA = new Date(a.date).getTime();
      var dateB = new Date(b.date).getTime();
      return dateA > dateB ? 1 : -1;
    };
    if (items) {
      if (props.location.state != null) {
        items.sort(sortFunction)
        setArticles(items.reverse());
        setSelectedArticle(props.location.state);
      }
      else {
        items.sort(sortFunction)
        setArticles(items.reverse());
      }

    }

    setAllCount(totalItems)

    if (items.length > 0 && !selectedArticle) {
      setSelectedArticle(items[0]);
    }
  }, [search, limit]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const fetchMoreData = async () => {
    setLimit(limit + 10);
  };

  const deleteArticle = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.ARTICLE, { id: selectedArticle?.id });
    setSelectedArticle(undefined);
    refresh();
  }

  const toListItems = (article?: Article): ListItem<Article> | undefined => {
    if (!article) {
      return;
    }

    return {
      id: article.id as string,
      title: article.title,
      status: ListItemStatus.Green,
      ref: article
    }
  }

  const addArticle = () => {
    setFormMode(FormMode.Adding);
  }

  const editArticle = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (articles) {
      const currentArticleIndex = articles.findIndex(article => !!selectedArticle && article.id === selectedArticle.id);
      if (currentArticleIndex + 1 < articles.length && currentArticleIndex !== -1) {
        setSelectedArticle(articles[currentArticleIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (articles) {
      const currentArticleIndex = articles.findIndex(article => !!selectedArticle && article.id === selectedArticle.id);
      if (currentArticleIndex - 1 > -1 && currentArticleIndex !== -1) {
        setSelectedArticle(articles[currentArticleIndex - 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedArticle(undefined);
    setActiveGroupFilter(target);
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }
  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: 'All', icon: 'phone-21', count: allCount },
                ]}
                activeGroupFilter={activeGroupFilter}
                onGroupSelect={selectGroup}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={articles?.map(toListItems) as ListItem<Article>[]}
                activeItem={toListItems(selectedArticle)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setArticles(undefined); refresh(); }}
                onClick={(item: ListItem<Article>) => { setSelectedArticle(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                articles !== undefined ? (
                  selectedArticle ? (
                    <View
                      componentName={"article"}
                      showAdd={true}
                      showEdit={true}
                      showAudit={true}
                      showDelete={true}
                      showNext={true}
                      showPrev={true}
                      onAdd={addArticle}
                      onEdit={editArticle}
                      onDelete={deleteArticle}
                      onNext={onNext}
                      onPrev={onPrev}
                      onAudit={() => checkAudit(selectedArticle.id)}
                    >
                      <SelectedItemContent
                        selectedArticle={selectedArticle}
                      />
                    </View>
                  ) :  addButtonDisplay &&(
                  <div className="mx-auto py-5 text-center">
                      <button onClick={addArticle} className="btn btn-primary text-bold">{t('article.addArticle')}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t('article.wait')}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedArticle ? (
                  <ArticlePost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedArticle.id}
                    onAfterSave={() => refresh()}
                  />
                ) : (
                  <ArticlePost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    onAfterSave={() => refresh()}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
            groupFilters={[
              { title: 'All', icon: 'phone-21', count: allCount },
            ]}
            activeGroupFilter={activeGroupFilter}
            onGroupSelect={selectGroup}
          />

          <ListItems
            items={articles?.map(toListItems) as ListItem<Article>[]}
            activeItem={toListItems(selectedArticle)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setArticles(undefined); refresh(); }}
            onClick={(item: ListItem<Article>) => { setSelectedArticle(item.ref); }}
          />

          {formMode === undefined ? (
            articles !== undefined ? (
              selectedArticle ? (
                <View
                  componentName={"article"}
                  showAdd={true}
                  showEdit={true}
                  showAudit={true}
                  showDelete={true}
                  showNext={true}
                  showPrev={true}
                  onAdd={addArticle}
                  onEdit={editArticle}
                  onDelete={deleteArticle}
                  onNext={onNext}
                  onPrev={onPrev}
                  onAudit={() => checkAudit(selectedArticle.id)}
                >
                  <SelectedItemContent
                    selectedArticle={selectedArticle}
                  />
                </View>
              ) :addButtonDisplay && (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addArticle} className="btn btn-primary text-bold">{t('article.addArticle')}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t('article.wait')}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedArticle ? (
              <ArticlePost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedArticle.id}
                onAfterSave={() => refresh()}
              />
            ) : (
              <ArticlePost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh()}
              />
            )
          )}
        </List>
      }
    </div>
  );
}
