import { RouteProps } from "react-router-dom";
// import { DispatchDashboard } from "./dashboard.component";
import { Dispatch } from "./dispatch";

export enum DispatchRoute {
  Dashboard = '/dispatch/dashboard',
}

export const DispatchRouteMap = [
  {
    path: DispatchRoute.Dashboard,
    component: Dispatch,
  }
] as RouteProps[];
