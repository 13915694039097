import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ReportTable } from '../../../components/table/report.component';
import { Endpoint } from '../../../services/api.service';

export function ZoneReport() {

    // column data for georeference report
    const columnData = ['DriverID','VehiclePlate','Zone','TripCode','BookingCode','EnterTime','ExitTime','TimeDuration']



    return (
        <>
            <ReportTable
                colData={columnData}
                translationColumnName={'georeference'}
                endpoint={Endpoint.GEO_REFERENCE}
                disableExportCsvDisplay={true}
                disableSearchForColumns={['EnterTime', 'ExitTime', 'TimeDuration']}
            />
        </>
    )
}
