import { RouteProps } from "react-router-dom";
import { GroupList } from "./list/list.component";
import { GroupPost } from "./post/post.component";

export enum GroupRoute {
  List = '/group/list',
  Post = '/group/post',
}

export const GroupRouteMap = [
  {
    path: GroupRoute.List,
    component: GroupList,
  },
  {
    path: GroupRoute.Post,
    component: GroupPost,
  },
] as RouteProps[];
