import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "../../../../components/form/input/select.component";
import { FormSection } from "../../../../components/form/section.component";
import { Feature } from "../../../../entities/feature.entity";
import { Settings } from "../../../../entities/settings.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../services/api.service";
interface props{ 
  settings:Settings|undefined

}
export function VehiclePostFeature({settings}:props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const [features, setFeatures] = useState<Feature[]>([]);
  
  useEffect(() => { init(); }, [])

  const init = async () => {
    const featureList = await Api.get<IPaginationResponse<Feature>, {type:'vehicle'}>(Endpoint.FEATURE_LIST,{type:'vehicle'});
    setFeatures(featureList.items);
  }

  return (
    <FormSection
      title={ t("vehicle.featureSection.title") }
      subtitle={ t("vehicle.featureSection.subTitle") }
    >
      <div className="row">
        <FormSelect<Feature>
          label={ t("vehicle.featureSection.featuresLabel") }
          name="features"
          className="col-sm-12"
          showLabel={ true }
          multiple={ true }
          validation={{required:settings?.vehicleRequirementFeature?true:false}}
          items={ features }
          labelField="title"
          onChange={ () => {} }
          tabIndex={18}
          { ...formProps }
        />
      </div>
    </FormSection>
  );

}
