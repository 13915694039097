import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ContentWrapper,
  ViewContent
} from "../../../components/view/content.component";
import {
  ViewContentItem,
  ViewContentItems
} from "../../../components/view/item.component";
import { Currency } from "../../../entities/currency.entity";
import { Service } from "../../../entities/service.entity";
import { Settings } from "../../../entities/settings.entity";
import { Api, Endpoint } from "../../../services/api.service";
import { SettingsService } from "../../../services/settings.service";
import { ProfilePicSize } from "./list.component";

interface Props {
  selectedService: Service;
  onRefresh: () => void;
  setSelectedService?: (service: undefined | Service) => void;
  getProfilePic: (driver: Service, size: ProfilePicSize) => string | undefined;
  errorMessage: string | undefined
}

interface ServiceStatisticsResponse {
  tripCount: number;
  revenue: number;
  distance: number;
  totalTime: number;
  tripPercentage: number;
  revenuePercentage: number;
  distancePercentage: number;
  prevTripCount: number;
  prevRevenue: number;
  prevDistance: number;
  prevTotalTime: number;
  timePercentage: number;
}

interface Time {
  time: string;
  id: string | undefined;
}

export function SelectedItemContent({
  selectedService,
  onRefresh,
  setSelectedService,
  getProfilePic,
  errorMessage
}: Props) {
  const { t } = useTranslation(["forms", "dashboard"]);
  const [symbol, setSymbol] = useState<string | null>("");
  const [time, setTime] = useState<string>("today");
  const [subUnit, setSubUnit] = useState<number>(100);
  const [state, setState] = useState<ServiceStatisticsResponse>(undefined!);

  useEffect(() => {
    const settings: Settings | undefined = SettingsService.getSetting();
    const currency = settings?.generalCurrency
      ? (settings?.generalCurrency as Currency).symbol
      : null;
    const unit = settings?.generalCurrency
      ? (settings?.generalCurrency as Currency).subunits
      : 100;
    setSymbol(currency);
    setSubUnit(unit);
  }, []);

  const onclickRecover = async (selectedService: Service) => {
    console.log("Recover This Service:", selectedService);
    const response = await Api.post<Service, { id: string }>(
      Endpoint.SERVICE_RECOVER,
      { id: selectedService.id as string }
    );
    console.log("Recover This Service Response:", response);
    if (onRefresh && setSelectedService) {
      setSelectedService(undefined);
      onRefresh();
    }
  };

  const stats = [
    {
      title: "Rides",
      value: "0",
      percentage: "0",
      trend: "neutral",
    },
    {
      title: "Earnings",
      value: "0",
      percentage: "0",
      trend: "neutral",
    },
    {
      title: "Earnings per Ride",
      value: "0",
      percentage: "0",
      trend: "neutral",
    },
    {
      title: "Shift Time",
      value: "0 Hrs",
      percentage: "0",
      trend: "neutral",
    },
  ];

  const changeTime = (e: any) => {
    setTime(e.target.value);
  };

  return (
    <>
      {errorMessage && <div className="m-b row">
        <div className=" col-md-6">
          <h6
            style={{
              backgroundColor: "#eb1f1fd9",
              padding: "10px",
              color: "white",
              fontSize: "10",
            }}
          >
            {errorMessage}
          </h6>
        </div>
      </div>}
      <ContentWrapper className="row">
        <ViewContent className="col-md-6" title={selectedService.title}
          imgSrc={getProfilePic(selectedService, ProfilePicSize.Single)} >
          <ViewContentItems>
            {selectedService.deletedTime ? (
              <div className="alert alert-success borderless">
                <h5 className="alert-heading">Service Deleted</h5>
                <p>Do you want to recover this service data?</p>
                <div className="alert-btn">
                  <a
                    className="btn btn-white-gold"
                    href={"#href"}
                    onClick={() => onclickRecover(selectedService)}
                  >
                    <i className="os-icon os-icon-ui-92"></i>
                    <span>{"Recover Service"}</span>
                  </a>
                </div>
              </div>
            ) : null}
            <ViewContentItem title={t("forms:service.title")}>
              {selectedService.title}
            </ViewContentItem>

            <ViewContentItem title={t("forms:service.default")}>
              {selectedService.isDefault ? "true" : "false"}
            </ViewContentItem>
          </ViewContentItems>
        </ViewContent>
      </ContentWrapper>
    </>
  );
}
