import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Day, FareAssignment } from '../../../entities/fare-assignment.entity';
import { Fare } from '../../../entities/fare.entity';
import { Service } from '../../../entities/service.entity';
import { Zone } from '../../../entities/zone.entity';
import { IPaginationOptions, IPaginationResponse } from '../../../interfaces/paginate.interface';
import { Api, Endpoint } from '../../../services/api.service';
import { to12HrFormat } from '../../../util/date.util';
import { capitalize } from '../../../util/string.util';

interface HeaderItem {
  name: string,
  value: Day | number,
}

interface FareListOptions {
  zone: Zone | string;
  service: Service | string;
}

interface Props {
  selectedZone: Zone | undefined;
  selectedService: Service | undefined;
  selectedFare: Fare | undefined;
}

/**
 * Displays fares in a calenderisque table with days as columns
 * and hours as rows.
 * 
 * Cells are colors to match fare colors.
 * 
 * Multiple cells can be selected and actions applied to them.
 */
export function FareDashboardTable({ selectedZone, selectedService, selectedFare }: Props) {

  const { t } = useTranslation('list');

  /**
   * Generate row left-side headers (hours)
   */
  const hours: HeaderItem[] = genHours('am').concat(genHours('pm'));
  /**
   * Generate column top headers (week days)
   */
  const days: HeaderItem[] = [
    { name: 'Monday', value: Day.Monday },
    { name: 'Tuesday', value: Day.Tuesday },
    { name: 'Wednesday', value: Day.Wednesday },
    { name: 'Thursday', value: Day.Thursday },
    { name: 'Friday', value: Day.Friday },
    { name: 'Saturday', value: Day.Saturday },
    { name: 'Sunday', value: Day.Sunday },
  ];

  /**
   * List of fare assignments
   */
  const [fareAssignments, setFareAssignments] = useState<FareAssignment[]>();

  /**
   * Selected fare assignments
   * Multiple assignments can be selected and actions applied to them
   */
  const [selectedFareAssignments, setSelectedFareAssignments] = useState<FareAssignment[]>();

  /**
   * Tracks when fare assignments are being loaded
   */
  const [, setLoadingAssignments] = useState<boolean>(false);

  /**
   * Tracks when fare assignments are being applied
   */
  const [applyingAssignments, setApplyingAssignments] = useState<boolean>(false);

  /**
   * Load fare assignments
   */
  const loadFareAssignments = useCallback(async () => {
    // Reset fare assignment list if zone or service not selected
    if (!selectedZone || !selectedZone.id || !selectedService || !selectedService.id) {
      setFareAssignments(undefined);
      return;
    }

    setLoadingAssignments(true);
    const fareAssignments = await Api.get<IPaginationResponse<FareAssignment>, IPaginationOptions & FareListOptions>(Endpoint.FARE_LIST_ASSIGNMENTS,
      { zone: selectedZone.id, service: selectedService.id });
    setFareAssignments(fareAssignments.items);
    setLoadingAssignments(false);
  }, [selectedZone, selectedService]);

  /**
   * Refresh fare assignments when selected zone or service changes
   */
  useEffect(() => {
    loadFareAssignments();
  }, [loadFareAssignments, selectedZone, selectedService]);

  /**
   * Apply the currently selected fare to the selected fare assignments
   * Meaning the selected fare is applied to the selected days and their hours
   */
  const applySelectedFareAssignment = async () => {
    if (!selectedFareAssignments || selectedFareAssignments.length === 0 || !selectedFare) {
      return;
    }

    setApplyingAssignments(true);
    await Api.post<undefined, { assignments: FareAssignment[] }>(Endpoint.FARE_ASSIGN, {
      assignments: selectedFareAssignments.map(selectedFareAssignment => ({
        fare: selectedFare.id,
        day: selectedFareAssignment.day,
        hour: selectedFareAssignment.hour,
        service: selectedService?.id,
        zone: selectedZone?.id,
      }))
    });
    
    setApplyingAssignments(false);
    setSelectedFareAssignments([])
    loadFareAssignments();
  }

  const getFareAssignment = (day: Day, hour: number): FareAssignment | undefined => {
    return fareAssignments?.find(a => a.day === day && a.hour === hour);
  }

  const getFareAssignmentColor = (day: Day, hour: number): string | undefined => {
    const fareAssignment = getFareAssignment(day, hour);
    if (fareAssignment) {
      return (fareAssignment.fare as Fare)?.color;
    }
    return;
  }

  const isFareAssignmentSelected = (day: Day, hour: number): boolean => {
    return !!selectedFareAssignments?.find(selectedFareAssignment =>
      day === selectedFareAssignment?.day && hour === selectedFareAssignment?.hour
    );
  }

  const onFareAssignmentClick = (event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>, day: Day, hour: number) => {
    if (event.shiftKey && selectedFareAssignments) {
      setSelectedFareAssignments([
        ...selectedFareAssignments,
        { day, hour },
      ]);
    } else {
      setSelectedFareAssignments([{ day, hour }]);
    }
  }

  const selectRow = (hour: number) => {
    // Find cells that are already selected
    const rowAssignments = selectedFareAssignments ? selectedFareAssignments.filter(a => a.hour === hour) : [];
    // If all cells are selected, then unselect all of them
    if (rowAssignments.length === 7) {
      setSelectedFareAssignments(selectedFareAssignments?.filter(a => a.hour !== hour));
      // Otherwise, select the unselect cells in that row
    } else {
      const newSelectedFareAssigments = selectedFareAssignments ? [...selectedFareAssignments] : [];
      for (let day of days) {
        const isCellSelected = selectedFareAssignments?.find(a => a.hour === hour && a.day === day.value);
        if (!isCellSelected) {
          newSelectedFareAssigments.push({ hour, day: day.value as Day });
        }
      }
      setSelectedFareAssignments(newSelectedFareAssigments);
    }
  }

  const selectColumn = (day: Day) => {
    // Find cells that are already selected
    const columnAssignments = selectedFareAssignments ? selectedFareAssignments.filter(a => a.day === day) : [];
    // If all cells are selected, then unselect all of them
    if (columnAssignments.length === 24) {
      setSelectedFareAssignments(selectedFareAssignments?.filter(a => a.day !== day));
      // Otherwise, select the unselect cells in that column
    } else {
      const newSelectedFareAssigments = selectedFareAssignments ? [...selectedFareAssignments] : [];
      for (let hour of hours) {
        const isCellSelected = selectedFareAssignments?.find(a => a.hour === hour.value && a.day === day);
        if (!isCellSelected) {
          newSelectedFareAssigments.push({ hour: hour.value as number, day });
        }
      }
      setSelectedFareAssignments(newSelectedFareAssigments);
    }
  }

  function genHours(suffix: 'am' | 'pm'): HeaderItem[] {
    return Array(12).fill(null).map((_, i) => ({
      name: `${i === 0 ? 12 : i}${suffix}`,
      value: suffix === 'am' ? i : i + 12,
    }));
  }

  if (!selectedZone || !selectedService) {
    return (
      <div className="element-wrapper">
        <div className="element-box">
          <h2>{t("fare.noZoneSection.title")}</h2>
          <p>{t("fare.noZoneSection.subTitle")}</p>
        </div>
      </div>
    )
  }
  return (
    <div className="fares">
      {selectedFare && selectedFareAssignments && (
        <div className="actions">
          {selectedFareAssignments.length === 1 ? (
            <span>
              {t("fare.selectedFareSection.title", {
                toTitle: selectedFare.title,
                day: capitalize(selectedFareAssignments[0].day),
                hour: to12HrFormat(selectedFareAssignments[0].hour),
                interpolation: { escapeValue: false },
              })
              }
            </span>
          ) : (
            <span>Assign fares to selected times: </span>
          )}
          <button
            className="btn btn-sm btn-warning"
            disabled={applyingAssignments}
            onClick={() => { applySelectedFareAssignment() }}>
            {!applyingAssignments ? t("fare.selectedFareSection.assignButton") : t("fare.selectedFareSection.assigningButton")}
          </button>
        </div>
      )}
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th></th>
              {days.map(day => <th key={day.name} onClick={() => selectColumn(day.value as Day)}>{day.name}</th>)}
            </tr>
          </thead>
          <tbody>
            {hours.map(hour => (
              <tr key={hour.name}>
                <th onClick={() => selectRow(hour.value as number)}>{hour.name}</th>
                {days.map(day => (
                  <td
                    key={`${day.name}-${hour.name}`}
                    style={{ backgroundColor: getFareAssignmentColor(day.value as Day, hour.value as number) }}
                    className={classNames('fare', { selected: isFareAssignmentSelected(day.value as Day, hour.value as number) })}
                    onClick={(e) => { onFareAssignmentClick(e, day.value as Day, hour.value as number); }}
                  />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}