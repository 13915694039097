import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormAddressInput } from "../../../../components/form/input/address.component";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormSection } from "../../../../components/form/section.component";

export function DriverPostPrivate() {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  
  return (
    <FormSection
      title={ t("driver.privateSection.title") }
      subtitle={ t("driver.privateSection.subTitle") }
    >
      <div className="row">
        <FormInput
          label={ t("common.email") }
          name="email"
          type="email"
          className="col-sm-6"
          validation={{ required: true }}
          tabIndex={5}
          { ...formProps }
        />
        <FormInput
          label={ t("common.phone") }
          name="phone"
          type="tel"
          className="col-sm-6"
          validation={{ required: true }}
          tabIndex={6}
          { ...formProps }
        />
      </div>
      <div className="row">
        <FormInput
          label={ t("common.dob") }
          name="dateOfBirth"
          type="date"
          className="col-sm-6"
          validation={{ required: true }}
          tabIndex={7}
          { ...formProps }
        />
        <FormAddressInput
          label={t('driver.privateSection.homeAddress')}
          name="address"
          className="col-sm-6"
          validation={{ required: false }}
          tabIndex={8}
          { ...formProps }
        />
      </div>
      <div className="row">
        <FormInput
          label={t('driver.privateSection.abn') }
          name="businessRegistration"
          type="text"
          className="col-sm-6"
          validation={{ required: true }}
          tabIndex={9}
          { ...formProps }
        />
        <FormInput
          label={ t("common.status") }
          name="status"
          type="text"
          className="col-sm-4"
          validation={{ required: true }}
          tabIndex={24}
          { ...formProps }
        />
      </div>
    </FormSection>
  );

}
