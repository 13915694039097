import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../../components/alerts/loading.component';
import { Service } from '../../../entities/service.entity';
import { IPaginationOptions, IPaginationResponse } from '../../../interfaces/paginate.interface';
import { Api, Endpoint } from '../../../services/api.service';
import { ServiceRoute } from '../../service/routes';

interface Props {
  /**
   * onChange function called when a service is selected
   */
  onChange?: (service: Service | undefined) => void,
}

/**
 * Service list displayed on the fare dashboard
 */
export function FareDashboardServices({ onChange }: Props) {

  const { t } = useTranslation('main');
  /**
   * History push to navigate
   */
  const { push: navigateTo } = useHistory();

  /**
   * List of services
   */
  const [services, setServices] = useState<Service[]>();

  /**
   * Keep track of selected service
   * Required to highlight selected service in list
   */
  const [selectedService, setSelectedService] = useState<Service>();

  /**
   * Determines if service list is loading
   */
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Load service list upon component ready
   */
  useEffect(() => {
    init();
  }, []);

  /**
   * When selected service changes, trigger onChange function
   */
  useEffect(() => {
    if (onChange) {
      onChange(selectedService);
    }
  }, [ onChange, selectedService ]);

  /**
   * Load service list upon init
   */
  async function init() {
    setLoading(true);
    const serviceList = await Api.get<IPaginationResponse<Service>, IPaginationOptions>(Endpoint.SERVICE_LIST, { limit: 100 });
    setServices(serviceList.items);
    setLoading(false);
  }

  const getEditLink = (service: Service): string => {
    return `${ServiceRoute.Post}?id=${service.id}`;
  }

  /**
   * Render
   */
  return (
    <ul className="ae-main-menu">
      <li className="header"><a href="services"><span>{ t("entities.service_plural") }</span></a></li>
      <Loading className="loading" loading={ loading } />
      { services?.map(service => (
        <li key={ service.id } className={ classNames({ active: service.id === selectedService?.id })}>
          <a
            href="#service"
            onClick={ () => { setSelectedService(service); }}
            onDoubleClick={ () => { navigateTo(getEditLink(service)); } }
          >
            <span>{ service.title }</span>
          </a>
        </li>
      ))}
    </ul>
  );
}
