import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "../../../../../components/form/input/select.component";
import { FormSection } from "../../../../../components/form/section.component";
import { Role } from "../../../../../entities/role.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../../services/api.service";

export function UserPostRoleSelect() {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const [roles, setRoles] = useState<Role[]>([]);
  
  useEffect(() => { init(); }, [])

  const init = async () => {
    const roleList = await Api.get<IPaginationResponse<Role>, IPaginationOptions>(Endpoint.USER_ROLE_LIST);
    setRoles(roleList.items);
  }
  
  return (
    <FormSection
      title={ t("user.roleSection.title") }
      subtitle={ t("user.roleSection.subTitle") }
    >
      <div className="row">
        <FormSelect<Role>
          label={ t("user.roleSection.roleSelect") }
          name="role"
          className="col-sm-12"
          showLabel={ false }
          validation={{ required: true }}
          items={ roles }
          onChange={ () => {} }
          tabIndex={7}
          { ...formProps }
        />
      </div>
    </FormSection>
  );

}
