import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Operator } from "../../../entities/operator.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../enums/form.enum";
import { Endpoint } from '../../../services/api.service';
import { ValidationError } from "../../../types/error.interface";
import { OperatorRoute } from "../routes";
import { OperatorPostBasic } from "./section/basic.component";

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: Operator) => void;
}

/**
 * Add or update a operator
 */
export function OperatorPost({
  style, cancel, formMode, entityId, onAfterSave,
}: Props) {

  const { t } = useTranslation('main');
  const [value, setValue] = useState('')

  const onBeforeSave = (mode: FormMode, form: Operator) => {
    if(value === null){
      throw new ValidationError({
        field: form.contactPhone,
        message: `phone number should not be empty!`,
      });
    }
    console.log("phone no",form)
    if(!isValidPhoneNumber(`${value}`)){
      throw new ValidationError({
        field: form.contactPhone,
        message: `phone number is incorrect !`,
      });
    }
    form.contactPhone = value
  }
  const processData = (operator: Operator): Operator => { 
    setValue(operator.contactPhone)
    return operator
  }

    /**
   * Custom Header
   */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Title } title={ t("entities.operator") } />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Controls } />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={ FormHeaderType.Title } title={ t("entities.operator") } />
    )
  );

  /**
   * Render
   */
  return (
    <Form<Operator>
      endpoint={ Endpoint.OPERATOR }
      returnLink={ OperatorRoute.List }
      cancel={ cancel }
      formMode={ formMode }
      entityId={ entityId }
      onBeforeSave={onBeforeSave}
      processData={processData}
      onAfterSave={ onAfterSave }
      className={ style === FormStyle.Containerized && "ae-content-w" }
    >
      { style === FormStyle.Containerized && header() }
      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Title } title={ t("entities.operator") } /> }
        <FormSections>
          <OperatorPostBasic onChange = {setValue} value = {value} formMode={formMode} />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Controls } /> }
      </SectionsWrapper>
    </Form>
  );
}
