import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormSelect } from "../../../../components/form/input/select.component";
import { FormTextArea } from "../../../../components/form/input/textarea.component";
import { FormSection } from "../../../../components/form/section.component";



export enum Action {
  Call = 'call',
  Message = 'message',
  Email = 'email',
}

interface Props {
  onActionSelect: any;
}

export function RequestCategoryPostBasic({ onActionSelect }: Props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  const [options, setOptions] = useState<any>([
    { value: Action.Call, label: Action.Call },
    { value: Action.Message, label: Action.Message },
    { value: Action.Email, label: Action.Email }
  ])
  const [selectedAction, setselectedAction] = useState<Action>()
  const setAction = (e: any) => {
    console.log('THIS IS MESSAGE VALUE:', e)
    setselectedAction(e.value);
    onActionSelect(e);
  }
  return (
    <FormSection
      title={t("requestCategory.basicSection.title")}
      subtitle={t("requestCategory.basicSection.subTitle")}
    >
      <div className="row">
        <div className="col-md-6">
          <FormInput
            label={t("requestCategory.basicSection.title")}
            name="title"
            type="text"
            validation={{ required: true }}
            tabIndex={1}
            autoFocus={true}
            {...formProps}
          />     
          <FormSection
            title={t("requestCategory.basicSection.action")}
          >
            <div className="row">
              {<FormSelect<any>
                label={t("requestCategory.basicSection.action")}
                name="action"
                className="col-sm-12"
                showLabel={false}
                multiple={false}
                validation={{ required: true }}
                items={options}
                valueField='value'
                labelField="label"
                onChange={(e: any) => setAction(e)}
                tabIndex={16}
                {...formProps}
              />
              }
            </div>
            <FormInput
          label={t("requestCategory.basicSection.position")}
          name="position"
          type="number"
          validation={{ required: true }}
          tabIndex={1}
          autoFocus={true}
          {...formProps}
        />  
          </FormSection>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {(selectedAction == Action.Call || selectedAction == Action.Email) ? <FormInput
            label={t("requestCategory.basicSection.target")}
            name="target"
            type="text"
            validation={{ required: false }}
            tabIndex={3}
            {...formProps}
          /> : null}
          {selectedAction == Action.Message ? <FormTextArea
            name={`message`}
            label={t("requestCategory.basicSection.message")}
            validation={{ required: false }}
            rows={5}
            {...formProps}
          /> : null}
        </div>
      </div>
    </FormSection>
  );

}
