import { RouteProps } from "react-router-dom";
import { AuthLogin } from "./login.component";
import { AuthLogout } from "./logout.component";
import { AuthForgot } from "./forgot.component";
import { PasswordRecovery } from "./password.recovery.component";

export enum AuthRoute {
  Login = "/auth/login",
  Logout = "/auth/logout",
  Forgot = "/auth/forgot",
  Password_Recovery = "/auth/passwordrecovery",
}

export const AuthRouteMap = [
  {
    path: AuthRoute.Login,
    component: AuthLogin,
  },
  {
    path: AuthRoute.Logout,
    component: AuthLogout,
  },
  {
    path: AuthRoute.Forgot,
    component: AuthForgot,
  }, {
    path: AuthRoute.Password_Recovery,
    component: PasswordRecovery,
  },
] as RouteProps[];
