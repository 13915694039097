import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { RequestCategory } from "../../../entities/request-category.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../enums/form.enum";
import { Endpoint } from '../../../services/api.service';
import { RequestCategoryRoute } from "../routes";
import { RequestCategoryPostBasic } from "./section/basic.component";

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: RequestCategory) => void;
}

/**
 * Add or update a requestCategory
 */
export function RequestCategoryPost({
  style, cancel, formMode, entityId, onAfterSave,
}: Props) {
const [action,setAction]=useState<any>();
  const { t } = useTranslation('main');
  const onBeforeSave = (mode: FormMode, form: RequestCategory) => {
    console.log('This is final data:',form,action);
    form.action=(action as any)?.value;
  }

    /**
   * Custom Header
   */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Title } title={ t("entities.requestCategory") } />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Controls } />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={ FormHeaderType.Title } title={ t("entities.requestCategory") } />
    )
  );
  const processData = (requestCategory: any): any => {
    console.log('This is DATa:',requestCategory)
    requestCategory.action={value:requestCategory.action,label:requestCategory.action};
    setAction(requestCategory.action);
    return requestCategory;
  }
  /**
   * Render
   */
  return (
    <Form<RequestCategory>
      endpoint={ Endpoint.REQUEST_CATEGORY }
      returnLink={ RequestCategoryRoute.List }
      cancel={ cancel }
      formMode={ formMode }
      entityId={ entityId }
      processData={processData}
      onBeforeSave={onBeforeSave}
      onAfterSave={ onAfterSave }
      className={ style === FormStyle.Containerized && "ae-content-w" }
    >
      { style === FormStyle.Containerized && header() }
      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Title } title={ t("entities.requestCategory") } /> }
        <FormSections>
          <RequestCategoryPostBasic onActionSelect={(e:any)=>setAction(e)}/>
        </FormSections>
      </SectionsWrapper>
      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Controls } /> }
      </SectionsWrapper>
    </Form>
  );
}
