import React, { useCallback, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Column, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { Loading } from '../../../components/alerts/loading.component';
import { Audit } from '../../../entities/audit.entity';
import { User } from '../../../entities/user.entity';
import { Api, Endpoint } from '../../../services/api.service';

interface Sort {
  sortDirection: string | undefined,
  accessor: string | undefined
}

interface AuditListRequest {
  limit: number,
  offset: number,
  search?: string,
  direction?: string,
  column?: string
}

export interface AuditListResponse {
  AuditArray: Audit[],
  count: number
}

export function AuditList() {
  const [transaction, setTransaction] = useState<any[]>([])
  const [transactionData, setTransactionData] = useState<any[]>([])
  const [pageCounts, setPageCounts] = useState<number>(1);
  const [count, setCount] = useState(0)
  const [itemCount, setItemCount] = useState<number>(0)
  const [search, setSearch] = useState<string>('')
  let history = useHistory();
  const [sort, setSort] = useState<Sort>({ sortDirection: undefined, accessor: undefined });
  let params: any = useParams();
  const translationReport = useTranslation('main')

  const getData = async (key: Audit) => {
    const items = await Api.get<any, any>(Endpoint.AUDIT_ENTITY, { key: key });
    console.log("target items", items);
    if (key.entity === "Role") {
      history.replace({ pathname: `/user/${key.entity}/list`, state: items });
    }
    else if (key.entity === "Driver" || key.entity === "User") {
      history.replace({ pathname: `/${key.entity}/list`, state: items });
    }
    else if (key.entity === "Emergency") {
      history.replace({ pathname: `/security/${key.entity}/list`, state: items });
    }
    else if (key.entity === "Protocol") {
      history.replace({ pathname: `/security/${key.entity}s/list`, state: items });
    }
    else {
      history.replace({ pathname: `/${key.entity}/list/detail`, state: items });
    }
  }

  const getUser = async (key: User) => {
    console.log("target Data", key);
    history.replace({ pathname: `/user/list`, state: key });
  }

  const refres = useCallback(async (limit: number, offset: number, search?: string, direction?: string, column?: string) => {
    let ro: any = [];   
    let csvData: any = [];
    if (params.id !== ':id') {
      const trans = await Api.get<AuditListResponse, any>(Endpoint.AUDIT_DATA, { id: params.id, limit, offset, search, direction, column });
      setItemCount(trans.count)
      console.log('trans:', trans.AuditArray, trans.count)
      if (trans && trans?.AuditArray?.length > 0) {
        trans.AuditArray.map((key: any, index: any) => {
          ro.push({
            'action': key.action,
            'entity': key.entity,
            'message': <><span style={{ textTransform: "uppercase" }}>{key.message} {key.entity}</span></>,
            "userId": <><a href="#" onClick={() => { getUser(key.user) }}>{key.user.firstName} {key.user.lastName}</a></>
          })
          csvData.push({
            'action': key.action,
            'entity': key.entity,
            'message': `${key.message} ${key.entity}`,
            "user": `${key.user.firstName} ${key.user.lastName}`
          
          })
        });
        setTransaction(ro)
        setTransactionData(csvData)
      }
    } else {
      const trans = await Api.get<AuditListResponse, AuditListRequest>(Endpoint.AUDIT, { limit, offset, search, direction, column });
      setItemCount(trans.count)
      if (trans && trans?.AuditArray?.length > 0) {
        trans.AuditArray.map((key: Audit) => {
          const user = key.user as User
          ro.push({
            'action': key.action,
            'entity': <><a href="#" onClick={() => { getData(key) }}>{key.entity}</a></>,
            "message": <><span style={{ textTransform: "uppercase" }}>{key.message} {key.entity}</span></>,
            "userId": <><a href="#" onClick={() => { getUser(user) }}>{user.firstName} {user.lastName}</a></>
          });
          csvData.push({
            'action': key.action,
            'entity': key.entity,
            'message': `${key.message} ${key.entity}`,
            "user": `${user.firstName} ${user.lastName}`
          
          })
        })
      }
    }
    setTransaction(ro)
    setTransactionData(csvData)
  }, [transaction, itemCount])

  const data = React.useMemo(
    () => [...transaction],
    [transaction]
  )

  const columns: Array<Column<any>> = React.useMemo(
    () => [
      {
        Header: 'entity',
        accessor: 'entity',
        sortType: 'basic',
        sortDirection: sort.accessor === 'entity' ? sort.sortDirection : undefined
      },
      {
        Header: 'message',
        accessor: 'message',
        sortType: 'basic',
        sortDirection: sort.accessor === 'message' ? sort.sortDirection : undefined
      },
      {
        Header: 'user',
        accessor: 'userId',
        sortType: 'basic',
        sortDirection: sort.accessor === 'userId' ? sort.sortDirection : undefined
      },
      {
        Header: 'action',
        accessor: 'action',
        sortType: 'basic',
        sortDirection: sort.accessor === 'action' ? sort.sortDirection : undefined
      }
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    setPageSize,
    prepareRow,
  } = useTable({
    columns, data, manualPagination: true, manualSortBy: true, manualFilters: true
  }, useFilters, useSortBy, usePagination)

  const { pageSize } = state

  const getPageCount = useCallback(async () => {
    // const trans = await Api.get<any, any>(Endpoint.SHIFT_COUNT);
    const temp = Math.ceil(itemCount / pageSize)
    const tempOffset = count * pageSize
    setPageCounts(temp)
    refres(pageSize, tempOffset, search, sort.sortDirection, sort.accessor)
  }, [pageSize, count, search, sort, itemCount])

  useEffect(() => {
    getPageCount()
  }, [getPageCount])

  const onNextPage = () => {
    setCount(prevCount => prevCount + 1)
  }

  const onPreviousPage = () => {
    setCount(prevCount => prevCount - 1)
  }

  const onNextNextPage = () => {
    setCount(pageCounts - 1)
  }

  const onPreviousPreviousPage = () => {
    setCount(0)
  }

  const onClickHeader = (column: any) => {
    switch (column.sortDirection) {
      case undefined:
        column.sortDirection = 'ASC'
        setSort({ sortDirection: 'ASC', accessor: column.id });
        break;
      case 'ASC':
        column.sortDirection = 'DESC'
        setSort({ sortDirection: 'DESC', accessor: column.id });
        break;
      case 'DESC':
        column.sortDirection = undefined
        setSort({ sortDirection: undefined, accessor: column.id });
        break;
    }
  }

  const onChangeSeaech = (e: any, column: any) => {
    setSearch(e.target.value)
  }

  return (
    <>
      {transaction === undefined ?
        <div className="mx-auto py-5 text-center">
          <p>Please Wait</p>
          <Loading loading={true} />
        </div> :
        <div>
          <div className=" py text-center">
            <CSVLink style={{ float: "right", marginTop: "26px", marginRight: "20px" }} filename={"shift-report.csv"} className="btn btn-primary" data={transactionData}>{translationReport.t('reports.exportCSV')}</CSVLink>
          </div>
          <div style={{ marginLeft: "5px", marginRight: "5px", overflowX: "scroll", width:"100%" }} className=" py-5 text-center r2">
            <table {...getTableProps()} className="table table-striped table-Info table-hover table-bordered">
              <thead>
                {headerGroups.map((headerGroup: any) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())} onClick={() => onClickHeader(column)} className="bg-primary">
                        {column.render('Header')?translationReport.t(`reports.audit.${column.id}`):""}
                        <span>
                          {column.sortDirection === 'ASC' ? (
                            ' 🔽'
                          ) : column.sortDirection === 'DESC' ? (
                            ' 🔼'
                          ) : null}
                        </span>
                        <div><span>
                          <input onChange={(e) => onChangeSeaech(e, column)}
                          />
                        </span></div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row: any) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className=" py-5 text-center">
          <span>
          {translationReport.t('reports.page')}{' '}
            <strong>
              {count + 1} {translationReport.t("reports.of")} {pageCounts}
            </strong>{' '}
          </span>
          <span>
            | {translationReport.t("reports.goPage")}{''}
            <input
              type='number'
              defaultValue={count + 1}
              onChange={(e: any) => {
                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                setCount(pageNumber)
              }}
              style={{ marginRight: "10px", width: "70px", marginLeft: "5px", borderRadius: "5px" }}
            />
          </span>
          <select
            value={pageSize}
            onChange={(e: any) => setPageSize(Number(e.target.value))}
            style={{ marginRight: "10px", width: "80px", marginLeft: "5px", borderRadius: "5px" }}
          >
            {[10, 25, 50, 100].map((pageSize: any) => {
              return <option key={pageSize} value={pageSize}>
                {translationReport.t('reports.show')} {pageSize}
              </option>
            })}
          </select>
          <button type="button" className="btn btn-primary" onClick={() => onPreviousPreviousPage()} disabled={count > 0 ? false : true}>{'<<'}</button>
          <button type="button" style={{ marginRight: "10px", marginLeft: "10px" }} className="btn btn-primary" onClick={() => onPreviousPage()} disabled={count > 0 ? false : true}>{translationReport.t('reports.previous')}</button>
          <button type="button" style={{ marginRight: "10px" }} className="btn btn-primary" onClick={() => onNextPage()} disabled={(count + 1) < pageCounts ? false : true}>{translationReport.t('reports.next')}</button>
          <button type="button" className="btn btn-primary" onClick={() => onNextNextPage()} disabled={(count + 1) < pageCounts ? false : true}>{'>>'}</button>
        </div>
      </div>
}
    </>
  )
}