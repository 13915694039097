import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { Article } from '../../../entities/article.entity';

interface Props {
  selectedArticle: Article
}


export function SelectedItemContent({
  selectedArticle
}: Props) {
  const [doc,setDoc] = useState(new DOMParser().parseFromString(selectedArticle.description, "text/xml"));
  const [date,setDate] =useState<string>();
  useEffect(()=>{
    console.log('Selected Ki Date: ',selectedArticle.date);
    const date:Date=new Date(selectedArticle.date);
    const month=((date.getMonth()+1)<10)?`0${date.getMonth()+1}`:`${date.getMonth()+1}`
   const day=((date.getDate())<10)?`0${date.getDate()}`:`${date.getDate()}`
   const formattedDate = `${date.getFullYear()}-${month}-${day}`;
   setDate(formattedDate);
  },[selectedArticle])
  const { t } = useTranslation('forms');
  return(
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-12"
        title={ selectedArticle.title }
      >
        <ViewContentItems>
          <ViewContentItem title={ t("article.basicSection.title") }>
            { selectedArticle.title }
          </ViewContentItem>

          <ViewContentItem title={ t("article.basicSection.date") }>
          {date}
          </ViewContentItem>
          <ViewContentItem title={t("article.basicSection.excerpt")}>
            { selectedArticle.excerpt }
          </ViewContentItem >
          <ViewContentItem title={t("article.basicSection.description")}>
          <div dangerouslySetInnerHTML={{__html: selectedArticle.description}}></div>
          </ViewContentItem >
        </ViewContentItems>
      </ViewContent>
    </ContentWrapper>
  );
}