import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { Currency } from '../../../entities/currency.entity';
import { Fare } from '../../../entities/fare.entity';
import { Settings } from '../../../entities/settings.entity';
import { Api, Endpoint } from '../../../services/api.service';
import { SettingsService } from './../../../services//settings.service';

interface Props {
  selectedFare: Fare,
  onRefresh?: () => void,
  setSelectedFare?:(fare:undefined|Fare)=>void
}

export function SelectedItemContent({
  selectedFare,onRefresh,setSelectedFare
}: Props) {

  const { t } = useTranslation('forms');
  const settings: Settings | undefined = SettingsService.getSetting();
  const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;

  const onclickRecover=async (selectedFare:Fare)=>{  
    console.log('Recover This Fare:',selectedFare);  
const response = await Api.post<Fare,{id:string}>(Endpoint.FARE_RECOVER, { id:selectedFare.id as string });
console.log('Recover This Fare Response:',response)
if (onRefresh && setSelectedFare) {
  setSelectedFare(undefined);
  onRefresh();
}
}
  const toSubUnit = (value: number): number => {
  const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;

    return value / unit;
  }

  return (
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={selectedFare.title}
      >
        <ViewContentItems>
        {selectedFare.deletedTime ? <div className="alert alert-success borderless">
            <h5 className="alert-heading">Fare Deleted</h5>
            <p>Do you want to recover this fare data?</p>
            <div className="alert-btn">
              <a className="btn btn-white-gold" href={'#href'} onClick={() => onclickRecover(selectedFare)}>
                <i className="os-icon os-icon-ui-92"></i>
                <span>{'Recover Fare'}</span>
              </a>
            </div>
          </div> : null
          }
          <ViewContentItem title={t('fare.color')}>
            <div style={{ width: 200, height: 30, backgroundColor: selectedFare.color }}></div>
          </ViewContentItem>
          {/* <ViewContentItem title={ t('fare.service') }>
            { selectedFare.service }
          </ViewContentItem>
          <ViewContentItem title={ t('fare.zone') }>
            { selectedFare.zone }
          </ViewContentItem> */}
          <ViewContentItem title={t('fare.chargesSection.appBookingFee')}>
            {currency} {toSubUnit(selectedFare.appBookingCharge)}
          </ViewContentItem>
          <ViewContentItem title={t('fare.chargesSection.startCharge')}>
            {currency} {toSubUnit(selectedFare.startCharge)}
          </ViewContentItem>
          <ViewContentItem title={t('fare.chargesSection.minCharge')}>
            {currency} {toSubUnit(selectedFare.minimumCharge)}
          </ViewContentItem>
          <ViewContentItem title={t('fare.chargesSection.waitCharge')}>
            {currency} {toSubUnit(selectedFare.waitChargePerThreshold)}
          </ViewContentItem>
          <ViewContentItem title={t('fare.chargesSection.waitThreshold')}>
            {selectedFare.waitThreshold} seconds
          </ViewContentItem>
          <ViewContentItem title={t('fare.chargesSection.distanceCharge')}>
            {currency} {toSubUnit(selectedFare.distanceChargePerThreshold)}
          </ViewContentItem>
          <ViewContentItem title={t('fare.chargesSection.distanceThreshold')}>
            {selectedFare.distanceThreshold} meters
          </ViewContentItem>
          <ViewContentItem title={t('fare.chargesSection.freeDistance')}>
            {selectedFare.freeDistance} meters
          </ViewContentItem>
        </ViewContentItems>
      </ViewContent>
    </ContentWrapper>
  );
}