import { intervalToDuration } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/alerts/loading.component';
import { Trip } from '../../../entities/trip.entity';
import { getDatesForFilters, StatsFilterPeriod, StatsRequest, StatsType } from "../../../interfaces/stats.interface";
import { Api, Endpoint } from "../../../services/api.service";
import { getCurrencyFormatting, getGeneralDistanceFormat, getGeneralTimeFormat } from '../../../util/distance';

type DetailsStatsResponse = {
    itemCount: number, items: Trip[], totalItems: number, pageCount: number
}

interface Props {
    time: StatsFilterPeriod, vehicle: string; activity: StatsType;
    openLink: (link: string) => void; CUSTOM?: { start: Date, end: Date };
}
export function RideDetails(props: Props) {
    const [stats, setStats] = useState<{ code: string, value: string, id: string, endTime: string }[]>();
    const [pagination, setPagination] = useState({ total: 1, current: 0, last: 4 });

    function getEndTime(endtime: Date | undefined) {
        let endDate: Date;

        if (!endtime) endDate = new Date();
        else if (typeof endtime == "object") endDate = endtime;
        else endDate = new Date(endtime);

        const { days, hours, months, weeks, years } = intervalToDuration({
            start: new Date(), end: endDate
        });

        if (years) return years + " years ago";
        if (months) return months + " months ago";
        if (weeks) return weeks + " weeks ago";
        if (days) return days + " days ago";
        if (hours) return hours + " hours ago";

        return "recently added";
    }

    async function getRidesWithVehicle(page: number) {
        try {
            let startDate: Date, endDate: Date;
            if (props.CUSTOM) {
                startDate = props.CUSTOM.start; endDate = props.CUSTOM.end;
            }
            else ({ endDate, startDate } = getDatesForFilters(props.time));
            const response = await Api.get<DetailsStatsResponse, StatsRequest & { page: number, type: StatsType }>(Endpoint.STATS_DETAILS, {
                startDate, endDate, page: page, timeline: props.time, vehicle: props.vehicle, type: props.activity
            });

            setPagination((previous) => ({
                current: page, total: response.pageCount,
                last: page > previous.last ? previous.last + 4 > response.pageCount ? response.pageCount : previous.last : previous.last
            }));

            switch (props.activity) {
                case "Distance":
                    setStats(response.items.map(({ code, distance, id, endTime }) => ({ code, id: id!, value: getGeneralDistanceFormat(distance || 0), endTime: getEndTime(endTime) })));
                    break;
                case "Earnings":
                    setStats(response.items.map(({ code, totalFare, id, endTime }) => ({ code, id: id!, value: getCurrencyFormatting(totalFare || 0), endTime: getEndTime(endTime) })));
                    break;
                case "Rides":
                    setStats(response.items.map(({ code, id, type, endTime }) => ({ code, id: id!, value: type, endTime: getEndTime(endTime) })));
                    break;
                case "Shift Time":
                    setStats(response.items.map(({ code, id, rideTime, endTime }) => ({ code, id: id!, value: getGeneralTimeFormat(rideTime || 0), endTime: getEndTime(endTime) })));
                    break;
            }
        }
        catch (err) {

        }
    }

    useEffect(() => {
        getRidesWithVehicle(1);
    }, []);
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {!stats && <Loading loading />}
            {stats && (
                <>
                    {stats.map(({ code, id, value, endTime }) => (
                        <div key={id} className='d-flex flex-row w-auto justify-content-around align-items-center bg-white p-2 my-2'
                            style={{ marginRight: '2rem', borderBottom: '1px solid blue', cursor: "pointer" }}
                            onClick={() => props.openLink('/security/track/' + code)}
                        >
                            <div className='flex-fill'>
                                <p className="fs-4 mb-0">{code}</p>
                                <p className="fs-6 mb-0">{endTime}</p>
                            </div>
                            <div >
                                <p className='fs-6 mb-0'>{value}</p>
                            </div>
                        </div>
                    ))}

                    <div style={{ display: 'flex', alignSelf: 'center' }}>
                        <ul className="pagination align-items-center">
                            <li onClick={() => {
                                if (pagination.last - 4 != 0) setPagination((previous) => ({ ...previous, last: previous.last - 4 }));
                            }} className={`page-item${pagination.last - 4 == 0 ? " disabled" : ""}`}>
                                <a className="page-link m-0 p-1">&laquo;</a>
                            </li>

                            <li onClick={() => getRidesWithVehicle(pagination.last - 3)} className="page-link m-0 p-1">
                                <a className={`page-link${(pagination.last - 3) == pagination.current ? " active" : ""}`}>{pagination.last - 3}</a>
                            </li>
                            {pagination.total >= pagination.last - 2 && (
                                <li onClick={() => getRidesWithVehicle(pagination.last - 2)} className="page-link m-0 p-1">
                                    <a className={`page-link${(pagination.last - 2) == pagination.current ? " active" : ""}`}>{pagination.last - 2}</a>
                                </li>
                            )}
                            {pagination.total >= pagination.last - 1 && (
                                <li onClick={() => getRidesWithVehicle(pagination.last - 1)} className="page-link m-0 p-1">
                                    <a className={`page-link${(pagination.last - 1) == pagination.current ? " active" : ""}`}>{pagination.last - 1}</a>
                                </li>
                            )}
                            {pagination.total >= pagination.last && (
                                <li onClick={() => getRidesWithVehicle(pagination.last)} className={"page-link m-0 p-1"}>
                                    <a className={`page-link ${pagination.last == pagination.current ? " active" : ""}`}>{pagination.last}</a>
                                </li>
                            )}


                            <li onClick={() => {
                                if (!(pagination.last >= pagination.total)) setPagination((previous) => ({ ...previous, last: previous.last + 4 }));
                            }} className={`page-item${pagination.last >= pagination.total ? " disabled" : ""}`}>
                                <a className="page-link m-0 p-1" >&raquo;</a>
                            </li>
                        </ul>
                    </div>
                </>
            )}
        </div>
    )
}