import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../../components/form/input/input.component";
import { FormSection } from "../../../../../components/form/section.component";
import { FormTextArea } from "../../../../../components/form/input/textarea.component";

export function ProtocolPostBasic() {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  
  return (
    <FormSection
      title={ t("protocol.basicSection.title") }
      subtitle={ t("protocol.basicSection.subTitle") }
    >
      <div className="row">
        <div className="col-md-6">
          <FormInput
            label={ t("protocol.basicSection.name") }
            name="title"
            type="text"
            validation={{ required: true }}
            tabIndex={1}
            autoFocus
            { ...formProps }
          />
        </div>
      </div>
      <div className="row">
     
        <FormTextArea
          label={ t("protocol.basicSection.content") }
          name="description"
          //type="FormTextArea"
          tabIndex={2}
          className="col-md-12"
          validation={{ required: true }}
          { ...formProps }
        />
      </div>
    </FormSection>
  );

}
