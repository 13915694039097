import { RouteProps } from "react-router-dom";
import { FleetList } from "./list/list.component";
import { FleetPost } from "./post/post.component";

export enum FleetRoute {
  List = '/fleet/list',
  Post = '/fleet/post',
}

export const FleetRouteMap = [
  {
    path: FleetRoute.List,
    component: FleetList,
  },
  {
    path: FleetRoute.Post,
    component: FleetPost,
  },
] as RouteProps[];
