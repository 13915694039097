import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormSection } from '../../../../../components/form/section.component';

export function RolePostInstructions() {
    
    const { t } = useTranslation('forms');

    return(
        <FormSection
            title={ t("role.instructionSection.title") }
            subtitle=" "
        >
            <p>{ t("role.instructionSection.header") }</p>
            <p>{ t("role.instructionSection.description") }</p>
            <p><em>{ t("role.instructionSection.stepsHeader") }</em></p>
            <ul>
            <li>{ t("role.instructionSection.step1") }</li>
            <li>{ t("role.instructionSection.step2") }</li>
            <li>{ t("role.instructionSection.step3") }</li>
            <li>{ t("role.instructionSection.step4") }</li>
            </ul>
            <p>{ t("role.instructionSection.footer") }</p>
        </FormSection>
    );
}