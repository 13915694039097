import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormAddressInput } from "../../../../components/form/input/address.component";
import { FormInput } from "../../../../components/form/input/input.component";
import { PhoneInputs } from "../../../../components/form/input/phoneNumber";
import { FormSection } from "../../../../components/form/section.component";

interface Props {
  value: string;
  onChange: any
}

export function NetworkPostBasic({ value, onChange }: Props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  return (
    <FormSection
      title={t("network.basicSection.title")}
      subtitle={t("network.basicSection.subTitle")}
    >
      <div className="row">
        <div className="col-md-6">
          <FormInput
            label={t("network.basicSection.title")}
            name="title"
            type="text"
            validation={{ required: true }}
            tabIndex={1}
            autoFocus
            {...formProps}
          />
        </div>
      </div>
      <div className="row">
        <FormInput
          label={t("network.basicSection.contactPerson")}
          name="contactPerson"
          type="text"
          className="col-sm-6"
          validation={{ required: true }}
          tabIndex={2}
          {...formProps}
        />
        <PhoneInputs
          placeholder={t("network.basicSection.contactPhone")}
          value={value}
          onChange={onChange}
          name='phone'
          className="col-sm-6"
          {...formProps}
        />
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormInput
            label={t("network.basicSection.contactEmail")}
            name="contactEmail"
            type="email"
            validation={{ required: true }}
            tabIndex={4}
            {...formProps}
          />
        </div>
        <div className="col-md-6">
          <FormAddressInput
            label={t("network.basicSection.address")}
            name="address"
            validation={{ required: true }}
            tabIndex={5}
            {...formProps}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormInput
            label={t("network.basicSection.businessRegistration")}
            name="businessRegistration"
            type="text"
            validation={{ required: false }}
            tabIndex={6}
            {...formProps}
          />
        </div>
      </div>
    </FormSection>
  );

}
