import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "../../../../components/form/input/select.component";
import { FormSection } from "../../../../components/form/section.component";
import { Service } from "../../../../entities/service.entity";
import { Settings } from "../../../../entities/settings.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../services/api.service";
interface props{ 
  settings:Settings|undefined

}
export function VehiclePostService({settings}:props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const [services, setServices] = useState<Service[]>([]);
  
  useEffect(() => { init(); }, [])

  const init = async () => {
    const serviceList = await Api.get<IPaginationResponse<Service>, IPaginationOptions>(Endpoint.SERVICE_LIST);
    setServices(serviceList.items);
  }
  
  return (
    <FormSection
      title={ t("vehicle.serviceSection.title") }
      subtitle={ t("vehicle.serviceSection.subTitle") }
    >
      <div className="row">
        <FormSelect<Service>
          label={ t("vehicle.serviceSection.servicesLabel") }
          name="services"
          className="col-sm-12"
          showLabel={ true }
          validation={{required:settings?.vehicleRequirementService?true:false}}
          items={ services }
          multiple={true}
          labelField="title"
          onChange={ () => {} }
          tabIndex={17}
          { ...formProps }
        />
      </div>
    </FormSection>
  );

}
