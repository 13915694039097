import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Feature } from "../../../entities/feature.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../enums/form.enum";
import { Endpoint } from '../../../services/api.service';
import { FeatureRoute } from "../routes";
import { FeatureRequest } from "./section/basic.component";

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: Feature) => void;
}

/**
 * Add or update a feature
 */
export function FeaturePost({
  style, cancel, formMode, entityId, onAfterSave,
}: Props) {

  const { t } = useTranslation('main');

  /**
   * Custom Header depending upon the type of form style
   * In case of containerized style, we're seperating headers in the seperate SectionsWrapper tags,
   * Otherwise if the form is supposed to be shown on full screen then don't use custom header, but rather
   * Use it seperately for each FormSections tag as shown below
   */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Title } title={ t("entities.feature") } />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Controls } />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={ FormHeaderType.Title } title={ t("entities.feature") } />
    )
  );

  /**
   * Render
   */
  
  return (
    <Form<Feature>
      endpoint={ Endpoint.FEATURE }
      returnLink={ FeatureRoute.List }
      formMode={ formMode }
      cancel={ cancel }
      entityId={ entityId }
      onAfterSave={ onAfterSave }
      className={ style === FormStyle.Containerized && "ae-content-w" }
    >
      {/* Using the custom header in case of using containerized form */}
      { style === FormStyle.Containerized && header() }
      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        {/* If not containerized, then use it normally */}
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Title } title={ t("entities.feature") } /> }
        <FormSections>
          <FeatureRequest />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        {/* If not containerized, then use it normally */}
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Controls } /> }
      </SectionsWrapper>
    </Form>
  );
}
