import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Hotspot } from "../../../entities/hotspot.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../enums/form.enum";
import { Endpoint } from '../../../services/api.service';
import { HotspotRoute } from "../routes";
import { HotspotPostBasic } from "./section/basic.component";


interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: Hotspot) => void;
}

/**
 * Add or update a hotspot
 */
export function HotspotPost({
  style, cancel, formMode, entityId, onAfterSave,
}: Props) {

  const { t } = useTranslation('main');

  const processData = (hotspot: any): any => {
    return hotspot;
  }
  /**
 * Custom Header
 */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Title} title={t("entities.hotspot")} />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Controls} />
        </SectionsWrapper>
      </>
    ) : (
        <FormHeader type={FormHeaderType.Title} title={t("entities.hotspot")} />
      )
  );

  /**
   * Render
   */
  return (
    <Form<Hotspot>
      endpoint={Endpoint.HOTSPOT}
      returnLink={HotspotRoute.List}
      cancel={cancel}
      processData={processData}
      formMode={formMode}
      entityId={entityId}
      onAfterSave={onAfterSave}
      onBeforeSave={(mode,form)=>{console.log("form before",form)}}
      className={style === FormStyle.Containerized && "ae-content-w"}
    >
      { style === FormStyle.Containerized && header()}
      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Title} title={t("entities.hotspot")} />}
        <FormSections>
          <HotspotPostBasic key={entityId} />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Controls} />}
      </SectionsWrapper>
    </Form>
  );
}
