export enum ModuleName {
  Accounting = 'accounting',
  ApiLogs = 'apiLogs',
  Article = 'article',
  Audit = 'audit',
  Call = 'call',
  CustomTolls = 'customTolls',
  Dashboard = 'dashboard',
  Dispatch = 'dispatch',
  Driver = 'driver',
  Emergency = 'emergency',
  Fare = 'fare',
  Feature = 'feature',
  Fleet = 'fleet',
  Group = 'group',
  Hotspot = 'hotspot',
  Incident = 'incident',
  Inspection = 'inspection',
  Network = 'network',
  Operator = 'operator',
  Passenger = 'passenger',
  Report = 'report',
  Request = 'request',
  RequestCategory = 'requestCategory',
  Security = 'security',
  Service = 'service',
  Settings = 'settings',
  Subscriber = 'subscriber',
  Support = 'support',
  User = 'user',
  Vehicle = 'vehicle',
  Zone = 'zone',
  corporate = 'corporate',
}

export enum FormMode {
  Adding,
  Editing,
}
