import { Acl } from "../../interfaces/core.interface";

export enum ZoneAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Create = "create",
  Update = "update",
  Delete = "delete", 
  Audit = 'audit'
}

export const ZoneAcl = [
  {
    code: ZoneAclCode.ListAll,
    title: "zone.listAll.title",
    description: "zone.listAll.subTitle",
  },
  {
    code: ZoneAclCode.ListOwn,
    title: "zone.listOwn.title",
    description: "zone.listOwn.subTitle",
  },
  {
    code: ZoneAclCode.Create,
    title: "zone.create.title",
    description: "zone.create.subTitle",
  },
  {
    code: ZoneAclCode.Update,
    title: "zone.update.title",
    description: "zone.update.subTitle",
  },
  {
    code: ZoneAclCode.Delete,
    title: "zone.delete.title",
    description: "zone.delete.subTitle",
  },
  {
    code: ZoneAclCode.Audit,
    title: 'zone.audit.title',
    description: 'zone.audit.subTitle',
  },
] as Acl<ZoneAclCode>;
