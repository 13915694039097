import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../components/form/form.component";
import { FormHeader } from "../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../components/form/sections.component";
import { CreateInspection } from "../../entities/CreateInspection.entity";
import { FormMode } from "../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../enums/form.enum";
import { Api, Endpoint } from '../../services/api.service';
import { InspectionDriver } from "./section/driver.component";
import { InspectionVehicle } from "./section/vehicle.component";
import { ShiftCheckListDescription } from "./section/shiftDesciption.component";
import { InspectionRoute } from "./routes";
import { ShiftChecklist } from "./section/shift.component";
import { Link, useHistory } from "react-router-dom";
import { Role, RoleAssignment } from "../../entities/role.entity";
import { FormSection } from "../../components/form/section.component";
import { AuthService } from "../../services/auth.service";
import { useEffect } from "react";
import { IPaginationOptions, IPaginationResponse } from "../../interfaces/paginate.interface";
import { Inspection, InspectionType } from "../../entities/inspection.entity";
import { FormInput } from "../../components/form/input/input.component";
import { useFormContext } from "react-hook-form";
import { FormTextArea } from "../../components/form/input/textarea.component";
import { Settings, Timezone } from "../../entities/settings.entity";

export interface DriverInspection {
  id: string;
  title: string;
}

export interface VehicleInspection {
  id: string;
  title: string;
}

export interface ShiftChecklists {
  id: string;
  title: string;
}

interface ReadOnly {
  id: number | undefined;
  readonly: boolean
}

interface Value {
  id: number | undefined;
  value: string
}

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
}

/**
 * Add or update a inspection
 */
export function InspectionPost({
  style, cancel, formMode, entityId
}: Props) {

  const { t } = useTranslation('main');
  const formProps = useFormContext();

  const [audit, setAudit] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<ReadOnly>({ id: undefined, readonly: true });
  const [value, setValue] = useState<Value>({ id: undefined, value: '' });
  const [shiftCheckListdescription, setShiftCheckListdescription] = useState<string>();
  const [readOnlyVehicle, setReadOnlyVehicle] = useState<ReadOnly>({ id: undefined, readonly: true });
  const [valueVehicle, setValueVehicle] = useState<Value>({ id: undefined, value: '' });
  const [readOnlyShift, setReadOnlyShift] = useState<ReadOnly>({ id: undefined, readonly: true });
  const [valueShift, setValueShift] = useState<Value>({ id: undefined, value: '' });
  const [driverInspection, setDriverInspection] = useState<Inspection[] | undefined>(undefined)
  const [vehicleInspection, setVehicleInspection] = useState<Inspection[] | undefined>(undefined)
  const [shiftInspection, setShiftInspection] = useState<Inspection[] | undefined>(undefined)
  const [selectedInspection, setSelectedInspection] = useState<Inspection>({ id: '', title: '', type: InspectionType.Driver });
  const [inspection, setInspection] = useState<Inspection[]>([])
  const [inspectionList, setInspectionList] = useState<Inspection[]>([])
  const Payload: any | undefined = AuthService.getUser();
  const { user } = Payload;
  const assignments: RoleAssignment[] = user ? [...((user?.role as Role).assignments as RoleAssignment[])] : [];
  const moduleCodes = Array.from(assignments, assignment => ({
    module: assignment.module,
    action: assignment.action
  }));

  const refresh = useCallback(async () => {

    const { items } = await Api.get<
      IPaginationResponse<Inspection>,
      IPaginationOptions
    >(Endpoint.INSPECTION_LIST, { page: 0, limit: 1000 });
    
    console.log("AFter Inspection:",items)
    const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);

    if (items && items.length > 0) {
      setInspectionList(items)
      setDriverInspection(items.filter(item => item.type === InspectionType.Driver))
      setVehicleInspection(items.filter(item => item.type === InspectionType.Vehicle))
      setShiftInspection(items.filter(item => item.type === InspectionType.Shift))
      setShiftCheckListdescription(data.shiftCheckListdescription);
    }

  }, [inspectionList])

  useEffect(() => {
    refresh()
  }, [])

  const onDoubleDriverClick = (i: number, e: any, item: Inspection) => {
    setReadOnly({ id: i, readonly: false })
    setSelectedInspection(item)
  }

  const onChangeDriver = (i: number, e: any, item: Inspection) => {
    setValue({ id: i, value: e.target.value })
    setSelectedInspection({ id: item.id, title: e.target.value, type: item.type })
    const inspec: Inspection[] | undefined = []
    inspec.push(selectedInspection)
    setInspection(inspec)
  };

  const onDoubleVehicleClick = (i: number, e: any, item: Inspection) => {
    setReadOnlyVehicle({ id: i, readonly: false })
    setSelectedInspection(item)
  }

  const onChangeVehicle = (i: number, e: any, item: Inspection) => {
    setValueVehicle({ id: i, value: e.target.value })
    setSelectedInspection({ id: item.id, title: e.target.value, type: item.type })
    const inspec: Inspection[] | undefined = []
    inspec.push(selectedInspection)
    setInspection(inspec)
  };

  const onDoubleShiftClick = (i: number, e: any, item: Inspection) => {
    setReadOnlyShift({ id: i, readonly: false })
    setSelectedInspection(item)
  }

  const onChangeShift = (i: number, e: any, item: Inspection) => {
    setValueShift({ id: i, value: e.target.value })
    setSelectedInspection({ id: item.id, title: e.target.value, type: item.type })
    const inspec: Inspection[] | undefined = []
    inspec.push(selectedInspection)
    setInspection(inspec)
  };


  /**
   * Custom Header depending upon the type of form style
   * In case of containerized style, we're seperating headers in the seperate SectionsWrapper tags,
   * Otherwise if the form is supposed to be shown on full screen then don't use custom header, but rather
   * Use it seperately for each FormSections tag as shown below
   */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Title} title={t("entities.inspection")} />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Controls} />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={FormHeaderType.Title} title={t("entities.inspection")} />
    )
  );

  const onBeforeSave = async (mode: FormMode, form: any) => {
    console.log('Inspection Complete Data:',form)
    console.log('DriverInspection:',form.driverInspection)
    console.log('vehicleInspection:',form.vehicleInspection.split('\n'))
    console.log('shiftCheckListdescription:',form.shiftCheckListdescription)
    form.driverInspection = form.driverInspection?form.driverInspection.split('\n'):[];
    form.vehicleInspection = form.vehicleInspection?form.vehicleInspection.split('\n'):[]
    form.shiftChecklist = form.shiftChecklist?form.shiftChecklist.split('\n'):[];
    // if (inspection && inspection.length > 0 && selectedInspection.title.length > 0) {
    //   await Api.patch(Endpoint.INSPECTION, { id: inspection[0].id, title: selectedInspection.title, type: inspection[0].type })
    //   refresh()
    // }
    // if (inspection && inspection.length > 0 && selectedInspection.title.length === 0) {
    //   await Api.delete(Endpoint.INSPECTION, { id: inspection[0].id })
    //   refresh()
    // }
  }

  const onclickDriver = async (item: Inspection) => {
    console.log('hahahahahahha:', item)
    await Api.delete(Endpoint.INSPECTION, { id: item.id })
    refresh()
  }

  const onAfterSave = () => {
    refresh()
  }

  /**
   * Render
   */

  let history = useHistory();
  function checkAudit() {
    history.push(`/audit/Inspection`);
  }

  useEffect(() => {
    moduleCodes.filter((el) => {
      if (el.module === 'inspection' && el.action === "audit") {
        setAudit(true)
      }
    })
  },[])


  return (
    <Form<CreateInspection>
      endpoint={Endpoint.INSPECTION}
      returnLink={InspectionRoute.Post}
      formMode={formMode}
      entityId={entityId}
      className={style === FormStyle.Containerized && "ae-content-w"}
      onBeforeSave={onBeforeSave}
      onAfterSave={onAfterSave}
    >

      {/* Using the custom header in case of using containerized form */}
      {style === FormStyle.Containerized && header()}
      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>

        {/* If not containerized, then use it normally */}
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Title} title={t("entities.inspection")} />}

        <FormSections>
          <InspectionDriver driverInspectionData={driverInspection} />
          {/* {driverInspection?.map((item, i) => {
            return <div className='row'> <FormInput
              name={`title-${i}`}
              value={i === value?.id ? value.value : item.title}
              type="text"
              tabIndex={i} 
              style={{ marginTop: "-35px" }}
              readonly={i === readOnly.id ? readOnly?.readonly : true}
              validation={{ required: false }}
              onDoubleClick={(e: any) => {
                onDoubleDriverClick(i, e, item)
              }}
              onChange={(e: any) => {
                onChangeDriver(i, e, item)
              }}
              {...formProps}
            />
              <a style = {{zIndex:100000,marginTop:-45, marginLeft:"95%"}} href="#delete" onClick={(e: any) => {
                onclickDriver(item)
              }}>
                <i className="os-icon os-icon-delete"></i>
              </a>
            </div>
          })} */}
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-4" : ""}>
        {/* If not containerized, then use it normally */}
        {audit ? <button type="button" className="btn btn-primary" onClick={checkAudit} style={{ position: 'relative', marginLeft: "10px", float: 'right', zIndex: 99 }}>Audit</button> : ""}
        {/* <Link to={'/audit/Inspection'} className="btn btn-primary"  type="button">Audit</Link> */}
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Controls} />}

        <FormSections>
          <InspectionVehicle vehicleInspectionData={vehicleInspection}/>
          {/* {vehicleInspection?.map((item, i) => {
            return<div className='row'> <FormInput
              name={`title-${i}`}
              value={i === valueVehicle?.id ? valueVehicle.value : item.title}
              type="text"
              tabIndex={i} 
              style={{ marginTop: "-35px" }}
              readonly={i === readOnlyVehicle.id ? readOnlyVehicle?.readonly : true}
              validation={{ required: false }}
              onDoubleClick={(e: any) => {
                onDoubleVehicleClick(i, e, item)
              }}
              onChange={(e: any) => {
                onChangeVehicle(i, e, item)
              }}
              {...formProps}
            />
            <a style = {{zIndex:100000,marginTop:-45, marginLeft:"95%"}} href="#delete" onClick={(e: any) => {
                onclickDriver(item)
              }}>
                <i className="os-icon os-icon-delete"></i>
              </a>
            </div>
          })} */}
        </FormSections>
      </SectionsWrapper>

      {style === FormStyle.Containerized && header()}
      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
        {/* If not containerized, then use it normally */}
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Title} title={t("entities.shift")} />}
        <FormSections>
          <ShiftChecklist shiftCheckList={shiftInspection} />
          <ShiftCheckListDescription shiftCheckListDescription={shiftCheckListdescription}/>
          {/* {shiftInspection?.map((item, i) => {
            return <div className='row'> <FormInput
              name={`title-${i}`}
              value={i === valueShift?.id ? valueShift.value : item.title}
              type="text"
              tabIndex={i}
              style={{ marginTop: "-35px" }} 
              readonly={i === readOnlyShift.id ? readOnlyShift?.readonly : true}
              validation={{ required: false }}
              onDoubleClick={(e: any) => {
                onDoubleShiftClick(i, e, item)
              }}
              onChange={(e: any) => {
                onChangeShift(i, e, item)
              }}
              {...formProps}
            />
            <a style = {{zIndex:100000,marginTop:-45, marginLeft:"95%"}} href="#delete" onClick={(e: any) => {
              onclickDriver(item)
            }}>
              <i className="os-icon os-icon-delete"></i>
            </a>
          </div>
          })} */} 
        </FormSections>
      </SectionsWrapper>
    </Form>
  );
}
