import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormCheckBox, LabelPositon } from "../../../components/form/input/checkbox.component";
import { FormInput } from "../../../components/form/input/input.component";
import { FormRadio } from "../../../components/form/input/radio.component";
import { FormSection } from "../../../components/form/section.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Settings } from "../../../entities/settings.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType } from "../../../enums/form.enum";
import { Api, Endpoint } from "../../../services/api.service";
import { checkCCStatus } from "../../../util/checkCCStatus";
import { SettingsRoute } from "../routes";

/**
 * Update general settings
 */
export function SettingsSectionDriverRequirements() {

  const { t } = useTranslation('settings');
  const [entity, setEntity] = useState<any>("");
  useEffect(() => {
    a();
  }, [])

  const a = async () => {
    const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
    setEntity(data.id);
  };


  const onBeforeSave = (_: FormMode, form: Settings) => {
    form.entityId = entity
    form.shiftMaxAllowed = form.shiftMaxAllowed ? Math.round(form.shiftMaxAllowed * 60) : 0;
    form.shiftMinGap = form.shiftMinGap ? Math.round(form.shiftMinGap * 60) : 0;
  }

  return (
    <Form<Settings>
      endpoint={Endpoint.SETTINGS}
      returnLink={SettingsRoute.DriverRequirement}
      onBeforeSave={onBeforeSave}
    >
      <SectionsWrapper>
        <FormHeader type={FormHeaderType.Title} title={t("driverRequirements.heading")} fullTitle={true} />
        <FormSections>
          <DriverSettingsSection />
        </FormSections>
      </SectionsWrapper>
      <SectionsWrapper>
        <FormHeader type={FormHeaderType.Controls} />
      </SectionsWrapper>
    </Form>
  );
}

const DriverSettingsSection = () => {

  const { t } = useTranslation('settings');
  const translationForm = useTranslation("forms");
  const childProps = useFormContext();
  const [ENABLE_CC, setENABLE_CC] = useState<any>();

  const [, setSettings] = useState<Settings>();

  const ref = useCallback(() => {
    const refresh = async () => {
      const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
      data.shiftMaxAllowed = data.shiftMaxAllowed ? Math.round(data.shiftMaxAllowed / 60) : undefined;
      data.shiftMinGap = data.shiftMinGap ? Math.round(data.shiftMinGap / 60) : undefined;
      childProps.reset(data);
      setSettings(data);
      let CC = await checkCCStatus()
      setENABLE_CC(CC);
    };
    refresh();
  }, [childProps])

  useEffect(() => {
    ref();
  }, []);
  const onChange = (e: any) => {
    console.log('This is onchange:', e);
  }
  useEffect(() => {
    console.log("ENABLE_CC: ", ENABLE_CC)
  }, [ENABLE_CC]);

  return (
    ENABLE_CC !== undefined ? <FormSection
      title={t("driverRequirements.title")}
      subtitle={t("driverRequirements.subTitle")}
    >
      <div className="row">
        <div className="col">
          <FormCheckBox
            label={translationForm.t("common.firstName")}
            name="driverRequirementFirstName"
            validation={{ required: true }}
            labelPositon={LabelPositon.Left}
            checked={true}
            disabled={true}
            {...childProps}
          />
          <FormCheckBox
            label={translationForm.t("common.lastName")}
            name="driverRequirementLastName"
            validation={{ required: true }}
            labelPositon={LabelPositon.Left}
            checked={true}
            disabled={true}
            {...childProps}
          />
        </div>
        <div className="col">
          <FormCheckBox
            label={translationForm.t("common.phone")}
            name="driverRequirementPhone"
            validation={{ required: true }}
            labelPositon={LabelPositon.Left}
            checked={true}
            disabled={true}
            {...childProps}
          />
          <FormCheckBox
            label={translationForm.t("common.email")}
            name="driverRequirementEmail"
            validation={{ required: false }}
            labelPositon={LabelPositon.Left}
            disabled={ENABLE_CC ? true : false}
            {...childProps}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("common.dob")}
            name="driverRequirementDateOfBirth"
            validation={{ required: ENABLE_CC ? true : false }}
            disabled={ENABLE_CC ? true : false}
            {...childProps}
          />
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("common.homeAddress")}
            name="driverRequirementAddress"
            validation={{ required: ENABLE_CC ? true : false }}
            disabled={ENABLE_CC ? true : false}
            {...childProps}
          />
        </div>
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.bankSection.accountNumber")}
            name="driverRequirementBankAccountNumber"
            validation={{ required: ENABLE_CC ? true : false }}
            disabled={ENABLE_CC ? true : false}
            {...childProps}
          />
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={`${process.env.REACT_APP_ROUTING_LABEL}`}
            name="driverRequirementBankAccountRouting"
            validation={{ required: ENABLE_CC ? true : false }}
            disabled={ENABLE_CC ? true : false}
            {...childProps}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.bankSection.title")}
            name="driverRequirementBankAccountTitle"
            validation={{ required: ENABLE_CC ? true : false }}
            disabled={ENABLE_CC ? true : false}
            {...childProps}
          />
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.licenseSection.licenseNumber")}
            name="driverRequirementLicenseNumber"
            validation={{ required: false }}
            {...childProps}
          />
        </div>
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.verificationSection.poiLabelFront")}
            name="driverRequirementIdentityFiles"
            validation={{ required: ENABLE_CC ? true : false }}
            disabled={ENABLE_CC ? true : false}
            {...childProps}
          />

          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.licenseSection.licenseExpiration")}
            name="driverRequirementLicenseExpiryDate"
            validation={{ required: false }}
            {...childProps}
          /></div>
      </div>
      <div className="row">
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("common.contactPerson")}
            name="driverRequirementEmergencyName"
            validation={{ required: false }}
            {...childProps}
          />
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("common.contactPhone")}
            name="driverRequirementEmergencyEmail"
            validation={{ required: false }}
            {...childProps}
          />
        </div>
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("common.contactEmail")}
            name="driverRequirementEmergencyPhone"
            validation={{ required: false }}
            {...childProps}
          />
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.bankSection.bankName")}
            name="driverRequirementBankName"
            validation={{ required: false }}
            {...childProps}
          /></div>
      </div>
      <div className="row">
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.licenseSection.licenseExpiration")}
            name="driverRequirementLicenseType"
            validation={{ required: false }}
            {...childProps}
          />    <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.privateSection.etag1")}
            name="driverRequirementEtag1"
            validation={{ required: false }}
            {...childProps}
          />
        </div>
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("Bussiness Registration")}
            name="driverRequirementBusinessRegistration"
            validation={{ required: false }}
            {...childProps}
          />
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.privateSection.etag2")}
            name="driverRequirementEtag2"
            validation={{ required: false }}
            {...childProps}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">

          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.publicSection.profilePic")}
            name="driverRequirementPicFile"
            validation={{ required: false }}
            {...childProps}
          />
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.licenseSection.licenseLabel")}
            name="driverRequirementLicenseFiles"
            validation={{ required: false }}
            {...childProps}
          />
        </div>
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("common.gender")}
            name="driverRequirementGender"
            validation={{ required: false }}
            {...childProps}
          />
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.verificationSection.policeCheckLabel")}
            name="driverRequirementPoliceFiles"
            validation={{ required: false }}
            {...childProps}
          /></div>
      </div>
      <div className="row">
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.verificationSection.drivingHistoryLabel")}
            name="driverRequirementHistoryFiles"
            validation={{ required: false }}
            {...childProps}
          />
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.verificationSection.vevoLabel")}
            name="driverRequirementVevoFiles"
            validation={{ required: false }}
            {...childProps}
          /></div>
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.verificationSection.medicareLabel")}
            name="driverRequirementMedicareCardFiles"
            validation={{ required: false }}
            {...childProps}
          />
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.verificationSection.proficiencyLabel")}
            name="driverRequirementEnglishLanguageProficiencyFiles"
            validation={{ required: false }}
            {...childProps}
          /></div>
      </div>
      <div className="row">
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.fleetSection.fleetsLabel")}
            name="driverRequirementFleets"
            validation={{ required: false }}
            {...childProps}
          />
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.networkSection.networkLabel")}
            name="driverRequirementNetworks"
            validation={{ required: false }}
            {...childProps}
          /></div>
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.operatorSection.operatorLabel")}
            name="driverRequirementOperators"
            validation={{ required: false }}
            {...childProps}
          />
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.groupSection.groupsLabel")}
            name="driverRequirementGroups"
            validation={{ required: false }}
            {...childProps}
          /></div>
      </div>
      <div className="row">
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("driver.featureSection.featuresLabel")}
            name="driverRequirementFeatures"
            validation={{ required: false }}
            {...childProps}
          />
        </div>
        <div className="col">
        </div>
      </div>
    </FormSection> : null
  );
}