import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { generate } from "shortid";
import { FormTextArea } from "../../../components/form/input/textarea.component";
import { FormSection } from "../../../components/form/section.component";
import { Inspection } from "../../../entities/inspection.entity";
import { ShiftChecklists } from "../post.component";

interface Props {
  shiftCheckList: Inspection[] | undefined
}

export function ShiftChecklist(props: Props) {
  const { t } = useTranslation("forms");
  const formProps = useFormContext();

  const [data, setData] = useState<ShiftChecklists[]>([{ id: generate(), title: '' }]);
  const [sentence, setSentence] = useState<string>(props?.shiftCheckList?props?.shiftCheckList?.map((shiftCheckList:Inspection)=>{return shiftCheckList.title}).join('\n'):'');

  useEffect(
    () => {
      setSentence(props?.shiftCheckList?props?.shiftCheckList?.map((shiftCheckList:Inspection)=>{return shiftCheckList.title}).join('\n'):'')
    },
    [ props])

    const onChange = (e:any) => {
      // console.log('Inside Driver Change');
      const title = e.target.value;
      console.log('This is Driver Filed Data:',title)
        setData([...data, title]);
        setSentence(title)
 }

  return (
    <FormSection title={t("inspection.shiftChecklist")} subtitle="">
          <FormTextArea
            name={`shiftChecklist`}
            label={t("inspection.title")}
            // autoFocus={i === 0}
            validation={{ required: false }}
            rows={5}
            value={sentence}
            onchange={onChange}
            {...formProps}
          />
    </FormSection>
  );
}
