import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../../../components/form/input/input.component";
import { FormSelect } from "../../../../../../components/form/input/select.component";
import { FormSection } from "../../../../../../components/form/section.component";
import { Currency } from "../../../../../../entities/currency.entity";
import { Driver } from "../../../../../../entities/driver.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../../../services/api.service";

export function CurrencyPostBasic() {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  return (
    <FormSection
      title={ t("currency.basicSection.title") }
      subtitle={ t("currency.basicSection.subTitle") }
    >
      <div className="row">
        <FormInput
          label={ t("currency.basicSection.name") }
          name="name"
          type="text"
          className="col-md-6"
          validation={{ required: true }}
          tabIndex={1}
          autoFocus
          { ...formProps }
        />
        <FormInput
          label={ t("currency.basicSection.code") }
          name="code"
          type="text"
          className="col-md-6"
          validation={{ required: true }}
          tabIndex={2}
          { ...formProps }
        />
      </div>
      <div className="row">
      <FormInput
          label={ t("currency.basicSection.symbol") }
          placeholder={ t("currency.basicSection.titlePlaceholder") }
          name="symbol"
          type="text"
          className="col-md-6"
          maxLength={2}
          validation={{ required: true }}
          tabIndex={3}
          autoFocus
          { ...formProps }
        />
        <FormInput
          label={ t("currency.basicSection.subunits") }
          name="subunits"
          type="number"
          className="col-md-6"
          validation={{ required: false,min:1 }}
          tabIndex={4}
          { ...formProps }
        />
      </div>
    </FormSection>
  );

}
