import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Api, Endpoint } from "../../../services/api.service";
import { Activity, DashboardSessionActivity, SessionStatus } from "./activity.component";
import { differenceInDays, differenceInMilliseconds, formatRelative, subDays } from 'date-fns';
import { Session } from "../../../entities/session.entity";
import { Driver } from "../../../entities/driver.entity";
import { Vehicle } from "../../../entities/vehicle.entity";

export interface SessionResponse {
  id: string,
  activity: Activity,
}
enum ActivityStatus {
  Active, InActive
}

const StatusMap = new Map([
  [ActivityStatus.Active, [SessionStatus.Active]],
  [ActivityStatus.InActive, [SessionStatus.InActive]],
]);
let SessionList: Map<string | undefined, Activity>;
let SessionExtra: Map<string | undefined, Activity>
let sessionCheck: boolean = false;

export function DashboardSession() {
  const [sessionList, setSessionList] = useState<Map<string | undefined, Activity>>(new Map());
  const [active, setActive] = useState<Activity[]>([]);
  const [inActive, setInActive] = useState<Activity[]>([]);
  const [eventInit, setEventInit] = useState<number>(0);
  const [eventInitCheck, setEventInitCheck] = useState<boolean>(false);
  const ref = useRef<Map<string | undefined, Activity>>(new Map());

  const loadActivities = (status: ActivityStatus): Activity[] => {
    console.log('Inside Load activites', status)
    let sessions: Activity[];
    if (SessionExtra?.values() !== undefined) {
      // console.log('Session Extra Data', SessionExtra)
      sessions = Array.from(SessionExtra.values())
    } else {
      sessions = Array.from(sessionList.values())
    }
    SessionList = sessionList;
    return Array.from(sessions.filter(session => StatusMap.get(status)?.includes(session.status)));
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if (eventInit === 1) {
      Api.on('session_update', (data) => handleSessionUpdate(sessionList, data));
      Api.on('session_alert', (data) => handleSessionAlert(sessionList, data))
      setEventInit(2);
    }
    if (!eventInitCheck) {
      setEventInit(1);
      setEventInitCheck(true);
    }
    setActive(loadActivities(ActivityStatus.Active));
    setInActive(loadActivities(ActivityStatus.InActive));
  }, [sessionList, console.log('Session Extra Data', SessionExtra)])

  const init = useCallback(async () => {
    try {
      const response = await Api.get<SessionResponse[], void>(Endpoint.DASHBOARD_SESSION);
      const data = new Map(response.map(session => [session.id, session.activity]))
      console.log('SessionResponse:', data)
      setSessionList(data);
      // SessionList = data
      ref.current = data;
    } catch (error: any) {
      console.log('Failed to load booking list', error.message);
    }
  }, [sessionList])

  const handleSessionUpdate = async (list: Map<string | undefined, Activity>, data: Session) => {
    console.log('SessionList2: ', list)
    console.log('SessionList2 REFFF: ', ref)
    // console.log('SessionList2 BookingList: ', SessionList)
    const newActivity = createActivity(data);
    if (!sessionCheck) {
      console.log('Inside Map Initialize Should be one only')
      SessionExtra = new Map(list);
      sessionCheck = true;
    }
    console.log('SessionList2 BookingList: ', data)
    SessionExtra.set(data.id, newActivity);
    setSessionList(SessionExtra);
    setActive(loadActivities(ActivityStatus.Active));
    setInActive(loadActivities(ActivityStatus.InActive));
  }
  const handleSessionAlert = (list: Map<string | undefined, Activity>, data: Session) => {
    const newActivity = createActivity(data);
    if (!sessionCheck) {
      console.log('Inside Map Initialize Should be one only')
      SessionExtra = new Map(list);
      sessionCheck = true;
    }
    SessionExtra.set(data.id, newActivity);
    setSessionList(SessionExtra);
    setActive(loadActivities(ActivityStatus.Active));
    setInActive(loadActivities(ActivityStatus.InActive));
  }
  const createActivity = (data: Session) => {
    const driver = data.driverId as Driver;
    const vehicle = data.vehicleId as Vehicle;
    let lastestSessionData: any
    if (data.distance === null) {
      lastestSessionData = {
        code: data.code,
        status: SessionStatus.Active,
        driver: driver.code,
        vehicle: vehicle.plate,
        sessionTime: formatRelative(subDays(new Date(data.createTime), differenceInDays(new Date(), new Date(data.createTime))), new Date(data.createTime)),
      }
    } else {
      lastestSessionData = {
        code: data.code,
        status: SessionStatus.InActive,
        driver: driver.code,
        vehicle: vehicle.plate,
        sessionTime: formatRelative(subDays(new Date(data.updateTime), differenceInDays(new Date(), new Date(data.updateTime))), new Date(data.updateTime)),
      }
    }
    return lastestSessionData;
  }

  const { t } = useTranslation("acl");
  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <DashboardSessionActivity title={t("dashboard.session.session-elements.active")} activities={active} color="secondary" />
          </div>
          <div className="col-sm-12 col-md-3">
            <DashboardSessionActivity title={t("dashboard.session.session-elements.inActive")} activities={inActive} color="primary" />
          </div>
        </div>
      </div>
    </div>
  );
}