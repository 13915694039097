import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormSection } from "../../../../components/form/section.component";

export function DriverPostBank() {
  const { t } = useTranslation("forms");
  const formProps = useFormContext();

  return (
    <FormSection
      title={t("driver.bankSection.title")}
      subtitle={t("driver.bankSection.subTitle")}
    >
      <div className="row">
        <FormInput
          label={t("driver.bankSection.bankName")}
          name="bankName"
          type="text"
          className="col-sm-6"
          tabIndex={12}
          {...formProps}
        />
        <FormInput
          label={t("driver.bankSection.accountNumber")}
          name="bankAccountNumber"
          type="text"
          className="col-sm-6"
          tabIndex={13}
          {...formProps}
        />
        <FormInput
          label={t("driver.bankSection.routing")}
          name="bankAccountRouting"
          type="text"
          className="col-sm-6"
          tabIndex={14}
          {...formProps}
        />
        <FormInput
          label={t("driver.bankSection.nameOnAccount")}
          name="bankAccountTitle"
          type="text"
          className="col-sm-6"
          tabIndex={15}
          {...formProps}
        />
      </div>
    </FormSection>
  );
}
