import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../../components/alerts/loading.component';
import { Zone } from '../../../entities/zone.entity';
import { IPaginationOptions, IPaginationResponse } from '../../../interfaces/paginate.interface';
import { Api, Endpoint } from '../../../services/api.service';
import { ZoneRoute } from '../../zone/routes';

interface Props {
  /**
   * onChange function called when a zone is selected
   */
  onChange?: (zone: Zone | undefined) => void,
}

/**
 * Zone list displayed on the fare dashboard
 */
export function FareDashboardZones({ onChange }: Props) {

  const { t } = useTranslation('main');
  /**
   * History push to navigate
   */
  const { push: navigateTo } = useHistory();

  /**
   * List of zones
   */
  const [ zones, setZones ] = useState<Zone[]>();

  /**
   * Selected zone
   */
  const [ selectedZone, setSelectedZone ] = useState<Zone>();

  /**
   * Determines if zone list is loading
   */
  const [ loading, setLoading ] = useState<boolean>(false);

  /**
   * Load zone list upon component ready
   */
  useEffect(() => {
    init();
  }, []);

  /**
   * When selected zone changes, trigger onChange function
   */
  useEffect(() => {
    if (onChange) {
      onChange(selectedZone);
    }
  }, [ onChange, selectedZone ]);

  /**
   * Load zone list upon init
   */
  async function init() {
    setLoading(true);
    const zoneList = await Api.get<IPaginationResponse<Zone>, IPaginationOptions>(Endpoint.ZONE_LIST);
    setZones(zoneList.items);
    setLoading(false);
  }

  const getEditLink = (zone: Zone): string => {
    return `${ZoneRoute.Post}?id=${zone.id}`;
  }

  /**
   * Render
   */
  return (
    <ul className="ae-main-menu">
      <li className="header"><a href="zones"><span>{ t("entities.zone_plural") }</span></a></li>
      <Loading className="loading" loading={ loading } />
      { zones?.map(zone => (
        <li key={ zone.id } className={ classNames({ active: zone.id === selectedZone?.id })}>
          <a
            href="#zone"
            onClick={ () => { setSelectedZone(zone); }}
            onDoubleClick={() => navigateTo(getEditLink(zone))}
          >
            <span>{ zone.title }</span>
          </a>
        </li>
      ))}
    </ul>
  );
}
