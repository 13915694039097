import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormInput } from "../../../components/form/input/input.component";
import { FormRadio } from "../../../components/form/input/radio.component";
import { FormSelect } from "../../../components/form/input/select.component";
import { FormSection } from "../../../components/form/section.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Settings } from "../../../entities/settings.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType } from "../../../enums/form.enum";
import { Api, Endpoint } from "../../../services/api.service";
import { SettingsRoute } from "../routes";

interface PaymentMethod {
  id: string;
  name: string;
}

const paymentMethods = [
  { name: 'Cash', id: 'cash' },
  { name: 'App', id: 'app' },
  { name: 'Credit Card', id: 'credit-card' },
] as PaymentMethod[];

/**
 * Update general settings
 */
export function SettingsSectionPayment() {
  
  const { t } = useTranslation('settings');
  const [entity,setEntity] = useState<any>("");
  useEffect(()=>{
  
    a();
  },[])

  const a =async()=>{
    const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
    setEntity(data.id)
  };
 
  const onBeforeSave = (_: FormMode, form: Settings) => {
    form.entityId = entity
    form.paymentCCChargeValue = form.paymentCCChargeValue ? Math.round(form.paymentCCChargeValue * 100) : 0;
    form.paymentMethods = form.paymentMethods.map(paymentMethod => (paymentMethod as any).id);
  }
  
  return (
    <Form
      endpoint={ Endpoint.SETTINGS }
      returnLink = {SettingsRoute.Payment}
      onBeforeSave={ onBeforeSave }
    >
      <SectionsWrapper>
        <FormHeader type={ FormHeaderType.Title } fullTitle={ true } title={ t("payment.title") } />
        <FormSections>
          <PaymentMethodSection />
          <CreditCardSection />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper>
        <FormHeader type={ FormHeaderType.Controls } />
      </SectionsWrapper>
    </Form>
  );
}

const PaymentMethodSection = () => {
  
  const childProps = useFormContext();
  const { t } = useTranslation('settings');

  const [,setSettings] = useState<Settings>();

  const ref = useCallback(()=>{
    const refresh = async () => {
      const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
      data.paymentCCChargeValue /= 100;
      // @ts-ignore
      // data.paymentMethods = data?.paymentMethods.map(paymentMethod => (
      //   {
      //     id: paymentMethod as string,
      //     name: paymentMethod as string,
      //   }
      // ));
      childProps.reset(data);
      setSettings(data);
    };
    refresh();
  }, [childProps])
   

  useEffect(() => {
    ref();
  }, []);

  return(
    <FormSection
      title={ t("payment.methodsSection.title") }
      subtitle={ t("payment.methodsSection.subTitle") }
      className="row"
    >
      <FormSelect<PaymentMethod>
        label="Methods"
        name="paymentMethods"
        className="col-sm-12"
        showLabel={ false }
        multiple={ true }
        validation={{}}
        items={ paymentMethods }
        onChange={ () => {} }
        tabIndex={1}
        autoFocus
        { ...childProps }
      />
    </FormSection>
  );
}

function CreditCardSection() {

  const { t } = useTranslation('settings');
  const childProps = useFormContext();

  return(
    <FormSection
      title={ t("payment.creditCardSection.title") }
      subtitle=" "
    >
      <FormRadio 
        label={ t("payment.creditCardSection.typeLabel") }
        name="paymentCCChargeType"
        className="col-md-3"
        validation={{ required: true }}
        { ...childProps }
        options={[
          { label: t("payment.creditCardSection.typeFixed"), value: 'fixed' },
          { label: t("payment.creditCardSection.typePercentage"), value: 'percentage', selected: true },
        ]}
        tabIndex={2}
      />
      <FormInput
        name="paymentCCChargeValue"
        label={ t("payment.creditCardSection.cardChargeLabel") }
        placeholder=""
        type="number"
        step={ 0.01 }
        className="col-sm-4"
        tabIndex={3}
        validation={{ required: true }}
        { ...childProps }
      />
    </FormSection>
  );
}