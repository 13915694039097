import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "../../../../components/form/input/select.component";
import { FormSection } from "../../../../components/form/section.component";
import { Group } from "../../../../entities/group.entity";
import { Settings } from "../../../../entities/settings.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../services/api.service";

interface props {
  settings: Settings | undefined
}
export function DriverPostGroup({ settings }: props) {
  const { t } = useTranslation("forms");
  const formProps = useFormContext();
  const [groups, setGroups] = useState<Group[]>([]);
  
  useEffect(() => {
    init();
    return ()=>{
      setGroups([]);
    }
  }, []);

  const init = async () => {
    const groupList = await Api.get<IPaginationResponse<Group>, IPaginationOptions>(Endpoint.GROUP_LIST);
    setGroups(groupList.items);
  };

  return (
    <FormSection
      title={t("driver.groupSection.title")}
      subtitle={t("driver.groupSection.subTitle")}
    >
      <div className="row">
        <FormSelect<Group>
          label={t("driver.groupSection.groupsLabel")}
          name="groups"
          className="col-sm-12"
          showLabel={true}
          multiple={true}
          validation={{ required: settings?.driverRequirementGroups ? true : false }}
          items={groups}
          labelField="title"
          onChange={() => { }}
          tabIndex={0}
          {...formProps}
        />
      </div>
    </FormSection>
  );
}