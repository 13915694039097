import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormCheckBox, LabelPositon } from "../../../components/form/input/checkbox.component";
import { FormInput } from "../../../components/form/input/input.component";
import { FormRadio } from "../../../components/form/input/radio.component";
import { FormSection } from "../../../components/form/section.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Settings } from "../../../entities/settings.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType } from "../../../enums/form.enum";
import { Api, Endpoint } from "../../../services/api.service";
import { SettingsRoute } from "../routes";

/**
 * Update general settings
 */
export function SettingsSectionVehicleRequirements() {

  const { t } = useTranslation('settings');
  const [entity, setEntity] = useState<any>("");
  useEffect(() => {
    a();
  }, [])

  const a = async () => {
    const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
    setEntity(data.id);
  };


  const onBeforeSave = (_: FormMode, form: Settings) => {
    form.entityId = entity
    form.shiftMaxAllowed = form.shiftMaxAllowed ? Math.round(form.shiftMaxAllowed * 60) : 0;
    form.shiftMinGap = form.shiftMinGap ? Math.round(form.shiftMinGap * 60) : 0;
  }

  return (
    <Form<Settings>
      endpoint={Endpoint.SETTINGS}
      returnLink={SettingsRoute.VehicleRequirement}
      onBeforeSave={onBeforeSave}
    >
      <SectionsWrapper>
        <FormHeader type={FormHeaderType.Title} title={t("vehicleRequirements.heading")} fullTitle={true} />
        <FormSections>
          <VehicleSettingsSection />
        </FormSections>
      </SectionsWrapper>
      <SectionsWrapper>
        <FormHeader type={FormHeaderType.Controls} />
      </SectionsWrapper>
    </Form>
  );
}

const VehicleSettingsSection = () => {

  const { t } = useTranslation('settings');
  const translationForm = useTranslation('forms');

  const childProps = useFormContext();
  const [ENABLE_CC, setENABLE_CC] = useState<any>();

  const [, setSettings] = useState<Settings>();

  const ref = useCallback(() => {
    const refresh = async () => {
      const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
      data.shiftMaxAllowed = data.shiftMaxAllowed ? Math.round(data.shiftMaxAllowed / 60) : undefined;
      data.shiftMinGap = data.shiftMinGap ? Math.round(data.shiftMinGap / 60) : undefined;
      childProps.reset(data);
      setSettings(data);
      let CC:any = await Api.get<string, undefined>(Endpoint.SETTINGS_CC);
      if(CC=='true'){
        CC=true
      }
      else{CC=false}
      setENABLE_CC(CC);
    };
    refresh();
  }, [childProps])

  useEffect(() => {
    ref();
  }, []);
const onChange=(e:any)=>{
  console.log('This is onchange:',e);
}
  useEffect(() => {
    console.log("ENABLE_CC: ", ENABLE_CC)
  }, [ENABLE_CC]);

  return (
    ENABLE_CC !== undefined ? <FormSection
      title={t("vehicleRequirements.title")}
      subtitle={t("vehicleRequirements.subTitle")}
    >
      <div className="row">
        <div className="col">
          <FormCheckBox
            label={translationForm.t("vehicle.registrationSection.plateNumber")}
            name="vehicleRequirementPlateNumber"
            validation={{ required: true }}
            labelPositon={LabelPositon.Left}
            checked={true}
            disabled={true}
            {...childProps}
          />
          <FormCheckBox
            label={translationForm.t("vehicle.registrationSection.vinDigits")}
            name="vehicleRequirementVIN"
            validation={{ required: true }}
            labelPositon={LabelPositon.Left}
            checked={true}
            disabled={true}
            {...childProps}
          />
        </div>
        <div className="col">
          <FormCheckBox
            label={translationForm.t("vehicle.modelSection.year")}
            name="vehicleRequirementYear"
            validation={{ required: true }}
            labelPositon={LabelPositon.Left}
            checked={true}
            disabled={true}
            {...childProps}
          />
          <FormCheckBox
            label={translationForm.t("vehicle.modelSection.make")}
            name="vehicleRequirementMake"
            validation={{ required: true }}
            labelPositon={LabelPositon.Left}
            checked={true}
            disabled={true}
            {...childProps}
          />

        </div>
      </div>
      <div className="row">
        <div className="col">
        <FormCheckBox
            label={translationForm.t("vehicle.modelSection.color")}
            name="vehicleRequirementColor"
            validation={{ required: true }}
            labelPositon={LabelPositon.Left}
            checked={true}
            disabled={true}
            {...childProps}
          />
         

<FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("vehicle.modelSection.numberOfPassenger")}
            name="vehicleRequirementNumberofPassengers"
            validation={{ required: true }}
            checked={true}
            disabled={true}
            {...childProps}
          />
        </div>
        <div className="col">
          
<FormCheckBox
            label={translationForm.t("vehicle.modelSection.model")}
            name="vehicleRequirementModel"
            validation={{ required: true }}
            labelPositon={LabelPositon.Left}
            checked={true}
            disabled={true}
            {...childProps}
          />
         
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("vehicle.modelSection.numberOfLuggages")}
            name="vehicleRequirementNumberofLuggages"
            validation={{ required: true }}
            checked={true}
            disabled={true}
            {...childProps}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
        <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("vehicle.operatorSection.operatorsLabel")}
            name="vehicleRequirementOperator"
            validation={{ required: false }}
             checked={true}
            disabled={true}
            {...childProps}
          />
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("vehicle.modelSection.insurancePictureLabel")}
            name="vehicleRequirementInsurance"
            validation={{ required: false }}
            {...childProps}
          />
        </div>
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("vehicle.registrationSection.title")}
            name="vehicleRequirementRegistration"
            validation={{ required: false }}
            {...childProps}
          />
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("vehicle.fleetSection.fleetsLabel")}
            name="vehicleRequirementFleet"
            validation={{ required: false }}
            {...childProps}
          /></div>
      </div>
      <div className="row">
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("vehicle.networkSection.networksLabel")}
            name="vehicleRequirementNetwork"
            validation={{ required: false }}
            {...childProps}
          />
        

<FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("vehicle.modelSection.vehiclePictureLabel")}
            name="vehicleRequirementPictures"
            validation={{ required: false }}
            {...childProps}
          />
        </div>
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("vehicle.serviceSection.servicesLabel")}
            name="vehicleRequirementService"
            validation={{ required: false }}
            {...childProps}
          />
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("vehicle.featureSection.featuresLabel")}
            name="vehicleRequirementFeature"
            validation={{ required: false }}
            {...childProps}
          /></div>
      </div>

      <div className="row">
        <div className="col">
          <FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("vehicle.registrationSection.platePictureLabel")}
            name="vehicleRequirementPlatePicture"
            validation={{ required: false }}
            {...childProps}
          />

<FormCheckBox
            labelPositon={LabelPositon.Left}
            label={translationForm.t("vehicle.registrationSection.registrationExpiry")}
            name="vehicleRequirementRegistrationExpiry"
            validation={{ required: false }}
            {...childProps}
          />
         
        </div>
       
      </div>
      
      
    </FormSection> : null
  );
}