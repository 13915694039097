import { IEntityBase, IEntityCreatedBy, IEntityTimestamp } from "../interfaces/entity.interface";

export enum InspectionType {
  Driver = 'driver',
  Vehicle = 'vehicle',
  Shift = 'shift',
}

export interface Inspection extends IEntityBase, IEntityTimestamp, IEntityCreatedBy {
  [key: string]: any; 
  title: string;
  type: InspectionType;
}