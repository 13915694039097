import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormAddressInput } from "../../../../components/form/input/address.component";
import { FormInput } from "../../../../components/form/input/input.component";
import { PhoneInputs } from "../../../../components/form/input/phoneNumber";
import { FormSection } from "../../../../components/form/section.component";

export function OperatorPostBasic({formMode, value, onChange}:any) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  console.log("form props",formMode)

  return (
    <FormSection
      title={t("operator.basicSection.title")}
      subtitle={t("operator.basicSection.subTitle")}
    >
      <div className="row">
        <div className="col-md-6">
          <FormInput
            label={t("operator.basicSection.title")}
            name="title"
            type="text"
            validation={{ required: true }}
            tabIndex={1}
            autoFocus
            {...formProps}
          />
          {formMode == 0 ?<FormInput
            label={t("common.password")}
            name="password"
            type="password"
            validation={{ required: true }}
            tabIndex={2}
            
            {...formProps}
          />:null}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormInput
            label={t("operator.basicSection.contactPerson")}
            name="contactPerson"
            type="text"
            validation={{ required: true }}
            tabIndex={2}
            {...formProps}
          />
        </div>
        <div className="col-md-6">
        <PhoneInputs
          placeholder={t("operator.basicSection.contactPhone")} 
          value={value}
          onChange={onChange}
          name="contactPhone"

          className="col-md-12"  
          {...formProps}
        /> 
          
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormInput
            label={t("operator.basicSection.contactEmail")}
            name="contactEmail"
            type="email"
            validation={{ required: true }}
            tabIndex={4}
            {...formProps}
          />
        </div>
        <div className="col-md-6">
          <FormAddressInput
            label={t("operator.basicSection.contactAddress")}
            name="contactAddress"
            validation={{ required: true }}
            tabIndex={5}
            {...formProps}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormInput
            label={t("operator.basicSection.businessRegistration")}
            name="businessRegistration"
            type="text"
            validation={{ required: false }}
            tabIndex={6}
            {...formProps}
          />
        </div>
        <div className="col-md-6">
          <FormInput
            label={t("operator.basicSection.accreditionNumber")}
            name="accreditionNumber"
            type="text"
            validation={{ required: false }}
            tabIndex={7}
            {...formProps}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormInput
            label={t(`${process.env.REACT_APP_ROUTING_LABEL}`)}
            name="bankSwiftCode"
            type="text"
            validation={{ required: false }}
            tabIndex={8}
            {...formProps}
          />
        </div> 
        <div className="col-md-6">
          <FormInput
            label={t("operator.basicSection.bankName")}
            name="bankName"
            type="text"
            validation={{ required: false }}
            tabIndex={8}
            {...formProps}
          />
        </div>
      </div>   
      <div className="row">
        <div className="col-md-6">
          <FormInput
            label={t("operator.basicSection.bankAccountNumber")}
            name="bankAccountNumber"
            type="number"
            validation={{ required: false }}
            tabIndex={8}
            {...formProps}
          />
        </div> 
        <div className="col-md-6">
          <FormInput
            label={t("operator.basicSection.bankAccountTitle")}
            name="bankAccountTitle"
            type="text"
            validation={{ required: false }}
            tabIndex={8}
            {...formProps}
          />
        </div>
      </div> 
    </FormSection>
  );

}
