import { Acl } from "../../interfaces/core.interface";

export enum SecurityAclCode {
  IncidentList = 'incident-list',
  IncidentCreate = 'incident-create',
  IncidentUpdate = 'incident-update',
  IncidentDelete = 'incident-delete',
  VideoMonitoring = 'monitoring',
  TrackVehicle = 'track',
  Protocols = 'protocols',
  EmergencyList = "emergencyList",
  TrackShift = "track-shift"
}

export const SecurityAcl = [
  {
    code: SecurityAclCode.IncidentList,
    title: 'security.incidentList.title',
    description: 'security.incidentList.subTitle',
  },
  {
    code: SecurityAclCode.IncidentCreate,
    title: 'security.incidentCreate.title',
    description: 'security.incidentCreate.subTitle',
  },
  {
    code: SecurityAclCode.IncidentUpdate,
    title: 'security.incidentUpdate.title',
    description: 'security.incidentUpdate.subTitle',
  },
  {
    code: SecurityAclCode.IncidentDelete,
    title: 'security.incidentDelete.title',
    description: 'security.incidentDelete.subTitle',
  },
  {
    code: SecurityAclCode.VideoMonitoring,
    title: 'security.monitoring.title',
    description: 'security.monitoring.subTitle',
  },
  {
    code: SecurityAclCode.TrackVehicle,
    title: 'security.track.title',
    description: 'security.track.subTitle',
  },
  {
    code: SecurityAclCode.TrackShift,
    title: 'security.trackShift.title',
    description: 'security.trackShift.subTitle',
  },
  {
    code: SecurityAclCode.Protocols,
    title: 'security.protocols.title',
    description: 'security.protocols.subTitle',
  },
  {
    code: SecurityAclCode.EmergencyList,
    title: 'security.emergency.title',
    description: 'security.emergency.subTitle'
  }
] as Acl<SecurityAclCode>;