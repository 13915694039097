import { RouteProps } from "react-router-dom";
import { ArticleList } from "./list/list.component";
import { ArticlePost } from "./post/post.component";

export enum ArticleRoute {
  List = '/article/list',
  Post = '/article/post',
}

export const ArticleRouteMap = [
  {
    path: ArticleRoute.List,
    component: ArticleList,
  },
  {
    path: ArticleRoute.Post,
    component: ArticlePost,
  },
] as RouteProps[];
