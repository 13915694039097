import { RouteProps } from "react-router-dom";
import { RequestCategoryList } from "./list/list.component";
import { RequestCategoryPost } from "./post/post.component";

export enum RequestCategoryRoute {
  List = '/requestCategory/list',
  Post = '/requestCategory/post',
}

export const RequestCategoryRouteMap = [
  {
    path: RequestCategoryRoute.List,
    component: RequestCategoryList,
  },
  {
    path: RequestCategoryRoute.Post,
    component: RequestCategoryPost,
  },
] as RouteProps[];
