import { Acl } from "../../interfaces/core.interface";

export enum EmergencyAclCode {
    Add = 'add',
    Handled = 'handled',
    Action = 'action'
}

export const EmergencyAcl = [
  {
    code: EmergencyAclCode.Add,
    title: 'Emergency.add.title',
    description: 'Emergency.add.subTitle',
  },
  {
    code: EmergencyAclCode.Handled,
    title: 'Emergency.handled.title',
    description: 'Emergency.handled.subTitle',
  },
  {
    code: EmergencyAclCode.Action,
    title: 'Emergency.action.title',
    description: 'Emergency.action.subTitle',
  },
] as Acl<EmergencyAclCode>;
