import React, { useCallback, useEffect, useState } from "react";
import { RideReport } from "../../../report/list/rides.component";


export function CorporateBookingList(props: any) {

  const corporateColumns = ["Company Name"]

  return (
  <RideReport isCorporate={true}
   cooperateCol={corporateColumns}
   />
  );

}
