import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormPropsContext } from "../../../../components/form/form.component";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormRadio } from "../../../../components/form/input/radio.component";
import { FormUpload, UploadPreviewUrl } from "../../../../components/form/input/upload.component";
import { FormSection } from "../../../../components/form/section.component";
import { getUrl } from "../../../../components/form/util";
import { Driver } from "../../../../entities/driver.entity";
import { FormMode } from "../../../../enums/core.enum";

export function DriverPostPublic() {

  const { t } = useTranslation('forms');
  const { mode } = useContext(FormPropsContext);
  const formProps = useFormContext();
  const { getValues } = formProps;

  const getProfilePicUrls = (): UploadPreviewUrl[] | undefined => {
    const form: Driver = getValues() as Driver; 
    console.log('form:', form)
    if (form.id && mode === FormMode.Editing) {
      return [getProfilePic(form)];
    }
    return undefined;
  }

  const getProfilePic = (driver: Driver): UploadPreviewUrl => {
    const pic = driver.picFile; 
    return {
      thumb: getUrl(`/driver/${driver.id}/pic/${pic?.id}/admin_single.${pic?.extension}`),
      full: getUrl(`/driver/${driver.id}/pic/${pic?.id}/admin_large.${pic?.extension}`),
    }
  } 

  return (
    <FormSection
      title={ t("driver.publicSection.title") }
      subtitle={ t("driver.publicSection.subTitle") }
    >
      <div className="row">
        <FormUpload
          urls={ getProfilePicUrls() }
          label={ t("driver.publicSection.profilePic") }
          name="newPicFile"
          originalName="picFile"
          className="col-md-4 col-sm-12"
          validation={{ required: true }}
          tabIndex={4}
          { ...formProps }
        />
        <div className="col-md-4 col-sm-12">
          <FormInput
            label={ t("common.firstName") }
            name="firstName"
            type="text"
            autoFocus
            tabIndex={1}
            validation={{ required: true }}
            { ...formProps }
          />
          <FormRadio label={ t("common.gender") } name="gender" validation={{ required: true }} { ...formProps }
            options={[
              { label: t("common.genderMale"), value: 'male' },
              { label: t("common.genderFemale"), value: 'female' },
            ]}
            tabIndex={3}
          />
        </div>
        <div className="col-md-4 col-sm-12">
          <FormInput
            label={ t("common.lastName") }
            name="lastName"
            type="text"
            validation={{ required: true }}
            tabIndex={2}
            { ...formProps }
          />
        </div>
      </div>
    </FormSection>
  );

}
