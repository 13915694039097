import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { RequestCategory } from "../../../entities/request-category.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';
import { RequestCategoryPost } from "../post/post.component";
import { SelectedItemContent } from "./content.component";

const groupFilters: GroupFilter[] = [
  { title: 'All', icon: 'phone-21' },
];

export function RequestCategoryList(props: any) {


  const { t } = useTranslation('list');
  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('')
  const [allCount, setAllCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [requestCategorys, setRequestCategorys] = useState<RequestCategory[] | undefined>(undefined);
  const [selectedRequestCategory, setSelectedRequestCategory] = useState<RequestCategory>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [size, setSize] = useState<number>()


  useEffect(() => {
    setSelectedRequestCategory(props.location.state);
  }, [props.location.state]);

  useEffect(() => {
    setSelectedRequestCategory(props.location.state)
  }, [props.location.state])
  const refresh = useCallback(async (e?: any) => {
    const { items, totalItems } = await Api.get<IPaginationResponse<RequestCategory>, IPaginationOptions>(Endpoint.REQUEST_CATEGORY_LIST, { page: 0, limit, search });
    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }
    if (items)
      setRequestCategorys(items);

    setAllCount(totalItems)

    if (items.length > 0 && !selectedRequestCategory) {
      setSelectedRequestCategory(items[0]);
    }
    if (selectedRequestCategory && e) {
      const data = items.filter((item: RequestCategory) => { return item.id == selectedRequestCategory.id })
      setSelectedRequestCategory(data[0]);

    }

  }, [search, limit, activeGroupFilter, selectedRequestCategory]);

  useEffect(() => { refresh(); }, [refresh]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const deleteRequestCategory = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.REQUEST_CATEGORY, { id: selectedRequestCategory?.id });
    setSelectedRequestCategory(undefined);
    refresh();
  }

  const toListItems = (requestCategory?: RequestCategory): ListItem<RequestCategory> | undefined => {
    if (!requestCategory) {
      return;
    }

    return {
      id: requestCategory.id as string,
      title: requestCategory.title,
      status: ListItemStatus.Green,
      ref: requestCategory
    }
  }

  const addRequestCategory = () => {
    setFormMode(FormMode.Adding);
  }

  const editRequestCategory = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (requestCategorys) {
      const currentRequestCategoryIndex = requestCategorys.findIndex(requestCategory => !!selectedRequestCategory && requestCategory.id === selectedRequestCategory.id);
      if (currentRequestCategoryIndex + 1 < requestCategorys.length && currentRequestCategoryIndex !== -1) {
        setSelectedRequestCategory(requestCategorys[currentRequestCategoryIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (requestCategorys) {
      const currentRequestCategoryIndex = requestCategorys.findIndex(requestCategory => !!selectedRequestCategory && requestCategory.id === selectedRequestCategory.id);
      if (currentRequestCategoryIndex - 1 > -1 && currentRequestCategoryIndex !== -1) {
        setSelectedRequestCategory(requestCategorys[currentRequestCategoryIndex - 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedRequestCategory(undefined);
    setActiveGroupFilter(target);
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: 'All', icon: 'phone-21', count: allCount },
                ]}
                activeGroupFilter={activeGroupFilter}
                onGroupSelect={selectGroup}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={requestCategorys?.map(toListItems) as ListItem<RequestCategory>[]}
                activeItem={toListItems(selectedRequestCategory)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setRequestCategorys(undefined); refresh(); }}
                onClick={(item: ListItem<RequestCategory>) => { setSelectedRequestCategory(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                requestCategorys !== undefined ? (
                  selectedRequestCategory ? (
                    <View
                      componentName={"requestCategory"}
                      showAdd={true}
                      showEdit={true}
                      showDelete={false}
                      showNext={true}
                      showPrev={true}
                      showAudit={true}
                      onAdd={addRequestCategory}
                      onEdit={editRequestCategory}
                      onDelete={deleteRequestCategory}
                      onNext={onNext}
                      onPrev={onPrev}
                      onAudit={() => checkAudit(selectedRequestCategory.id)}
                    >
                      <SelectedItemContent
                        selectedRequestCategory={selectedRequestCategory}
                      />
                    </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button onClick={addRequestCategory} className="btn btn-primary text-bold">{t('requestCategory.addRequestCategory')}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t('requestCategory.wait')}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedRequestCategory ? (
                  <RequestCategoryPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedRequestCategory.id}
                    onAfterSave={() => refresh()}
                  />
                ) : (
                  <RequestCategoryPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    onAfterSave={() => refresh()}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
            groupFilters={[
              { title: 'All', icon: 'phone-21', count: allCount },
            ]}
            activeGroupFilter={activeGroupFilter}
            onGroupSelect={selectGroup}
          />
          <ListItems
            items={requestCategorys?.map(toListItems) as ListItem<RequestCategory>[]}
            activeItem={toListItems(selectedRequestCategory)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setRequestCategorys(undefined); refresh(); }}
            onClick={(item: ListItem<RequestCategory>) => { setSelectedRequestCategory(item.ref); }}
          />

          {formMode === undefined ? (
            requestCategorys !== undefined ? (
              selectedRequestCategory ? (
                <View
                  componentName={"requestCategory"}
                  showAdd={true}
                  showEdit={true}
                  showDelete={false}
                  showNext={true}
                  showPrev={true}
                  showAudit={true}
                  onAdd={addRequestCategory}
                  onEdit={editRequestCategory}
                  onDelete={deleteRequestCategory}
                  onNext={onNext}
                  onPrev={onPrev}
                  onAudit={() => checkAudit(selectedRequestCategory.id)}
                >
                  <SelectedItemContent
                    selectedRequestCategory={selectedRequestCategory}
                  />
                </View>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addRequestCategory} className="btn btn-primary text-bold">{t('requestCategory.addRequestCategory')}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t('requestCategory.wait')}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedRequestCategory ? (
              <RequestCategoryPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedRequestCategory.id}
                onAfterSave={() => refresh(true)}
              />
            ) : (
              <RequestCategoryPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh(true)}
              />
            )
          )}
        </List>
      }
    </div>
  );
}
