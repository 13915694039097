import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "../../../../components/form/input/select.component";
import { FormSection } from "../../../../components/form/section.component";
import { Network } from "../../../../entities/network.entity";
import { Settings } from "../../../../entities/settings.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../services/api.service";

interface props{
  settings: Settings|undefined
}

export function DriverPostNetwork({settings}:props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const [network, setNetworks] = useState<Network[]>([]);
  
  useEffect(() => { init();
  return ()=>{
    setNetworks([]);
  } }, [])

  const init = async () => {
    const networkList = await Api.get<IPaginationResponse<Network>, IPaginationOptions>(Endpoint.NETWORK_LIST);
    setNetworks(networkList.items);
  }
  
  return (
    settings?<FormSection
      title={ t("driver.networkSection.title") }
      subtitle={ t("driver.networkSection.subTitle") }
    >
      <div className="row">
        <FormSelect<Network>
          label={ t("driver.networkSection.networkLabel") }
          name="networks"
          className="col-sm-12"
          showLabel={ true }
          multiple={ true }
          validation={{required:settings?.driverRequirementNetworks?true:false}}
          items={ network }
          labelField="title"
          tabIndex={0}
          { ...formProps }
        />
      </div>
    </FormSection>:null
  );

}
