import { RouteProps } from "react-router-dom";
import { InspectionPost } from "./post.component";

export enum InspectionRoute {
  Post = '/inspection/post',
}

export const InspectionRouteMap = [
  {
    path: InspectionRoute.Post,
    component: InspectionPost,
  }
] as RouteProps[];
