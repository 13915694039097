import { Circle, GoogleMap, Marker } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheckBox } from "../../../components/form/input/checkbox.component";
import { FormInput } from "../../../components/form/input/input.component";
import { FormSection } from "../../../components/form/section.component";
import { Role, RoleAssignment } from "../../../entities/role.entity";

interface Props {
  marker: google.maps.LatLng|undefined;
  setMarker: (e:any) => void;
}
export function AddCustomTolls({setMarker,marker}:Props) {
  const [checked, setChecked] = useState<boolean>(false);

  const [map, setMap] = useState<google.maps.Map>()
  const [zoom, setZoom] = useState(18);
  const [center, setCenter] = useState<google.maps.LatLng>();
  const { t } = useTranslation('forms');

  const [point, setPoint] = useState<google.maps.Point>();
  const [radius, setRadius] = useState<google.maps.Circle>();
  const [size, setSize] = useState<google.maps.Size>();
  const onLoad = React.useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    map.setOptions({
      draggableCursor: "pointer",
    });
    setMap(map)
    setTimeout(() => {
      const DEFAULT_CENTER = new google.maps.LatLng(-33.8688, 151.2093);
      const DEFAULT_POINT = new google.maps.Point(32, 32);
      const DEFAULT_SIZE = new google.maps.Size(64, 64);
      console.log("This is Marker:",marker)
      var circle = new google.maps.Circle({
        center: marker,
        map: map,
        radius: 20,         
        fillColor: '#FF6600',
        fillOpacity: 0.3,
        strokeColor: "#FFF",
        strokeWeight: 0,
    });
    setRadius(circle);
      setCenter(marker?marker:DEFAULT_CENTER);
      setZoom(marker?10:2)
      setPoint(DEFAULT_POINT)
      setSize(DEFAULT_SIZE)
    }, 100);
  }, [])

  const onUnmount = React.useCallback(() => {
    setMap(undefined)
  }, []);
  const mapclicked = (e: any) => {
    console.log("map clicked", e.latLng)
    setMarker(e.latLng);
    radius?.setMap(null);
    var circle = new google.maps.Circle({
      center: e.latLng,
      map: map,
      radius: 20,        
      fillColor: '#FF6600',
      fillOpacity: 0.3,
      strokeColor: "#FFF",
      strokeWeight: 0,
      clickable:false
  });
  setRadius(circle);
  //   setRadius(new google.maps.Circle({
  //     center: e.latLng,
  //     map: map,
  //     strokeColor: '#000',
  //     strokeWeight: 2,
  //     strokeOpacity: 0.5,
  //     fillColor: '#f0f0f0',
  //     fillOpacity: 0.5,
  //     radius: 2 * 1000
  // }));
  }
  const formProps = useFormContext();

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  return (
    <FormSection
      title=""
      subtitle=""
    >
      <div className="row">
      </div>
      <FormInput
        name="title"
        label={t("common.title")}
        type="text"
        tabIndex={1}
        autoFocus = {true}
        validation={{ required: true }}
        {...formProps}
      />
            <FormInput
        name="price"
        label={t("common.price")}
        type="number"
        step={0.01}
        tabIndex={2}
        autoFocus = {false}
        validation={{ required: true }}
        {...formProps}
      />
      <GoogleMap
        mapContainerStyle={{ height: `650px`, width: '100%' }}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={mapclicked}
      >
        {marker && <Marker position={marker} />}
      </GoogleMap>
    </FormSection>
  );

}
