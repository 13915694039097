import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Currency } from '../../../entities/currency.entity';
import { Fare } from '../../../entities/fare.entity';
import { Settings } from '../../../entities/settings.entity';
import { Api, Endpoint } from '../../../services/api.service';
import { SettingsService } from '../../../services/settings.service';
import { FareRoute } from '../routes';

interface Props {
  fare: Fare | undefined,
  onDelete?: () => void,
}

/**
 * Displays a fare's details and actions
 * Triggers onDelete when current fare is deleted
 */
export function FareDashboardView({ fare, onDelete }: Props) {

  const { t } = useTranslation('main');
  /**
   * Decides whether to show fare delete confirmation
   * prompt
   */
  const [deleteFarePrompt, setDeleteFarePrompt] = useState<boolean>(false);

  /**
   * Tracks whether fare is currently being deleted
   */
  const [deletingFare, setDeletingFare] = useState<boolean>(false);
  const [symbol, setSymbol] = useState<string | null>('')
  const [subUnit, setSubUnit] = useState<number>(100)
  const settings: Settings | undefined = SettingsService.getSetting();
  useEffect(()=>{
    
    const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;
    const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
   
    setSubUnit(unit)
    setSymbol(currency)
  },[])

  /**
   * Delete current fare
   */
  const deleteFare = async () => {
    if (!fare) {
      return;
    }

    // Set fare deleting boolean to show indicator
    setDeletingFare(true);
    await Api.delete(Endpoint.FARE, { id: fare.id });
    // Reset fare deleting boolean to hide indicator
    setDeletingFare(false);

    // Hide the fare delete confirmation prompt
    setDeleteFarePrompt(false);

    if (onDelete) {
      onDelete();
    }
  }

  /**
   * Generates fare edit link
   * 
   * @param fare Fare
   * @returns string
   */
  const getFareEditLink = (fare: Fare): string => {
    return `${FareRoute.Post}?id=${fare.id}`;
  }

  const toSubUnit = (value: number): number => {
    const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
    console.log("unit",unit,value)
    return value / unit;
  }
  /**
   * Render
   */
  if (!fare) {
    return null;
  }
  return (
    <div className="balance-table pl-sm-2">
      <table className="table table-lightborder table-bordered table-v-compact mb-0">
        <thead>
          {!deleteFarePrompt ? (
            <tr>
              <th>
                <Link to={getFareEditLink(fare)}>
                  <i className="os-icon os-icon-pencil-1"></i> {t("form.controls.edit")}
                </Link>
              </th>
              <th>
                <a href="#delete-fare" onClick={() => { setDeleteFarePrompt(true); }}>
                  <i className="os-icon os-icon-trash"></i> {t("form.controls.delete")}
                </a>
              </th>
            </tr>
          ) : (
              <tr>
                <th>
                  <button className="btn btn-danger" disabled={deletingFare} onClick={deleteFare}>
                    {!deletingFare ? 'Delete' : 'Deleting...'}
                  </button>
                </th>
                <th>
                  <button className="btn btn-primary" disabled={deletingFare} onClick={() => { setDeleteFarePrompt(false); }}>
                    {t("form.controls.cancel")}
                  </button>
                </th>
              </tr>
            )}
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>{symbol}{toSubUnit(fare.startCharge)}</strong>
              <div className="balance-label smaller lighter text-nowrap">{t("form.controls.start")}</div>
            </td>
            <td>
              <strong>{symbol}{toSubUnit(fare?.minimumCharge)}</strong>
              <div className="balance-label smaller lighter text-nowrap">{t("mainMenu.fares.minimum")}</div>
            </td>
          </tr>
          <tr>
            <td>
              <strong>{symbol}{toSubUnit(fare.distanceChargePerThreshold)}</strong>
              <div className="balance-label smaller lighter text-nowrap">{t("mainMenu.fares.distanceCharge")}</div>
            </td>
            <td>
              <strong>{fare.distanceThreshold}m</strong>
              <div className="balance-label smaller lighter text-nowrap">{t("mainMenu.fares.distanceThreshold")}</div>
            </td>
          </tr>
          <tr>
            <td>
              <strong>{symbol}{toSubUnit(fare.waitChargePerThreshold)}</strong>
              <div className="balance-label smaller lighter text-nowrap">{t("mainMenu.fares.waitCharge")}</div>
            </td>
            <td>
              <strong>{fare.waitThreshold}s</strong>
              <div className="balance-label smaller lighter text-nowrap">{t("mainMenu.fares.waitThreshold")}</div>
            </td>
          </tr>
          <tr>
            <td>
              <strong>{fare?.freeDistance}m</strong>
              <div className="balance-label smaller lighter text-nowrap">{t("mainMenu.fares.free")}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
