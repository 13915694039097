import React from "react";
import { useEffect } from "react";
import { Redirect, Route } from "react-router";
import { BookingStatus } from "../../../entities/booking.entity";
import { Settings } from "../../../entities/settings.entity";
import { SettingsService } from "../../../services/settings.service";
import { Currency } from "../../../entities/currency.entity";
import { useState } from "react";
import { Api, Endpoint } from "../../../services/api.service";
import { Zone } from "../../../entities/zone.entity";
import { useTranslation } from "react-i18next";
import { getGeneralToSubUnit } from "../../../util/distance";

export interface Activity {
  route: string
  type: string
  status: BookingStatus
  estimate: string
  timestamp: string
  bookingCode: string
  currencySymbol?: string
  zone:string|Zone

}

interface Props {
  title: string
  activities?: Array<Activity>
  color: string
  onclick: any
  cancelBooking?: any
}

export function DashboardBookingActivity(props: Props) {
  const [symbol, setSymbol] = useState<string | null>('')
  const {t} = useTranslation('main')
  useEffect(() => {

    const settings: Settings | undefined = SettingsService.getSetting();
    const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;
    setSymbol(currency)
    console.log("BOOKING DATA ::", props.activities)
  }, [])

  const onClick = (bookingCode: string) => {
    props.onclick(bookingCode);
  }

 
  const cancelBooking = async (code: any) => {
    props.cancelBooking(code)
  }
  const dateFormate = (date: any) => {
    const settings: Settings | undefined = SettingsService.getSetting(); 

    let d:any = new Date(date)
    return d.toLocaleString(undefined, { timeZone: (settings as Settings)?.zone?.generalTimezone });
    
  }
  return (
    <div className="element-wrapper">
      <div className="element-box">
        <h6 className="element-header">
          <span className={`badge badge-${props.color}`}>{props?.activities?.length}</span> &nbsp;
          {props.title}
        </h6>


        <table className="table table-clean">
          <tbody>
            {props?.activities?.map((activity,i:number) => (
              <React.Fragment key={i}>
                <tr role="button" onClick={() => onClick(activity.bookingCode)} className="hovering" >
                  <td style={{ border: "1px solid white" }}>
                    <div className="value"><span className="badge badge-success">{`${activity.bookingCode}`}</span> {activity.route}</div>
                    <span className="sub-value">{activity.status}</span>
                  </td>
                  <td className="text-right">
                    <div className="value">{getGeneralToSubUnit(parseInt(activity?.estimate ? activity?.estimate : "0"))}</div>
                    <div><span className="sub-value">{dateFormate(activity.timestamp)}</span></div>
                  </td>
                </tr>
                {activity.status === BookingStatus.NotAccepted || activity.status === BookingStatus.PassengerCancelled || activity.status === BookingStatus.DriverCancelled ?
                  null :
                  <tr style={{ textAlign: "center", borderBottom: "1px solid whitesmoke" }}>
                    <td colSpan={2}>
                      <button style={{ width: "50%", padding:"10px" }} className="btn btn-danger btn-md" onClick={() => { cancelBooking(activity.bookingCode) }}>{t('mainMenu.dashboard.cancelBooking')}</button>
                    </td>
                  </tr>
                }
              </React.Fragment>
            ))}

          </tbody>
        </table>
      </div>
    </div>
  );
}
