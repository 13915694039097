import { RouteProps } from "react-router-dom";
import { UserList } from "./manage/list/list.component";
import { UserPost } from "./manage/post/post.component";
import { UserRoleList } from "./role/list/list.component";
import { UserRolePost } from "./role/post/post.component";

export enum UserRoute {
  List = '/user/list',
  Post = '/user/post',
  ListRole = '/user/role/list',
  PostRole = '/user/role/post',
}

export const UserRouteMap = [
  {
    path: UserRoute.List,
    component: UserList,
  },
  {
    path: UserRoute.Post,
    component: UserPost,
  },
  {
    path: UserRoute.ListRole,
    component: UserRoleList,
  },
  {
    path: UserRoute.PostRole,
    component: UserRolePost,
  },
] as RouteProps[];
