import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../../components/alerts/loading.component";
import { ListItem, ListItems, ListItemStatus } from "../../../../components/list/items.component";
import { List } from "../../../../components/list/wrapper.component";
import { View } from '../../../../components/view/wrapper.component';
import { Incident } from "../../../../entities/incident.entity";
import { FormMode } from "../../../../enums/core.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../../services/api.service';
import IncidentPost, { FormStyle } from "../post/post.component";
import SelectedItemContent from "./content.component";

export default function IncidentList() {

  const { t } = useTranslation('list');
  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [incidents, setIncidents] = useState<Incident[]>();
  const [selectedIncident, setSelectedIncident] = useState<Incident>();
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [size, setSize] = useState<number>()

  const refresh = useCallback(async () => {
    setIncidents([]);
    const { items, totalItems } = await Api.get<IPaginationResponse<Incident>, IPaginationOptions>(Endpoint.INCIDENT_LIST, { page: 0, limit, search });

    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items.length > 0 && !selectedIncident) {
      setSelectedIncident(items[0]);
    }
    setIncidents(items);
  }, [search, limit, selectedIncident]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const deleteIncident = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.INCIDENT, { id: selectedIncident?.id });
    setSelectedIncident(undefined);
    refresh();
  }

  const editIncident = () => {
    setFormMode(FormMode.Editing);
  }

  const addIncident = () => {
    setFormMode(FormMode.Adding);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (incidents) {
      const currentIncidentIndex = incidents.findIndex(incident => !!selectedIncident && incident.id === selectedIncident.id);
      if (currentIncidentIndex + 1 < incidents.length && currentIncidentIndex !== -1) {
        setSelectedIncident(incidents[currentIncidentIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (incidents) {
      const currentIncidentIndex = incidents.findIndex(incident => !!selectedIncident && incident.id === selectedIncident.id);
      if (currentIncidentIndex - 1 > -1 && currentIncidentIndex !== -1) {
        setSelectedIncident(incidents[currentIncidentIndex - 1]);
      }
    }
  }

  const toListItems = (incident?: Incident): ListItem<Incident> | undefined => {
    if (!incident) {
      return;
    }

    return {
      id: incident.id as string,
      title: incident.title,
      status: ListItemStatus.Green,
      ref: incident,
    };
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListItems
                items={incidents?.map(toListItems) as ListItem<Incident>[]}
                activeItem={toListItems(selectedIncident)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setIncidents(undefined); refresh(); }}
                onClick={(item: ListItem<Incident>) => { setSelectedIncident(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                incidents !== undefined ? (
                  selectedIncident ? (
                    <View
                      componentName={"incident"}
                      showAdd={true}
                      showEdit={true}
                      showDelete={true}
                      showNext={true}
                      showPrev={true}
                      showAudit={true}
                      onAdd={addIncident}
                      onEdit={editIncident}
                      onDelete={deleteIncident}
                      onNext={onNext}
                      onPrev={onPrev}
                      onAudit={() => checkAudit(selectedIncident.id)}
                    >
                      <SelectedItemContent
                        selectedIncident={selectedIncident}
                      />
                    </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button onClick={addIncident} className="btn btn-primary text-bold">{t("incident.addIncident")}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t("incident.wait")}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedIncident ? (
                  <IncidentPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedIncident.id}
                    onAfterSave={() => refresh()}
                  />
                ) : (
                  <IncidentPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    onAfterSave={() => refresh()}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListItems
            items={incidents?.map(toListItems) as ListItem<Incident>[]}
            activeItem={toListItems(selectedIncident)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setIncidents(undefined); refresh(); }}
            onClick={(item: ListItem<Incident>) => { setSelectedIncident(item.ref); }}
          />

          {formMode === undefined ? (
            incidents !== undefined ? (
              selectedIncident ? (
                <View
                  componentName={"incident"}
                  showAdd={true}
                  showEdit={true}
                  showDelete={true}
                  showNext={true}
                  showPrev={true}
                  showAudit={true}
                  onAdd={addIncident}
                  onEdit={editIncident}
                  onDelete={deleteIncident}
                  onNext={onNext}
                  onPrev={onPrev}
                  onAudit={() => checkAudit(selectedIncident.id)}
                >
                  <SelectedItemContent
                    selectedIncident={selectedIncident}
                  />
                </View>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addIncident} className="btn btn-primary text-bold">{t("incident.addIncident")}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t("incident.wait")}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedIncident ? (
              <IncidentPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedIncident.id}
                onAfterSave={() => refresh()}
              />
            ) : (
              <IncidentPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh()}
              />
            )
          )}
        </List>
      }
    </div>
  );
}
