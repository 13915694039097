import { Acl } from "../../interfaces/core.interface";

export enum PassengerAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Audit = 'audit'
}

export const PassengerAcl = [
  {
    code: PassengerAclCode.ListAll,
    title: 'passenger.listAll.title',
    description: 'passenger.listAll.subTitle',
  },
 
  {
    code: PassengerAclCode.ListOwn,
    title: 'passenger.listOwn.title',
    description: 'passenger.listOwn.subTitle',
  },
  {
    code: PassengerAclCode.Create,
    title: 'passenger.create.title',
    description: 'passenger.create.subTitle',
  },
  {
    code: PassengerAclCode.Update,
    title: 'passenger.update.title',
    description: 'passenger.update.subTitle',
  },
  {
    code: PassengerAclCode.Delete,
    title: 'passenger.delete.title',
    description: 'passenger.delete.subTitle',
  },
  {
    code: PassengerAclCode.Audit,
    title: 'passenger.audit.title',
    description: 'passenger.audit.subTitle',
  },
] as Acl<PassengerAclCode>;
