import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Role, RoleAssignment } from "../../../entities/role.entity";
import { ModuleName } from "../../../enums/core.enum";
import { Api, Endpoint } from "../../../services/api.service";
import { AuthService } from "../../../services/auth.service";
import todo from './todo.json';

interface TodoItem {
  header: string;
  subHeader: string;
  completed: boolean;
  link: string;
  module: string
}

interface DashboardListResponse {
  zoneCount: number;
  vehicleCount: number;
  serviceCount: number;
  driverCount: number;
  fareCount: number;
  settingCount: number;
}


export function DashboardOverviewTodo() {
  const [zoneCount, setZoneCount] = useState<number>();
  const [vehicleCount, setVehicleCount] = useState<number>();
  const [serviceCount, setServiceCount] = useState<number>();
  const [driverCount, setDriverCount] = useState<number>();
  const [fareCount, setFareCount] = useState<number>();
  const [todoList, setTodoList] = useState<Map<ModuleName, boolean>>(new Map());
  const [percentage, setPercentage] = useState<number>(0);
  const [responsive, setResponsive] = useState<boolean>(false)

  /**
   * Selected fare
   */

  /**
   * Determines if fare list is loading
   */

  /**
   * Load fares
   */

  useEffect(() => {
    console.log('To DO list Data:', todoList)
  }, [todoList])

  const loadDashboardList = useCallback(async () => {
    let user: any = AuthService.getUser();
    user = user?.id ? user : user.user
    console.log('userData: ', user?.role?.assignments)
    let Roles: RoleAssignment[] = [];
    const driverRole = (user?.role as Role)?.assignments?.map((item: RoleAssignment) => {
      if (((item.module == 'zone' || item.module == 'driver' || item.module == 'vehicle' || item.module == 'fare' || item.module == 'service') && item.action == 'list-all')) {
        setTodoList(todoList?.set(item.module, true))
        Roles = Roles.filter((temp) => temp.module !== item.module);
        Roles.push(item)
      }
      else if (((item.module == 'zone' || item.module == 'driver' || item.module == 'vehicle' || item.module == 'fare' || item.module == 'service') && item.action == 'list-own') || item.module == 'settings' && item.action == 'view-general') {
        setTodoList(todoList?.set(item.module, true))

        if (Roles.filter((temp) => temp.module == item.module).length == 0)
          Roles.push(item)
      }
    })
    console.log('userData Role:', Roles)
    const dashboardList: DashboardListResponse = await Api.get(Endpoint.DASHBOARD_COMPLETION, { owner: user.id, roleAssignments: Roles });

    setZoneCount(dashboardList?.zoneCount);
    setDriverCount(dashboardList?.driverCount);
    setFareCount(dashboardList?.fareCount);
    setServiceCount(dashboardList?.serviceCount);
    setVehicleCount(dashboardList?.vehicleCount);

    let barPer: number = 0
    todo.map((item: any) => {
      if (item.module == 'settings' && dashboardList?.settingCount > 0) {
        item.completed = true
      }

      if (item.module == 'zone' && dashboardList?.zoneCount > 0) {
        item.completed = true
      }
      if (item.module == 'service' && dashboardList?.serviceCount > 0) {
        item.completed = true
      }
      if (item.module == 'fare' && dashboardList?.fareCount > 0) {
        item.completed = true
      }
      if (item.module == 'driver' && dashboardList?.driverCount > 0) {
        item.completed = true
      }
      if (item.module == 'vehicle' && dashboardList?.vehicleCount > 0) {
        item.completed = true
      }
      return item

    })

    if (dashboardList?.settingCount > 0) {
      barPer += 20
    }

    if (dashboardList?.zoneCount > 0) {
      barPer += 20
    }
    if (dashboardList?.serviceCount > 0) {
      barPer += 20
    }
    if (dashboardList?.fareCount > 0) {
      barPer += 20
    }
    if (dashboardList?.driverCount > 0) {
      barPer += 10
    }
    if (dashboardList?.vehicleCount > 0) {
      barPer += 10
    }
    setPercentage(barPer)
  }, [percentage]);

  useEffect(() => {
    if (window.screen.width <= 1150) {
      setResponsive(true)
    }
  }, [responsive])

  /**
   * Refresh fare list upon zone or service change
   */
  useEffect(() => {
    loadDashboardList();
  }, [loadDashboardList]);

  const { t } = useTranslation('dashboard');
  return (
    <div className="col-md-3 col-sm-12 content-panel color-scheme-dark">
      <div className="element-wrapper compact">
        <h6 className="element-header">{t("todo.title")}</h6>
        <div className="element-box-tp">
          <div className="fancy-progress-with-label">
            <div className="fpl-label">{percentage}%</div>
            <div className="fpl-progress-w">
              <div className="fpl-progress-i" style={{ width: `${percentage}%` }}></div>
            </div>
          </div>
          <div className="todo-list">

            {todo.map((item: TodoItem, i: number) => (
              todoList?.get(item.module as ModuleName) ? <Link key={i} className={`todo-item ${item.completed ? 'complete' : ''}`} to={item.link}>
                <div className="ti-info">
                  <div className="ti-header">{t(item.header)}</div>
                  {i === 0 ?
                    <div className="ti-sub-header">{t(item.subHeader)}</div> :
                    i === 1 ?
                      <div className="ti-sub-header">{`${t('todo.text1')} ${zoneCount} ${t('todo.zoneSetup')} `}</div> :
                      i === 2 ?
                        <div className="ti-sub-header">{`${t('todo.text4')} ${serviceCount} ${t('todo.text2')} `}</div> :
                        i === 3 ?
                          <div className="ti-sub-header">{`${t('todo.text4')} ${fareCount} ${t('todo.fareSetup')} `}</div> :
                          i === 4 ?
                            <div className="ti-sub-header">{`${t('todo.text1')} ${driverCount} ${t('todo.driverSetup')} `}</div> :

                            i === 5 ? <div className="ti-sub-header">{`${t('todo.text1')} ${vehicleCount} ${t('todo.vehicleSetup')} `}</div> : null
                  }
                </div>
                <div className="ti-icon">
                  <i className={`os-icon os-icon-${item.completed ? 'check' : 'arrow-right7'}`}></i>
                </div>
              </Link> : null
            ))}
          </div>
        </div>
      </div>
    </div>
  );

}
