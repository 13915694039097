import React, { useEffect, useState } from "react";
import { AudioCall } from "../entities/call.entity";
import { IceServerResponse } from "../entities/ice.servers.entity";
import { Call } from "../enums/call.enum";
import { Api, Endpoint } from "../services/api.service";
import { CallService } from "../services/call.service";
import { ACTIONS } from "../services/emergency.service";

export enum CALL_MESSAGES {
    START = "CONNECTING...",
    CONNECTING = "CALLING...",
    RINGING = "RINGING...",
    CONNECTED = "IN PROGRESS",
    MIC_ON = "MIC ON!",
    MIC_OFF = "MIC OFF!",
    RECONNECT = "RECONNECTING...",
    COMPLETED = "COMPLETED",
    DECLINED = "CALL DECLINED!",
    INTERNET_DOWN = "INTERNET DOWN!",
    ERROR = "UNABLE TO CALL!"
}

export type CallContextType = {
    user?: string;
    callStatus?: Call.Status;
    callType?: Call.Type;
    makeCall: (user: string) => void;
    endCall: (send: boolean, status: Call.Status) => void;
    setCallStatus: React.Dispatch<React.SetStateAction<Call.Status | undefined>>;
    setMic: (state: boolean) => void;
    mic: boolean;
    callMessage: CALL_MESSAGES;
    setCallMessage: React.Dispatch<React.SetStateAction<CALL_MESSAGES>>;
    time: string;
    timePosition: "top" | "bottom"
}

export const CallContext = React.createContext<CallContextType>({
    makeCall: () => { }, setCallStatus: () => { }, setMic: () => { }, mic: true, setCallMessage: () => { },
    endCall: () => { }, time: "00:00:00", callMessage: CALL_MESSAGES.START, timePosition: "bottom"
});

let time = 0;
let callData: AudioCall | undefined = undefined;
let callService: CallService | undefined = undefined;
let TIME_INTERVAL: NodeJS.Timer | undefined = undefined;
interface Props {
    children: React.ReactChild | React.ReactChild[]
}
export function CallContextProvider(props: Props) {
    const [formattedTime, setFormattedTime] = useState("00:00:00");
    const [mic, setMic] = useState(true);
    const [user, setUser] = useState<string>();
    const [callStatus, setCallStatus] = useState<Call.Status>();
    const [callMessage, setCallMessage] = useState<CALL_MESSAGES>(CALL_MESSAGES.START);
    const [callType, setCallType] = useState<Call.Type>(Call.Type.Admin);
    const [timePosition, setTimePosition] = useState<"top" | "bottom">("bottom");

    function startTime() {
        return setInterval(() => {
            time = time + 1;
            setFormattedTime(formatTime(time));
        }, 1000);
    }
    async function makeCall(user: string) {
        setUser(user);
        setCallStatus(Call.Status.New);
        //@ts-ignore
        const call: AudioCall = {
            type: "DRIVER",
            destination: Call.Type.Driver,
            driver: user,
            source: Call.Type.Admin,
            status: Call.Status.New,
        }

        try {
            const iceServers = await Api.get<IceServerResponse["iceServers"], any>(Endpoint.ICE);
            if (iceServers) {
                setCallMessage(CALL_MESSAGES.CONNECTING);
                callService = new CallService(user, (action) => {
                    switch (action) {
                        case ACTIONS.CONNECTING:
                            setTimePosition("bottom");
                            setCallMessage(CALL_MESSAGES.RECONNECT);
                            if (TIME_INTERVAL) clearInterval(TIME_INTERVAL)
                            break;
                        case ACTIONS.CONNECTED:
                            setTimePosition("top");
                            setCallMessage(CALL_MESSAGES.CONNECTED);
                            TIME_INTERVAL = startTime();
                            break;
                        case ACTIONS.INTERNET_DOWN:
                            setCallMessage(CALL_MESSAGES.INTERNET_DOWN);
                            endCall(true);
                            break;
                    }
                }, iceServers);

                callData = await Api.post(Endpoint.CALL, call);
                setCallStatus(Call.Status.DriverReceived);
                setCallMessage(CALL_MESSAGES.RINGING);
            }
        }
        catch (err) {
            setCallMessage(CALL_MESSAGES.ERROR);
            endCall(true);
        }
    }
    async function endCall(send: boolean, status?: Call.Status) {
        setTimePosition("bottom");
        if (TIME_INTERVAL) clearInterval(TIME_INTERVAL);
        if (callService) callService.endCall(send);
        if (status) {
            Api.patch(Endpoint.CALL, { id: callData?.id, status: status });
        }

        setTimeout(() => {
            time = 0;
            setUser(undefined);
            callData = undefined;
            callService = undefined;
            setCallStatus(undefined);
            setCallMessage(CALL_MESSAGES.START);
        }, 2000);
    }
    function formatTime(value: number): string {
        try {
            const times = new Date(value * 1000).toISOString().substr(11, 8);
            return times;
        } catch (err) {
            console.log('err = ', err);
            return '00:00:00';
        }
    }
    function toggleMic(state: boolean) {
        const message = callMessage;
        if (callService) {
            if (state) setCallMessage(CALL_MESSAGES.MIC_ON);
            else setCallMessage(CALL_MESSAGES.MIC_OFF);
            callService.toggleMic(state);
            setTimeout(() => {
                setCallMessage(message);
            }, 1000);
        }
        setMic(state);
    }

    useEffect(() => {
        Api.on("call_update", (data: AudioCall) => {
            if (callData) {
                if (callData.id == data.id) {
                    setCallStatus(data.status);
                    switch (data.status) {
                        case Call.Status.DriverAccepted:
                            setTimePosition("top");
                            setCallMessage(CALL_MESSAGES.CONNECTED);
                            TIME_INTERVAL = startTime();
                            break;
                        case Call.Status.DriverCompleted:
                            setCallMessage(CALL_MESSAGES.COMPLETED);
                            endCall(false);
                            break;
                        case Call.Status.DriverDeclined:
                            setCallMessage(CALL_MESSAGES.DECLINED);
                            endCall(true);
                            break;
                    }
                }
            }
        })
    }, []);

    return (
        <CallContext.Provider value={{
            callStatus, callType, user, makeCall, setCallStatus, setMic: toggleMic, mic, setCallMessage, endCall,
            time: formattedTime, callMessage, timePosition
        }}>
            {props.children}
        </CallContext.Provider>
    )
}