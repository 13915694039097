import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentWrapper, ViewContent } from '../../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../../components/view/item.component';
import { Incident } from '../../../../entities/incident.entity';

interface Props {
  selectedIncident: Incident;
}

export default function SelectedItemContent({ selectedIncident }: Props) {

  const { t } = useTranslation('list');

  return (
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={selectedIncident.title}
      >
        <ViewContentItems>
          <ViewContentItem title={t("title")}>
            {(selectedIncident.title)}
          </ViewContentItem>
          <ViewContentItem title={t("incident.description")}>
            {(selectedIncident.description)}
          </ViewContentItem>
          <ViewContentItem title={t("task.dueTimestamp")}>
            {(new Date(selectedIncident.timestamp).toLocaleString())}
          </ViewContentItem>
        </ViewContentItems>
      </ViewContent>
    </ContentWrapper>
  );
}