import { Acl } from "../../interfaces/core.interface";

export enum FeatureAclCode {
  ListAll = 'list-all',
  listOwn = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Audit = 'audit'
}

export const FeatureAcl = [
  {
    code: FeatureAclCode.ListAll,
    title: 'feature.listAll.title',
    description: 'feature.listAll.subTitle',
  },
  {
    code: FeatureAclCode.listOwn,
    title: 'feature.listOwn.title',
    description: 'feature.listOwn.subTitle',
  },
  {
    code: FeatureAclCode.Create,
    title: 'feature.create.title',
    description: 'feature.create.subTitle',
  },
  {
    code: FeatureAclCode.Update,
    title: 'feature.update.title',
    description: 'feature.update.subTitle',
  },
  {
    code: FeatureAclCode.Delete,
    title: 'feature.delete.title',
    description: 'feature.delete.subTitle',
  },
  {
    code: FeatureAclCode.Audit,
    title: 'feature.audit.title',
    description: 'feature.audit.subTitle',
  },
] as Acl<FeatureAclCode>;
