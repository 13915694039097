import React from 'react';
import { ApiLogs } from "../../../entities/api-log.entity";
import { ApiLogsProtocol, ApiLogsSource } from '../../../enums/api-log.enum';

interface RowDataProps {
    source: ApiLogsSource;
    apiLog: ApiLogs & { method: string }
    onLogClick: (reqId: string) => void;
    onFilter: (eventName: string) => void;
    reqId?: string;
}
export function RowData(props: RowDataProps) {
    return <td >
        {props.source !== props.apiLog.source ? null : (
            <div className="d-flex row" style={{ 
                borderLeft: `3px solid ${props.apiLog.responseCode !== 200 ? 'red' : 'green'}`,
                backgroundColor: props.reqId == props.apiLog.id ? "skyblue": "white"
            }}>
                <p className='flex-fill mb-0 px-1'>
                    <strong style={{ cursor: "pointer" }} onClick={() => props.onLogClick(props.apiLog.id)}>
                        {props.apiLog.protocol == ApiLogsProtocol.Socket && props.apiLog.method + ":"}{props.apiLog.url}<br/>
                    </strong>
                    {`↓ ${props.apiLog.requestLength} @ ${props.apiLog.travelTime} ms`}<br />
                    {`↑ ${props.apiLog.responseLength} @ ${props.apiLog.duration} ms`}<br />
                </p>

                <div style={{ cursor: 'pointer' }} onClick={() => props.onFilter(`${props.apiLog.method}:${props.apiLog.url}`)}>
                    <p ><strong>X</strong></p>
                </div>
            </div>
        )}
    </td>
}