import { Acl } from "../../interfaces/core.interface";

export enum InspectionAclCode {
  Update = 'update',
  Audit = 'audit'
}

export const InspectionAcl = [
  {
    code: InspectionAclCode.Update,
    title: 'inspection.update.title',
    description: 'inspection.update.subTitle',
  },
  {
    code: InspectionAclCode.Audit,
    title: 'inspection.audit.title',
    description: 'inspection.audit.subTitle',
  },
] as Acl<InspectionAclCode>;
