import { Acl } from "../../interfaces/core.interface";

export enum RequestAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Approve = 'approve',
  Audit = 'audit'
}

export const RequestAcl = [
  {
    code: RequestAclCode.ListAll,
    title: 'request.listAll.title',
    description: 'request.listAll.subTitle',
  },
  {
    code: RequestAclCode.ListOwn,
    title: 'request.listOwn.title',
    description: 'request.listOwn.subTitle',
  },
  {
    code: RequestAclCode.Create,
    title: 'request.create.title',
    description: 'request.create.subTitle',
  },
  {
    code: RequestAclCode.Update,
    title: 'request.update.title',
    description: 'request.update.subTitle',
  },
  {
    code: RequestAclCode.Delete,
    title: 'request.delete.title',
    description: 'request.delete.subTitle',
  },
  {
    code: RequestAclCode.Audit,
    title: 'request.audit.title',
    description: 'request.audit.subTitle',
  },
] as Acl<RequestAclCode>;
