export enum ApiLogsProtocol {
    Socket = "socket",
    Rest = "rest"
}
export enum ApiLogsMethod {
    Get = "get",
    Post = "post",
    Patch = "patch",
    Delete = "delete",
    Socket = 'socket',
    Fcm = 'fcm'
}
export enum ApiLogsSource {
    ApiCore = "api-core",
    ApiDriver = "api-driver",
    ApiPax = "api-pax"
}
