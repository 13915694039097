import classNames from 'classnames';
import React from 'react';
import { UseFormMethods, ValidationRules } from 'react-hook-form';
import { getErrorMessage } from '../util';


interface Props {
  label: string;
  placeholder?: string;
  name: string;
  validation?: ValidationRules;
  className?: any;
  autoFocus?: boolean;
  tabIndex?: number;
  rows?: number;
  value?:string;
  onchange?:any;
}

export function FormTextArea(props: Props & UseFormMethods) {
  const { register, errors, label, placeholder, name, className,rows,
      autoFocus, tabIndex,value,onchange } = props;
  let { validation } = props;
  validation = validation || {};

  return (
    <div className={ classNames('form-group', { 'has-danger': errors && errors[name] }, className) }>
      <label className={ classNames({ required: validation.required }) }>{ label }</label>
      <textarea
      style={{whiteSpace:'pre-wrap'}}
        name={name}
        value={value}
        onChange={onchange}
        placeholder={placeholder}
        className="form-control"
        autoFocus={autoFocus}
        ref={register ? register(validation) : null}
        autoComplete="turn-off"
        tabIndex={tabIndex}
        rows={ rows }
      />
      {errors && errors[name] && (
      <div className="help-block form-text with-errors form-control-feedback">
        <ul className="list-unstyled"><li>{ getErrorMessage(errors[name], label) }</li></ul>
      </div>
      )}
    </div>
  );
}
