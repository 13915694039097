import { Acl } from "../../interfaces/core.interface";

export enum ApiLogsAclCode {
  ListAll = 'list-all',
}

export const ApiLogsAcl = [
  {
    code: ApiLogsAclCode.ListAll,
    title: 'ApiLogs.listAll.title',
    description: 'ApiLogs.listAll.subTitle',
  },
] as Acl<ApiLogsAclCode>;
