import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { RouteComponentProps } from "react-router-dom";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormTextArea } from "../../../components/form/input/textarea.component";
import { FormSection } from "../../../components/form/section.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Consent } from "../../../entities/consent.entity";
import { OperatorConsent } from "../../../entities/operatorConsent.entity";
import { FormHeaderType } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../services/api.service";
import { SettingsRoute } from "../routes";

/**
 * Update general settings
 */
export function SettingsSectionConsent(props: RouteComponentProps) {

  const [consentList, setConsentList] = useState<Consent[]>([]);
  const [operatorConsentList, setOperatorConsentList] = useState<OperatorConsent[]>([]);

  const [currentConsentVersion, setCurrentConsentVersion] = useState<number>();
  const [operatorCurrentConsentVersion, setOperatorCurrentConsentVersion] = useState<number>();
  

  const refresh = useCallback(async () => {
    try {
      const { items } = await Api.get<IPaginationResponse<Consent>, IPaginationOptions>(Endpoint.CONSENT_LIST, { page: 0 });
      setConsentList(items.reverse());
      console.log('driver Consent: ', items);
      let consentVerisonlist: number[] = [];
      items.map((item) => {
        consentVerisonlist = [...consentVerisonlist, item.version]
      })
      setCurrentConsentVersion(Math.max(...consentVerisonlist));
    } catch (error) {
      console.log(error);
    }
  }, []);

  const operatorRefresh = useCallback(async () => {
    try {
      const { items } = await Api.get<IPaginationResponse<OperatorConsent>, IPaginationOptions>(Endpoint.OPERATOR_CONSENT_LIST, { page: 0 });
      setOperatorConsentList(items.reverse());
      console.log('Operator Consent: ', items);
      let operatorConsentVerisonlist: number[] = [];
      items.map((item) => {
        operatorConsentVerisonlist = [...operatorConsentVerisonlist, item.operatorVersion]
      })
      setOperatorCurrentConsentVersion(Math.max(...operatorConsentVerisonlist));
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    refresh();
    operatorRefresh();
  }, []);

  const { t } = useTranslation('settings');
  const onAfterSave = () => {
    refresh();
  }

  const onAfterOperatorSave = () => {
    operatorRefresh();
  }

  return (
    <div>
      <Form
        endpoint={Endpoint.CONSENT}
        returnLink={SettingsRoute.Consent}
        onAfterSave={onAfterSave}
      >
        <SectionsWrapper>
          <FormHeader type={FormHeaderType.Title} fullTitle={true} title={t("consent.driverTitle")} />
          <FormSections>
            <DriverUseOfInformation consentList={consentList} currentConsentVersion={currentConsentVersion} />
          </FormSections>
        </SectionsWrapper>

        <SectionsWrapper>
          <FormHeader type={FormHeaderType.Controls} />
          <FormSections>
            <DriverAgreement consentList={consentList} currentConsentVersion={currentConsentVersion} />
          </FormSections>
        </SectionsWrapper>
      </Form>
      <Form
        endpoint={Endpoint.OPERATOR_CONSENT}
        returnLink={SettingsRoute.Consent}
        onAfterSave={onAfterOperatorSave}
      >
        <SectionsWrapper>
          <FormHeader type={FormHeaderType.Title} fullTitle={true} title={t("consent.operatorTitle")} />
          <FormSections>
            <OperatorUseOfInformation operatorConsentList={operatorConsentList} operatorCurrentConsentVersion={operatorCurrentConsentVersion} />
          </FormSections>
        </SectionsWrapper>

        <SectionsWrapper>
          <FormHeader type={FormHeaderType.Controls} />
          <FormSections>
            <OperatorAgreement operatorConsentList={operatorConsentList} operatorCurrentConsentVersion={operatorCurrentConsentVersion} />
          </FormSections>
        </SectionsWrapper>
      </Form>
    </div>
  );
}

const DriverUseOfInformation = (props: any) => {
  const { t } = useTranslation('settings');
  const [useOfInformation, setUseOfInformation] = useState<string>();
  const [value, setvalue] = useState<string>();
  const [open, setOpen] = useState(false);

  const onOpenModal = (useOfInformation: any) => {
    setUseOfInformation(useOfInformation)
    setOpen(true)
  };
  const onCloseModal = () => setOpen(false);
  const childProps = useFormContext();

  const refresh = useCallback(async () => {
    childProps.setValue('driverUseOfInformation', '');
    childProps.setValue('driverAgreement', '');

  }, []);

  const onchange = (e: any) => {
    if (e.target.value.trim() == '')
      setvalue(e.target.value.trim())
    else {
      setvalue(e.target.value)
    }
  }

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <FormSection
      title={t("consent.driverUseOfInformation.title")}
      subtitle={t("consent.driverUseOfInformation.subTitle")}
    >
      <FormTextArea
        name="driverUseOfInformation"
        label={t("consent.driverUseOfInformation.title")}
        autoFocus={true}
        onchange={onchange}
        value={value}
        validation={{ required: true }}
        rows={5}
        tabIndex={1}
        {...childProps}
      />
      <ul>
        {props.consentList.map((item: Consent) => {

          return <li>
            <h6 style={{ color: '#047bf8' }} onClick={() => onOpenModal(item.driverUseOfInformation)}>{t("consent.version")}
              {props.currentConsentVersion === item.version ?
                ` ${item.version} (Current Version)`
                : ` ${item.version}`}</h6>
          </li>

        })}
      </ul>

      <Modal open={open} onClose={onCloseModal} center>
        <h2 style={{ marginTop: '20px' }}>{t("consent.driverUseOfInformation.title")}</h2>
        <pre style={{fontSize:"121.5%"}}>{useOfInformation}</pre>
      </Modal>
    </FormSection>
  );
}

function DriverAgreement(props: any) {
  const { t } = useTranslation('settings');
  const childProps = useFormContext();
  const [open, setOpen] = useState(false);
  const [value, setvalue] = useState<string>();
  const [driverAgreement, setDriverAgreement] = useState<string>();

  const onOpenModal = (driverAgreement: any) => {
    setDriverAgreement(driverAgreement)
    setOpen(true)
  };
  const onCloseModal = () => setOpen(false);

  const onchange = (e: any) => {
    if (e.target.value.trim() == '')
      setvalue(e.target.value.trim())
    else {
      setvalue(e.target.value)
    }
  }
  return (
    <FormSection
      title={t("consent.driverAgreement.title")}
      subtitle={t("consent.driverAgreement.subTitle")}
    >
      <FormTextArea
        name="driverAgreement"
        label={t("consent.driverAgreement.title")}
        rows={5}
        onchange={onchange}
        value={value}
        validation={{ required: true }}
        tabIndex={2}
        {...childProps}
      />
      <ul>
        {props.consentList.map((item: Consent) => {

          return <li>
            <h6 style={{ color: "#047bf8" }} onClick={() => onOpenModal(item.driverAgreement)}>{t("consent.version")}
              {props.currentConsentVersion === item.version ?
                ` ${item.version} (Current Version)`
                : ` ${item.version}`}</h6>
          </li>

        })}
      </ul>

      <Modal open={open} onClose={onCloseModal} center>
        <h2 style={{ marginTop: '20px' }}>{t("consent.driverAgreement.title")}</h2>
        <pre style={{fontSize:"121.5%"}}>{driverAgreement}</pre>
      </Modal>
    </FormSection>
  );
}

const OperatorUseOfInformation = (props: any) => {
  const { t } = useTranslation('settings');
  const [operatorUseOfInformation, setOperatorUseOfInformation] = useState<string>();
  const [value, setvalue] = useState<string>();
  const [open, setOpen] = useState(false);

  const onOpenModal = (useOfInformation: any) => {
    setOperatorUseOfInformation(useOfInformation)
    setOpen(true)
  };
  const onCloseModal = () => setOpen(false);
  const childProps = useFormContext();

  const refresh = useCallback(async () => {
    childProps.setValue('operatorUseOfInformation', '');
    childProps.setValue('operatorAgreement', '');

  }, []);

  const onchange = (e: any) => {
    if (e.target.value.trim() == '')
      setvalue(e.target.value.trim())
    else {
      setvalue(e.target.value)
    }
  }

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <FormSection
      title={t("consent.operatorUseOfInformation.title")}
      subtitle={t("consent.operatorUseOfInformation.subTitle")}
    >
      <FormTextArea
        name="operatorUseOfInformation"
        label={t("consent.operatorUseOfInformation.title")}
        onchange={onchange}
        value={value}
        validation={{ required: true }}
        rows={5}
        tabIndex={3}
        {...childProps}
      />
      <ul>
        {props.operatorConsentList.map((item: OperatorConsent) => {

          return <li>
            <h6 style={{ color: '#047bf8' }} onClick={() => onOpenModal(item.operatorUseOfInformation)}>{t("consent.version")}
              {props.operatorCurrentConsentVersion === item.operatorVersion ?
                ` ${item.operatorVersion} (Current Version)`
                : ` ${item.operatorVersion}`}</h6>
          </li>

        })}
      </ul>

      <Modal open={open} onClose={onCloseModal} center>
        <h2 style={{ marginTop: '20px' }}>{t("consent.operatorUseOfInformation.title")}</h2>
        <pre style={{fontSize:"121.5%"}}>{operatorUseOfInformation}</pre>
      </Modal>
    </FormSection>
  );
}

function OperatorAgreement(props: any) {
  const { t } = useTranslation('settings');
  const childProps = useFormContext();
  const [open, setOpen] = useState(false);
  const [value, setvalue] = useState<string>();
  const [operatorAgreement, setOperatorAgreement] = useState<string>();

  const onOpenModal = (operatorAgreement: any) => {
    setOperatorAgreement(operatorAgreement)
    setOpen(true)
  };
  const onCloseModal = () => setOpen(false);

  const onchange = (e: any) => {
    if (e.target.value.trim() == '')
      setvalue(e.target.value.trim())
    else {
      setvalue(e.target.value)
    }
  }
  return (
    <FormSection
      title={t("consent.operatorAgreement.title")}
      subtitle={t("consent.operatorAgreement.subTitle")}
    >
      <FormTextArea
        name="operatorAgreement"
        label={t("consent.operatorAgreement.title")}
        rows={5}
        onchange={onchange}
        value={value}
        validation={{ required: true }}
        tabIndex={4}
        {...childProps}
      />
      <ul>
        {props.operatorConsentList.map((item: OperatorConsent) => {

          return <li>
            <h6 style={{ color: "#047bf8" }} onClick={() => onOpenModal(item.operatorAgreement)}>{t("consent.version")}
              {props.operatorCurrentConsentVersion === item.operatorVersion ?
                ` ${item.operatorVersion} (Current Version)`
                : ` ${item.operatorVersion}`}</h6>
          </li>

        })}
      </ul>

      <Modal open={open} onClose={onCloseModal} center>
        <h2 style={{ marginTop: '20px' }}>{t("consent.operatorAgreement.title")}</h2>
        <pre style={{fontSize:"121.5%"}}>{operatorAgreement}</pre>
      </Modal>
    </FormSection>

  );
}