import React, { useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { Loading } from "../../components/alerts/loading.component";
import { FormInput } from "../../components/form/input/input.component";
import { Settings } from "../../entities/settings.entity";
import { Api, ApiType, Endpoint } from "../../services/api.service";
import { AuthRoute } from "./routes";
import { getUrl } from "../../components/form/util";


const logo = require(`../../img/${process.env.REACT_APP_LOGO}`);

interface RecoveryCodeRequest {
  recoveryCode: number;
  password: string;
}

/**
 * Forgot screen
 */
export function PasswordRecovery({ history }: RouteComponentProps) {

  const form = useForm();
  const childProps = { ...form };
  
  const { setError, handleSubmit } = form;

  const { t } = useTranslation("main");
  const [processing, setProcessing] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<boolean>(false);
  const [, setErrorMessage] = useState<string | null>();
  const [settingLogo,setSettingLogo] = useState<string>();


  useLayoutEffect(()=>{
    getProfilePic()
  },[])

  const getProfilePic = async() => {
    const setting = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
    const company = setting?.companyLogo;
    const logo = {
      thumb: getUrl(`/settings/${setting?.id}/companyLogo/${company?.id}/admin_single.${company?.extension}`),
      full: getUrl(`/settings/${setting?.id}/companyLogo/${company?.id}/admin_large.${company?.extension}`),
    }
    setSettingLogo(logo.thumb)   
  } 


  async function save({ recoveryCode,password }: RecoveryCodeRequest) {
    setErrorMessage(null);
    setProcessing(true);
console.log('recoveryCode is this:',recoveryCode);
    try {
      const data = await Api.post<Boolean, RecoveryCodeRequest>(
        Endpoint.Password_Recovery,
        { recoveryCode,password },
        ApiType.Rest
      );
      console.log('Response after Request: ',data)
      if(data){
        setProcessing(false);
        history.replace(AuthRoute.Login);
      }
      else{
        setProcessing(false);
      setError("recoveryCode", { type: "auth", message: "Invalid Recovery Code" });
      }
    } catch (err:any) {
      setError("recoveryCode", { type: "auth", message: "something went wrong..." });
      setError("password", { type: "auth", message: "Invalid Recovery Code" });
      setErrorMessage(err.message);
      console.log("Recovery Error", err);
      setProcessing(false);
    }
  }

  return (
    <div className="row">
      <div className="auth-subcontainer left col-md-6">
        <img src={settingLogo?settingLogo:""} className="logo" alt="Logo" />
        <div className="warning">
          <h2>{t("auth.login.warning")}</h2>
          <p>{t("auth.login.warningMsg")}</p>
        </div>
      </div>
      <div className="auth-subcontainer right col-md-6">
      <form className="row" 
        onSubmit={handleSubmit((form) => save(form as RecoveryCodeRequest))}
          onChange={() => setErrorMessage(null)}>
          <fieldset className="form-group">
            <FormInput
              name="recoveryCode"
              label={t("auth.login.recoveryCode")}
              type="text"
              autoFocus
              tabIndex={1}
              validation={{ required: true }}
              {...childProps}
            /> 
            <FormInput
            name="password"
            label={t("auth.login.password")}
            type="password"
            autoFocus
            tabIndex={1}
            validation={{ required: true }}
            {...childProps}
          />
{!processing ? <>
            <div className="buttons-w">
              <button className="btn btn-primary" type="submit">
                {t("auth.login.submit")}
              </button>
            </div>
            <div
              style={{
                marginTop: "10px",
                textDecoration: "underline",
              }}
            >
              <NavLink activeClassName="active" to="/auth/login">
                {t("auth.login.recover-password")}
              </NavLink>
            </div></> : <Loading loading={processing}/>
            }
          </fieldset>
        </form>
      </div>
    </div>
  );
}
