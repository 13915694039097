import { Acl } from "../../interfaces/core.interface";

export enum FleetAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Approve = 'approve',
  Audit = 'audit'
}

export const FleetAcl = [
  {
    code: FleetAclCode.ListAll,
    title: 'fleet.listAll.title',
    description: 'fleet.listAll.subTitle',
  },
  {
    code: FleetAclCode.ListOwn,
    title: 'fleet.listOwn.title',
    description: 'fleet.listOwn.subTitle',
  },
  {
    code: FleetAclCode.Create,
    title: 'fleet.create.title',
    description: 'fleet.create.subTitle',
  },
  {
    code: FleetAclCode.Update,
    title: 'fleet.update.title',
    description: 'fleet.update.subTitle',
  },
  {
    code: FleetAclCode.Delete,
    title: 'fleet.delete.title',
    description: 'fleet.delete.subTitle',
  },
  {
    code: FleetAclCode.Audit,
    title: 'fleet.audit.title',
    description: 'fleet.audit.subTitle',
  },
] as Acl<FleetAclCode>;
