import React, { useCallback, useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormPropsContext } from "../../../../../components/form/form.component";
import { FormAddressInput } from "../../../../../components/form/input/address.component";
import { FormInput } from "../../../../../components/form/input/input.component";
import { PhoneInputs } from "../../../../../components/form/input/phoneNumber";
import { FormUpload, UploadPreviewUrl } from "../../../../../components/form/input/upload.component";
import { FormSection } from "../../../../../components/form/section.component";
import { getUrl } from "../../../../../components/form/util";
import { CorporateAccount } from "../../../../../entities/corporate-account.entity";
import { FormMode } from "../../../../../enums/core.enum";
import { urlShow } from "../post.component";

interface Props {
  value: string;
  onChange: any
  FormEditingOnclick?: (e: any) => void,
  corporateAccount:CorporateAccount|undefined
}

export function CorporateAccountPostPersonal({ value, FormEditingOnclick ,onChange,corporateAccount }: Props) {
  const { t } = useTranslation('forms');
  const { mode } = useContext(FormPropsContext);
  const urlShows = useContext(urlShow)
  const formProps = useFormContext();

  const getProfilePicUrls = useCallback((): UploadPreviewUrl[] | undefined => {
//     const { getValues } = formProps;
// const form: CorporateAccount = getValues() as CorporateAccount;
    console.log('This is logic data',corporateAccount);
    if (urlShows === false) {
      if (corporateAccount?.id && mode === FormMode.Editing) {

        if (getLogoPic(corporateAccount) && getLogoPic(corporateAccount) !== undefined) 
        {
          console.log('This is get Logo Pic',getLogoPic(corporateAccount));
          return [getLogoPic(corporateAccount) as UploadPreviewUrl];
        }
        else {
          return undefined;
        }
      }
    }
      return undefined;
  }, [urlShows])


  const getLogoPic = (corporate: CorporateAccount): UploadPreviewUrl | undefined => {
    const pic = corporate.logoFile;
    if (corporate.id && pic?.id && pic?.extension) {
      const data={
        thumb: getUrl(`/corporateAccount/${corporate.id}/logo/${pic?.id}/admin_single.${pic?.extension}`),
        full: getUrl(`/corporateAccount/${corporate.id}/logo/${pic?.id}/admin_large.${pic?.extension}`),
      }
      console.log("Inside URL:",data)
      return data
    }
    else {
      return undefined;
    }
  }
  
  useEffect(() => {
    getProfilePicUrls()
  }, [getProfilePicUrls])

  return (
    <FormSection
      title={t("corporateBooking.personalSection.title")}
      subtitle={t("corporateBooking.personalSection.subTitle")}
    >
      <div className="row" style={{ height: '25vh' }}>
        <FormUpload
          label={t("corporateBooking.personalSection.logoTitle")}
          name="newLogoFile"
          urls={getProfilePicUrls()}
          originalName="logoFile"
          description={t("corporateBooking.personalSection.logoSubTitle")}
          className="col-md-4"
          multiple={false}
          FormEditingOnclick={FormEditingOnclick}
          validation={{ required: true }}
          tabIndex={1}
          {...formProps}
        />
      </div>
      <div className="row">
        <FormInput
          label={t("common.companyName")}
          name="companyName"
          type="text"
          className="col-md-4"
          autoFocus
          tabIndex={1}
          validation={{ required: true }}
          {...formProps}
        />
        <FormInput
          label={t("common.contactPerson")}
          name="contactPerson"
          type="text"
          className="col-md-4"
          validation={{ required: true }}
          tabIndex={1}
          {...formProps}
        />
      </div>
      <div className="row">
        <FormInput
          label={t("common.contactEmail")}
          name="contactEmail"
          type="email"
          className="col-sm-4"
          validation={{ required: true }}
          tabIndex={1}
          {...formProps}
        />
        <PhoneInputs
          placeholder={t("common.contactPhone")}
          value={value}
          onChange={onChange}
          validation={{ required: true }}
          className="col-md-4"
          tabIndex={1}
          name='contactPhone'
          {...formProps}
        />
      </div>
      <div className="row">
        <FormAddressInput
          label={t("corporateBooking.personalSection.address")}
          name="address"
          validation={{ required: true }}
          className="col-sm-4"
          tabIndex={5}
          {...formProps}
        />
        <FormInput
          label={t("corporateBooking.personalSection.abn")}
          name="abn"
          type="text"
          className="col-sm-4"
          validation={{ required: true }}
          tabIndex={1}
          {...formProps}
        />
        <FormInput
          label={t("corporateBooking.personalSection.department")}
          name="department"
          type="text"
          className="col-sm-4"
          validation={{ required: true }}
          tabIndex={1}
          {...formProps}
        />
      </div>

    </FormSection>
  );

}
