import { Settings } from '../entities/settings.entity';

class SettingsServiceClass {
  private readonly settingKey = 'setting';

  setSetting(sething: Settings) {
    localStorage.setItem(this.settingKey, JSON.stringify(sething));
  }

  getSetting(): Settings | undefined {
    const settingString = localStorage.getItem(this.settingKey);
    // console.log("settingString",settingString)
    if (settingString) {
      try {
        return JSON.parse(settingString);
      }
      catch (e) {
        return undefined;
      }
    }
    return;
  }
}

export const SettingsService = new SettingsServiceClass();