import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "../../../../components/form/input/select.component";
import { FormSection } from "../../../../components/form/section.component";
import { Feature } from "../../../../entities/feature.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../services/api.service";

export function DriverPostFeature() {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const [features, setFeatures] = useState<Feature[]>([]);
  
  useEffect(() => { init(); }, [])

  const init = async () => {
    const featureList = await Api.get<IPaginationResponse<Feature>, IPaginationOptions>(Endpoint.FEATURE_LIST);
    setFeatures(featureList.items);
  }

  return (
    <FormSection
      title={ t("driver.featureSection.title") }
      subtitle={ t("driver.featureSection.subTitle") }
    >
      <div className="row">
        <FormSelect<Feature>
          label={ t("driver.featureSection.featuresLabel") }
          name="features"
          className="col-sm-12"
          showLabel={ false }
          multiple={ true }
          validation={{}}
          items={ features }
          labelField="title"
          onChange={ () => {} }
          tabIndex={16}
          { ...formProps }
        />
      </div>
    </FormSection>
  );

}
