import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../components/form/form.component";
import { FormHeader } from "../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../components/form/sections.component";
import { Currency } from "../../entities/currency.entity";
import { CustomTolls } from "../../entities/customTolls.entity";
import { Settings } from "../../entities/settings.entity";
import { FormMode } from "../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../enums/form.enum";
import { Endpoint } from '../../services/api.service';
import { SettingsService } from "../../services/settings.service";
import { ValidationError } from "../../types/error.interface";
import { CustomTollsRoute } from "./routes";
import { AddCustomTolls } from "./section/basic.component";

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: CustomTolls) => void;
}

/**
 * Add or update a customTolls
 */
export function CustomTollsPost({
  style, cancel, formMode, entityId, onAfterSave
}: Props) {

  const settings: Settings | undefined = SettingsService.getSetting();
  const { t } = useTranslation('main');
  const [marker, setMarker] = React.useState<google.maps.LatLng>();
  const toSuperUnit = (value: number): number => {
    const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;

    return Math.round(value * unit);
  }
  /**
   * Custom Header depending upon the type of form style
   * In case of containerized style, we're seperating headers in the seperate SectionsWrapper tags,
   * Otherwise if the form is supposed to be shown on full screen then don't use custom header, but rather
   * Use it seperately for each FormSections tag as shown below
   */

  
   const processData = (customTollsForm: CustomTolls): CustomTolls => { 
    console.log("This is Phone:",customTollsForm)
    setMarker(new google.maps.LatLng(customTollsForm.latitude,customTollsForm.longitude))
    return customTollsForm;
  }
  
   const onBeforeSave = async (mode: FormMode, form: CustomTolls) => {
    console.log("form data before", form) 
if(marker){
    form.latitude=marker.lat();
    form.longitude=marker.lng();}
    form.price=toSuperUnit(form.price);
  };
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Title} title={t("entities.customTolls")} />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Controls} />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={FormHeaderType.Title} title={t("entities.customTolls")} />
    )
  );

  /**
   * Render
   */

  return (
    <Form<CustomTolls>
      endpoint={Endpoint.CUSTOM_TOLLS}
      returnLink={CustomTollsRoute.List}
      formMode={formMode}
      onBeforeSave={onBeforeSave}
      processData={processData}
      entityId={entityId}
      cancel={cancel}
      onAfterSave={onAfterSave}
      className={style === FormStyle.Containerized && "ae-content-w"}
    >
      {/* Using the custom header in case of using containerized form */}
      {style === FormStyle.Containerized && header()}
      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
        {/* If not containerized, then use it normally */}
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Title} title={t("entities.customTolls")} />}
        <FormSections>
          <AddCustomTolls marker={marker} setMarker={setMarker}/>
        </FormSections>
      </SectionsWrapper>
    </Form>
  );
}
