import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Passenger, PassengerStatus } from "../../../entities/passenger.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../enums/form.enum";
import { Endpoint } from '../../../services/api.service';
import { ValidationError } from "../../../types/error.interface";
import { PassengerRoute } from "../routes";
import { PassengerPostGroup } from "./section/group.component";
import { PassengerPostProfile } from "./section/profile.component";


interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
}

/**
 * Add or update a Passenger
 */
export function PassengerPost({
  style, cancel, formMode, entityId
}: Props) {

  const { t } = useTranslation('main'); 
  const [value, setValue] = useState('')


  const onBeforeSave = (mode: FormMode, form: Passenger) => { 
    if(form.phone === null){
      throw new ValidationError({
        field: form.phone,
        message: `phone number should not be empty!`,
      });
    }
    form.phone = value
    form.status = PassengerStatus.Active
  }

  const processData = (passenger: Passenger): Passenger => { 
    setValue(passenger.phone)
    return passenger
  }

    /**
   * Custom Header
   */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Title } title={ t("entities.passenger") } />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Controls } />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={ FormHeaderType.Title } title={ t("entities.passenger") } />
    )
  );

  /**
   * Render
   */
  return (
    <Form<Passenger>
      endpoint={ Endpoint.PASSENGER }
      returnLink={ PassengerRoute.List }
      onBeforeSave={onBeforeSave}
      processData={processData}
      cancel={ cancel }
      formMode={ formMode }
      entityId={ entityId }
      className={ style === FormStyle.Containerized && "ae-content-w" }
    >
      { style === FormStyle.Containerized && header() }
      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Title } title={ t("entities.passenger") } /> }
        <FormSections>
          <PassengerPostProfile onChange = {setValue} value = {value}/>
          <PassengerPostGroup/>
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Controls } /> }

      </SectionsWrapper>
     

    </Form>
  );
}
