import { RouteProps } from "react-router-dom";
import { SubscriberList } from "./list/list.component";
import { SubscriberPost } from "./post/post.component";

export enum SubscriberRoute {
  List = '/subscriber/list',
  Post = '/subscriber/post',
}

export const SubscriberRouteMap = [
  {
    path: SubscriberRoute.List,
    component: SubscriberList,
  },
  {
    path: SubscriberRoute.Post,
    component: SubscriberPost,
  },
] as RouteProps[];
