import { RouteProps } from "react-router-dom";
import { VehicleList } from "./list/list.component";
import { VehiclePost } from "./post/post.component";

export enum VehicleRoute {
  List = '/vehicle/list',
  Post = '/vehicle/post',
}

export const VehicleRouteMap = [
  {
    path: VehicleRoute.List,
    component: VehicleList,
  },
  {
    path: VehicleRoute.Post,
    component: VehiclePost,
  },
] as RouteProps[];
