import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentWrapper, ViewContent } from '../../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../../components/view/item.component';
import { Protocol } from '../../../../entities/protocol.entity';

interface Props {
  selectedProtocol: Protocol,
}

export function SelectedItemContent({ selectedProtocol }: Props) {

  const { t } = useTranslation('forms');

  return(
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={ selectedProtocol.title }
      >
        <ViewContentItems>
          <ViewContentItem title={ t('protocol.basicSection.name') }>
            { selectedProtocol.title }
          </ViewContentItem>

          <ViewContentItem title={ t('protocol.basicSection.content') }>
            { selectedProtocol.description }
          </ViewContentItem>
        </ViewContentItems>
      </ViewContent>
    </ContentWrapper>
  );
}