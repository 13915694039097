import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../components/form/input/input.component";
import { PhoneInputs } from "../../../../components/form/input/phoneNumber";
import { FormRadio } from "../../../../components/form/input/radio.component";
import { FormSection } from "../../../../components/form/section.component";

interface Props {
  value: string;
  onChange: any
}

export function PassengerPostProfile({ value, onChange }: Props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  return (
    <FormSection
      title={t("passenger.profileSection.title")}
      subtitle={t("passenger.profileSection.subTitle")}
    >
      <div className="row">
        <FormInput
          label={t("passenger.profileSection.firstName")}
          name="firstName"
          type="text"
          className="col-sm-4"
          validation={{ required: true }}
          tabIndex={1}
          {...formProps}
        />
        <FormInput
          label={t("passenger.profileSection.lastName")}
          name="lastName"
          type="text"
          className="col-sm-4"
          validation={{ required: true }}
          tabIndex={2}
          {...formProps}
        />
        <FormInput
          label={t("passenger.profileSection.password")}
          name="password"
          type="password"
          className="col-sm-4"
          validation={{ required: true }}
          tabIndex={3}
          autoFocus
          {...formProps}
        />
      </div>

      <div className="row">
        <FormInput
          label={t("passenger.profileSection.email")}
          name="email"
          type="email"
          className="col-sm-4"
          validation={{ required: true }}
          tabIndex={4}
          {...formProps}
        />

        <PhoneInputs
          placeholder={t("passenger.profileSection.phone")}
          value={value}
          onChange={onChange}
          name='phone'
          className="col-md-4"
          {...formProps}
        />
      </div>

      <div className="row">
        <FormInput
          label={t("common.dob")}
          name="dateOfBirth"
          type="date"
          className="col-sm-6"
          validation={{ required: true }}
          tabIndex={5}
          {...formProps}
        />
      </div>

      <div className="row">
        <FormRadio
          label={t("passenger.profileSection.gender")}
          name="gender"
          className="col-sm-4"
          validation={{ required: true }}
          options={[
            { label: t("passenger.profileSection.genderMale"), value: 'male' },
            { label: t("passenger.profileSection.genderFemale"), value: 'female' },
          ]}
          tabIndex={6}
          {...formProps}
        />
      </div>

    </FormSection>
  );

}
