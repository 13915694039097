export namespace Call {
    export enum Type {
      Passenger = 'passenger',
      Driver = 'driver',
      Admin = 'admin',
    }
    
    export enum Status {
      New = 'new',
      PassengerDeclined = 'passenger-declined',
      PassengerReceived = 'passenger-recieved',
      DriverReceived = 'driver-recieved',
      DriverDeclined = 'driver-declined',
      PassengerAccepted = 'passenger-accepted',
      DriverAccepted = 'driver-accepted',
      PassengerCompleted = 'passenger-completed',
      DriverCompleted = 'driver-completed',
    }
  }
  