import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormSection } from '../../../components/form/section.component';
import PolygonIconImg from '../../../img/polygon-icon.png';

export function ZoneInstructions() {

    const { t } = useTranslation('forms');

    return(
        <FormSection
            title={ t("zone.instructionSection.title") }
            subtitle={ t("zone.instructionSection.instructionsLeadLine") }
        >
            <ol>
                <li><b>{ t("zone.instructionSection.searchCity") }</b> { t("zone.instructionSection.searchCityDescription") }</li>
                <li><b>{ t("zone.instructionSection.manual") }</b> { t("zone.instructionSection.manualDescription1") } <img src={ PolygonIconImg } style={{ width: 24 }} alt="Polygon icon" /> { t("zone.instructionSection.manualDescription2") }</li>
            </ol>
            <p><em>{ t("zone.noteSection.title") }</em></p>
            <ul>
                <li>{ t("zone.noteSection.note1") }</li>
                <li>{ t("zone.noteSection.note2") }</li>
                <li>{ t("zone.noteSection.note3") }</li>
            </ul>
        </FormSection>
    );
}