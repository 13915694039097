import { RouteProps } from "react-router-dom";
import { PassengerPost } from "./post/post.component";
import { PassengerList } from "./list/list.component";



export enum PassengerRoute {
  Post = '/passenger/post',
  List = '/passenger/list',
}

export const PassengerRouteMap = [
  {
    path: PassengerRoute.Post,
    component: PassengerPost,
  },
  {
    path: PassengerRoute.List,
    component: PassengerList,
  },
] as RouteProps[];
