import React from 'react';
import { useTranslation } from 'react-i18next';
import { ViewContent } from '../../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../../components/view/item.component';
import { Role } from '../../../../entities/role.entity';
import { Gender, User } from '../../../../entities/user.entity';
import FemaleImg from '../../../../img/female.png';
import MaleImg from '../../../../img/male.png';
import { capitalize } from '../../../../util/string.util';

interface Props {
  getFullName: Function;
  selectedUser: User;
}

export default function SelectedUserContent({
  getFullName, selectedUser
}: Props) {
  const { t } = useTranslation('forms');

  return(
    <ViewContent
      childClassName="row"
      title={ getFullName(selectedUser) }
      imgSrc={ selectedUser?.gender === Gender.Female ? FemaleImg : MaleImg }
    >
      <ViewContentItems className="col-md-6">
        <ViewContentItem title={t("common.email")}>
          <a href={`mailto:${selectedUser.email}`}>{ selectedUser.email }</a>
        </ViewContentItem>
        
        <ViewContentItem title={t("common.phone")}>
          <a href={`tel:${selectedUser.phone}`}>{ selectedUser.phone }</a>
        </ViewContentItem>
        
        <ViewContentItem title={t("common.gender")}>
          { capitalize(selectedUser.gender) }
        </ViewContentItem>
        
        <ViewContentItem title={t("user.roleSection.title")}>
          { capitalize((selectedUser.role as Role)?.name) }
        </ViewContentItem>
      </ViewContentItems>
      
      <div className="col-md-6">
      </div>
      
    </ViewContent>
  );
}