import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemStatus } from './items.component';

export interface GroupFilter {
  title: string;
  icon?: string;
  count?: number;
}

export interface CurrencyFilter {
  name: string;
  code?: string;
  symbol?: string;
}

export interface StatusFilter {
  title: string;
  status: ListItemStatus,
}

interface Props {
  groupFilters?: GroupFilter[],
  CurrencyFilters?: CurrencyFilter[],
  statusFilters?: StatusFilter[],
  activeGroupFilter?: GroupFilter,
  activeCurrencyFilter?: CurrencyFilter,
  activeStatusFilter?: StatusFilter,
  onGroupSelect?: (group: GroupFilter) => void,
  onCurrencySelect?: (currency: CurrencyFilter) => void,
  onStatusSelect?: (status: StatusFilter | undefined) => void,
}

export function ListFilter({
  groupFilters, CurrencyFilters, statusFilters, onGroupSelect, onStatusSelect, activeGroupFilter, onCurrencySelect, activeCurrencyFilter, activeStatusFilter
}: Props) {

  const { t } = useTranslation('main');

  return (
    <div className="ae-side-menu">
      {groupFilters && (
        <ul className="ae-main-menu">
          {groupFilters.map(groupFilter => (
            <li key={groupFilter.title} className={classNames({ active: groupFilter.title === activeGroupFilter?.title })}>
              <a
                href="#group"
                onClick={() => { onGroupSelect && onGroupSelect(groupFilter); }}
              >
                {groupFilter.icon && <i className={`os-icon os-icon-${groupFilter.icon}`}></i>}
                <span>{t(`view.groupFilters.${groupFilter.title.toLowerCase()}`)} {groupFilter.count ? `(${groupFilter.count})` : ""}</span>
              </a>
            </li>
          ))}
        </ul>
      )}
      {CurrencyFilters && (
        <ul className="ae-main-menu">
          {CurrencyFilters.map(currencyFilter => (
            <li key={currencyFilter.name} className={classNames({ active: currencyFilter.name === activeCurrencyFilter?.name })}>
              <a
                href="#group"
                onClick={() => { onCurrencySelect && onCurrencySelect(currencyFilter); }}
              >
                {currencyFilter.symbol && <i className={`os-icon os-icon-${currencyFilter.symbol}`}></i>}
                <span>{t(`view.groupFilters.${currencyFilter.name.toLowerCase()}`)}</span>
              </a>
            </li>
          ))}
        </ul>
      )}
      {statusFilters && (
        <div className="ae-labels">
          {statusFilters.map(statusFilter => (
            <a className={
              classNames(
                'ae-label',
                `ae-label-${statusFilter.status}`,
                { active: statusFilter.title === activeStatusFilter?.title },
              )}
              href="#status-filter"
              key={statusFilter.title}
              onClick={() => { onStatusSelect && onStatusSelect(statusFilter); }}
            >
              <span className="label-pin"></span>
              <span className="label-value">{t(`view.statusFilters.${statusFilter.title.toLowerCase()}`)}</span>
            </a>
          ))}
        </div>
      )}
    </div>
  )
}
