import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { InspectionAssignment } from '../../../entities/inspectionAssignment.entity';
import { Passenger } from '../../../entities/passenger.entity';
import { capitalize } from '../../../util/string.util';
import { ProfilePicSize } from './list.component';
import { Rides } from './rides.component';

interface Props {
    selectedPassenger: Passenger;
    getFullName: (passenger: Passenger) => string;
    getProfilePic: (passenger: Passenger, size: ProfilePicSize) => string;
}

export function SelectedItemContent({
    selectedPassenger, getFullName, getProfilePic
}: Props) {

    const getLocaleDateOfBirth = (passenger: Passenger): string => {
        return new Date(passenger.dateOfBirth).toLocaleDateString();
    }

    const getPassengerId = (passenger: Passenger): string => {
        return [passenger.firstName, passenger.lastName, '-', passenger.phone].join('');
    }

    const [inspectionAssignment, setInspectionAssignment] = useState<InspectionAssignment[]>([]);

    const { t } = useTranslation('forms');


    return (
        <ContentWrapper className="row">
            <ViewContent
                className="col-md-6"
                title={getFullName(selectedPassenger)}
                subtitle={getPassengerId(selectedPassenger)}
            >
                <ViewContentItems>
                    <ViewContentItem title={t("passenger.profileSection.email")}>
                        <a href={`mailto:${selectedPassenger.email}`}>{selectedPassenger.email}</a>
                    </ViewContentItem>

                    <ViewContentItem title={t("passenger.profileSection.phone")}>
                        <a href={`tel:${selectedPassenger.phone}`}>{selectedPassenger.phone}</a>
                    </ViewContentItem>

                    <ViewContentItem title={t("passenger.profileSection.gender")}>
                        {capitalize(selectedPassenger.gender)}
                    </ViewContentItem>

                    <ViewContentItem title={t("passenger.profileSection.dob")}>
                        {selectedPassenger.dateOfBirth !== null? getLocaleDateOfBirth(selectedPassenger): ''}
                    </ViewContentItem>
                    <ViewContentItem title={t("passenger.profileSection.groups")}>
                        {selectedPassenger?.groups?.map((n:any) => n.title).join(', ')}
                    </ViewContentItem>
                </ViewContentItems>

            </ViewContent>
         
            <div className="col-md-6">
                <Rides
                    key={selectedPassenger.id}
                    passenger={selectedPassenger}
                />

            </div>

        </ContentWrapper>
    );
}