import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Driver } from "../../../entities/driver.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../enums/form.enum";
import { Endpoint } from '../../../services/api.service';
import { CallRoute } from "../routes";
import { DriverPostBank } from "./section/bank.component";
import { DriverPostEmergency } from "./section/emergency.component";
import { DriverPostFeature } from "./section/feature.component";
import { DriverPostFleet } from "./section/fleet.component";
import { DriverPostGroup } from "./section/group.component";
import { DriverPostLicense } from "./section/license.component";
import { DriverPostNetwork } from "./section/network.component";
import { DriverPostOperator } from "./section/operator.component";
import { DriverPostPrivate } from "./section/private.component";
import { DriverPostPublic } from "./section/public.component";
import { DriverPostVerification } from "./section/verification.component";

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: Driver) => void;
}

/**
 * Add or update a driver
 */
export function DriverPost({
  style, cancel, formMode, entityId, onAfterSave,
}: Props) {

  const { t } = useTranslation(['main', 'forms']);

  /**
   * Custom Header
   */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Title} title={t("entities.driver")} />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Controls} />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={FormHeaderType.Title} title={t("entities.driver")} />
    )
  );

  /**
   * Render
   */

  return (
    <Form<Driver>
      endpoint={Endpoint.DRIVER}
      returnLink={CallRoute.List}
      cancel={cancel}
      formMode={formMode}
      entityId={entityId}
      className={style === FormStyle.Containerized && "ae-content-w"}
      fileEndpoint={Endpoint.DRIVER_FILES}
      onAfterSave={onAfterSave}
      formFiles={[
        { required: true, count: 1, originalName: 'picFile', name: "newPicFile", desc: t("forms:driver.publicSection.profilePic") },
        { required: false, count: 4, originalName: 'licenseFiles', name: "newLicenseFiles", desc: t("forms:driver.licenseSection.licenseLabel") },
        { required: false, count: 4, originalName: 'identityFiles', name: "newIdentityFiles", desc: t("forms:driver.verificationSection.poiLabel") },
        { required: false, count: 4, originalName: 'policeFiles', name: "newPoliceFiles", desc: t("forms:driver.verificationSection.policeCheckLabel") },
        { required: false, count: 4, originalName: 'historyFiles', name: "newHistoryFiles", desc: t("forms:driver.verificationSection.drivingHistoryLabel") },
        { required: false, count: 4, originalName: 'insuranceFiles', name: "newInsuranceFiles", desc: t("forms:driver.verificationSection.insuranceLabel") }
      ]}
    >
      {style === FormStyle.Containerized && header()}
      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Title} title={t("entities.driver")} />}
        <FormSections>
          <DriverPostPublic />
          <DriverPostPrivate />
          <DriverPostEmergency />
          <DriverPostLicense />
          <DriverPostBank />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Controls} />}
        <FormSections>
          <DriverPostVerification />
          <DriverPostFleet />
          <DriverPostNetwork />
          <DriverPostOperator />
          <DriverPostGroup />
          <DriverPostFeature />
        </FormSections>
      </SectionsWrapper>
    </Form>
  );
}
