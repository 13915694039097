import { Role, RoleAssignment } from "../entities/role.entity"
import { AuthService } from "../services/auth.service"

export function checkAssignment(module:string,action:string){
    let assingmentStatus = false;
    let userData = AuthService.getUser()
    userData = userData?.user?.id ? userData.user : userData
    const assignData =userData ? [...((userData?.role as Role).assignments as RoleAssignment[])] : []
    assignData.map((item)=>{
      if(item.module == module && item.action == action){
        assingmentStatus = true
      }
    })

    return assingmentStatus

    
  }