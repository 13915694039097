import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GroupFilter, ListFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { ViewContentItems, ViewContentItem } from "../../../components/view/item.component";
import { View } from "../../../components/view/wrapper.component";
import { ApiLogs } from "../../../entities/api-log.entity";
import { ApiLogsMethod, ApiLogsProtocol, ApiLogsSource } from "../../../enums/api-log.enum";
import { IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../services/api.service";
import { SelectedItemContent } from "./content.component";
import { RowData } from "./row.component";

const sourceFilters: GroupFilter[] = [
    { title: ApiLogsSource.ApiCore, icon: 'phone-21' },
    { title: ApiLogsSource.ApiDriver, icon: 'phone-21' },
    { title: ApiLogsSource.ApiPax, icon: 'phone-21' }
];
const protocolFilters: GroupFilter[] = [
    { title: ApiLogsProtocol.Socket, icon: 'phone-21' },
    { title: ApiLogsProtocol.Rest, icon: 'phone-21' }
];
const methodFilters: GroupFilter[] = [
    { title: "All", icon: 'phone-21' },
    { title: ApiLogsMethod.Get, icon: 'phone-21' },
    { title: ApiLogsMethod.Post, icon: 'phone-21' },
    { title: ApiLogsMethod.Patch, icon: 'phone-21' },
    { title: ApiLogsMethod.Delete, icon: 'phone-21' },
    { title: ApiLogsMethod.Socket, icon: 'phone-21' },
    { title: ApiLogsMethod.Fcm, icon: 'phone-21' }
];
const timeZones = [
    { country: 'Pakistan', timezone: 'Asia/Karachi'},
    { country: 'Australia', timezone: 'Australia/Canberra'},
    { country: 'Mexico', timezone: 'Mexico/General'}
]

function getTimes(date: Date, timeZones: {country: string, timezone: string}[], type: "sm" | "lg") {
    let str = "";
    for (let { country, timezone } of timeZones) {
        if (type == "sm") {
            str = str + `[${country.substring(0,3).toUpperCase()} ${date.toLocaleTimeString('en-US', { timeZone: timezone })}]\n`;
        }
        else {
            str = str + `${country.toUpperCase()} ${date.toLocaleString('en-US', { timeZone: timezone })} | `;
        }
    }
    return str;
}

export function ApiLogsList() {
    const { t } = useTranslation('forms');
    const [limit, setLimit] = useState<number>(20);
    const eventSet = useRef<Set<string>>(new Set());
    const [search, setSearch] = useState<string>('');
    const [showSearch, setShowSearch] = useState(false);
    const [allCount, setAllCount] = useState<number>(0);
    const query = useRef<Map<string, string>>(new Map());
    const [searchModal, setSearchModal] = useState(false);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [debugId, setDebugId] = useState<string | undefined>();
    const [selectedApiLog, setSelectedApiLog] = useState<ApiLogs>();
    const [apiLogs, setApiLogs] = useState<ApiLogs[] | undefined>(undefined);
    const [methodFilter, setMethodFilter] = useState<GroupFilter>(methodFilters[0]);
    const [sourceFilter, setSourceFilter] = useState<GroupFilter>(sourceFilters[0]);
    const [protocolFilter, setProtocolFilter] = useState<GroupFilter>(protocolFilters[0]);
    const [reqData, setReqData] = useState<{userAgent: any, request: any, response: any, reqId: string}>();

    const refresh = useCallback(async () => {
        // setSelectedApiLog(undefined);
        const data = {
            page: 0, limit, search, method: methodFilter.title, source: sourceFilter.title, protocol: protocolFilter.title
        }
        let { items, totalItems } = await Api.get<IPaginationResponse<ApiLogs>, any>(Endpoint.API_LOGS, data);
        items = items.filter(({ url }) => !eventSet.current.has(url));
        
        if (items && items.length !== totalItems) setHasMore(true)
        else setHasMore(false);
        setApiLogs(items);
        setAllCount(totalItems);

        if (items.length > 0) {
            await getReqData(items[0].id);
            setSelectedApiLog(items[0]);
        }
        // if (items.length == 0) {setReqData(undefined); setSelectedApiLog(undefined);}
    }, [search, limit, sourceFilter, methodFilter, protocolFilter]);
    const getReqData = async (reqId: string) => {
        try {
            const data = await Api.get<any, any>(Endpoint.API_LOGS_REQ_DATA, { id: reqId });
            setReqData({ userAgent: data.userAgent, response: data.response, request: data.request, reqId: reqId });
        }
        catch(e: any) {
            alert("Failed fetching req data issue = "+e.message || e);
        }
    }
    const getTimeline = async (reqId: string) => {
        try {
            const response = await Api.get<any, any>(Endpoint.API_LOGS_TIMELINE, { id: reqId });
            setApiLogs(response);
        }
        catch(e: any) {
            alert('Cannot get timeline record issue = '+e.message || e);
        }
    }
    function onSearchChange(search: string) {
        setSearch(search);
        if (search == "") {
            setDebugId(undefined); setShowSearch(false);
        }
    }
    function fetchMoreData() {
        setLimit(limit + 10);
    }
    function selectGroup(target: GroupFilter, type: "method" | "protocol" | "source") {
        setReqData(undefined); setSelectedApiLog(undefined);
        switch (type) {
            case "method":
                setMethodFilter(target);
                break;
            case "protocol":
                setProtocolFilter(target);
                break;
            case "source":
                setSourceFilter(target);
                break;
        }
    }
    function toListItems(value?: ApiLogs): ListItem<ApiLogs> | undefined {
        if (!value) return undefined;
        return {
            id: value.id as string,
            title: value.url,
            ref: value, 
            status: value.responseCode !== 200 ? ListItemStatus.Red : undefined,
            deleted: value.responseCode != 200 ? true: undefined,
            onRemove: () => {
                eventSet.current.add(value.url);
                const list = [...(apiLogs || [])].filter(({ url }) => !eventSet.current.has(url));
                console.log("list = ",list);
                setApiLogs(list);
            }
        }
    }
    function filterEvents(eventName: string) {
        eventSet.current.add(eventName);
        setApiLogs((previous) => {
            if (typeof previous !== "undefined") {
                previous = previous.filter(({ url, method }) => !eventSet.current.has(`${method}:${url}`));
                return [...previous];
            }
            else return [];
        });
    }
    const onNext = async () => {
        if (apiLogs) {
            const apiLogIndex = apiLogs.findIndex(apiLog => !!selectedApiLog && apiLog.id === selectedApiLog.id);
            if (apiLogIndex + 1 < apiLogs.length && apiLogIndex !== -1) {
                await getReqData(apiLogs[apiLogIndex + 1].id);
                setSelectedApiLog(apiLogs[apiLogIndex + 1]);
            }
        }
    }
    const onPrev = async () => {
        if (apiLogs) {
            const apiLogIndex = apiLogs.findIndex(apiLog => !!selectedApiLog && apiLog.id === selectedApiLog.id);
            if (apiLogIndex - 1 > -1 && apiLogIndex !== -1) {
                await getReqData(apiLogs[apiLogIndex - 1].id);
                setSelectedApiLog(apiLogs[apiLogIndex - 1]);
            }
        }
    }
    const PrettyPrintJson = React.memo(({ data }: any) => (
        <div>
            <pre className="text-white">{JSON.stringify(data, null, 2)}
            </pre>
        </div>
    ));

    useEffect(() => {
        refresh();
    }, [refresh]);

    useEffect(() => {
        document.getElementById('modal')!.addEventListener('wheel', preventScroll, { passive: false });
        function preventScroll(e: any) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    }, []);
    return (
        // needed to be done because we are adding 50px to class header component > menu-has-selected-link
        <div >
            <List>
                <div className="row w-100">
                    {!debugId && (
                        <div className="col-md-2" style={{ padding: 0 }}>
                            <ListFilter
                                groupFilters={sourceFilters}
                                activeGroupFilter={sourceFilter}
                                onGroupSelect={(target) => selectGroup(target, "source")}
                            />
                            <div style={{ height: '2px', backgroundColor: '#293145' }}></div>
                            <ListFilter
                                groupFilters={methodFilters}
                                activeGroupFilter={methodFilter}
                                onGroupSelect={(target) => selectGroup(target, "method")}
                            />
                            <div style={{ height: '2px', backgroundColor: '#293145' }}></div>
                            <ListFilter
                                groupFilters={protocolFilters}
                                activeGroupFilter={protocolFilter}
                                onGroupSelect={(target) => selectGroup(target, "protocol")}
                            />
                        </div>
                    )}
                    {debugId && (
                        <div className="col-md-8">
                            <table className="table">
                                <thead style={{ position: "sticky", top: 0, backgroundColor: '#fff' }}>
                                    <tr style={{ borderBottom: '1px solid black' }}>
                                        <th style={{ cursor: 'pointer' }} scope="col" onClick={()=>{
                                            if (debugId) {
                                                eventSet.current = new Set();
                                                getTimeline(debugId);
                                            }
                                            else alert("ID NOT SELECTED!");
                                        }}>#</th>
                                        <th scope="col">api-driver</th>
                                        <th scope="col">api-pax</th>
                                        <th scope="col">api-core</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {!apiLogs ? <h4>LOADING...</h4>: (
                                        <>
                                        {apiLogs.map((data, index) => (
                                            <tr >
                                                <th scope="row">
                                                    <span style={{ whiteSpace: 'pre-line' }}>
                                                        {getTimes(new Date(data.createTime), timeZones, "sm")}
                                                    </span>
                                                </th>
                                                <RowData 
                                                    //@ts-ignore
                                                    reqId={reqData?.reqId} apiLog={data} source={ApiLogsSource.ApiDriver} onLogClick={getReqData} onFilter={filterEvents} />
                                                <RowData 
                                                    //@ts-ignore
                                                    reqId={reqData?.reqId} apiLog={data} source={ApiLogsSource.ApiPax} onLogClick={getReqData} onFilter={filterEvents}/>
                                                <RowData 
                                                    //@ts-ignore
                                                    reqId={reqData?.reqId} apiLog={data} source={ApiLogsSource.ApiCore} onLogClick={getReqData} onFilter={filterEvents}/>
                                            </tr>
                                        ))}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {!debugId && (
                        <div className="col-md-3" style={{ padding: 0 }}>
                            <ListItems
                                showSearch={true}
                                hasMore={hasMore}
                                showRefresh={true}
                                fetchMoreData={fetchMoreData}
                                onSearchChange={onSearchChange}
                                activeItem={toListItems(selectedApiLog)}
                                searchString={showSearch ? search: undefined}
                                onSearchDoubleClick={() => {setSearchModal(true); query.current.set("source", sourceFilter.title)}}
                                onRefresh={() => { setApiLogs(undefined); refresh(); }}
                                onClick={async(item: ListItem<ApiLogs>) => { 
                                    await getReqData(item.ref.id);
                                    setSelectedApiLog(item.ref);
                                }}
                                items={debugId ? []: apiLogs?.map(toListItems) as ListItem<ApiLogs>[]}
                            />
                        </div>
                    )}
                    {debugId && (
                        <div className="col-md-4">
                            <div className="d-grid gap-2">
                                <h6 >DEBUG ID</h6>
                                <p >{debugId}</p>
                                <button className="btn btn-dark w-100" type="button" onClick={() => {
                                    setSearch(""); setDebugId(undefined);
                                }}>CLOSE DEBUG MODE</button>
                            </div>
                            
                            <div className="d-grid gap-2">
                                {reqData && (
                                    <ViewContentItems >
                                        <ViewContentItem title={t("apilogs.userAgent")} className="m-1 bg-secondary p-1">
                                            <PrettyPrintJson data={JSON.parse(reqData.userAgent)} />
                                        </ViewContentItem>
                                        <ViewContentItem title={t("apilogs.request")} className="m-1 bg-secondary p-1">
                                            <PrettyPrintJson data={JSON.parse(reqData.request)} />
                                        </ViewContentItem>
                                        <ViewContentItem title={t("apilogs.response")} className="m-1 bg-secondary p-1">
                                            <PrettyPrintJson data={JSON.parse(reqData.response)} />
                                        </ViewContentItem>
                                    </ViewContentItems>
                                )}
                            </div>
                        </div>
                    )}
                    {!debugId && (
                        <div className="col-md-7">
                            {apiLogs !== undefined ? (
                                <>
                                    {selectedApiLog ? (
                                        <View
                                            componentName={"apiLogs"}
                                            showNext={true}
                                            showPrev={true}
                                            showDelete={true}
                                            onNext={onNext}
                                            onPrev={onPrev}
                                            childClassName={"p-0"}
                                            onDelete={() => {
                                                const value = prompt("Will delete logs older than days", "3");
                                                try {
                                                    const days = parseInt(value + "");
                                                    if (days > -1) {
                                                        Api.delete(Endpoint.API_LOGS, { days });
                                                    }
                                                    else {
                                                        alert('Days cannot be less than 0');
                                                    }
                                                }
                                                catch(e) {
                                                    alert(e);
                                                }
                                            }}
                                        >
                                            <SelectedItemContent
                                                reqData={reqData}
                                                selectedApiLog={selectedApiLog}
                                                subtitle={getTimes(new Date(selectedApiLog.createTime), timeZones, "lg")}
                                            />
                                            {eventSet.current.size > 0 && (
                                                <div style={{ 
                                                    position: "absolute", top: 5, left: 5, backgroundColor: "orangered", padding: '5px',
                                                    borderRadius: 30, display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: 'center'
                                                }}>
                                                    <p style={{ color: 'white', marginBottom: 0, fontSize: 13 }}>
                                                        {eventSet.current.size + " Items Filtered Out"} 
                                                    </p>
                                                    <div onClick={() => {
                                                      eventSet.current.clear(); 
                                                      refresh(); 
                                                    }} className="btn btn-white-outline">X</div>
                                                </div>
                                            )}
                                        </View>
                                    ) : (
                                        <div >
                                            SELECT SOMETHING
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div >
                                    LOADING API LOGS
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </List>

            <div id="modal">
                {searchModal && (
                    <div className="d-flex justify-content-center align-items-center" style={{
                        width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.7)', position: 'absolute', top: 0, right: 0, overflow: "auto hidden"
                    }}>
                        <div style={{ width: '50%', backgroundColor: 'white' }}>
                            <div className="d-flex flex-row p-2 align-items-end">
                                <div className="d-flex flex-row flex-fill align-items-end">
                                    <h3 >Advance Search </h3>
                                </div>
                            </div>

                            <form className="container mb-2">
                                {(sourceFilter.title == ApiLogsSource.ApiCore || sourceFilter.title == ApiLogsSource.ApiPax) && (
                                    <>
                                        <div className="mb-3">
                                            <label className="form-label">EMAIL OR SESSION CODE</label>
                                            <input type="email" className="form-control" onChange={(e) => query.current.set("email", e.target.value)} />
                                            <div id="emailHelp" className="form-text">Search logs via email</div>
                                        </div>
                                    </>
                                )}
                                {sourceFilter.title == ApiLogsSource.ApiDriver && (
                                    <div className="d-flex flex-row justify-content-around">
                                        <div className="mb-3">
                                            <label className="form-label">DRIVER CODE</label>
                                            <input type="text" className="form-control" onChange={(e) => query.current.set("driver", e.target.value)}/>
                                            <div id="code" className="form-text">Search using driver code</div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">SESSION CODE</label>
                                            <input type="text" className="form-control" onChange={(e) => query.current.set("session", e.target.value)} />
                                            <div id="code" className="form-text">Search using session code</div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">TRIP CODE</label>
                                            <input type="text" className="form-control" onChange={(e) => query.current.set("trip", e.target.value)} />
                                            <div id="code" className="form-text">Search using trip code</div>
                                        </div>
                                    </div>                                    
                                )}
                                    <div className="d-flex flex-row justify-content-around">
                                        <div className="mb-3 flex-grow-1">
                                            <label className="form-label">START DATE</label>
                                            <input type="date" className="form-control" onChange={(e) => query.current.set("startDate", e.target.value)} />
                                            <div id="dateHelp" className="form-text">Add a start date</div>
                                        </div>
                                        <div className="mb-3 flex-grow-1">
                                            <label className="form-label">END DATE</label>
                                            <input type="date" className="form-control" onChange={(e) => query.current.set("endDate", e.target.value)} />
                                            <div id="dateHelp" className="form-text">Add an end date</div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-around">
                                        <div className="mb-3 flex-grow-1">
                                            <label className="form-label">EVENT</label>
                                            <input type="text" className="form-control" onChange={(e) => query.current.set("event", e.target.value)} />
                                            <div id="eventHelp" className="form-text">Search specific events</div>
                                        </div>
                                        <div className="mb-3 flex-grow-1">
                                            <label className="form-label">SOURCE</label>
                                            <select className="form-control form-select" id="sourceSelect" onChange={(e) => query.current.set("source", e.target.value)}>
                                                <option value={ApiLogsSource.ApiCore}>api-core</option>
                                                <option value={ApiLogsSource.ApiDriver}>api-driver</option>
                                                <option value={ApiLogsSource.ApiPax}>api-pax</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">DEBUG ID</label>
                                        <input type="text" className="form-control" onChange={(e) => query.current.set("debugId", e.target.value)} />
                                        <div id="eventHelp" className="form-text">DEBUG MODE: Searches all records available with the unique uuid</div>
                                    </div>
                                    <div className="d-flex flex-row flex-fill justify-content-around">
                                        <button className="btn btn-primary" onClick={(e: any) => {
                                            e.preventDefault();

                                            if (query.current.has("debugId")) {
                                                const id = query.current.get("debugId");
                                                setDebugId(id);
                                                setApiLogs(undefined); setReqData(undefined); setSelectedApiLog(undefined);
                                                getTimeline(id!);
                                            }
                                            else {
                                                let queryString = "?";
                                                for (let [key, value] of query.current) {
                                                    queryString = queryString + key + "=" + value + "&";
                                                }

                                                console.log("queryString = ",queryString);
    
                                                query.current.clear();
                                                setShowSearch(true);
                                                setSearch(queryString);
                                                setSearchModal(false);
                                            }

                                        }}>
                                            SEARCH
                                        </button>
                                        <button className="btn btn-secondary" onClick={() => setSearchModal(false)}>
                                            CLOSE
                                        </button>
                                    </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}