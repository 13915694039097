import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormTextArea } from "../../../components/form/input/textarea.component";
import { FormSection } from "../../../components/form/section.component";
import { Inspection } from "../../../entities/inspection.entity";

interface Props {
  driverInspectionData: Inspection[] | undefined
}

export function InspectionDriver(props: Props) {
  const { t } = useTranslation("forms");
  const formProps = useFormContext();
  const [data, setData] = useState<Inspection[]>(props?.driverInspectionData ? props?.driverInspectionData : []);
  const [sentence, setSentence] = useState<string>(props?.driverInspectionData ? props?.driverInspectionData?.map((driverInspection: Inspection) => { return driverInspection.title }).join('\n') : '');

  useEffect(
    () => {
      console.log('Inside Props Changes Driver')
      setSentence(props?.driverInspectionData ? props?.driverInspectionData?.map((driverInspection: Inspection) => { return driverInspection.title }).join('\n') : '')
    },
    [props])

  const onChange = (e: any) => {
    // console.log('Inside Driver Change');
    const title = e.target.value;
    console.log('This is Driver Filed Data:', title)
    setData([...data, title]);
    setSentence(title)
  }

  return (
    <FormSection title={t("inspection.driverInspection")} subtitle="">
      <FormTextArea
        name={`driverInspection`}
        label={t("inspection.title")}
        autoFocus={true}
        validation={{ required: false }}
        onchange={onChange}
        value={sentence}
        rows={5}
        {...formProps}
      />
    </FormSection>
  );
}
