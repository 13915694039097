import { Acl } from "../../interfaces/core.interface";

export enum ArticleAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Approve = 'approve',
  Audit = 'audit'
}

export const ArticleAcl = [
  {
    code: ArticleAclCode.ListAll,
    title: 'article.listAll.title',
    description: 'article.listAll.subTitle',
  },
  {
    code: ArticleAclCode.ListOwn,
    title: 'article.listOwn.title',
    description: 'article.listOwn.subTitle',
  },
  {
    code: ArticleAclCode.Create,
    title: 'article.create.title',
    description: 'article.create.subTitle',
  },
  {
    code: ArticleAclCode.Update,
    title: 'article.update.title',
    description: 'article.update.subTitle',
  },
  {
    code: ArticleAclCode.Delete,
    title: 'article.delete.title',
    description: 'article.delete.subTitle',
  },
  {
    code: ArticleAclCode.Audit,
    title: 'article.audit.title',
    description: 'article.audit.subTitle',
  },
] as Acl<ArticleAclCode>;
