import { formatRelative } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useHistory } from "react-router-dom";
import { FormInput } from '../../../components/form/input/input.component';
import { Booking } from '../../../entities/booking.entity';
import { Passenger } from '../../../entities/passenger.entity';
import { Api, Endpoint } from '../../../services/api.service';
interface Props {
    passenger: Passenger,
}
interface PastRidesResponse {
    bookings: Booking[],
    trips: Booking[],
    time : string
    from :string
    to :string
}

export function Rides(props: Props) {
    let history = useHistory();
    const [rideData, setrideData] = useState<Booking[]>([]);
    const [tripData, settripData] = useState<Booking[]>([]);
    const [data, setData] = useState<any>();
    const [perPage, setPerPage] = useState<any>(3);
    const [pageCount, setPageCount] = useState<any>(3);
    const [offset, setOffset] = useState<any>(0);
    const [passenger, setPassenger] = useState<any>(props.passenger);
    const [pageData, setPageData] = useState<Booking[]>([]);
    const { t } = useTranslation('forms');
    const translationReport = useTranslation('main')
    const translationReportDashboard = useTranslation('dashboard')
    const [time, setTime] = useState<string>('today');
    const [to, setTo] = useState<string>('');
    const [from, setFrom] = useState<string>('');
    const formProps = useFormContext();

    const loadCommentsFromServer = async (tempOffset?: any) => {
        let responseData:any;
        console.log("time",time)
        if (time === 'custome' && from && to) {
            const datetime = new Date();
            if (new Date(from).getFullYear() >= 2000 && new Date(from).getFullYear() <= datetime.getFullYear() && new Date(to).getFullYear() <= datetime.getFullYear()) {
                 responseData = await Api.get<PastRidesResponse, any>(Endpoint.PASSENGER_PAST_RIDES, { passengerId: passenger.id, limit: 3, offset: tempOffset, time,from,to });
            }
        }
        else if (time === 'today' || time === 'yesterday' || time === 'this-week' || time === 'last-week' || time === 'this-month' || time === 'last-month' || time === 'this-year') {
            console.log("response data time",time)
            responseData = await Api.get<PastRidesResponse, any>(Endpoint.PASSENGER_PAST_RIDES, { passengerId: passenger.id, limit: 3, offset: tempOffset, time,from,to });
            console.log("response data",responseData)
        }
        const {bookings} = responseData
        // setPageCount(bookings.length / 3);
        setPageData(bookings);
    }
    useEffect(() => {
        ridesData();
    }, []);

    useEffect(() => { 
        console.log('Data Inside Page Data = ',pageData)
    }, [pageData]);

    useEffect(() => { 
        console.log('Data Inside Page Data = ',pageData)
        ridesData()
        loadCommentsFromServer(offset)
    }, [time,from,to]);

    const ridesData = async () => {
        console.log("passenger Data:", passenger);
        const { bookings } = await Api.get<PastRidesResponse, any>(Endpoint.PASSENGER_PAST_RIDES, { passengerId: passenger.id, offset: offset, time:time });
        console.log('response in Ridessssssssssss', bookings);
        setrideData(bookings);
        for (let i = 0; i < perPage; i++) {
            if (bookings[i] == null) { break; }
            setPageData(prevState => [ ...prevState, bookings[i] ]);
        }
        setPageCount(bookings.length / 3)
    }
    const onclick = (bookingCode: string) => {
        history.push(`/security/track/${bookingCode}`);
    }
    const handlePageClick = (data: any) => {
        let selected = data.selected;
        console.log("selected", selected);
        console.log("per page", Math.ceil(selected * perPage));
        let tempOffset = selected * perPage;
        setOffset(tempOffset);
        loadCommentsFromServer(tempOffset);
    };
    const onChangeTo = (e: any) => {
        setTo(e.target.value)
      }
    
      const onChangeFrom = (e: any) => {
        setFrom(e.target.value)
      }
    
      const changeTime = (e: any) => {
        setTime(e.target.value)
      }
    return (
        <>
            <div className="element-wrapper" >
            {time === 'custome' ?
          <>
            <div className="row">
              <FormInput
                label={translationReportDashboard.t("From")}
                name="from"
                type="date"
                onChange={onChangeFrom}
                className="col-sm-3"
                validation={{ required: true }}
                tabIndex={7}
                {...formProps}
              />
              <FormInput
                label={translationReportDashboard.t("To")}
                name="to"
                type="date"
                onChange={onChangeTo}
                className="col-sm-3"
                validation={{ required: true }}
                tabIndex={7}
                {...formProps}
              />
            </div>

          </> : null}
                <div className="element-box">
               
                    <h6 style={{
                        marginTop: '10px',
                        fontWeight: 500,
                        fontSize: '1.25rem',
                        textAlign: 'center',
                        marginBottom: '20px',
                        paddingBottom: '58px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                        paddingTop: '10px'
                    }}>
                        {t('passenger.profileSection.pastRides')}
                    </h6>
                    {/* Date Filter */}
        <div> 
          <div style={{ marginRight: '15px', marginBottom: '10px', marginTop: '15px' }}>
            <form className="form-inline justify-content-sm-end">
              <select value={time} onChange={changeTime} className="form-control form-control-sm rounded">
                <option value="today">{translationReportDashboard.t('dropdown.today')}</option>
                <option value="yesterday">{translationReportDashboard.t('dropdown.yesterday')}</option>
                <option value="this-week">{translationReportDashboard.t('dropdown.thisWeek')}</option>
                <option value="last-week">{translationReportDashboard.t('dropdown.lastWeek')}</option>
                <option value="this-month">{translationReportDashboard.t('dropdown.thisMonth')}</option>
                <option value="last-month">{translationReportDashboard.t('dropdown.lastMonth')}</option>
                <option value="this-year">{translationReportDashboard.t('dropdown.thisYear')}</option>
                <option value="custome">{translationReportDashboard.t('dropdown.custome')}</option>
              </select>
            </form>
          </div> </div>
                    <table className="table table-clean">
                        <tbody>
                            {
                                pageData.map((data,key) => {
                                    return <tr key={key} role="button" onClick={() => onclick(data.code)}>
                                        <td>
                                            <div className="value">
                                                <span className="badge badge-success">
                                                    {data?.code}
                                                </span>
                                                <br />
                                                {data.pickupAddress ? `From: ${data.pickupAddress.text}` : ""}
                                                <br />
                                                {data.dropOffAddress ? `To: ${data.dropOffAddress.text}` : ""}
                                            </div>
                                            <span className="sub-value">
                                                {formatRelative(new Date(data?.createTime), new Date())}
                                            </span>                                         
                                        </td>
                                    </tr>
                                })
                            }
                            {pageData.length>0?<ReactPaginate
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                                nextLabel="Next"
                                previousLabel="Prev"
                                pageLinkClassName={"pageLink"}
                            />:""}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}




