import classNames from 'classnames';
import React from 'react';
import { UseFormMethods, ValidationRules } from 'react-hook-form';
import { getErrorMessage } from '../util';

interface Props {
  label?: string;
  placeholder?: string | undefined;
  name: string;
  defualtValue?: any;
  type: 'text' | 'password' | 'email' | 'time' | 'tel' | 'date' | 'color' | 'number' | 'checkbox' | 'hidden' | 'datetime-local';
  step?: number;
  maxLength?: number;
  validation?: ValidationRules;
  className?: any;
  autoFocus?: boolean;
  value?: any;
  style?: any,
  readonly?: boolean;
  tabIndex?: number;
  onChange?: any;
  onDoubleClick?: (e: any) => void
}
export function FormInput(props: Props & UseFormMethods) {
  const { register, errors, label, placeholder, name, step, type, value, readonly, className, style,
    onChange, maxLength, autoFocus, tabIndex, onDoubleClick, defualtValue } = props;
  let { validation } = props;
  validation = validation || {};

  if (type === 'email') {
    if (label) {
      validation.pattern = {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        message: getErrorMessage({ type: 'email' }, label),
      };
    }
  }

  return (
    <div style={style} className={classNames('form-group', { 'has-danger': errors && errors[name] }, className)}>
      <label className={classNames({ required: validation.required })}>{label}</label>
      <input
        value={value}
        name={name}
        placeholder={placeholder}
        className="form-control"
        type={type}
        step={step}
        maxLength={maxLength || 1000}
        size={maxLength || 1000}
        autoFocus={autoFocus}
        defaultValue={defualtValue}
        ref={register ? register(validation) : null}
        autoComplete="off"
        tabIndex={tabIndex}
        readOnly={readonly}
        onDoubleClick={onDoubleClick}
        onChange={(e) => onChange && onChange(e)}
      />
      {errors && errors[name] && (
        <div className="help-block form-text with-errors form-control-feedback">
          <ul className="list-unstyled"><li>{label ? getErrorMessage(errors[name], label) : ''}</li></ul>
        </div>
      )}
    </div>
  );
}
