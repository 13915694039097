import { Acl } from "../../interfaces/core.interface";

export enum FareAclCode {
  ListAllZones = 'list-all',
  ListOwnZones = 'list-own',
  ListAllServices = 'list-all',
  ListOwnServices = 'list-own',
  ListAllFares = 'list-all',
  ListOwnFares = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Assign = 'assign',
  Audit = 'audit'
}

export const FareAcl = [  
  {
    code: FareAclCode.ListAllFares,
    title: 'fare.listAllFares.title',
    description: 'fare.listAllFares.subTitle',
  },
  {
    code: FareAclCode.ListOwnFares,
    title: 'fare.listOwnFares.title',
    description: 'fare.listOwnFares.subTitle',
  },
  {
    code: FareAclCode.Create,
    title: 'fare.create.title',
    description: 'fare.create.subTitle',
  },
  {
    code: FareAclCode.Update,
    title: 'fare.update.title',
    description: 'fare.update.subTitle',
  },
  {
    code: FareAclCode.Delete,
    title: 'fare.delete.title',
    description: 'fare.delete.subTitle',
  },
  {
    code: FareAclCode.Assign,
    title: 'fare.assign.title',
    description: 'fare.assign.subTitle',
  },
  {
    code: FareAclCode.Audit,
    title: 'fare.audit.title',
    description: 'fare.audit.subTitle',
  },
] as Acl<FareAclCode>;
