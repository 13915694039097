import { Acl } from '../../interfaces/core.interface';

export enum SettingsAclCode {
  ViewGeneral = 'view-general',
  UpdateGeneral = 'update-general',
  ViewMeter = 'view-meter',
  UpdateMeter = 'update-meter',
  ViewPayment = 'view-payment',
  UpdatePayment = 'update-payment',
  ViewShifts = 'view-shifts',
  UpdateShifts = 'update-shifts',
  ViewPenalties = 'view-penalties',
  UpdatePenalties = 'update-penalties',
  ViewConsent = 'view-consent',
  UpdateConsent = 'update-consent',
  ViewLanguage = 'view-language',
  ViewCurrency = 'view-Currency',
  UpdateCurrency = 'update-currency',
  CreateCurrency = 'create-currency',
  ViewDriverRequirement = 'view-driver-requirements',
  ViewVehicleRequirement = 'view-vehicle-requirements'


}

export const SettingsAcl = [
  {
    code: SettingsAclCode.ViewGeneral,
    title: 'settings.viewGeneral.title',
    description: 'settings.viewGeneral.subTitle',
  },
  {
    code: SettingsAclCode.UpdateGeneral,
    title: 'settings.updateGeneral.title',
    description: 'settings.updateGeneral.subTitle',
  },
  {
    code: SettingsAclCode.ViewMeter,
    title: 'settings.viewMeter.title',
    description: 'settings.viewMeter.subTitle',
  },
  {
    code: SettingsAclCode.UpdateMeter,
    title: 'settings.updateMeter.title',
    description: 'settings.updateMeter.subTitle',
  },
  {
    code: SettingsAclCode.ViewPayment,
    title: 'settings.viewPayment.title',
    description: 'settings.viewPayment.subTitle',
  },
  {
    code: SettingsAclCode.UpdatePayment,
    title: 'settings.updatePayment.title',
    description: 'settings.updatePayment.subTitle',
  },
  {
    code: SettingsAclCode.ViewShifts,
    title: 'settings.viewShifts.title',
    description: 'settings.viewShifts.subTitle',
  },
  {
    code: SettingsAclCode.UpdateShifts,
    title: 'settings.updateShifts.title',
    description: 'settings.updateShifts.subTitle',
  },
  {
    code: SettingsAclCode.ViewPenalties,
    title: 'settings.viewPenalties.title',
    description: 'settings.viewPenalties.subTitle',
  },
  {
    code: SettingsAclCode.UpdatePenalties,
    title: 'settings.updatePenalties.title',
    description: 'settings.updatePenalties.subTitle',
  },
  {
    code: SettingsAclCode.ViewConsent,
    title: 'settings.viewConsent.title',
    description: 'settings.viewConsent.subTitle',
  },
  {
    code: SettingsAclCode.UpdateConsent,
    title: 'settings.updateConsent.title',
    description: 'settings.updateConsent.subTitle',
  },
  {
    code: SettingsAclCode.ViewLanguage,
    title: 'settings.viewLanguage.title',
    description: 'settings.viewLanguage.subTitle',
  },  {
    code: SettingsAclCode.ViewCurrency,
    title: 'settings.viewCurrency.title',
    description: 'settings.viewCurrency.subTitle',
  },  {
    code: SettingsAclCode.UpdateCurrency,
    title: 'settings.updateCurrency.title',
    description: 'settings.updateCurrency.subTitle',
  },
  {
    code: SettingsAclCode.CreateCurrency,
    title: 'settings.createCurrency.title',
    description: 'settings.createCurrency.subTitle',
  },
  {
    code: SettingsAclCode.ViewDriverRequirement,
    title: 'settings.ViewDriverRequirement.title',
    description: 'settings.ViewDriverRequirement.subTitle',
  },

  {
    code: SettingsAclCode.ViewVehicleRequirement,
    title: 'settings.ViewVehicleRequirement.title',
    description: 'settings.ViewVehicleRequirement.subTitle',
  }
] as Acl<SettingsAclCode>;
