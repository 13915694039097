import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Role, RoleAssignment } from '../../entities/role.entity';
import { FormMode } from '../../enums/core.enum';
import { FormHeaderType } from '../../enums/form.enum';
import { DashboardRoute } from '../../pages/dashboard/routes';
import { Api, Endpoint } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { Loading } from '../alerts/loading.component';
import { FormPropsContext } from './form.component';

/*  The props that the header component expects to be passed to it 
    type (required): Either be Title or Options. Title header uses the title passed as the prop to show heading.
        Controls header shows the save and cancel controls 
    title (optional): The title that'll be shown on top of the form
    className (optional): Needed if additional styling needs to be provided */
interface Props {
    type: FormHeaderType;
    title?: string;
    className?: any;
    fullTitle?: boolean;
}

/* The FormHeader component */
export function FormHeader({
    type, title, className, fullTitle
}: Props) {
    /* The FormPropsContext provides the required properties to the header */
    const context = useContext(FormPropsContext);
    const form = useFormContext();
    const {clearErrors,errors,formState:{isSubmitted,isSubmitting}} = form
    const { returnLink, errorMessage, cancel, loading, mode, defaultLoading, useParentFormContext,saving } = context;
    
    /* The useTranslation hook */
    const { t } = useTranslation('main');
    const [show, setShow] = useState<boolean>(false)
    const Payload: any | undefined = AuthService.getUser();
    const { user } = Payload;
    const assignments: RoleAssignment[] = user ? [...((user?.role as Role).assignments as RoleAssignment[])] : [];
    const moduleCodes = Array.from(assignments, assignment => ({
        module: assignment.module,
        action: assignment.action
    }));
    const [errorMsg,setErrorMsg] = useState<string|null|undefined>("")

    useEffect(()=>{
        if(Object.keys(errors).length>0){
        setErrorMsg((errors[Object.keys(errors)[0]]).message)
        }
        else{
                setErrorMsg(null)       
        }
        // errorMessage(errors)

    },[form,clearErrors,errors])
    const history = useHistory();
    const recoverAccount = async()=>{

        const data = form.getValues()

        const ITEMS = await Api.post<
        any,
        any
      >(Endpoint.DRIVER_RECOVER, { email: data.email });

      console.log("items",ITEMS);

      if(cancel){
          cancel()
      }


    }
    useEffect(()=>{
        if(window.location.pathname.substr(1,10) === 'inspection'){
            setShow(true)
        } else{
            setShow(false)
        }
    },[show])

    /* The getHeading function */
    const getHeading = () => {
        /* Return the loading message if the loading state provided by the form component is true */
        if (loading) {
            return t("form.header.loading");
        }

        if(fullTitle) {
            return (title);
        }
        /* Otherwise, show the form header based on the title prop provided to it */
        return mode === FormMode.Adding ? t("form.header.newTitle", { what: title }) : t("form.header.updateTitle", { what: title });
    }
    
    /* Return the header based on the type provided to it */
    return type === FormHeaderType.Title ?
        /* The title header */
        <h6 className={ classNames("element-header", className) }>
            <Loading loading={ !!loading } />
            { getHeading() }
        </h6> : 
        /* The controls header */
        <h6 className={ classNames("element-header", className) }>
            <Loading loading={ !!loading } className="invisible" />

            <div className="form-actions">
                {/* In case of error message, show it */}
                { errorMsg && (<span className="form-error">{ errorMsg }</span>)}

                {/* The return link if one decides to cancel the form */}
                {show === false?
                ( !cancel ? (
                    <Link to={ returnLink ? returnLink : DashboardRoute.Overview } className="btn btn-primary-outline" type="button">{ t("form.controls.cancel") }</Link>
                ) : (
                    <button onClick={ () => { cancel(); } } className="btn btn-primary-outline" type="button">{ t("form.controls.cancel") }</button>
                ) ):null}
{errorMsg=="Driver with this email/phone combination was deleted and cannot be re-added. Recover deleted driver?"?
                <button onClick={ () => {recoverAccount()} } className="btn btn-primary-primary" type="button">Yes</button>:""}
                
                {/* If no error message and form isn't in saving condition, show the save button */}
                {!isSubmitting && !errorMsg  && <button className="btn btn-success" type="submit">{ t("form.controls.save") }</button> }
                
                {/* Show the Saving... button if the form is in saving condition */}
                { isSubmitting &&  <button className="btn btn-primary" disabled type="button">{ t("form.controls.saving") }</button> }
                
                {/* In case of error, disable the save button */}
                { errorMsg &&             <button className="btn btn-danger" disabled type="button" title={ errorMsg }>{ t("form.controls.error")}</button> }
            </div>
        </h6>;
}