import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayAssignment } from './role.interface';

interface Props {
  name: string;
  value?: DisplayAssignment,
  readonly?: boolean;
  className?: string; 
  tabIndex?: number;
  onChange?: (value: DisplayAssignment | undefined) => void,
}

export function UserRolePostAssignment(props: Props) {
  const { value, readonly, className, tabIndex, onChange } = props;

  const { t } = useTranslation('acl');

  const { id, role, module, action } = value || {};

  function toggle() {
    if (readonly) {
      return;
    }
    let newValue: DisplayAssignment | undefined;
    if (action && module) {
      newValue = { id, action, module, role, checked: !value?.checked, show: value?.show };
    } else {
      newValue = undefined;
    }
    if (onChange) {
      onChange(newValue);
    }
  }

  return (
    <div
      className={ classNames('todo-item', value?.checked ? 'complete' : 'incomplete', className)}
      style={{ display: value?.show ? 'flex' : 'none' }}
      tabIndex={ tabIndex }
      onClick={ toggle }
    >
      <div className="ti-info">
        <div className="ti-header">{ t(`${action?.title}`) }</div>
        <div className="ti-sub-header">{ t(`${action?.description}`) }</div>
      </div>
      { !readonly && (
        <div className="ti-icon">
          <i className={ classNames('os-icon', value?.checked ? 'os-icon-check' : 'os-icon-x') } />
        </div>
      )}
    </div>
  )
}
