import { RouteProps } from "react-router-dom";
import { CorporateBookingList } from "./booking/list/list.component";
import { CorporateAccountList } from "./account/list/list.component";
import { CorporateUserList } from "./user/list/list.component";


export enum CorporateBookingRoute {
  Account = '/corporate/account',
  Booking = '/corporate/booking',
  User = '/corporate/user',
}

export const CorporateBookingRouteMap = [
  {
    path: CorporateBookingRoute.Account,
    component:CorporateAccountList,
  },  
  {
    path: CorporateBookingRoute.User,
    component:CorporateUserList,
  },
  {
    path: CorporateBookingRoute.Booking,
    component: CorporateBookingList,
  },
 
] as RouteProps[];
