import { RouteProps } from "react-router-dom";
import { ZoneReport } from "./list/zone-report.component";
import { GpsReport } from "./list/gps.component";
import { BalanceSheet } from "./list/list.component";
import { RideReport } from "./list/rides.component";
import { ShiftReport } from "./list/shift.component";



export enum ReportRoute {
  Sheet = '/report/sheet',
  Ride = '/report/ride',
  Shift = '/report/shift',
  Gps = '/report/gps',
  ZoneReport = '/report/zone'
}

export const ReportRouteMap = [
  {
    path: ReportRoute.Sheet,
    component: BalanceSheet,
  },
  {
    path: ReportRoute.Ride,
    component: RideReport,
  },
  {
    path: ReportRoute.Shift,
    component: ShiftReport,
  },
  {
    path: ReportRoute.Gps,
    component: GpsReport,
  },
  {
    path: ReportRoute.ZoneReport,
    component: ZoneReport,
  },
] as RouteProps[];
