import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Subscriber } from "../../../entities/subscriber.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';
import { checkAssignment } from "../../../util/roleAssignment";
import { SubscriberPost } from "../post/post.component";
import { SelectedItemContent } from "./content.component";


const groupFilters: GroupFilter[] = [
  { title: 'All', icon: 'phone-21' },
];

export function SubscriberList() {


  const { t } = useTranslation('list');
  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('')
  const [allCount, setAllCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [subscribers, setSubscribers] = useState<Subscriber[] | undefined>(undefined);
  const [selectedSubscriber, setSelectedSubscriber] = useState<Subscriber>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [size, setSize] = useState<number>()
  

  const refresh = useCallback(async () => {
    const group = activeGroupFilter?.title;
    const { items, totalItems } = await Api.get<IPaginationResponse<Subscriber>, IPaginationOptions>(Endpoint.SUBSCRIBER_LIST, { page: 0, limit, group, search });

    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items) {
      setSubscribers(items);
    }

    setAllCount(totalItems)

    if (items.length > 0 && !selectedSubscriber) {
      setSelectedSubscriber(items[0]);
    }
  }, [search, limit]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const deleteSubscriber = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.SUBSCRIBER, { id: selectedSubscriber?.id });
    setSelectedSubscriber(undefined);
    refresh();
  }

  const toListItems = (subscriber?: Subscriber): ListItem<Subscriber> | undefined => {
    if (!subscriber) {
      return;
    }

    return {
      id: subscriber.id as string,
      title: subscriber.email,
      status: ListItemStatus.Green,
      ref: subscriber
    }
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (subscribers) {
      const currentSubscriberIndex = subscribers.findIndex(subscriber => !!selectedSubscriber && subscriber.id === selectedSubscriber.id);
      if (currentSubscriberIndex + 1 < subscribers.length && currentSubscriberIndex !== -1) {
        setSelectedSubscriber(subscribers[currentSubscriberIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (subscribers) {
      const currentSubscriberIndex = subscribers.findIndex(subscriber => !!selectedSubscriber && subscriber.id === selectedSubscriber.id);
      if (currentSubscriberIndex - 1 > -1 && currentSubscriberIndex !== -1) {
        setSelectedSubscriber(subscribers[currentSubscriberIndex - 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedSubscriber(undefined);
    setActiveGroupFilter(target);
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: 'All', icon: 'phone-21', count: allCount },
                ]}
                activeGroupFilter={activeGroupFilter}
                onGroupSelect={selectGroup}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={subscribers?.map(toListItems) as ListItem<Subscriber>[]}
                activeItem={toListItems(selectedSubscriber)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setSubscribers(undefined); refresh(); }}
                onClick={(item: ListItem<Subscriber>) => { setSelectedSubscriber(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                subscribers !== undefined ? (
                  selectedSubscriber ? (
                    <View
                      componentName={"subscriber"}
                      showDelete={true}
                      showNext={true}
                      showAudit={true}
                      showPrev={true}
                      onDelete={deleteSubscriber}
                      onNext={onNext}
                      onPrev={onPrev}
                      onAudit={() => checkAudit(selectedSubscriber.id)}
                    >
                      <SelectedItemContent
                        selectedSubscriber={selectedSubscriber}
                      />
                    </View>
                  ) : null
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t('subscriber.wait')}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedSubscriber ? (
                  <SubscriberPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedSubscriber.id}
                    onAfterSave={() => refresh()}
                  />
                ) : (
                  <SubscriberPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    onAfterSave={() => refresh()}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
            groupFilters={[
              { title: 'All', icon: 'phone-21', count: allCount },
            ]}
            activeGroupFilter={activeGroupFilter}
            onGroupSelect={selectGroup}
          />

          <ListItems
            items={subscribers?.map(toListItems) as ListItem<Subscriber>[]}
            activeItem={toListItems(selectedSubscriber)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setSubscribers(undefined); refresh(); }}
            onClick={(item: ListItem<Subscriber>) => { setSelectedSubscriber(item.ref); }}
          />

          {formMode === undefined ? (
            subscribers !== undefined ? (
              selectedSubscriber ? (
                <View
                  componentName={"subscriber"}
                  showDelete={true}
                  showNext={true}
                  showAudit={true}
                  showPrev={true}
                  onDelete={deleteSubscriber}
                  onNext={onNext}
                  onPrev={onPrev}
                  onAudit={() => checkAudit(selectedSubscriber.id)}
                >
                  <SelectedItemContent
                    selectedSubscriber={selectedSubscriber}
                  />
                </View>
              ) : null
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t('subscriber.wait')}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedSubscriber ? (
              <SubscriberPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedSubscriber.id}
                onAfterSave={() => refresh()}
              />
            ) : (
              <SubscriberPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh()}
              />
            )
          )}
        </List>
      }
    </div>
  );

}
