import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormInput } from "../../../components/form/input/input.component";
import { FormSection } from "../../../components/form/section.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Settings } from "../../../entities/settings.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType } from "../../../enums/form.enum";
import { Api, Endpoint } from "../../../services/api.service";
import { SettingsRoute } from "../routes";

/**
 * Update general settings
 */
export function SettingsSectionMeter() {

  const { t } = useTranslation('settings');
  const [entity,setEntity] = useState<any>("");
  useEffect(()=>{
  
    a();
  },[])

  const a =async()=>{
    const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
    setEntity(data.id)
  };
 

  const onBeforeSave = (_: FormMode, form: Settings) => {
    form.entityId = entity
    form.meterTax = form.meterTax ? Math.round(form.meterTax * 100) : 0;
    form.meterVAT = form.meterVAT ? Math.round(form.meterVAT * 100) : 0;
  }

  return (
    <Form
      endpoint={Endpoint.SETTINGS}
      returnLink = {SettingsRoute.Meter}
      onBeforeSave={onBeforeSave}
    >
      <SectionsWrapper>
        <FormHeader type={FormHeaderType.Title} title={t("meter.title")} fullTitle={true} />
        <FormSections>
          <MeterSection />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper>
        <FormHeader type={FormHeaderType.Controls} />
      </SectionsWrapper>
    </Form>
  );
}

const MeterSection = () => {

  const { t } = useTranslation('settings');
  const childProps = useFormContext();

  const refresh = useCallback(async () => {
    const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
    data.meterTax /= 100;
    data.meterVAT /= 100;
    childProps.reset(data);
  }, [childProps]);

  useEffect(() => {
    refresh();
  }, []);

  return (
    <FormSection
      title={t("meter.taxSection.title")}
      subtitle=" "
    >
      <div className="row">
        <FormInput
          name="meterTax"
          label={t("meter.taxSection.taxAmountLabel")}
          placeholder="Percentage"
          type="number"
          step={0.01}
          className="col-sm-4"
          tabIndex={1}
          autoFocus
          validation={{ required: true }}
          {...childProps}
        />
        <FormInput
          name="meterTaxLabel"
          label={t("meter.taxSection.taxLabel")}
          placeholder="Ex. GST"
          type="text"
          className="col-sm-4"
          tabIndex={2}
          validation={{}}
          {...childProps}
        />
        <FormInput
          name="meterVAT"
          label={t("meter.taxSection.vatLabel")}
          placeholder=""
          type="number"
          step={0.01}
          className="col-sm-4"
          tabIndex={3}
          validation={{}}
          {...childProps}
        />
      </div>
    </FormSection>
  );
}