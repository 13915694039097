import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from '../../../components/form/form.component';
import { FormHeader } from '../../../components/form/header.component';
import { FormSection } from '../../../components/form/section.component';
import { FormSections, SectionsWrapper } from '../../../components/form/sections.component';
import { FormHeaderType } from '../../../enums/form.enum';
import { Api, Endpoint } from '../../../services/api.service';

export const SettingsSectionLanguage =() =>{

    const { t } = useTranslation('settings'); 
  
    return (
      <Form >
        <SectionsWrapper>
          <FormHeader type={ FormHeaderType.Title } title={ t("language.title") } fullTitle={ true } />
          <FormSections>
            <LanguageSection />
          </FormSections>
        </SectionsWrapper> 
      </Form>
    );
}

const LanguageSection = () =>{
    const { t, i18n } = useTranslation("settings");

    const handleClick = async(e:any) => { 
        i18n.changeLanguage(e.target.value);
        const data = await Api.post<any, any>(Endpoint.SETTINGS_UPDATE_LANGUAGE,{language:e.target.value});
    }

    return(
        <FormSection
            title={ t("language.languageSection.title") }
            subtitle={ t("language.languageSection.subtitle") }
        >
            <div className = "row"> 
                <div className="element-actions">
                    <form className="col-sm-12">
                        <select defaultValue={i18n.language} onChange = {handleClick} className="form-control form-control-sm rounded">
                        <option value = "en">{ t('language.languageSection.english') }</option>
                        <option value = "es">{ t('language.languageSection.spanish') }</option> 
                        <option value = "fr">{ t('language.languageSection.french') }</option> 
                        </select>
                    </form>
                </div>
            </div>
        </FormSection>
         
    );
}