import { IEntityBase } from "../interfaces/entity.interface";
import { Booking } from "./booking.entity";
import { Driver } from "./driver.entity";
import { Fare } from "./fare.entity";
import { Passenger } from "./passenger.entity";
import { Service } from "./service.entity";
import { Session } from "./session.entity";
import { User } from "./user.entity";
import { Vehicle } from "./vehicle.entity";
import { Zone } from "./zone.entity";

export enum TripStatus {
    Enroute = "enroute",
    Metering = "metering",
    Completed = "completed",
    Cancelled = "cancelled",
    QrScanCancelled = "qr-cancelled",
    DriverEnroute = "driver-enroute",
    Arrived = "arrived"
}

export enum TripType {
    Hail = "hail",
    App = "app",
    Dispatch = "dispatch"
} 
enum RateType {
    Normal = "normal",
    Fixed = "fixed",
    Discounted = "discounted"
}
export enum DiscountType {
    Normal = "normal",
    Fixed = "fixed",
    Percentage = "percentage"
}
enum paidBy {
    CreditCard = "credit-card",
    Cash = "cash"
}

export interface Trip extends IEntityBase {
    [key: string]: any; 
    code: string;
    fare: Fare | string;
    zone: Zone | string;
    service: Service | string;
    passenger?: Passenger | string;
    startTime: Date;
    endTime?: Date;
    startLatitude: number;
    startLongitude: number;
    stopLatitude?: number;
    stopLongitude?: number;
    session: Session | string;
    booking: Booking | string;
    distance?: number;
    waitTime?: number;
    type: TripType;
    status: TripStatus;
    createTime: Date;
    updateTime: Date;
    createdBy: User | string;
    owner: User | string;
    deletedTime: Date;
    totalFare?: number;
    distanceCharge?: number;
    waitCharge?: number;
    bookingCharge?: number;
    tax?: number;
    taxLabel: string;
    levy?: number;
    tolls?: number;
    rideTime?: number;
    ccCharge?: number;
    extraCharge?: number;
    startCharge?: number;
    tip?: number; 
    driver: Driver | string;
    vehicle: Vehicle | string;  
    fuel?: number; 
    rateType: RateType;
    discountType: DiscountType;
    discountValue?: number; 
    paidBy: paidBy; 
  }