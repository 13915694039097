import { ModuleName } from '../enums/core.enum';
import { Acl } from '../interfaces/core.interface';

class AclServiceClass {
  private actionMap = new Map<ModuleName, Acl<any> | undefined>();
  private modules = new Set<ModuleName>();

  setModuleActions<AclCode>(module: ModuleName, actions: Acl<AclCode> | undefined) {
    this.actionMap.set(module, actions);
    this.modules.add(module);
  }

  getModuleActions<AclCode>(module: ModuleName): Acl<AclCode> | undefined {
    return this.actionMap.get(module);
  }

  getModules(): ModuleName[] {
    return Array.from(this.modules);
  }
}

export const AclService = new AclServiceClass();
