import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Booking, BookingStatus } from "../../../entities/booking.entity";
import { Api, Endpoint } from "../../../services/api.service";
import { Activity, DashboardBookingActivity } from "./activity.component";
import { differenceInDays, formatRelative, subDays } from 'date-fns';
import { useHistory } from "react-router-dom"
import { Settings } from "../../../entities/settings.entity";
import { SettingsService } from "../../../services/settings.service";
import { Currency } from "../../../entities/currency.entity";
import { Modal } from "react-responsive-modal";
import { checkAssignment } from "../../../util/roleAssignment";

export interface BookingResponse {
  id: string,
  activity: Activity,
}
enum ActivityStatus {
  Scheduled, Waiting, Enroute, Escalated,
}
const StatusMap = new Map([
  [ActivityStatus.Scheduled, [BookingStatus.ScheduledDriverEnroute, BookingStatus.ScheduledPending,BookingStatus.ScheduledPendingLastAttempt,BookingStatus.ScheduledTaken,BookingStatus.ScheduledTakenCancelled]],
  [ActivityStatus.Waiting, [BookingStatus.Pickup, BookingStatus.AwaitingAccept]],
  [ActivityStatus.Enroute, [BookingStatus.DriverEnroute, BookingStatus.DestinationEnroute]],
  [ActivityStatus.Escalated, [BookingStatus.DriverCancelled, BookingStatus.PassengerCancelled, BookingStatus.NotAccepted]],
]);
let BookingList: Map<string | undefined, Activity>
let BookingExtra: Map<string | undefined, Activity>
let bookingCheck: boolean = false;
let intialBookingCheck: number = 0;


export function DashboardBooking(props: any) {
  let history = useHistory();
  const [bookingList, setBookingList] = useState<Map<string | undefined, Activity>>(new Map());
  const [Scheduled, setScheduled] = useState<Activity[]>([]);
  const [waiting, setWaiting] = useState<Activity[]>([]);
  const ref = useRef<Map<string | undefined, Activity>>(new Map());
  const [eventInit, setEventInit] = useState<number>(0);
  const [eventInitCheck, setEventInitCheck] = useState<boolean>(false);

  const [enroute, setEnroute] = useState<Activity[]>([]);
  const [escalated, setEscalated] = useState<Activity[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [bookingCode, setBookingCode] = useState<string>();

  useEffect(() => {
    // check if given module and action is assigned to the role or not return boolean value
    if (!checkAssignment("dashboard", "booking")) {

      history.push(`/dashboard/overview`);

    }
  }, [])

  const onclick = (bookingCode: string) => {
    history.push(`/security/track/${bookingCode}`);
  }

  const loadActivities = (status: ActivityStatus): Activity[] => { 
    let bookings: Activity[];
    if (BookingExtra?.values() !== undefined) {
      bookings = Array.from(BookingExtra.values())
    } else {
      bookings = Array.from(bookingList.values()) 
    }
    BookingList = bookingList;
    return Array.from(bookings.filter(booking => StatusMap.get(status)?.includes(booking.status)));
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if (eventInit === 1) {
      Api.on('booking_update', (data) => handleBookingUpdate(bookingList, data));
      Api.on('booking_alert', (data) => handleBookingAlert(bookingList, data))
      setEventInit(2);
    }
    if (!eventInitCheck) {
      setEventInit(1);
      setEventInitCheck(true);
    }
    setScheduled(loadActivities(ActivityStatus.Scheduled));
    setWaiting(loadActivities(ActivityStatus.Waiting));
    setEnroute(loadActivities(ActivityStatus.Enroute));
    setEscalated(loadActivities(ActivityStatus.Escalated));
  }, [bookingList]);

  const init = async () => {
    try {
      const response = await Api.get<BookingResponse[], void>(Endpoint.DASHBOARD_BOOKING);
      console.log("Reso", response);
      const data = new Map(response.map(booking => [booking.id, booking.activity]))
      setBookingList(data);
      ref.current = data;
    } catch (error: any) {
      console.log('Failed to load booking list', error.message);
    }
  }

  const handleBookingUpdate = async (list: Map<string | undefined, Activity>, data: Booking) => {
    console.log("booking update:",data)
    console.log("old list",data)
    const newActivity = createActivity(data); 
    console.log("newActivity: ",newActivity)
   
    if (!bookingCheck) { 
      BookingExtra = new Map(list);
      bookingCheck = true;
    }
    
    console.log("BookingExtra: ",BookingExtra) 
    let tempMap:Map<any,any>= new Map() 
    tempMap.set(data.id, newActivity)
    let triplistData = Array.from(BookingExtra).map((item) => {
      if(item[0]!==data.id)
      tempMap.set(item[0], item[1])
    })
    console.log("tempMap: ",BookingExtra) 
    BookingExtra= tempMap
    setBookingList(tempMap);
    setScheduled(loadActivities(ActivityStatus.Scheduled));
    setWaiting(loadActivities(ActivityStatus.Waiting));
    setEnroute(loadActivities(ActivityStatus.Enroute));
    setEscalated(loadActivities(ActivityStatus.Escalated));
  }


  const handleBookingAlert = (list: Map<string | undefined, Activity>, data: Booking) => { 
    const newActivity = createActivity(data);
    if (!bookingCheck) { 
      BookingExtra = new Map(list);
      bookingCheck = true;
    } 
    let tempMap:Map<any,any>= new Map() 
    tempMap.set(data.id, newActivity)
    let triplistData = Array.from(BookingExtra).map((item) => {
      if(item[0]!==data.id)
      tempMap.set(item[0], item[1])
    })
    BookingExtra= tempMap
    setBookingList(tempMap);
    setScheduled(loadActivities(ActivityStatus.Scheduled));
    setWaiting(loadActivities(ActivityStatus.Waiting));
    setEnroute(loadActivities(ActivityStatus.Enroute));
    setEscalated(loadActivities(ActivityStatus.Escalated));
  }
  const dateFormate = (date: any) => {
    const settings: Settings | undefined = SettingsService.getSetting(); 

    let d:any = new Date(date)
    return d.toLocaleString(undefined, { timeZone: (settings as Settings)?.zone?.generalTimezone });
    
  }

  const createActivity = (data: Booking) => {
    const pickupAddress = data.pickupAddress ? data.pickupAddress.text.split(',') : ["UNKNOWN"];
    let finalPickupAddress = pickupAddress[pickupAddress.length - 1];
    if (pickupAddress.length == 2) {
      finalPickupAddress = pickupAddress[0]
    }
    else if (pickupAddress.length > 2) {
      finalPickupAddress = pickupAddress[1]
    }
    const dropOffAddress = data.dropOffAddress ? data.dropOffAddress.text.split(',') : ['UNKNOWN'];
    let finalDropOffAddress = dropOffAddress[dropOffAddress.length - 1];
    if (dropOffAddress.length == 2) {
      finalDropOffAddress = dropOffAddress[0]
    }
    else if (pickupAddress.length > 2) {
      finalDropOffAddress = dropOffAddress[1]
    }
    const lastestBookingData: Activity = {
      route: `${finalPickupAddress} to ${finalDropOffAddress}`,
      status: data.status,
      bookingCode: data.code,
      type: data.type,
      estimate: toSubUnit(data.estimate || 0).toString(),
      timestamp: data.updateTime.toString(),
      zone : data.zone
    }
    return lastestBookingData;
  }

  const toSubUnit = (value: number): number => {
    // const settings: Settings | undefined = SettingsService.getSetting(); 
    // const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
    // const val = value / unit;
    return value;
  } 

  const cancelBooking = (bookingCode: string) => { 
    console.log("cancel booking from parent", bookingCode)
    setModal(true)
    setBookingCode(bookingCode)
  }

  const bookingCancel = async () => { 
    const response = await Api.patch(Endpoint.CANCEL_BOOKING, { code: bookingCode }); 
    setModal(false);
  }

  const { t } = useTranslation("acl");
  return (
    <>
      <Modal open={modal}
        onClose={() => {
          setModal(false)
          return false
        }}
        center>
        <h5 style={{ margin: "inherit", padding: "inherit" }} className="modal-title">Are you want to cancel booking ?</h5>
        <div className="modal-footer" style={{ justifyContent: "center" }}>
          <button type="button" onClick={bookingCancel} className="btn btn-primary btn-lg">Yes</button>
          <button type="button" onClick={() => { setModal(false) }} className="btn btn-danger btn-lg" data-dismiss="modal">No</button>
        </div>
      </Modal>
      <div className="content-i">
        <div className="content-box">
          <div className="row">
            <div className="col-sm-12 col-md-3">
              <DashboardBookingActivity onclick={onclick} cancelBooking={cancelBooking} title={t("dashboard.booking.booking-elements.scheduled")} activities={Scheduled} color="secondary" />
            </div>
            <div className="col-sm-12 col-md-3">
              <DashboardBookingActivity onclick={onclick} cancelBooking={cancelBooking} title={t("dashboard.booking.booking-elements.waiting")} activities={waiting} color="primary" />
            </div>
            <div className="col-sm-12 col-md-3">
              <DashboardBookingActivity onclick={onclick} cancelBooking={cancelBooking} title={t("dashboard.booking.booking-elements.way")} activities={enroute} color="success" />
            </div>
            <div className="col-sm-12 col-md-3">
              <DashboardBookingActivity onclick={onclick} title={t("dashboard.booking.booking-elements.escalated")} activities={escalated} color="danger" />
            </div>
          </div>
        </div>
      </div>
    </>
  );

}