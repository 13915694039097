import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormAddressInput } from "../../../../components/form/input/address.component";
import { FormInput } from "../../../../components/form/input/input.component";
import { PhoneInputs } from "../../../../components/form/input/phoneNumber";
import { FormSelect } from "../../../../components/form/input/select.component";
import { FormUpload } from "../../../../components/form/input/upload.component";
import { FormSection } from "../../../../components/form/section.component";
import { User } from "../../../../entities/user.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../services/api.service";

interface Props{
  value: string;
  onChange:any
}

export function FleetPostBasic({value, onChange}:Props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  const [users, setUsers] = useState<User[]>([]);
  
  useEffect(() => { init(); }, [])

  const init = async () => {
    const userList = await Api.get<IPaginationResponse<User>, IPaginationOptions>(Endpoint.USER_LIST);
    setUsers(userList.items);
  }
  return (
    <FormSection
      title={ t("fleet.basicSection.title") }
      subtitle={ t("fleet.basicSection.subTitle") }
    >
      <div className="row">
        <FormUpload
          urls={ []}
          label={t('fleet.basicSection.logo')}
          name="newLogoFile"
          originalName="logoFile"
          className="col-md-4"
          validation={{ required: true }}
          tabIndex={3}
          { ...formProps }
        />
        <FormInput
          label={ t("fleet.basicSection.title") }
          name="title"
          type="text"
          className="col-md-4"
          validation={{ required: true }}
          tabIndex={1}
          autoFocus
          { ...formProps }
        />
        <FormInput
          label={ t("fleet.basicSection.color") }
          name="color"
          type="color"
          className="col-md-4"
          validation={{ required: true }}
          tabIndex={2}
          { ...formProps }
        />
      </div>
      <div className="row">
        <FormInput
          label={t("fleet.basicSection.corporateACN")}
          name="businessRegistration"
          type="text"
          className="col-md-6"
          validation={{ required: true }}
          tabIndex={3}
          { ...formProps }
        />
        <FormAddressInput
          label={t("fleet.basicSection.address") }
          name="address"
          className="col-md-6"
          validation={{ required: true }}
          tabIndex={4}
          { ...formProps }
        />
      </div>
      <div className="row">
        <FormInput
          label={t("fleet.basicSection.contactPerson")}
          name="contactPerson"
          type="text"
          className="col-md-4"
          validation={{ required: true }}
          tabIndex={5}
          { ...formProps }
        />
        <PhoneInputs
          placeholder={t("fleet.basicSection.contactPhone")}  
          value={value}
          onChange={onChange}
          name="contactPhone"
          className="col-md-4"  
          {...formProps}
        /> 
        <FormInput
          label={t("fleet.basicSection.contactEmail") }
          name="contactEmail"
          type="text"
          className="col-md-4"
          validation={{ required: true }}
          tabIndex={7}
          { ...formProps }
        />
        <FormSelect<User>
          label={ t("fleet.basicSection.manager") }
          name="manager"
          className="col-sm-12"
          showLabel={ true }
          multiple={ false }
          validation={{ required: false }}
          items={ users }
          labelField="email"
          onChange={ () => {} }
          tabIndex={14}
          { ...formProps }
        />
      </div>
    </FormSection>
  );

}
