import { RouteProps } from "react-router-dom";
import { CustomTollsPost } from "./post.component";
import { CustomTollsList } from "./list/list.component";

export enum CustomTollsRoute {
  Post = '/customTolls/post',
  List = '/customTolls/list'
}

export const CustomTollsRouteMap = [
  {
    path: CustomTollsRoute.List,
    component: CustomTollsList,
  },
  {
    path: CustomTollsRoute.Post,
    component: CustomTollsPost,
  }
] as RouteProps[];
