import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormInput } from "../../../components/form/input/input.component";
import { FormRadio } from "../../../components/form/input/radio.component";
import { FormSection } from "../../../components/form/section.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Currency } from "../../../entities/currency.entity";
import { Settings } from "../../../entities/settings.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType } from "../../../enums/form.enum";
import { Api, Endpoint } from "../../../services/api.service";
import { SettingsService } from "../../../services/settings.service";
import { SettingsRoute } from "../routes";


/**
 * Update general settings
 */
export function SettingsSectionPenalties() {

  const { t } = useTranslation('settings');
  const [entity,setEntity] = useState<any>("");
  useEffect(()=>{
  
    a();
  },[])

  const a =async()=>{
    const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
    setEntity(data.id)
  };
 

  const onBeforeSave = (_: FormMode, form: Settings) => {
    form.entityId = entity
    form.penaltyDriverLateValue = form.penaltyDriverLateValue ? Math.round(form.penaltyDriverLateValue * 100) : 0;
    form.penaltyDriverCancelValue = form.penaltyDriverCancelValue ? Math.round(form.penaltyDriverCancelValue * 100) : 0;
    form.penaltyDriverRefusalValue = form.penaltyDriverRefusalValue ? Math.round(form.penaltyDriverRefusalValue * 100) : 0;
    form.penaltyPaxCancelValue = form.penaltyPaxCancelValue ? Math.round(form.penaltyPaxCancelValue * 100) : 0;
  }
  
  return (
    <Form<Settings>
      endpoint={ Endpoint.SETTINGS }
      returnLink = {SettingsRoute.Penalty}
      onBeforeSave={ onBeforeSave }
    >
      <SectionsWrapper>
        <FormHeader type={ FormHeaderType.Title } fullTitle={ true } title={ t("penalties.title") } />
        <FormSections>
          <DriverPenalties />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper>
        <FormHeader type={ FormHeaderType.Controls } />
        <FormSections>
          <VehiclePenalties />
        </FormSections>
      </SectionsWrapper>
    </Form>
  );
}

const DriverPenalties = () => {

  const { t } = useTranslation('settings');
  const childProps = useFormContext();

  const settings : Settings | undefined = SettingsService.getSetting();

  const currency =  (settings?.generalCurrency as Currency)?.code;

  const [, setSettings] = useState<Settings>();

  const ref = useCallback(()=>{
    const refresh = async () => {
      const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
      data.penaltyDriverLateValue = data.penaltyDriverLateValue ? Math.round(data.penaltyDriverLateValue / 100) : undefined;
      data.penaltyDriverCancelValue = data.penaltyDriverCancelValue ? Math.round(data.penaltyDriverCancelValue / 100) : undefined;
      data.penaltyDriverRefusalValue = data.penaltyDriverRefusalValue ? Math.round(data.penaltyDriverRefusalValue / 100) : undefined;
      data.penaltyPaxCancelValue = data.penaltyPaxCancelValue ? Math.round(data.penaltyPaxCancelValue / 100) : undefined;
      childProps.reset(data);
      setSettings(data);
    };
    refresh()
  }, [childProps])
   
  useEffect(() => {
    ref();
  }, []);

  return(
    <FormSection
      title={ t("penalties.driverSection.title") }
      subtitle={ t("penalties.driverSection.subTitle") }
    >
      <div className="row">
        <FormRadio 
          label={ t("penalties.driverSection.lateLabel") }
          name="penaltyDriverLateAction"
          className="col-md-6"
          validation={{}}
          { ...childProps }
          options={[
            { label: t("penalties.driverSection.lateDisableMetering"), value: 'disable-meter' },
            { label: t("penalties.driverSection.lateCharge"), value: 'charge' },
            { label: t("penalties.driverSection.lateWarn"), value: 'warn' },
          ]}
          tabIndex={1}
        />
        <FormInput
          name="penaltyDriverLateValue"
          label={ t("penalties.driverSection.lateValueLabel") }
          placeholder={`Minutes / ${currency}`}
          type="number"
          step={ 0.01 }
          className="col-sm-4"
          tabIndex={2}
          validation={{}}
          { ...childProps }
        />
      </div>
      <div className="row">
        <FormRadio 
          label={ t("penalties.driverSection.cancellationLabel") }
          name="penaltyDriverCancelAction"
          className="col-md-6"
          validation={{}}
          { ...childProps }
          options={[
            { label: t("penalties.driverSection.lateDisableMetering"), value: 'disable-meter' },
            { label: t("penalties.driverSection.lateCharge"), value: 'charge' },
            { label: t("penalties.driverSection.lateWarn"), value: 'warn' },
          ]}
          tabIndex={3}
        />
        <FormInput
          name="penaltyDriverCancelValue"
          label={ t("penalties.driverSection.cancelValueLabel") }
          placeholder={`Minutes / ${currency}`}
          type="number"
          step={ 0.01 }
          className="col-sm-4"
          tabIndex={4}
          validation={{}}
          { ...childProps }
        />
      </div>
      <div className="row">
        <FormRadio 
          label={ t("penalties.driverSection.refusalLabel") }
          name="penaltyDriverRefusalAction"
          className="col-md-6"
          validation={{}}
          { ...childProps }
          options={[
            { label: t("penalties.driverSection.lateDisableMetering"), value: 'disable-meter' },
            { label: t("penalties.driverSection.lateCharge"), value: 'charge' },
            { label: t("penalties.driverSection.lateWarn"), value: 'warn' },
          ]}
          tabIndex={5}
        />
        <FormInput
          name="penaltyDriverRefusalValue"
          label={ t("penalties.driverSection.refusalValueLabel") }
          placeholder={`Minutes / ${currency}`}
          type="number"
          step={ 0.01 }
          className="col-sm-4"
          tabIndex={6}
          validation={{}}
          { ...childProps }
        />
      </div>

     
    </FormSection>
  );
}

const VehiclePenalties = () => {

  const { t } = useTranslation('settings');
  const childProps = useFormContext();

  const settings : Settings | undefined = SettingsService.getSetting();

  const currency =  (settings?.generalCurrency as Currency)?.code;

  return(
    <FormSection
      title={ t("penalties.passengerSection.title") }
      subtitle={ t("penalties.passengerSection.subTitle") }
    >             
      <div className="row">
        <FormRadio 
          label={ t("penalties.passengerSection.cancelActionLabel") }
          name="penaltyPaxCancelAction"
          className="col-md-6"
          validation={{}}
          { ...childProps }
          options={[
            { label: t("penalties.passengerSection.cancelActionCharge"), value: 'charge' },
            { label: t("penalties.passengerSection.cancelActionWarn"), value: 'warn' },
          ]}
          tabIndex={7}
        />
        <FormInput
          name="penaltyPaxCancelValue"
          label={ t("penalties.passengerSection.cancelValueLabel") }
          placeholder={`Minutes / ${currency}`}
          type="number"
          step={ 0.01 }
          className="col-sm-4"
          tabIndex={8}
          validation={{}}
          { ...childProps }
        />
      </div>
    </FormSection>
  );
}