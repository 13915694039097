import { GoogleMap, Marker } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ContentWrapper,
  ViewContent
} from "../../../components/view/content.component";
import {
  ViewContentItem,
  ViewContentItems
} from "../../../components/view/item.component";
import { Currency } from "../../../entities/currency.entity";
import { CustomTolls } from "../../../entities/customTolls.entity";
import { Settings } from "../../../entities/settings.entity";
import { Api, Endpoint } from "../../../services/api.service";
import { SettingsService } from "../../../services/settings.service";
import { ProfilePicSize } from "./list.component";

interface Props {
  selectedCustomTolls: CustomTolls;
  onRefresh: () => void;
  setSelectedCustomTolls?: (customTolls: undefined | CustomTolls) => void;
  errorMessage: string | undefined
}

interface CustomTollsStatisticsResponse {
  tripCount: number;
  revenue: number;
  distance: number;
  totalTime: number;
  tripPercentage: number;
  revenuePercentage: number;
  distancePercentage: number;
  prevTripCount: number;
  prevRevenue: number;
  prevDistance: number;
  prevTotalTime: number;
  timePercentage: number;
}

interface Time {
  time: string;
  id: string | undefined;
}

export function SelectedItemContent({
  selectedCustomTolls,
  onRefresh,
  setSelectedCustomTolls,
  errorMessage
}: Props) {
  const { t } = useTranslation(["forms", "dashboard"]);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: selectedCustomTolls.latitude,
    lng: selectedCustomTolls.longitude,
  });

  const [radius, setRadius] = useState<google.maps.Circle>();
  const [zoom, setZoom] = useState(15);
  const [map, setMap] = useState<google.maps.Map>()

  const settings: Settings | undefined = SettingsService.getSetting();

  const toSubUnit = (value: number): string => {
    const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
    const symbol = (settings?.generalCurrency as Currency).symbol
    return `${symbol} ${value / unit}`;
  }

  const onclickRecover = async (selectedCustomTolls: CustomTolls) => {
    console.log("Recover This CustomTolls:", selectedCustomTolls);
    const response = await Api.post<CustomTolls, { id: string }>(
      Endpoint.SERVICE_RECOVER,
      { id: selectedCustomTolls.id as string }
    );
    console.log("Recover This CustomTolls Response:", response);
    if (onRefresh && setSelectedCustomTolls) {
      setSelectedCustomTolls(undefined);
      onRefresh();
    }
  };

  useEffect(()=>{
    console.log("Inside Change selected Tools",selectedCustomTolls.id)
    radius?.setMap(null);
    var circle = new google.maps.Circle({
      center: {
        lat: selectedCustomTolls.latitude,
        lng: selectedCustomTolls.longitude,
      },
      map: map,
      radius: 20,
      fillColor: '#FF6600',
      fillOpacity: 0.3,
      strokeColor: "#FFF",
      strokeWeight: 0,
      clickable: false
    });
    setRadius(circle)
  },[selectedCustomTolls])
  const onLoad = React.useCallback((map) => {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
console.log("This is Onload map")
    map!.setCenter(center);
    setMap(map);
    setCenter({
      lat: selectedCustomTolls.latitude,
      lng: selectedCustomTolls.longitude,
    })
    // setTimeout(() => {
    //   setZoom(12); setCenter({ lat: selectedCustomTolls.latitude,
    //     lng: selectedCustomTolls.longitude,})
    // }, 100);
    var circle = new google.maps.Circle({
      center: center,
      map: map,
      radius: 20,
      fillColor: '#FF6600',
      fillOpacity: 0.3,
      strokeColor: "#FFF",
      strokeWeight: 0,
      clickable: false
    });
    setRadius(circle)
  }, [])

  const onUnmount = React.useCallback(() => {
    setMap(undefined)
  }, []);

  return (
    <>
      {errorMessage && <div className="m-b row">
        <div className=" col-md-6">
          <h6
            style={{
              backgroundColor: "#eb1f1fd9",
              padding: "10px",
              color: "white",
              fontSize: "10",
            }}
          >
            {errorMessage}
          </h6>
        </div>
      </div>}
      <ContentWrapper className="row">
        <ViewContent className="col-md-6" title={selectedCustomTolls.title} >
          <ViewContentItems>
            {/* {selectedCustomTolls.deletedTime ? (
              <div className="alert alert-success borderless">
                <h5 className="alert-heading">CustomTolls Deleted</h5>
                <p>Do you want to recover this customTolls data?</p>
                <div className="alert-btn">
                  <a
                    className="btn btn-white-gold"
                    href={"#href"}
                    onClick={() => onclickRecover(selectedCustomTolls)}
                  >
                    <i className="os-icon os-icon-ui-92"></i>
                    <span>{"Recover CustomTolls"}</span>
                  </a>
                </div>
              </div>
            ) : null} */}
            <ViewContentItem title={t("forms:customTolls.title")}>
              {selectedCustomTolls.title}
            </ViewContentItem>
            <ViewContentItem title={t("forms:common.price")}>
              {toSubUnit(selectedCustomTolls.price)}
            </ViewContentItem>
            <ViewContentItem title=''>
              <GoogleMap
                mapContainerStyle={{ height: '500px', width: '100%' }}
                center={new google.maps.LatLng(selectedCustomTolls.latitude, selectedCustomTolls.longitude)}
                zoom={zoom}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >

                {selectedCustomTolls.location && <Marker position={new google.maps.LatLng(selectedCustomTolls.latitude, selectedCustomTolls.longitude)} />}
              </GoogleMap>
            </ViewContentItem>
          </ViewContentItems>
        </ViewContent>
      </ContentWrapper>
    </>
  );
}
