import { GoogleMap, Marker } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import QRCode from 'react-qr-code';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { Hotspot } from '../../../entities/hotspot.entity';

interface Props {
  selectedHotspot: Hotspot
}


export function SelectedItemContent({
  selectedHotspot
}: Props) {
  const [doc,setDoc] = useState(new DOMParser().parseFromString(selectedHotspot.description, "text/xml"));
  const [date,setDate] =useState<string>();
  const [qrCode,setQrCode] = useState<boolean>();
  const [currentLocation,setCurrentLocation] = useState(new google.maps.LatLng(selectedHotspot.address.lat, selectedHotspot.address.lng));
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: -33.847927,
    lng: 150.651776,
  });
  const [zoom, setZoom] = useState(20);
  useEffect(()=>{
    console.log('Selected Ki Date: ',selectedHotspot.date);
    const date:Date=new Date(selectedHotspot.date);
    const month=((date.getMonth()+1)<10)?`0${date.getMonth()+1}`:`${date.getMonth()+1}`
   const day=((date.getDate())<10)?`0${date.getDate()}`:`${date.getDate()}`
   const formattedDate = `${date.getFullYear()}-${month}-${day}`;
   
   setDate(formattedDate);
   onLoad()
  },[selectedHotspot])



  const onLoad = ()=>{

   

    setCenter({
      lat: selectedHotspot.address.lat,
      lng:selectedHotspot.address.lng
    })

  }
  const { t } = useTranslation('forms');
  return(
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={ selectedHotspot.title }
      >
        <ViewContentItems>
          <ViewContentItem title={ t("hotspot.basicSection.title") }>
            { selectedHotspot.title }
          </ViewContentItem>

          <ViewContentItem title={ t("hotspot.basicSection.address") }>
          {selectedHotspot.address.text}
          </ViewContentItem>

          {/* <ViewContentItem title={ t("") }>
          <button className="btn btn-primary" onClick={()=>{setQrCode(true)}}>Generate QR CODE</button>
          </ViewContentItem> */}

          <ViewContentItem title={ t("") }>
          <QRCode value={`${process.env.REACT_APP_QR_CODE_URL}${selectedHotspot.id}`} />
          </ViewContentItem>
        
        </ViewContentItems>
      </ViewContent>

      <ViewContent
        className="col-md-6"
        title={ selectedHotspot.address.text }
      >
        <ViewContentItems>
          <ViewContentItem title={""}>
          <GoogleMap
              mapContainerStyle={{ height: '391px', width: '100%' }}
              center={center}
              zoom={zoom}
              onLoad={onLoad}
              // onUnmount={onUnmount}
            >
               <Marker
                            position={
                              new google.maps.LatLng(selectedHotspot.address.lat, selectedHotspot.address.lng)
                            }
                          
                          />
              
            </GoogleMap>
          </ViewContentItem>

       
        </ViewContentItems>
      </ViewContent>
    </ContentWrapper>
  );
}