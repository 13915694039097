import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Request } from "../../../entities/request.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';
import { RequestPost } from "../post/post.component";
import { SelectedItemContent } from "./content.component";

const groupFilters: GroupFilter[] = [
  { title: 'All', icon: 'phone-21' },
];

export function RequestList(props: any) {
  const { t } = useTranslation('list');
  const [search, setSearch] = useState<string>('')
  const [allCount, setAllCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [requests, setRequests] = useState<Request[] | undefined>(undefined);
  const [selectedRequest, setSelectedRequest] = useState<Request>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [size, setSize] = useState<number>()


  useEffect(() => {
    setSelectedRequest(props.location.state)
  }, [props.location.state])
  const refresh = useCallback(async (e?: any) => {
    const { items, totalItems } = await Api.get<IPaginationResponse<Request>, IPaginationOptions>(Endpoint.REQUEST_LIST, { page: 0, limit, search });
    console.log('items:', items);
    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items) {
      setRequests(items);
    }
    setAllCount(totalItems)
    if (items.length > 0 && !selectedRequest) {
      setSelectedRequest(items[0]);
    }
    if (selectedRequest && e) {
      const data = items.filter((item: Request) => { return item.id == selectedRequest.id })
      setSelectedRequest(data[0]);
    }
  }, [search, limit, activeGroupFilter, selectedRequest]);

  useEffect(() => { refresh(); }, [refresh]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const deleteRequest = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.REQUEST, { id: selectedRequest?.id });
    setSelectedRequest(undefined);
    refresh();
  }

  const toListItems = (request?: Request): ListItem<Request> | undefined => {
    if (!request) {
      return;
    }

    return {
      id: request.id as string,
      title: request.code,
      status: ListItemStatus.Green,
      ref: request
    }
  }

  const addRequest = () => {
    setFormMode(FormMode.Adding);
  }

  const editRequest = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (requests) {
      const currentRequestIndex = requests.findIndex(request => !!selectedRequest && request.id === selectedRequest.id);
      if (currentRequestIndex + 1 < requests.length && currentRequestIndex !== -1) {
        setSelectedRequest(requests[currentRequestIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (requests) {
      const currentRequestIndex = requests.findIndex(request => !!selectedRequest && request.id === selectedRequest.id);
      if (currentRequestIndex - 1 > -1 && currentRequestIndex !== -1) {
        setSelectedRequest(requests[currentRequestIndex - 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedRequest(undefined);
    setActiveGroupFilter(target);
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: 'All', icon: 'phone-21', count: allCount },
                ]}
                activeGroupFilter={activeGroupFilter}
                onGroupSelect={selectGroup}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={requests?.map(toListItems) as ListItem<Request>[]}
                activeItem={toListItems(selectedRequest)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setRequests(undefined); refresh(); }}
                onClick={(item: ListItem<Request>) => { setSelectedRequest(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                requests !== undefined ? (
                  selectedRequest ? (
                    <View
                      componentName={"request"}
                      showAdd={true}
                      showEdit={false}
                      showDelete={false}
                      showNext={true}
                      showPrev={true}
                      showAudit={true}
                      onAdd={addRequest}
                      onEdit={editRequest}
                      onDelete={deleteRequest}
                      onNext={onNext}
                      onPrev={onPrev}
                      onAudit={() => checkAudit(selectedRequest.id)}
                    >
                      <SelectedItemContent
                        selectedRequest={selectedRequest}
                        refresh={refresh}
                      />
                    </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button onClick={addRequest} className="btn btn-primary text-bold">{t('request.addRequest')}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t('request.wait')}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedRequest ? (
                  <RequestPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedRequest.id}
                    onAfterSave={() => refresh()}
                  />
                ) : (
                  <RequestPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    onAfterSave={() => refresh()}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
            groupFilters={[
              { title: 'All', icon: 'phone-21', count: allCount },
            ]}
            activeGroupFilter={activeGroupFilter}
            onGroupSelect={selectGroup}
          />

          <ListItems
            items={requests?.map(toListItems) as ListItem<Request>[]}
            activeItem={toListItems(selectedRequest)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setRequests(undefined); refresh(); }}
            onClick={(item: ListItem<Request>) => { setSelectedRequest(item.ref); }}
          />

          {formMode === undefined ? (
            requests !== undefined ? (
              selectedRequest ? (
                <View
                  componentName={"request"}
                  showAdd={true}
                  showEdit={false}
                  showDelete={false}
                  showNext={true}
                  showPrev={true}
                  showAudit={true}
                  onAdd={addRequest}
                  onEdit={editRequest}
                  onDelete={deleteRequest}
                  onNext={onNext}
                  onPrev={onPrev}
                  onAudit={() => checkAudit(selectedRequest.id)}
                >
                  <SelectedItemContent
                    selectedRequest={selectedRequest}
                    refresh={refresh}
                  />
                </View>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addRequest} className="btn btn-primary text-bold">{t('request.addRequest')}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t('request.wait')}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedRequest ? (
              <RequestPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedRequest.id}
                onAfterSave={() => refresh(true)}
              />
            ) : (
              <RequestPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh(true)}
              />
            )
          )}
        </List>
      }
    </div>
  );

}
