import React from 'react';
import LoadingImg from '../../img/loading.gif';

interface Props {
  loading: boolean;
  className?: string;
}

/**
 * Shows loading animation when loading is true
 */
export function Loading({ loading, className }: Props) {
  if (!loading) {
    return null;
  }
  
  return (
    <div className={ className }>
      <img src={ LoadingImg } alt="Loading..." />
    </div>
  );
}
