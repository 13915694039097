import classNames from 'classnames';
import React from 'react';
import { InspectionAssignment , InspectionAssignmentStatus } from '../../../entities/inspectionAssignment.entity';

interface Props {
  value?: InspectionAssignment,
  className?: string; 
  tabIndex?: number;
  onChange?: (value: InspectionAssignment) => void,
}

export function Checklist(props: Props) {
  const { value, className, tabIndex, onChange } = props;
  const { id, inspection, entity, status } = value || {};
  function toggle() {
    const newValue = { id, inspection, entity, status: status === InspectionAssignmentStatus.Done ? InspectionAssignmentStatus.Pending : InspectionAssignmentStatus.Done };
    console.log(newValue);
    if (onChange) {
      onChange(newValue);
    }
  }
  
  const isChecked = (value: InspectionAssignment | undefined): boolean => {
    return value?.status === InspectionAssignmentStatus.Done;
  }
  
  return (
    <div
      className={ classNames('todo-item', isChecked(value) ? 'complete' : 'incomplete', className)}
      tabIndex={ tabIndex }
      onClick={ toggle }
    >
      <div className="ti-info">
        <div className="ti-header">{ inspection?.title }</div>
      </div>
      {
        <div className="ti-icon">
          <i className={ classNames('os-icon', isChecked(value) ? 'os-icon-check' : 'os-icon-x') } />
        </div>
      }
    </div>
  )
}