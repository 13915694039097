import { Acl } from "../../interfaces/core.interface";

export enum VehicleAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Approve = 'approve',
  Block = 'block',
  Analytics = 'analytics',
  CreateRequiresConsent = 'create-requires-consent',
  UpdateRequiresConsent = 'update-requires-consent',
  Inspect = 'inspect',
  ManageInspection = 'manage-inspection',
  Audit = 'audit'
}

export const VehicleAcl = [
  {
    code: VehicleAclCode.ListAll,
    title: 'vehicle.listAll.title',
    description: 'vehicle.listAll.subTitle',
  },
  {
    code: VehicleAclCode.ListOwn,
    title: 'vehicle.listOwn.title',
    description: 'vehicle.listOwn.subTitle',
  },
  {
    code: VehicleAclCode.Create,
    title: 'vehicle.create.title',
    description: 'vehicle.create.subTitle',
  },
  {
    code: VehicleAclCode.Update,
    title: 'vehicle.update.title',
    description: 'vehicle.update.subTitle',
  },
  {
    code: VehicleAclCode.Delete,
    title: 'vehicle.delete.title',
    description: 'vehicle.delete.subTitle',
  },
  {
    code: VehicleAclCode.Approve,
    title: 'vehicle.approve.title',
    description: 'vehicle.approve.subTitle',
  },
  {
    code: VehicleAclCode.Block,
    title: 'vehicle.block.title',
    description: 'vehicle.block.subTitle',
  },
  {
    code: VehicleAclCode.Analytics,
    title: 'vehicle.analytics.title',
    description: 'vehicle.analytics.subTitle',
  },
  {
    code: VehicleAclCode.CreateRequiresConsent,
    title: 'vehicle.createRequiresConsent.title',
    description: 'vehicle.createRequiresConsent.subTitle',
  },
  {
    code: VehicleAclCode.UpdateRequiresConsent,
    title: 'vehicle.updateRequiresConsent.title',
    description: 'vehicle.updateRequiresConsent.subTitle',
  },
  {
    code: VehicleAclCode.Inspect,
    title: 'vehicle.inspect.title',
    description: 'vehicle.inspect.subTitle',
  },
  {
    code: VehicleAclCode.ManageInspection,
    title: 'vehicle.manageInspection.title',
    description: 'vehicle.manageInspection.subTitle',
  },
  {
    code: VehicleAclCode.Audit,
    title: 'vehicle.audit.title',
    description: 'vehicle.audit.subTitle',
  },
] as Acl<VehicleAclCode>;
