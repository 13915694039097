import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "../../../../components/form/input/select.component";
import { FormSection } from "../../../../components/form/section.component";
import { Operator } from "../../../../entities/operator.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../services/api.service";

export function DriverPostOperator() {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const [operator, setOperators] = useState<Operator[]>([]);
  
  useEffect(() => { init(); }, [])

  const init = async () => {
    const operatorList = await Api.get<IPaginationResponse<Operator>, IPaginationOptions>(Endpoint.OPERATOR_LIST);
    setOperators(operatorList.items);
  }
  
  return (
    <FormSection
      title={ t("driver.operatorSection.title") }
      subtitle={ t("driver.operatorSection.subTitle") }
    >
      <div className="row">
        <FormSelect<Operator>
          label={ t("driver.operatorSection.operatorLabel") }
          name="operator"
          className="col-sm-12"
          showLabel={ false }
          validation={{ required: false }}
          items={ operator }
          labelField="title"
          onChange={ () => {} }
          tabIndex={14}
          { ...formProps }
        />
      </div>
    </FormSection>
  );

}
