import { Acl } from "../../interfaces/core.interface";

export enum RequestCategoryAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Approve = 'approve',
  Audit = 'audit'
}

export const RequestCategoryAcl = [
  {
    code: RequestCategoryAclCode.ListAll,
    title: 'requestCategory.listAll.title',
    description: 'requestCategory.listAll.subTitle',
  },
  {
    code: RequestCategoryAclCode.ListOwn,
    title: 'requestCategory.listOwn.title',
    description: 'requestCategory.listOwn.subTitle',
  },
  {
    code: RequestCategoryAclCode.Create,
    title: 'requestCategory.create.title',
    description: 'requestCategory.create.subTitle',
  },
  {
    code: RequestCategoryAclCode.Update,
    title: 'requestCategory.update.title',
    description: 'requestCategory.update.subTitle',
  },
  {
    code: RequestCategoryAclCode.Delete,
    title: 'requestCategory.delete.title',
    description: 'requestCategory.delete.subTitle',
  },
  {
    code: RequestCategoryAclCode.Audit,
    title: 'requestCategory.audit.title',
    description: 'requestCategory.audit.subTitle',
  },
] as Acl<RequestCategoryAclCode>;
