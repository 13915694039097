import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../../components/list/items.component";
import { List } from "../../../../components/list/wrapper.component";
import { View } from "../../../../components/view/wrapper.component";
import { Role } from "../../../../entities/role.entity";
import { User, UserStatus } from "../../../../entities/user.entity";
import { FormMode } from "../../../../enums/core.enum";
import { FormStyle } from "../../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../../services/api.service';
import { UserPost } from "../post/post.component";
import SelectedUserContent from "./content.component";

const groupFilters: GroupFilter[] = [
  { title: "all", icon: 'phone-21' },
  { title: "active", icon: 'ui-15' },
  { title: "pending", icon: 'ui-15' },
  { title: "blocked", icon: 'ui-15' },
];

export function UserList(props: any) {

  const { y: pageYOffset } = useWindowScroll();

  const [changeStatus, setChangeStatus] = useState<UserStatus | undefined>(undefined)
  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(20);
  const [allCount, setAllCount] = useState<number>(0);
  const [activeCount, setActiveCount] = useState<number>(0);
  const [pendingCount, setPendingCount] = useState<number>(0);
  const [blockedCount, setBlockedCount] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [count, setCount] = useState<number>(1);
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [, setRoles] = useState<Role[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [size, setSize] = useState<number>()
  const [disable, setDisable] = useState<boolean>(false)

  const { t } = useTranslation('main');

  // useEffect(()=>{
  //   setSelectedUser(props.location.state)
  // },[props.location.state])

  const refresh = useCallback(async () => {
    let group: string | undefined = activeGroupFilter?.title;
    group = group === 'all' ? undefined : group;
    const { items, totalItems } = await Api.get<IPaginationResponse<User>, IPaginationOptions>(Endpoint.USER_LIST, { page: 0, limit, group, search });

    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items) {
      setUsers(items)
    }

    const ITEMS = await Api.get<IPaginationResponse<User>, IPaginationOptions>(Endpoint.USER_LIST, { limit: 10000000 });
    setAllCount(ITEMS.totalItems);
    setActiveCount(ITEMS.items.filter(item => item.status === 'active').length)
    setBlockedCount(ITEMS.items.filter(item => item.status === 'blocked').length)
    setPendingCount(ITEMS.items.filter(item => item.status === 'pending').length)

    console.log('items: ', ITEMS)
    if (selectedUser?.status === UserStatus.Blocked) {
      setChangeStatus(UserStatus.Active)
    } else if (selectedUser?.status === UserStatus.Active) {
      setChangeStatus(UserStatus.Blocked)
    } else {
      setChangeStatus(undefined)
    }

    if (items.length > 0 && !selectedUser) {
      if (props.location.state !== (null || undefined)) {

        console.log('props.location.state: ', props.location.state)
        setSelectedUser(props.location.state);

      }
      else {
        console.log('items[0]: ', items[0])
        setSelectedUser(items[0]);
      }
    }

    const selectedRole = selectedUser?.role as Role
    if (selectedRole?.isProtected === true) {
      setDisable(true)
    } else {
      setDisable(false)
    }

    refreshRoles();
  }, [search, activeGroupFilter, limit, selectedUser, disable]);

  useEffect(() => { refresh(); }, [refresh]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  async function refreshRoles() {
    setRoles([]);
    const { items } = await Api.get<IPaginationResponse<Role>, IPaginationOptions>(Endpoint.USER_ROLE_LIST, { page: 0, limit });
    setRoles(items);
  }

  const deleteUser = async () => {
    await Api.delete(Endpoint.USER, { id: selectedUser?.id });
    setSelectedUser(undefined);
    refresh();
  }

  const getFullName = (user: User): string => {
    return [user.firstName, user.lastName].join(' ');
  }

  const toListItems = (user?: User): ListItem<User> | undefined => {
    if (!user) {
      return;
    }

    return {
      id: user.id as string,
      title: getFullName(user),
      status: ListItemStatus.Green,
      ref: user,
    };
  }

  const addUser = () => {
    setFormMode(FormMode.Adding);
  }

  const editUser = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
    refresh();
  }

  const onNext = () => {
    if (users) {
      const currentUserIndex = users.findIndex(user => !!selectedUser && user.id === selectedUser.id);
      if (currentUserIndex + 1 < users.length && currentUserIndex !== -1) {
        setSelectedUser(users[currentUserIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (users) {
      const currentUserIndex = users.findIndex(user => !!selectedUser && user.id === selectedUser.id);
      if (currentUserIndex - 1 > -1 && currentUserIndex !== -1) {
        setSelectedUser(users[currentUserIndex - 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedUser(undefined);
    setActiveGroupFilter(target);
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  const statusChange = async () => {
    const selectedRole = selectedUser?.role as Role
    if (selectedUser?.status === UserStatus.Active && selectedRole?.name !== 'super admin') {
      await Api.patch(Endpoint.USER, { id: selectedUser.id, status: selectedUser.status = UserStatus.Blocked })
      refresh();
    } else if (selectedUser?.status === UserStatus.Blocked) {
      await Api.patch(Endpoint.USER, { id: selectedUser.id, status: selectedUser.status = UserStatus.Active })
      refresh();
    }
  }

  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: "all", icon: 'phone-21', count: allCount },
                  { title: "active", icon: 'ui-15', count: activeCount },
                  { title: "pending", icon: 'ui-15', count: pendingCount },
                  { title: "blocked", icon: 'ui-15', count: blockedCount },
                ]}
                activeGroupFilter={activeGroupFilter}
                onGroupSelect={selectGroup}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={users?.map(toListItems) as ListItem<User>[]}
                activeItem={toListItems(selectedUser)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setUsers(undefined); refresh(); }}
                onClick={(item: ListItem<User>) => { setSelectedUser(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                users !== undefined ? (
                  selectedUser ? (
                    <View
                      componentName={"user"}
                      changeUserStatus={changeStatus}
                      showAdd={true}
                      showEdit={true}
                      showDelete={true}
                      showNext={true}
                      showPrev={true}
                      showAudit={true}
                      disabled={disable}
                      showStateChange={true}
                      onDelete={deleteUser}
                      onAdd={addUser}
                      onEdit={editUser}
                      onStateChange={statusChange}
                      onPrev={onPrev}
                      onNext={onNext}
                      onAudit={() => checkAudit(selectedUser.id)}
                    >
                      <SelectedUserContent
                        getFullName={getFullName}
                        selectedUser={selectedUser}
                      />
                    </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button onClick={addUser} className="btn btn-primary text-bold">{t("mainMenu.users.addUser")}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t("mainMenu.users.wait")}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedUser ? (
                  <UserPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedUser.id}
                  />
                ) : (
                  <UserPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
            groupFilters={[
              { title: "all", icon: 'phone-21', count: allCount },
              { title: "active", icon: 'ui-15', count: activeCount },
              { title: "pending", icon: 'ui-15', count: pendingCount },
              { title: "blocked", icon: 'ui-15', count: blockedCount },
            ]}
            activeGroupFilter={activeGroupFilter}
            onGroupSelect={selectGroup}
          />
          <ListItems
            items={users?.map(toListItems) as ListItem<User>[]}
            activeItem={toListItems(selectedUser)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setUsers(undefined); refresh(); }}
            onClick={(item: ListItem<User>) => { setSelectedUser(item.ref); }}
          />

          {formMode === undefined ? (
            users !== undefined ? (
              selectedUser ? (
                <View
                  componentName={"user"}
                  changeUserStatus={changeStatus}
                  showAdd={true}
                  showEdit={true}
                  showDelete={true}
                  showNext={true}
                  showPrev={true}
                  showAudit={true}
                  disabled={disable}
                  showStateChange={true}
                  onDelete={deleteUser}
                  onAdd={addUser}
                  onEdit={editUser}
                  onStateChange={statusChange}
                  onPrev={onPrev}
                  onNext={onNext}
                  onAudit={() => checkAudit(selectedUser.id)}
                >
                  <SelectedUserContent
                    getFullName={getFullName}
                    selectedUser={selectedUser}
                  />
                </View>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addUser} className="btn btn-primary text-bold">{t("mainMenu.users.addUser")}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t("mainMenu.users.wait")}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedUser ? (
              <UserPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedUser.id}
              />
            ) : (
              <UserPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
              />
            )
          )}
        </List>
      }
    </div>
  );

}
