import { differenceInDays, startOfMonth, startOfYear, endOfDay, endOfWeek, startOfDay, startOfWeek, subDays, subMonths, subWeeks } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { Line } from 'react-chartjs-2';
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../components/alerts/loading.component";
import { FormInput } from "../../../components/form/input/input.component";
import { Currency } from "../../../entities/currency.entity";
import { Settings } from "../../../entities/settings.entity";
import { Api, Endpoint } from "../../../services/api.service";
import { SettingsService } from "../../../services/settings.service";

interface DashboardStatisticsResponse {
  passengerCount: number;
  rideCount: number;
  activeDriverCount: number;
  revenueSum: number;
  passengerPercentage: number;
  ridePercentage: number;
  activeDriverPercentage: number;
  prevPassenger: number;
  prevActiveDriver: number;
  prevRide: number;
  revenueSumPercentage: number;
  prevRevenueSum: number;
  passengerChartData: any[];
  rideChartData: any[];
  activeDriverChartData: any[];
  revenueChartData: any[];
}

interface Time {
  time: string
  from?: Date | string
  to?: string | Date
}

const chartOptions = {
  fill: true,
  borderJoinStyle: "miter",
  scales: {
    xAxes: [{
      gridLines: {
        display: true,
      },
      ticks: {
        display: true,
      }
    }],
    yAxes: [{
      gridLines: {
        display: false,
      },
      ticks: {
        display: false,
      }
    }]
  },
  legend: {
    display: false,
  },
}

export function DashboardOverviewStats(props: any) {
  const { t } = useTranslation('dashboard');

  const [time, setTime] = useState<string>('today');
  const [symbol, setSymbol] = useState<string | null>('')
  const [subUnit, setSubUnit] = useState<number>(1)
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');
  const [state, setState] = useState<DashboardStatisticsResponse>(undefined!);
  const [passengerChartData, setPassengerChartData] = useState({})
  const [driverChartData, setDriverChartData] = useState({})
  const [rideChartData, setRideChartData] = useState({})
  const [revenueChartData, setRevenueChartData] = useState({})
  const [tooltipsDate] = useState<Date>(new Date())
  const [dataAvail,setDataAvail] = useState<boolean>(true)

  const formProps = useFormContext();


  const refresh = useCallback(async () => {
    try {

      if (time === 'custome' && from && to) {
        const datetime = new Date();
        if (new Date(from).getFullYear() >= 2000 &&  new Date(from).getFullYear() <= datetime.getFullYear() && new Date(to).getFullYear() <= datetime.getFullYear()) {
          const response = await Api.get<DashboardStatisticsResponse, Time>(Endpoint.DASHBOARD_STATISTICS, { time: time, from: new Date(from), to: new Date(to) })
          setState(response)

          console.log("response stats", response)
        }
      } else if (time === 'today' || time === 'yesterday' || time === 'this-week' || time === 'last-week' || time === 'this-month' || time === 'last-month' || time === 'this-year') {
        const response = await Api.get<DashboardStatisticsResponse, Time>(Endpoint.DASHBOARD_STATISTICS, { time: time, from: new Date() })
        setState(response)
      }
    } catch (error) {
      console.log('error inside dashboard stats:', error);
      setDataAvail(false)
      
    }
  }, [time, from, to])

  useEffect(() => {
    refresh();
  }, [refresh])

  function addDays(theDate: any, days: any) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  useEffect(() => {
    let settings: Settings | undefined = SettingsService.getSetting();
    let labels: any[] = [];
    let data: any[] = [];
    let rideData: any[] = [];
    let revenueData: any[] = [];
    let driverData: any[] = [];
   
    const today = new Date()
    today.setMinutes(0)
    today.setSeconds(0)
    today.setMilliseconds(0)
    if (time === 'today') {
      labels = Array.from(Array(24).keys()).map(i => {
        const d = startOfDay(today);
        d.setUTCHours(i)
        return d.toISOString();
      });

      console.log("labels today",labels)
      data = labels.map(l => {
        const d = state?.passengerChartData.find(d => d.period.substr(11, 2) === l.substr(11, 2));
        return d ? d.count : 0;
      });
      rideData = labels.map(l => {
        const d = state?.rideChartData.find(d => d.period.substr(11, 2) === l.substr(11, 2));
        return d ? d.count : 0;
      });
      revenueData = labels.map(l => {
        const d = state?.revenueChartData.find(d => d.period.substr(11, 2) === l.substr(11, 2));
        return d ? d.count : 0;
      });
      driverData = labels.map(l => {
        const d = state?.activeDriverChartData.find(d => d.period.substr(11, 2) === l.substr(11, 2));
        return d ? d.count : 0;
      });
    }

    if (time === 'yesterday') {
      const yesterday = subDays(today, 1)
      labels = Array.from(Array(24).keys()).map(i => {
        const d = startOfDay(yesterday);
        d.setUTCHours(i)
        return d.toISOString();
      });

      data = labels.map(l => {
        const d = state?.passengerChartData.find(d => d.period.substr(11, 2) === l.substr(11, 2));
        return d ? d.count : 0;
      });
      rideData = labels.map(l => {
        const d = state?.rideChartData.find(d => d.period.substr(11, 2) === l.substr(11, 2));
        return d ? d.count : 0;
      });
      revenueData = labels.map(l => {
        const d = state?.revenueChartData.find(d => d.period.substr(11, 2) === l.substr(11, 2));
        return d ? d.count : 0;
      });
      driverData = labels.map(l => {
        const d = state?.activeDriverChartData.find(d => d.period.substr(11, 2) === l.substr(11, 2));
        return d ? d.count : 0;
      });
    }

    if (time === 'this-week') {
      for (let i = 0; i < 7; i++) {
        const d = startOfWeek(today, { weekStartsOn: 1 });
        d.setDate(d.getDate() + i);
        console.log("label d", d)
        labels.push(d.toISOString());

        console.log("labels week before", labels);


      }

      labels = labels.map(data => {
        let date = new Date(data);
        let year = date.getFullYear();
        let month: number | string = date.getMonth() + 1;
        let dt: number | string = date.getDate();
        let hr: number | string = date.getHours();

        if (hr < 10) {
          hr = '0' + hr;
        }
        if (dt < 10) {
          dt = '0' + dt;
        }
        if (month < 10) {
          month = '0' + month;
        }
        return `${year}-${month}-${dt}-${hr}`
      })


      console.log("labels in week", labels);
      data = labels.map(l => {

        const d = state?.passengerChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
      rideData = labels.map(l => {
        const d = state?.rideChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
      revenueData = labels.map(l => {
        const d = state?.revenueChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
      driverData = labels.map(l => {
        const d = state?.activeDriverChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });

      console.log("week label", labels);


      console.log("refine label", labels);
    }

    if (time === 'last-week') {
      const lastWeek = subWeeks(today, 1)
      for (let i = 0; i < 7; i++) {
        const d = startOfWeek(lastWeek, { weekStartsOn: 1 });
        d.setDate(d.getDate() + i)
        labels.push(d.toISOString());
      }

      labels = labels.map(data => {
        let date = new Date(data);
        let year = date.getFullYear();
        let month: number | string = date.getMonth() + 1;
        let dt: number | string = date.getDate();
        let hr: number | string = date.getHours();

        if (hr < 10) {
          hr = '0' + hr;
        }
        if (dt < 10) {
          dt = '0' + dt;
        }
        if (month < 10) {
          month = '0' + month;
        }
        return `${year}-${month}-${dt}-${hr}`
      })
      data = labels.map(l => {
        const d = state?.passengerChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
      rideData = labels.map(l => {
        const d = state?.rideChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
      revenueData = labels.map(l => {
        const d = state?.revenueChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
      driverData = labels.map(l => {
        const d = state?.activeDriverChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });



    }

    if (time === 'this-month') {
      const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()
      for (let i = 1; i <= daysInMonth; i++) {
        const d = startOfMonth(today)
        d.setDate(i)
        labels.push(d.toISOString());
      }
      console.log("labels", labels, state)

      labels = labels.map(data => {
        let date = new Date(data);
        let year = date.getFullYear();
        let month: number | string = date.getMonth() + 1;
        let dt: number | string = date.getDate();
        let hr: number | string = date.getHours();

        if (hr < 10) {
          hr = '0' + hr;
        }
        if (dt < 10) {
          dt = '0' + dt;
        }
        if (month < 10) {
          month = '0' + month;
        }
        return `${year}-${month}-${dt}-${hr}`
      })
      data = labels.map(l => {
        const d = state?.passengerChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
      rideData = labels.map(l => {
        const d = state?.rideChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
      revenueData = labels.map(l => {
        const d = state?.revenueChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
      driverData = labels.map(l => {
        const d = state?.activeDriverChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });


    }

    if (time === 'last-month') {
      const lastMonth = subMonths(today, 1)
      today.setMonth(new Date().getMonth() - 1)
      const month = new Date()
      month.setMonth(month.getMonth() - 1);
      const daysInMonth = new Date(new Date().getFullYear(), month.getMonth() + 1, 0).getDate()
      for (let i = 1; i <= daysInMonth; i++) {
        const d = startOfMonth(lastMonth);
        d.setDate(i)
        labels.push(d.toISOString());
      }

        labels = labels.map(data => {
        let date = new Date(data);
        let year = date.getFullYear();
        let month: number | string = date.getMonth() + 1;
        let dt: number | string = date.getDate();
        let hr: number | string = date.getHours();

        if (hr < 10) {
          hr = '0' + hr;
        }
        if (dt < 10) {
          dt = '0' + dt;
        }
        if (month < 10) {
          month = '0' + month;
        }
        return `${year}-${month}-${dt}-${hr}`
      })
      data = labels.map(l => {
        const d = state?.passengerChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
      rideData = labels.map(l => {
        const d = state?.rideChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
      revenueData = labels.map(l => {
        const d = state?.revenueChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
      driverData = labels.map(l => {
        const d = state?.activeDriverChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
    }

    if (time === 'this-year') {
      for (let i = 0; i <12; i++) {
        const d = startOfYear(today);
        d.setMonth(i);
        labels.push(d.toISOString());
      }

      data = labels.map(l => {
        const d = state?.passengerChartData.find(d => d.period.substr(5, 2) === l.substr(5, 2));
        return d ? d.count : 0;
      });
      rideData = labels.map(l => {
        const d = state?.rideChartData.find(d => d.period.substr(5, 2) === l.substr(5, 2));
        return d ? d.count : 0;
      });
      revenueData = labels.map(l => {
        const d = state?.revenueChartData.find(d => d.period.substr(5, 2) === l.substr(5, 2));
        return d ? d.count : 0;
      });
      driverData = labels.map(l => {
        const d = state?.activeDriverChartData.find(d => d.period.substr(5, 2) === l.substr(5, 2));
        return d ? d.count : 0;
      });


      labels = labels.map(data => {
        let date = new Date(data);
        let year = date.getFullYear();
        let month: number | string = date.getMonth();
        let dt: number | string = date.getDate();
        let hr: number | string = date.getHours();

        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];
        
        month = monthNames[month]
        if (hr < 10) {
          hr = '0' + hr;
        }
        if (dt < 10) {
          dt = '0' + dt;
        }
        // if (month < 10) {
        //   month = '0' + month;
        // }
        return `${year}-${month}`
      })

    }

    if (time === 'custome') {
      const date1: any = new Date(from);
      const date2: any = new Date(to);
      date1.setHours(0)
      date2.setHours(23)
      const diffTime = differenceInDays(date2, date1);
      for (let i = 0; i <= diffTime; i++) {
        const d = new Date(date1);
        d.setDate(d.getDate() + i);
        labels.push(d.toISOString());
      }
      data = labels.map(l => {
        const d = state?.passengerChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
      rideData = labels.map(l => {
        const d = state?.rideChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
      revenueData = labels.map(l => {
        const d = state?.revenueChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
      driverData = labels.map(l => {
        const d = state?.activeDriverChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
    }


    console.log("before labels", labels);
   

    for (let i = 0; i < labels.length; ++i) {
      if (time === 'today' || time === 'yesterday') {
        labels[i] = labels[i].substr(11, 2);
      } else {
        labels[i] = labels[i].substr(5, 5);
      }
    }

    setPassengerChartData({
      labels: labels,
      datasets: [
        {
          label: t('stats.newPassengers'),
          fill: true,
          backgroundColor: '#ff000022',
          borderColor: '#ff0000',
          data: data,
        },
      ]
    });

    setRideChartData({
      labels: labels,
      datasets: [
        {
          label: t('stats.rides'),
          fill: true,
          backgroundColor: '#0000ff22',
          borderColor: '#0000ff',
          data: rideData//generateData(6, 20),
        },
      ]
    })

    setRevenueChartData({
      labels: labels,
      datasets: [
        {
          label: `${t('stats.revenue')} ${symbol}`,
          fill: true,
          backgroundColor: '#00aa0022',
          borderColor: '#00aa00',
          data: revenueData.map(data => data / subUnit)//generateData(6, 2000),
        },
      ]
    })

    console.log('revenueData:', revenueData)

    setDriverChartData({
      labels: labels,
      datasets: [
        {
          label: t('stats.activeDrivers'),
          fill: true,
          backgroundColor: '#00000022',
          borderColor: '#000000',
          data: driverData// generateData(6, 150),
        },
      ]
    })

  }, [state])

  useEffect(() => {
    const settings: Settings | undefined = SettingsService.getSetting();
    const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;
    const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
    setSymbol(currency)
    setSubUnit(unit)
  }, [])

  const changeTime = (e: any) => {
    setTime(e.target.value)
  }

  const onChangeFrom = (e: any) => {
    setFrom(e.target.value)
  }

  const onChangeTo = (e: any) => {
    setTo(e.target.value)
  }

  return (
    <div className="content-box col-md-9 col-sm-12">
      {(state === undefined && dataAvail) ?
        <div className="mx-auto py-5 text-center">
          <p>{t("Please Wait")}</p>
          <Loading loading={true} />
        </div> :
        (
          !dataAvail?<h1>No Data Available</h1>:
        <div className="element-wrapper">
          {time === 'custome' ?
            <>
              <div className="row">
                <FormInput
                  label={t("From")}
                  name="from"
                  type="date"
                  onChange={onChangeFrom}
                  className="col-sm-3"
                  validation={{ required: true }}
                  tabIndex={7}
                  {...formProps}
                />
                <FormInput
                  label={t("To")}
                  name="to"
                  type="date"
                  onChange={onChangeTo}
                  className="col-sm-3"
                  validation={{ required: true }}
                  tabIndex={7}
                  {...formProps}
                />
              </div>




            </> : null}
          <div className="element-actions">
            <form className="form-inline justify-content-sm-end">
              <select value={time} onChange={changeTime} className="form-control form-control-sm rounded">
                <option value="today">{t('dropdown.today')}</option>
                <option value="yesterday">{t('dropdown.yesterday')}</option>
                <option value="this-week">{t('dropdown.thisWeek')}</option>
                <option value="last-week">{t('dropdown.lastWeek')}</option>
                <option value="this-month">{t('dropdown.thisMonth')}</option>
                <option value="last-month">{t('dropdown.lastMonth')}</option>
                <option value="this-year">{t('dropdown.thisYear')}</option>
                <option value="custome">{t('dropdown.custome')}</option>
              </select>
            </form>
          </div>
          <h6 className="element-header">{t('title')}</h6>
          <div className="element-content">
            <div className="tablos">
              <div className="row mb-xl-2 mb-xxl-3">
                {/* { stats.map((stat: StatItem, i: number) => ( */}
                <div className="col-sm-3 col-xxxl-6">
                  <a href="#" className="element-box el-tablo">
                    <div className="label">{t('stats.newPassengers')}</div>
                    <div className="value">{new Intl.NumberFormat().format(state?.passengerCount)}</div>
                    <div className={`trending trending-${state?.passengerCount >= state?.prevPassenger ? 'up' : 'down'}-basic`}>
                      <span>{state?.passengerPercentage === null ? '0' : Math.floor(state?.passengerPercentage)}%</span>
                      <i className={`os-icon os-icon-arrow-${state?.passengerCount >= state?.prevPassenger ? 'up2' : 'down'}`}></i>
                    </div>
                  </a>
                </div>
                <div className="col-sm-3 col-xxxl-6">
                  <a href="#" className="element-box el-tablo">
                    <div className="label">{t('stats.rides')}</div>
                    <div className="value">{new Intl.NumberFormat().format(state?.rideCount)}</div>
                    <div className={`trending trending-${state?.rideCount >= state?.prevRide ? 'up' : 'down'}-basic`}>
                      <span>{state?.ridePercentage === null ? '0' : Math.floor(state?.ridePercentage)}%</span>
                      <i className={`os-icon os-icon-arrow-${state?.rideCount >= state?.prevRide ? 'up2' : 'down'}`}></i>
                    </div>
                  </a>
                </div>
                <div className="col-sm-3 col-xxxl-6">
                  <a href="#" className="element-box el-tablo">
                    <div className="label">{t('stats.revenue')}</div>
                    <div className="value">{symbol} {state?.revenueSum === null ? '0' : new Intl.NumberFormat().format(state?.revenueSum / subUnit)}</div>
                    <div className={`trending trending-${state?.revenueSum >= state?.prevRevenueSum ? 'up' : 'down'}-basic`}>
                      <span>{state?.revenueSumPercentage === null ? '0' : Math.floor(state?.revenueSumPercentage)}%</span>
                      <i className={`os-icon os-icon-arrow-${state?.revenueSum >= state?.prevRevenueSum ? 'up2' : 'down'}`}></i>
                    </div>
                  </a>
                </div>
                <div className="col-sm-3 col-xxxl-6">
                  <a href="#" className="element-box el-tablo">
                    <div className="label">{t('stats.activeDrivers')}</div>
                    <div className="value">{new Intl.NumberFormat().format(state?.activeDriverCount)}</div>
                    <div className={`trending trending-${state?.activeDriverCount >= state?.prevActiveDriver ? 'up' : state?.activeDriverCount < state?.prevActiveDriver ? 'down' : ''}-basic`}>
                      <span>{state?.activeDriverPercentage === null ? '0' : Math.floor(state?.activeDriverPercentage)}%</span>
                      <i className={`os-icon os-icon-arrow-${state?.activeDriverCount >= state?.prevActiveDriver ? 'up2' : 'down'}`}></i>
                    </div>
                  </a>
                </div>
                {/* ))} */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="element-box pl-xxl-5 pr-xxl-5">
                  <div className="el-chart-w">
                    <div className="centered-header"><h6>{t("stats.newPassengers")}</h6></div>
                    <Line data={passengerChartData} options={{
                      ...chartOptions,
                      tooltips: {
                        enabled: true,
                        displayColors: false,
                        backgroundColor: 'rgb(150,50,0)',
                        titleFontColor: 'rgb(255,255,255)',
                        bodyFontColor: 'rgb(255,255,255)',
                        footerFontColor: 'rgb(255,255,255)',
                        footerFontStyle: 'normal',
                        borderColor: 'rgb(100,50,0)',
                        borderWidth: 1,
                        callbacks: {
                          label: function (item: any, everything: any) {
                            let value = item.yLabel
                            value = `New Passenger: ${value}`
                            return value
                          },
                          title: function (item: any, everything: any) {
                            let times = item[0].xLabel
                            if (time === 'today') {
                              times = `Time: ${tooltipsDate.toString().substr(0, 15)} ${times}:00:00`
                              return times
                            }
                            if (time === 'yesterday') {
                              const yesterday = subDays(tooltipsDate, 1)
                              times = `Time: ${yesterday.toString().substr(0, 15)} ${times}:00:00`
                              return times
                            }
                            else {
                              times = `Date: ${tooltipsDate.getFullYear()}-${times}`
                              return times
                            }
                          }
                        },
                      }
                    }} />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="element-box pl-xxl-5 pr-xxl-5">
                  <div className="el-chart-w">
                    <div className="centered-header"><h6>{t("stats.rides")}</h6></div>
                    <Line data={rideChartData} options={{
                      ...chartOptions,
                      tooltips: {
                        enabled: true,
                        displayColors: false,
                        backgroundColor: 'rgb(150,50,0)',
                        titleFontColor: 'rgb(255,255,255)',
                        bodyFontColor: 'rgb(255,255,255)',
                        footerFontColor: 'rgb(255,255,255)',
                        footerFontStyle: 'normal',
                        borderColor: 'rgb(100,50,0)',
                        borderWidth: 1,
                        callbacks: {
                          label: function (item: any, everything: any) {
                            let value = item.yLabel
                            value = `Rides: ${value}`
                            return value
                          },
                          title: function (item: any, everything: any) {
                            let times = item[0].xLabel
                            if (time === 'today') {
                              times = `Time: ${tooltipsDate.toString().substr(0, 15)} ${times}:00:00`
                              return times
                            }
                            if (time === 'yesterday') {
                              const yesterday = subDays(tooltipsDate, 1)
                              times = `Time: ${yesterday.toString().substr(0, 15)} ${times}:00:00`
                              return times
                            }
                            else {
                              times = `Date: ${tooltipsDate.getFullYear()}-${times}`
                              return times
                            }
                          }
                        },
                      }
                    }} />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="element-box pl-xxl-5 pr-xxl-5">
                  <div className="el-chart-w">
                    <div className="centered-header"><h6>{t("stats.revenue")}</h6></div>
                    <Line data={revenueChartData} options={{
                      ...chartOptions,
                      tooltips: {
                        enabled: true,
                        displayColors: false,
                        backgroundColor: 'rgb(150,50,0)',
                        titleFontColor: 'rgb(255,255,255)',
                        bodyFontColor: 'rgb(255,255,255)',
                        footerFontColor: 'rgb(255,255,255)',
                        footerFontStyle: 'normal',
                        borderColor: 'rgb(100,50,0)',
                        borderWidth: 1,
                        callbacks: {
                          label: function (item: any, everything: any) {
                            let value = item.yLabel
                            value = `Revenue: ${symbol} ${new Intl.NumberFormat().format(value)}`
                            return value
                          },
                          title: function (item: any, everything: any) {
                            let times = item[0].xLabel
                            if (time === 'today') {
                              times = `Time: ${tooltipsDate.toString().substr(0, 15)} ${times}:00:00`
                              return times
                            }
                            if (time === 'yesterday') {
                              const yesterday = subDays(tooltipsDate, 1)
                              times = `Time: ${yesterday.toString().substr(0, 15)} ${times}:00:00`
                              return times
                            }
                            else {
                              times = `Date: ${tooltipsDate.getFullYear()}-${times}`
                              return times
                            }
                          }
                        },
                      }
                    }} />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="element-box pl-xxl-5 pr-xxl-5">
                  <div className="el-chart-w">
                    <div className="centered-header"><h6>{t("stats.activeDrivers")}</h6></div>
                    <Line data={driverChartData} options={{
                      ...chartOptions,
                      tooltips: {
                        enabled: true,
                        displayColors: false,
                        backgroundColor: 'rgb(150,50,0)',
                        titleFontColor: 'rgb(255,255,255)',
                        bodyFontColor: 'rgb(255,255,255)',
                        footerFontColor: 'rgb(255,255,255)',
                        footerFontStyle: 'normal',
                        borderColor: 'rgb(100,50,0)',
                        borderWidth: 1,
                        callbacks: {
                          label: function (item: any, everything: any) {
                            let value = item.yLabel
                            value = `Active Driver: ${value}`
                            return value
                          },
                          title: function (item: any, everything: any) {
                            let times = item[0].xLabel
                            if (time === 'today') {
                              times = `Time: ${tooltipsDate.toString().substr(0, 15)} ${times}:00:00`
                              return times
                            }
                            if (time === 'yesterday') {
                              const yesterday = subDays(tooltipsDate, 1)
                              times = `Time: ${yesterday.toString().substr(0, 15)} ${times}:00:00`
                              return times
                            }
                            else {
                              times = `Date: ${tooltipsDate.getFullYear()}-${times}`
                              return times
                            }
                          }
                        },
                      }
                    }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)
      }
    </div>
  )
}