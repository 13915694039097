import React, { useState } from 'react';
import { Fare } from '../../entities/fare.entity';
import { Service } from '../../entities/service.entity';
import { Zone } from '../../entities/zone.entity';
import { FareDashboardList } from './list/list.component';
import { FareDashboardServices } from './sidebar/services.component';
import { FareDashboardZones } from './sidebar/zones.component';
import { FareDashboardTable } from './table/table.component';

/**
 * Displays fare dashboard for managing fares for zones and services
 */
export function FareDashboard() {

  /**
   * Keep track of selected zone
   */
  const [ selectedZone, setSelectedZone ] = useState<Zone>();

  /**
   * Keep track of selected service
   */
  const [ selectedService, setSelectedService ] = useState<Service>();

  /**
   * Keep track of selected fare
   */
  const [ selectedFare, setSelectedFare ] = useState<Fare>();

  /**
   * Render
   */
  return (
    <div>
      <div className="app-email-w forse-show-content">
        <div className="app-email-i row">
          <div className="ae-side-menu col-xl-2">
            <FareDashboardZones
              onChange={ setSelectedZone }
            />
          </div>
          <div className="ae-side-menu col-xl-2">
            <FareDashboardServices
              onChange={ setSelectedService }
            />
          </div>
          <div className="ae-side-menu col-xl-2">
            <FareDashboardList
              selectedService={ selectedService }
              selectedZone={ selectedZone }
              onSelect={ setSelectedFare }
            />
          </div>
          <div className="ae-content-w col-xl-6">
            <FareDashboardTable
              selectedService={ selectedService }
              selectedZone={ selectedZone }
              selectedFare={ selectedFare }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
