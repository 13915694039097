import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormAddressInput } from "../../../../components/form/input/address.component";
import { FormInput } from "../../../../components/form/input/input.component";
import { PhoneInputs } from "../../../../components/form/input/phoneNumber";
import { FormSelect } from "../../../../components/form/input/select.component";
import { FormTextArea } from "../../../../components/form/input/textarea.component";
import { FormUpload } from "../../../../components/form/input/upload.component";
import { FormSection } from "../../../../components/form/section.component";
import { Passenger } from "../../../../entities/passenger.entity";
import { RequestCategory } from "../../../../entities/request-category.entity";
import { User } from "../../../../entities/user.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../services/api.service";
import {showToastWarning } from "../../../../util/notification";

interface Props {
  onActionSelect: any;
}

export enum Status {
  New = 'new',
  Direct = 'direct',
  Assigned = 'assigned',
  Rejected = 'rejected',
  Completed = 'completed'
}
export function RequestPostBasic({ onActionSelect }: Props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  const [requestCategoryList, setRequestCategoryList] = useState<RequestCategory[]>([]);
  const [passengers, setPassenger] = useState<Passenger[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  
  const [options, setOptions] = useState<any>([
    { value: Status.New, label: Status.New },
    { value: Status.Assigned, label: Status.Assigned },
    { value: Status.Completed, label: Status.Completed },
    { value: Status.Assigned, label: Status.Direct },
    { value: Status.Rejected, label: Status.Rejected }
  ])

  useEffect(() => { init(); }, [])

  const init = async () => {
    const requestCategoryList = await Api.get<IPaginationResponse<RequestCategory>, IPaginationOptions>(Endpoint.REQUEST_CATEGORY_LIST);
    const passengerList = await Api.get<IPaginationResponse<Passenger>, IPaginationOptions>(Endpoint.PASSENGER_LIST);
    const userList = await Api.get<IPaginationResponse<User>, IPaginationOptions>(Endpoint.USER_LIST);
    console.log('Passenger Data:', userList)
    setPassenger(passengerList.items);
    setUsers(userList.items);
    if(requestCategoryList.items.length >0){
      setRequestCategoryList(requestCategoryList.items);
    }
    else{
      showToastWarning("PLEASE ADD REQUEST CATEGORY FROM HELP CATEGORY SECTION !")
    }
  }
  return (
    <FormSection
      title={t("request.basicSection.title")}
    >
      <div className="row">
        <FormTextArea
          name={`message`}
          label={t("requestCategory.basicSection.message")}
          validation={{ required: true }}
          className='col-md-6'
          rows={5}
          {...formProps}
        />
      </div>
      <div className="row">
        <FormSelect<RequestCategory>
          label={t("request.basicSection.category")}
          name="category"
          className="col-md-6"
          showLabel={true}
          multiple={false}
          validation={{ required: true }}
          items={requestCategoryList}
          labelField="title"
          onChange={() => { }}
          tabIndex={14}
          {...formProps}
        />
       
        </div>

<div className="row">
        
        <FormSelect<Passenger>
          label={t("request.basicSection.passenger")}
          name="passenger"
          className="col-md-6"
          showLabel={true}
          multiple={false}
          validation={{ required: false }}
          items={passengers}
          labelField="email"
          onChange={() => { }}
          tabIndex={14}
          {...formProps}
        /> </div>
      {/* <div className="row">
            <FormSelect<User>
          label={ t("request.basicSection.assignedTo") }
          name="assignedTo"
          className="col-md-4"
          showLabel={ true }
          multiple={ false }
          validation={{ required: false }}
          items={ users }
          labelField="email"
          onChange={ () => {} }
          tabIndex={14}
          { ...formProps }
        />
            <FormSelect<User>
          label={ t("request.basicSection.assignedBy") }
          name="assignedBy"
          className="col-md-4"
          showLabel={ true }
          multiple={ false }
          validation={{ required: false }}
          items={ users }
          labelField="email"
          onChange={ () => {} }
          tabIndex={14}
          { ...formProps }
        />
        <FormInput
          label={t("request.basicSection.assignedAt")}
          name="assignedAt"
          type="datetime-local"
          className="col-sm-4"
          validation={{ required: false }}
          tabIndex={7}
          {...formProps}
        />
        <FormSelect<User>
          label={ t("request.basicSection.rejectedBy") }
          name="rejectedBy"
          className="col-md-4"
          showLabel={ true }
          multiple={ false }
          validation={{ required: false }}
          items={ users }
          labelField="email"
          onChange={ () => {} }
          tabIndex={14}
          { ...formProps }
        />  
        <FormSelect<User>
        label={ t("request.basicSection.completedBy") }
        name="completedBy"
        className="col-md-4"
        showLabel={ true }
        multiple={ false }
        validation={{ required: false }}
        items={ users }
        labelField="email"
        onChange={ () => {} }
        tabIndex={14}
        { ...formProps }
      />
          <FormInput
          label={t("request.basicSection.finalizedAt")}
          name="finalizedAt"
          type="datetime-local"
          className="col-sm-4"
          validation={{ required: false }}
          tabIndex={7}
          {...formProps}
        />
      </div> */}
    </FormSection>
  );

}
