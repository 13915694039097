import React from "react";
import { useEffect } from "react";

/**
 * 
 * @param {{
 * children: React.ReactChild | React.ReactChild[]
 * className: string
 * visible: boolean
 * top?: number
 * left?: number
 * }} props 
 * @returns 
 */
export function DraggableView(props) {
  function dragElement(elmnt) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(elmnt.id + "header")) {
      // if present, the header is where you move the DIV from:
      document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
    } else {
      // otherwise, move the DIV from anywhere inside the DIV:
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }
  
  useEffect(() => {
    dragElement(document.getElementById("mydiv"));
  }, []);

  return (
    <div id="mydiv" className={props.className} style={{
      position: 'absolute', zIndex: 9, display: props.visible ? "block" : "none", top: props.top ? props.top: window.innerHeight * 0.84, left: props.left ? props.left: window.innerWidth * 0.75
    }}>
      {props.children}
    </div>
  )
}