import { Acl } from "../../interfaces/core.interface";

export enum NetworkAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Approve = 'approve',
  Audit = 'audit'
}

export const NetworkAcl = [
  {
    code: NetworkAclCode.ListAll,
    title: 'network.listAll.title',
    description: 'network.listAll.subTitle',
  },
 
  {
    code: NetworkAclCode.ListOwn,
    title: 'network.listOwn.title',
    description: 'network.listOwn.subTitle',
  },
  {
    code: NetworkAclCode.Create,
    title: 'network.create.title',
    description: 'network.create.subTitle',
  },
  {
    code: NetworkAclCode.Update,
    title: 'network.update.title',
    description: 'network.update.subTitle',
  },
  {
    code: NetworkAclCode.Delete,
    title: 'network.delete.title',
    description: 'network.delete.subTitle',
  },
  {
    code: NetworkAclCode.Audit,
    title: 'network.audit.title',
    description: 'network.audit.subTitle',
  },
] as Acl<NetworkAclCode>;
