import { IEntityBase, IEntityCreatedBy, IEntityTimestamp } from "../interfaces/entity.interface";
import { Driver } from "./driver.entity";
import { Inspection } from "./inspection.entity";
import { Vehicle } from "./vehicle.entity";

export enum InspectionAssignmentStatus {
    
    Done = 'done',
    Pending = 'pending',
     
}

export interface InspectionAssignment extends IEntityBase, IEntityTimestamp, IEntityCreatedBy {
    id?: string ;
    inspection?: Inspection ;
    entity?:  Driver | Vehicle | String ;
    status: InspectionAssignmentStatus ;
}