import React from 'react';
import { CorporateAccount } from '../../../../entities/corporate-account.entity';
import { ContentWrapper, ViewContent } from '../../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../../components/view/item.component';
import { Feature } from '../../../../entities/feature.entity';
import { capitalize } from '../../../../util/string.util';
import { useTranslation } from 'react-i18next';
import { ProfilePicSize } from './list.component';

interface Props {
  selectedCorporateAccount: CorporateAccount,
  getLogoPic: (corporateAccount: CorporateAccount, size: ProfilePicSize) => string|undefined;
}

export function SelectedItemContent({
  selectedCorporateAccount,getLogoPic
  
}: Props) {
  const { t } = useTranslation('forms');

  return(
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={ selectedCorporateAccount.companyName }
        imgSrc={getLogoPic(selectedCorporateAccount,ProfilePicSize.Single)}
      >
        <ViewContentItems>
        </ViewContentItems>
        <ViewContentItem title={t("common.contactPerson")}>
                        {capitalize(selectedCorporateAccount?.contactPerson)}
                    </ViewContentItem>

                    <ViewContentItem title={t("common.contactEmail")}>
                        <a href={`mailto:${selectedCorporateAccount?.email}`}>{selectedCorporateAccount?.contactEmail}</a>
                    </ViewContentItem>

                    <ViewContentItem title={t("common.contactPhone")}>
                        <a href={`tel:${selectedCorporateAccount?.phone}`}>{selectedCorporateAccount?.contactPhone}</a>
                    </ViewContentItem>

                    <ViewContentItem title={t("corporateBooking.personalSection.address")}>
                    {selectedCorporateAccount?.address?.text}
                    </ViewContentItem>

                    <ViewContentItem title={t("corporateBooking.personalSection.department")}>
                      {selectedCorporateAccount.department}
                    </ViewContentItem>

                    <ViewContentItem title={t("corporateBooking.personalSection.abn")}>
                      {selectedCorporateAccount.abn}
                    </ViewContentItem>
      </ViewContent>
    </ContentWrapper>
  );
}