import { ModuleName } from "../../enums/core.enum";
import { Module } from "../../interfaces/core.interface";
import { CorporateBookingAcl, CorporateBookingAclCode } from "./acl";
import { CorporateBookingRouteMap } from './routes';

export const CorporateBookingModule = {
  name: ModuleName.corporate,
  acl: CorporateBookingAcl,
  routes: CorporateBookingRouteMap,
} as Module<CorporateBookingAclCode>;
