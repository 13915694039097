import { Acl } from "../../interfaces/core.interface";

export enum CustomTollsAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete', 
  Audit = 'audit',
  setDefault = 'set-default'
}

export const CustomTollsAcl = [
  {
    code: CustomTollsAclCode.ListAll,
    title: 'customTolls.listAll.title',
    description: 'customTolls.listAll.subTitle',
  },
  {
    code: CustomTollsAclCode.ListOwn,
    title: 'customTolls.listOwn.title',
    description: 'customTolls.listOwn.subTitle',
  },
  {
    code: CustomTollsAclCode.Create,
    title: 'customTolls.create.title',
    description: 'customTolls.create.subTitle',
  },
  {
    code: CustomTollsAclCode.Update,
    title: 'customTolls.update.title',
    description: 'customTolls.update.subTitle',
  },
  {
    code: CustomTollsAclCode.Delete,
    title: 'customTolls.delete.title',
    description: 'customTolls.delete.subTitle',
  },
  {
    code: CustomTollsAclCode.Audit,
    title: 'customTolls.audit.title',
    description: 'customTolls.audit.subTitle',
  }
] as Acl<CustomTollsAclCode>;
