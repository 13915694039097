import React, { useCallback, useEffect, useState } from "react";
import { useWindowScroll } from 'react-use';
import { Loading } from "../../../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../../components/list/items.component";
import { List } from "../../../../components/list/wrapper.component";
import { View } from "../../../../components/view/wrapper.component";
import { FormMode } from "../../../../enums/core.enum";
import { FormStyle } from "../../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../../services/api.service';
// import { FeaturePost } from "../post/post.component";
import { SelectedItemContent } from "./content.component";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CorporateUserStatus, CorporateUser } from "../../../../entities/corporate-user.entity";
import { CorporateBookingPost } from "../post/post.component";

const groupFilters: GroupFilter[] = [
  { title: "all", icon: 'phone-21' },
  { title: "active", icon: 'ui-15' },
  { title: "pending", icon: 'ui-15' },
  { title: "blocked", icon: 'ui-15' },
];

export function CorporateUserList(props: any) {

  const { t } = useTranslation('list');
  const [search, setSearch] = useState<string>('');
  const [allCount, setAllCount] = useState<number>(0);
  const { y: pageYOffset } = useWindowScroll()
  const [limit, setLimit] = useState<number>(20)
  const [count, setCount] = useState<number>(1);
  const [activeCount, setActiveCount] = useState<number>(0);
  const [pendingCount, setPendingCount] = useState<number>(0);
  const [blockedCount, setBlockedCount] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [corporateUsers, setCorporateUsers] = useState<CorporateUser[] | undefined>(undefined);
  const [selectedCorporateUser, setSelectedCorporateUser] = useState<CorporateUser>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [size, setSize] = useState<number>()
  const [changeStatus, setChangeStatus] = useState<CorporateUserStatus | undefined>(undefined)


  const refresh = useCallback(async () => {
    
    let group: string | undefined = activeGroupFilter?.title;
    group = group === 'all' ? undefined : group;    
    console.log("cooperate")
    const { items, totalItems } = await Api.get<IPaginationResponse<CorporateUser>, IPaginationOptions>(Endpoint.CORPORATE_USER_LIST, { page: 0, limit, group, search });
    console.log("cooperate",items)
    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items) {
      if (props.location.state != null) {
        setSelectedCorporateUser(props.location.state);
        setCorporateUsers(items);
      }
      else {
        setCorporateUsers(items);
      }

    } 

    const ITEMS = await Api.get<IPaginationResponse<CorporateUser>, IPaginationOptions>(Endpoint.CORPORATE_USER_LIST, { limit: 10000000 });


    setAllCount(ITEMS.totalItems);
    setActiveCount(ITEMS.items.filter(item => item.status === 'active').length)
    setBlockedCount(ITEMS.items.filter(item => item.status === 'blocked').length)
    setPendingCount(ITEMS.items.filter(item => item.status === 'pending').length)

    if (selectedCorporateUser?.status === CorporateUserStatus.Blocked) {
    
      setChangeStatus(CorporateUserStatus.Active)
    } else if (selectedCorporateUser?.status === CorporateUserStatus.Active) {
      console.log("status",CorporateUserStatus.Active)
      setChangeStatus(CorporateUserStatus.Blocked)
    } else {
      setChangeStatus(undefined)
    }

    if (items.length > 0 && !selectedCorporateUser) {
      console.log("item",items[0])
      setSelectedCorporateUser(items[0]);
    }

   
  }, [search, limit, activeGroupFilter, selectedCorporateUser]);

  useEffect(() => { refresh(); }, [refresh,]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const deleteCorporateUser = async () => {
    setConfirmingDelete(false);
    // await Api.delete(Endpoint.FEATURE, { id: selectedCorporateUser?.id });
    setSelectedCorporateUser(undefined);
    refresh();
  }

  const toListItems = (corporateUser?: CorporateUser): ListItem<CorporateUser> | undefined => {
    if (!corporateUser) {
      return;
    }

    return {
      id: corporateUser.id as string,
      title: corporateUser.name,
      status: ListItemStatus.Green,
      ref: corporateUser
    }
  }

  const addFeature = () => {
    setFormMode(FormMode.Adding);
  }

  const editFeature = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (corporateUsers) {
      const currentUserIndex = corporateUsers.findIndex(corporateUser => !!selectedCorporateUser && corporateUser.id === selectedCorporateUser.id);
      if (currentUserIndex + 1 < corporateUsers.length && currentUserIndex !== -1) {
        setSelectedCorporateUser(corporateUsers[currentUserIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (corporateUsers) {
      const currentUserIndex = corporateUsers.findIndex(corporateUser => !!selectedCorporateUser && corporateUser.id === selectedCorporateUser.id);
      if (currentUserIndex - 1 > -1 && currentUserIndex !== -1) {
        setSelectedCorporateUser(corporateUsers[currentUserIndex - 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedCorporateUser(undefined);
    setActiveGroupFilter(target);
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  const statusChange = async () => {
    if (selectedCorporateUser?.status === CorporateUserStatus.Active) {
      const { data }:any = await Api.post(Endpoint.CORPORATE_USER_DEACTIVATE, { id: selectedCorporateUser.id })
      setSelectedCorporateUser(data);
      // refresh();
    } else if (selectedCorporateUser?.status === CorporateUserStatus.Blocked) {
      const { data }:any = await Api.post(Endpoint.CORPORATE_USER_ACTIVATE, { id: selectedCorporateUser.id });
      setSelectedCorporateUser(data);
      // refresh();
    }
  }


  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: "all", icon: 'phone-21', count: allCount },
                  { title: "active", icon: 'ui-15', count: activeCount },
                  { title: "blocked", icon: 'ui-15', count: blockedCount },
                ]}
                activeGroupFilter={activeGroupFilter}
                onGroupSelect={selectGroup}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={corporateUsers?.map(toListItems) as ListItem<CorporateUser>[]}
                activeItem={toListItems(selectedCorporateUser)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setCorporateUsers(undefined); refresh(); }}
                onClick={(item: ListItem<CorporateUser>) => { setSelectedCorporateUser(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                corporateUsers !== undefined ? (
                  selectedCorporateUser ? (
                    <View
                      componentName={"corporate"}
                      showAdd={true}
                      showEdit={true}
                      showDelete={false}
                      showAudit={false}
                      showNext={true}
                      showPrev={true}
                      onAdd={addFeature}
                      onEdit={editFeature}
                      onStateChange={statusChange}
                      // onDelete={deleteFeature}
                      changeCorporateUserStatus={changeStatus}
                      showStateChange={true}
                      onNext={onNext}
                      onPrev={onPrev}
                      onAudit={() => checkAudit(selectedCorporateUser.id)}
                    >
                      <SelectedItemContent
                        selectedCorporateUser={selectedCorporateUser}
                      />
                    </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button onClick={addFeature} className="btn btn-primary text-bold">{t('corporateUser.addCorporateUser')}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t('corporate.wait')}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedCorporateUser ? (
                  <CorporateBookingPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedCorporateUser.id}
                    onAfterSave={() => refresh()}
                  />
                ) : (
                  <CorporateBookingPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    onAfterSave={() => refresh()}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
           groupFilters={[
            { title: "all", icon: 'phone-21', count: allCount },
            { title: "active", icon: 'ui-15', count: activeCount },
            { title: "blocked", icon: 'ui-15', count: blockedCount },
          ]}
            activeGroupFilter={activeGroupFilter}
            onGroupSelect={selectGroup}
          />

          <ListItems
            items={corporateUsers?.map(toListItems) as ListItem<CorporateUser>[]}
            activeItem={toListItems(selectedCorporateUser)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setCorporateUsers(undefined); refresh(); }}
            onClick={(item: ListItem<CorporateUser>) => { setSelectedCorporateUser(item.ref); }}
          />

          {formMode === undefined ? (
            corporateUsers !== undefined ? (
              selectedCorporateUser ? (
                <View
                  componentName={"corporate"}
                  showAdd={true}
                  showEdit={true}
                  showDelete={false}
                  showAudit={false}
                  showNext={true}
                  showPrev={true}
                  onAdd={addFeature}
                  onEdit={editFeature}
                  onStateChange={statusChange}
                  changeCorporateUserStatus={changeStatus}
                  showStateChange={true}
                  // onDelete={deleteFeature}
                  onNext={onNext}
                  onPrev={onPrev}
                  onAudit={() => checkAudit(selectedCorporateUser.id)}
                >
                  <SelectedItemContent
                    selectedCorporateUser={selectedCorporateUser}
                  />
                </View>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addFeature} className="btn btn-primary text-bold">{t('corporateUser.addCorporateUser')}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t('corporateUser.wait')}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedCorporateUser ? (
              <CorporateBookingPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedCorporateUser.id}
                onAfterSave={() => refresh()}
              />
            ) : (
              <CorporateBookingPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh()}
              />
            )
          )}
        </List>
      }
    </div>
  );

}
