import { RouteProps } from "react-router-dom";
import { FareDashboard } from "./dashboard.component";
import { FarePost } from "./post/post.component";
import { FareList } from "./detail/list.component";

export enum FareRoute {
  Dashboard = '/fare/dashboard',
  Post = '/fare/post',
  List = '/fare/list/detail'
}

export const FareRouteMap = [
  {
    path: FareRoute.Dashboard,
    component: FareDashboard,
  },
  {
    path: FareRoute.Post,
    component: FarePost,
  },
  {
    path: FareRoute.List,
    component: FareList,
  },
] as RouteProps[];
