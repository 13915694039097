import { Currency } from "../entities/currency.entity";
import { Driver } from "../entities/driver.entity";
import { Protocol } from "../entities/protocol.entity";
import { Fare } from "../entities/fare.entity";
import { Feature } from "../entities/feature.entity";
import { Fleet } from "../entities/fleet.entity";
import { Group } from "../entities/group.entity";
import { Incident } from "../entities/incident.entity";
import { Inspection } from "../entities/inspection.entity";
import { Network } from "../entities/network.entity";
import { Operator } from "../entities/operator.entity";
import { Passenger } from "../entities/passenger.entity";
import { Role } from "../entities/role.entity";
import { Service } from "../entities/service.entity";
import { Settings } from "../entities/settings.entity";
import { User } from "../entities/user.entity";
import { Vehicle } from "../entities/vehicle.entity";
import { Zone } from "../entities/zone.entity";
import { DashboardRoute } from "../pages/dashboard/routes";
import { DriverRoute } from "../pages/driver/routes";
import { FareRoute } from "../pages/fare/routes";
import { FleetRoute } from "../pages/fleet/routes";
import { GroupRoute } from "../pages/group/routes";
import { InspectionRoute } from "../pages/inspection/routes";
import { NetworkRoute } from "../pages/network/routes";
import { OperatorRoute } from "../pages/operator/routes";
import { PassengerRoute } from "../pages/passenger/routes";
import { SecurityRoute } from "../pages/security/routes";
import { SettingsRoute } from "../pages/settings/routes";
import { UserRoute } from "../pages/user/routes";
import { VehicleRoute } from "../pages/vehicle/routes";
import { ZoneRoute } from "../pages/zone/routes";
import { CreateInspection } from "../entities/CreateInspection.entity";
import { ServiceRoute } from "../pages/service/routes";
import { ArticleRoute } from "../pages/article/routes";
import { Article } from "../entities/article.entity";
import { Subscriber } from "../entities/subscriber.entity";
import { SubscriberRoute } from "../pages/subscribers/routes";
import { CallRoute } from "../pages/call/routes";
import { ReportRoute } from "../pages/report/routes";
import { Report } from "../entities/report.entity";
import { FeatureRoute } from "../pages/feature/routes";
import { Hotspot } from "../entities/hotspot.entity";
import { HotspotRoute } from "../pages/hotspot/routes";
import { CorporateBookingRoute } from "../pages/corporate/routes";
import { CorporateAccount } from "../entities/corporate-account.entity";
import { CorporateUser } from "../entities/corporate-user.entity";
import { RequestRoute } from "../pages/request/routes";
import { Request } from "../entities/request.entity";
import { RequestCategory } from "../entities/request-category.entity";
import { RequestCategoryRoute } from "../pages/request-category/routes";
import { CustomTollsRoute } from "../pages/custom-tolls/routes";
import { CustomTolls } from "../entities/customTolls.entity";



export enum FormHeaderType {
  Title = "title",
  Controls = "controls",
}

export type FormCancelLink =
  UserRoute | 
  CustomTollsRoute|
  ArticleRoute |
  DashboardRoute |
  DriverRoute |
  CallRoute |
  FareRoute |
  ZoneRoute |
  VehicleRoute |
  OperatorRoute |
  FleetRoute |
  FeatureRoute |
  SubscriberRoute |
  PassengerRoute |
  RequestRoute |
  RequestCategoryRoute |
  InspectionRoute |
  NetworkRoute |
  GroupRoute |
  PassengerRoute |
  HotspotRoute |
  SettingsRoute |
  SecurityRoute |
  ReportRoute |
  CorporateBookingRoute|
  ServiceRoute;

export type Entities =
  CreateInspection |
  CustomTolls |
  User |
  Role |
  Driver |
  Feature |
  Service |
  Zone |
  Vehicle |
  Operator |
  Fleet |
  Passenger |
  Inspection |
  Group |
  Network |
  Passenger |
  Report |
  Request |
  RequestCategory |
  Settings |
  Fare |
  Currency |
  Protocol |
  Hotspot |
  Article |
  Subscriber |
  CorporateAccount|
  CorporateUser|
  Incident;

export enum FormStyle {
  Containerized = "Containerized",
}
