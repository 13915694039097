import { Acl } from "../../interfaces/core.interface";

export enum CallAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Delete = 'delete',
}

export const CallAcl = [
  {
    code: CallAclCode.ListAll,
    title: 'call.listAll.title',
    description: 'call.listAll.subTitle',
  },
  {
    code: CallAclCode.ListOwn,
    title: 'call.listOwn.title',
    description: 'call.listOwn.subTitle',
  },
  {
    code: CallAclCode.Delete,
    title: 'call.delete.title',
    description: 'call.delete.subTitle',
  },
] as Acl<CallAclCode>;
