import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from '../../../components/form/form.component';
import { FormSelect } from '../../../components/form/input/select.component';
import { Driver } from "../../../entities/driver.entity";
import { Fleet } from "../../../entities/fleet.entity";
import { Group } from "../../../entities/group.entity";
import { Network } from "../../../entities/network.entity";
import { Operator } from "../../../entities/operator.entity";
import { Service } from "../../../entities/service.entity";
import { Vehicle } from "../../../entities/vehicle.entity";
import { Zone } from "../../../entities/zone.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';

export const endpoints = [
  Endpoint.VEHICLE_LIST,
  Endpoint.ZONE_LIST,
  Endpoint.SERVICE_LIST,
  Endpoint.FLEET_LIST,
  Endpoint.DRIVER_LIST,
  Endpoint.NETWORK_LIST,
  Endpoint.OPERATOR_LIST,
  Endpoint.GROUP_LIST,
]

export function MonitoringFilters() {

  const [fleets, setFleets] = useState<Fleet[]>([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [networks, setNetwork] = useState<Network[]>([]);
  const [operators, setOperator] = useState<Operator[]>([]);
  const [groups, setGroup] = useState<Group[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [zones, setZones] = useState<Zone[]>([]);

  const { t } = useTranslation('forms');
  const childProps = useForm();

  const init = useCallback(async () => {
    const [vehicles, zones, services, fleets, drivers, networks, operators, groups]
      = await Promise.all(endpoints.map(getData));

    setVehicles((vehicles as IPaginationResponse<Vehicle>).items);
    setFleets((fleets as IPaginationResponse<Fleet>).items);
    setDrivers((drivers as IPaginationResponse<Driver>).items);
    setNetwork((networks as IPaginationResponse<Network>).items);
    setOperator((operators as IPaginationResponse<Operator>).items);
    setGroup((groups as IPaginationResponse<Group>).items);
    setZones((zones as IPaginationResponse<Zone>).items);
    setServices((services as IPaginationResponse<Service>).items);
  }, [])

  function getData<T>(endpoint: Endpoint): Promise<IPaginationResponse<T>> {
    return Api.get<IPaginationResponse<T>, IPaginationOptions>(endpoint, { page: 0, limit: 20 })
  }

  useEffect(() => {
    init();
  }, [init]);

  return (
    <Form>
      <div className="row">
        <FormSelect<Vehicle>
          label={t("video.vehicles")}
          name="vehicles"
          className="col-md-3"
          showLabel={false}
          multiple={true}
          validation={{}}
          labelField="plate"
          items={vehicles}
          tabIndex={1}
          {...childProps}
        />
        <FormSelect<Driver>
          label={t("video.drivers")}
          name="drivers"
          className="col-md-3"
          showLabel={false}
          multiple={true}
          validation={{}}
          labelField="firstName"
          items={drivers}
          tabIndex={2}
          {...childProps}
        />
        <FormSelect<Fleet>
          label={t("video.fleets")}
          name="fleets"
          className="col-md-3"
          showLabel={false}
          multiple={true}
          validation={{}}
          labelField="title"
          items={fleets}
          tabIndex={3}
          {...childProps}
        />
        <FormSelect<Network>
          label={t("video.networks")}
          name="networks"
          className="col-md-3"
          showLabel={false}
          multiple={true}
          validation={{}}
          labelField="title"
          items={networks}
          tabIndex={4}
          {...childProps}
        />
      </div>
      <div className="row">
        <FormSelect<Operator>
          label={t("video.operators")}
          name="operator"
          className="col-md-3"
          showLabel={false}
          multiple={true}
          validation={{}}
          labelField="title"
          items={operators}
          tabIndex={5}
          {...childProps}
        />
        <FormSelect<Group>
          label={t("video.group")}
          name="groups"
          className="col-md-3"
          showLabel={false}
          multiple={true}
          validation={{}}
          labelField="title"
          items={groups}
          tabIndex={6}
          {...childProps}
        />
        <FormSelect<Service>
          label={t("video.service")}
          name="services"
          className="col-md-3"
          showLabel={false}
          multiple={true}
          validation={{}}
          labelField="title"
          items={services}
          tabIndex={7}
          {...childProps}
        />
        <FormSelect<Zone>
          label={t("video.zones")}
          name="zones"
          className="col-md-3"
          showLabel={false}
          multiple={true}
          validation={{}}
          labelField="title"
          items={zones}
          tabIndex={8}
          {...childProps}
        />
      </div>
    </Form>
  );
}