import { IEntityBase, IEntityCreatedBy, IEntityTimestamp } from "../interfaces/entity.interface";
import { Address } from "./address.entity";
import { IFile } from "./file.entity";
export enum CorporateAccountStatus {
  Active = 'active',
  Pending = 'pending',
  Blocked = 'blocked',
}

export interface CorporateAccount extends IEntityBase, IEntityTimestamp, IEntityCreatedBy {
  [key: string]: any; 
  companyName: string;
  contactPerson: string;
  paymentMethod?: string[];
  contactEmail: string;
  contactPhone: string;    
  abn?: string;
  department?: string;
  address?: Address;
  logoFile?: IFile;
}
