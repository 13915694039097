import { Acl } from "../../interfaces/core.interface";

export enum CorporateBookingAclCode {
  ListAllBooking = 'list-all-booking',
  ListOwnBooking = 'list-own-booking',
  CreateAccount = 'create-account',
  UpdateAccount = 'update-account',
  DeleteAccount = 'delete-account',
  BlockAccount = 'block-account',
  AuditAccount = 'audit',
  Account = 'account',
  ListAllAccount = 'list-all-account',
  ListOwnAccount = 'list-own-account',

  User = 'user',
  CreateUser = 'create-User',
  UpdateUser = 'update-User',
  DeleteUser = 'delete-User',
  BlockUser = 'block-User',
  ListAllUser = 'list-all-User',
  ListOwnUser = 'list-own-User',
}

export const CorporateBookingAcl = [
  {
    code:  CorporateBookingAclCode.ListAllAccount,
    title: 'corporateBooking.listAllAccount.title',
    description: 'corporateBooking.listAllAccount.subTitle',
  },
  {
    code: CorporateBookingAclCode.ListOwnAccount,
    title: 'corporateBooking.listOwnAccount.title',
    description: 'corporateBooking.listOwnAccount.subTitle',
  },
  {
    code: CorporateBookingAclCode.CreateAccount,
    title: 'corporateBooking.createAccount.title',
    description: 'corporateBooking.createAccount.subTitle',
  },
  {
    code: CorporateBookingAclCode.UpdateAccount,
    title: 'corporateBooking.updateAccount.title',
    description: 'corporateBooking.updateAccount.subTitle',
  },
  {
    code: CorporateBookingAclCode.BlockAccount,
    title: 'corporateBooking.blockAccount.title',
    description: 'corporateBooking.blockAccount.subTitle',
  },
  {
    code:  CorporateBookingAclCode.ListAllUser,
    title: 'corporateBooking.listAllUser.title',
    description: 'corporateBooking.listAllUser.subTitle',
  },
  {
    code: CorporateBookingAclCode.ListOwnUser,
    title: 'corporateBooking.listOwnUser.title',
    description: 'corporateBooking.listOwnUser.subTitle',
  },
  {
    code: CorporateBookingAclCode.CreateUser,
    title: 'corporateBooking.createUser.title',
    description: 'corporateBooking.createUser.subTitle',
  },
  {
    code: CorporateBookingAclCode.UpdateUser,
    title: 'corporateBooking.updateUser.title',
    description: 'corporateBooking.updateUser.subTitle',
  },
  {
    code: CorporateBookingAclCode.BlockUser,
    title: 'corporateBooking.blockUser.title',
    description: 'corporateBooking.blockUser.subTitle',
  },
  {
    code: CorporateBookingAclCode.ListOwnBooking,
    title: 'corporateBooking.listOwnBooking.title',
    description: 'corporateBooking.listOwnBooking.subTitle',
  }
] as Acl<CorporateBookingAclCode>;
