import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput } from '../../../../components/form/input/input.component';
import { FormSection } from '../../../../components/form/section.component';

interface Props {
    rideType: string,
    discountType?: string,
    onRideTypeChange: (e: any) => void,
    onDiscountTypeChange?: (e: any) => void,
    onDistanceChange: (e: any) => void,
    onWaitTimeChange: (e: any) => void,
    onDiscountChange?: (e: any) => void,
    onExtraChange?: (e: any) => void,
}

export function CalculatorFarePost({
    onDistanceChange, onWaitTimeChange, onRideTypeChange, onDiscountTypeChange, onDiscountChange, onExtraChange, rideType, discountType
}: Props) {

    const { t } = useTranslation(['forms', 'main','settings']);
    const settingTranslation = useTranslation('settings')
    const childProps = useFormContext();

    return (
        <FormSection
            title={t("fare.calculatorSection.title")}
            subtitle={t("fare.calculatorSection.subTitle")}
        >
            <div className="row">
                <label className="col-sm-6">
                    {t("fare.calculatorSection.rideType")}
                    <select value={rideType} onChange={onRideTypeChange}>
                        <option value="Hail">{t('fare.calculatorSection.hail')}</option>
                        <option value="App">{t('fare.calculatorSection.app')}</option>
                        <option value="Dispatch">{t('fare.calculatorSection.dispatch')}</option>
                    </select>
                </label>
            </div>
            <div className="row">
                <FormInput
                    name="calculateDistance"
                    label={t("fare.calculatorSection.distance")}
                    type="number"
                    step={0.01}
                    className="col-sm-6"
                    onChange={onDistanceChange}
                    tabIndex={15}
                    validation={{}}
                    {...childProps}
                />
                <FormInput
                    name="calculateWaitTime"
                    label={t("fare.calculatorSection.waitTime")}
                    type="number"
                    step={0.01}
                    className="col-sm-6"
                    onChange={onWaitTimeChange}
                    tabIndex={16}
                    validation={{}}
                    {...childProps}
                />
            </div>
            <div className="row">
                <label className="col-sm-6">
                    {t("fare.calculatorSection.discountType")}
                    <select value={discountType} onChange={onDiscountTypeChange}>
                        <option value="percentage">{settingTranslation.t('payment.creditCardSection.typePercentage')}</option>
                        <option value="amount">{t("fare.calculatorSection.amount")}</option>
                    </select>
                </label>
                <FormInput
                    name="calculateDiscount"
                    label={t("fare.calculatorSection.discountValue")}
                    type="number"
                    step={0.01}
                    className="col-sm-6"
                    onChange={onDiscountChange}
                    tabIndex={17}
                    validation={{}}
                    {...childProps}
                />
            </div>
            <FormInput
                name="calculateExtraCharge"
                label={t("fare.calculatorSection.extraCharges")}
                type="number"
                step={0.01}
                className="col-sm-12"
                onChange={onExtraChange}
                tabIndex={18}
                validation={{}}
                {...childProps}
            />
        </FormSection>
    );
}
