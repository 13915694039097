import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';

const pdf = require(`../../img/pdf.png`);

export interface FileGroup {
  title: string;
  files?: { thumb: string, full: string }[];
}

interface Props {
  groups: FileGroup[];
}

export function ViewContentFiles({ groups }: Props) {

  let expandedImgRef!: HTMLImageElement | null;
  const [selectedGroup, setSelectedGroup] = useState<FileGroup>(groups[0]);
  const [count, setCount] = useState<number>(0);
  const [expanded, setExpanded] = useState<string>();




  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (expandedImgRef !== event?.currentTarget) {
        setExpanded(undefined);
      }
    }
    document.addEventListener('mousedown', listener);
    return () => { document.removeEventListener('mousedown', listener); }
  }, [expandedImgRef]);

  const onClick = (url: string) => {
    setExpanded(url);
  }

  const onPdfClick = (url: string) => {
    window.open(url);
  }

  //   useEffect(()=>{
  //     if(count==0){
  // setSelectedGroup(groups[0])
  //   }else{ 
  //     setSelectedGroup(selectedGroup);}

  //   },[groups])


  // const GroupRefresh = useCallback(()=>{

  //   setSelectedGroup(groups[0]);

  // });
  return (
    <div style={{ maxWidth: '768px' }}>
      <div className="os-tabs-controls">
        <div className='row'>
          <div className="col">
            <ul className="nav nav-tabs" style={{ flexWrap: "wrap" }}>
              {groups.map(group => (
                <li key={group.title} className="nav-item">
                  <button
                    className={classNames('nav-link', { active: group.title === selectedGroup?.title })}
                    data-toggle="tab"
                    onClick={() => {
                      setSelectedGroup(group);
                      return false
                    }}
                  >
                    {group.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>

        </div>

      </div>
      <div className="d-flex flex-row overflow-auto pb-4">
        {selectedGroup?.files?.map((file, index) =>
          file.thumb.includes('admin_single.pdf') ?
            <img key={index} src={pdf} style={{ cursor: 'pointer' }}
              alt={index.toString()} onClick={() => onPdfClick(file.full)}
            /> :
            <img className="mx-2" key={index} style={{ maxWidth: '200px' }} src={file.thumb} alt={index.toString()}
              onClick={() => onClick(file.full)} />
        )}
      </div>
      {expanded && (
        <div>
          <img
            ref={r => expandedImgRef = r}
            className="expanded-img"
            src={expanded}
            alt=''
          />
          <div className="expanded-backdrop"></div>
        </div>)}
    </div>
  );
}