import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../components/form/form.component";
import { FormHeader } from "../../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../../components/form/sections.component";
import { Protocol } from "../../../../entities/protocol.entity";
import { FormMode } from "../../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../../enums/form.enum";
import { Endpoint } from '../../../../services/api.service';
import { SecurityRoute } from "../../routes";
import { ProtocolPostBasic } from "./section/basic.component";

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: Protocol) => void;
}

/**
 * Add or update an Emergency Protocol
 */
export function ProtocolPost({
  style, cancel, formMode, entityId, onAfterSave,
}: Props) {

  const { t } = useTranslation('main');

    /**
   * Custom Header
   */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Title } title={ t("entities.protocol") } />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Controls } />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={ FormHeaderType.Title } title={ t("entities.protocol") } />
    )
  );

  /**
   * Render
   */
  return (
    <Form<Protocol>
      endpoint={ Endpoint.PROTOCOL }
      returnLink={ SecurityRoute.ProtocolList }
      cancel={ cancel }
      formMode={ formMode }
      entityId={ entityId }
      onAfterSave={ onAfterSave }
      className={ style === FormStyle.Containerized && "ae-content-w" }
    >
      { style === FormStyle.Containerized && header() }
      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Title } title={ t("entities.protocol") } /> }
        <FormSections>
          <ProtocolPostBasic />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Controls } /> }
      </SectionsWrapper>
    </Form>
  );
}
