import { IEntityBase, IEntityCreatedBy, IEntityTimestamp } from "../interfaces/entity.interface";

export enum CorporateUserStatus {
  Active = 'active',
  Pending = 'pending',
  Blocked = 'blocked',
}


export enum CorporateRole {
  Admin = 'admin',
  staff = 'staff',
}


export interface CorporateUser extends IEntityBase, IEntityTimestamp, IEntityCreatedBy {
  [key: string]: any; 
  email: string;
  phone: string;
  corporateAccount: CorporateUserStatus;
  role: CorporateRole;
}
