import { RouteProps } from "react-router-dom";
import { HotspotList } from "./list/list.component";
import { HotspotPost } from "./post/post.component";

export enum HotspotRoute {
  List = '/hotspot/list',
  Post = '/hotspot/post',
}

export const HotspotRouteMap = [
  {
    path: HotspotRoute.List,
    component: HotspotList,
  },
  {
    path: HotspotRoute.Post,
    component: HotspotPost,
  },
] as RouteProps[];
