import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { generate } from "shortid";
import { FormTextArea } from "../../../components/form/input/textarea.component";
import { FormSection } from "../../../components/form/section.component";
import { Inspection } from "../../../entities/inspection.entity";
import { VehicleInspection } from "../post.component";

interface Props {
  vehicleInspectionData:Inspection[]|undefined
}
export function InspectionVehicle(props: Props) {
  const { t } = useTranslation("forms");
  const formProps = useFormContext();

  const [data, setData] = useState<VehicleInspection[]>([{ id: generate(), title: "" }]);
  const [sentence, setSentence] = useState<string>(props?.vehicleInspectionData?props?.vehicleInspectionData?.map((vehicleInspection:Inspection)=>{return vehicleInspection.title}).join('\n'):'');

  useEffect(
    () => {
      setSentence(props?.vehicleInspectionData?props?.vehicleInspectionData?.map((vehicleInspection:Inspection)=>{return vehicleInspection.title}).join('\n'):'')
    },
    [ props])

  const onChange = (e:any) => {
    // console.log('Inside Driver Change');
    const title = e.target.value;
    console.log('This is Vehicle Inspection Data:',title)
      setData([...data, title]);  
      setSentence(title)
 
    }

  return (
    <FormSection title={t("inspection.vehicleInspection")} subtitle="">
            <FormTextArea
              name={`vehicleInspection`}
              label={t("inspection.title")}
              rows={5}
              validation={{ required: false }}
              onchange={onChange}
              value={sentence}
              {...formProps}
            />
    </FormSection>
  );
}
