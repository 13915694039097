import { RouteProps } from "react-router-dom";
import { OperatorList } from "./list/list.component";
import { OperatorPost } from "./post/post.component";

export enum OperatorRoute {
  List = '/operator/list',
  Post = '/operator/post',
}

export const OperatorRouteMap = [
  {
    path: OperatorRoute.List,
    component: OperatorList,
  },
  {
    path: OperatorRoute.Post,
    component: OperatorPost,
  },
] as RouteProps[];
