import classNames from 'classnames';
import React from 'react';
import { UseFormMethods, ValidationRules } from 'react-hook-form';
import { getErrorMessage } from '../util';
export enum LabelPositon{
  Left='left',
  Right='right'
}
interface Props {
  label: string;
  name: string;
  checked?: boolean,
  className?: string;  
  validation: ValidationRules;
  tabIndex?: number;
  onChange?: any,
  labelPositon?: LabelPositon,
  disabled?:boolean
}


export function FormCheckBox(props: Props & UseFormMethods) {
  const { register, validation, errors,disabled, labelPositon,label, name, checked, className, tabIndex, onChange } = props;

  return (
    <div className={classNames('form-group', 'vertical', className, { 'has-danger': !!errors[name] })}>
      {(labelPositon==undefined)||(labelPositon==LabelPositon.Right)?<label className={validation.required ? 'required' : ''}>{ label }
      
       <input
          ref={register(validation)} 
          className="form-check-input" 
          name={name}
          type="checkbox"  
          checked={checked}
          disabled={disabled}
          onChange = {onChange}
          tabIndex={tabIndex}
          style={{marginLeft:'1rem'}}
      />   
      </label>: labelPositon==LabelPositon.Left?
      <label style={{marginLeft:'2.5rem'}}  className={validation.required ? 'required' : ''}>
        
      <input
          ref={register(validation)} 
          className="form-check-input" 
          name={name}
          type="checkbox"  
          checked={checked}
          disabled={disabled}
          onChange = {onChange}
          tabIndex={tabIndex}
          // style={{marginLeft:'1rem'}}
      /> 
        { label }</label>:<input
          ref={register(validation)} 
          className="form-check-input" 
          name={name}
          type="checkbox"  
          checked={checked}
          disabled={disabled}
          onChange = {onChange}
          tabIndex={tabIndex}
          // style={{marginLeft:'1rem'}}
      /> }  
     
      {errors[name] && (
      <div className="help-block form-text with-errors form-control-feedback">
        <ul className="list-unstyled"><li>{ getErrorMessage(errors[name], label) }</li></ul>
      </div>
      )}
    </div>
  );
}