import React from 'react';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItems } from '../../../components/view/item.component';
import { Feature } from '../../../entities/feature.entity';

interface Props {
  selectedFeature: Feature
}

export function SelectedItemContent({
  
  selectedFeature
  
}: Props) {
  return(
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={ selectedFeature.title }
      >
        <ViewContentItems>
        </ViewContentItems>
      </ViewContent>
    </ContentWrapper>
  );
}