import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormInput } from "../../../components/form/input/input.component";
import { FormRadio } from "../../../components/form/input/radio.component";
import { FormSection } from "../../../components/form/section.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Settings } from "../../../entities/settings.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType } from "../../../enums/form.enum";
import { Api, Endpoint } from "../../../services/api.service";
import { SettingsRoute } from "../routes";

/**
 * Update general settings
 */
export function SettingsSectionShifts() {

  const { t } = useTranslation('settings');
  const [entity,setEntity] = useState<any>("");
  useEffect(()=>{
  
    a();
  },[])

  const a =async()=>{
    const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
    setEntity(data.id)
  };
 

  const onBeforeSave = (_: FormMode, form: Settings) => {
    form.entityId = entity
    form.shiftMaxAllowed = form.shiftMaxAllowed ? Math.round(form.shiftMaxAllowed * 60) : 0;
    form.shiftMinGap = form.shiftMinGap ? Math.round(form.shiftMinGap * 60) : 0;
  }
  
  return (
    <Form<Settings>
      endpoint={ Endpoint.SETTINGS }
      returnLink = {SettingsRoute.Shift}
      onBeforeSave={ onBeforeSave }
    >
      <SectionsWrapper>
        <FormHeader type={ FormHeaderType.Title } title={ t("shifts.title") } fullTitle={ true } />
        <FormSections>
          <ShiftTimeSection />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper>
        <FormHeader type={ FormHeaderType.Controls } />
      </SectionsWrapper>
    </Form>
  );
}

const ShiftTimeSection = () => {
  
  const { t } = useTranslation('settings');
  const childProps = useFormContext();

  const [, setSettings] = useState<Settings>();

  const ref = useCallback(()=>{
    const refresh = async () => {
      const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
      data.shiftMaxAllowed = data.shiftMaxAllowed ? Math.round(data.shiftMaxAllowed / 60) : undefined;
      data.shiftMinGap = data.shiftMinGap ? Math.round(data.shiftMinGap / 60) : undefined;
      childProps.reset(data);
      setSettings(data);
    };
    refresh();
  },[childProps])

  useEffect(() => {
    ref();
  }, []);

  return(
    <FormSection
      title={ t("shifts.timeSection.title") }
      subtitle={ t("shifts.timeSection.subTitle") }
    >
      <div className="row">
        <FormInput
          name="shiftMaxAllowed"
          label={ t("shifts.timeSection.allowedTimeLabel") }
          placeholder={ t("shifts.timeSection.hours") }
          type="number"
          step={ 1 }
          className="col-sm-4"
          tabIndex={1}
          autoFocus
          validation={{}}
          { ...childProps }
        />
        <FormInput
          name="shiftMinGap"
          label={ t("shifts.timeSection.minimumGapLabel") }
          placeholder={ t("shifts.timeSection.hours") }
          type="number"
          step={ 1 }
          className="col-sm-4"
          tabIndex={2}
          validation={{}}
          { ...childProps }
        />
      </div>
      <div className="row">
        <FormRadio 
          label={ t("shifts.timeSection.actionLabel") }
          name="shiftActionOnMaxType"
          className="col-md-6"
          validation={{}}
          { ...childProps }
          options={[
            { label: t("shifts.timeSection.actionDisableMetering"), value: 'disable-meter' },
            { label: t("shifts.timeSection.actionDisableLogin"), value: 'disable-login' },
            { label: t("shifts.timeSection.actionWarn"), value: 'warn' },
          ]}
          tabIndex={3}
        />
      </div>
    </FormSection>
  );
}