import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

/* The props that the FormSection component expects to be passed to it */
/* className (optional): Needed if custom styling class needs to be provided */
/* title (required): Title for the form section */
/* subtitle (required): Subtitle for the section providing extra details */
interface Props {
    className?: any;
    title?: string;
    subtitle?: string;
}

export function FormSection({
    children, className, title, subtitle
}: PropsWithChildren<Props>) {

    return (
        /* Fieldset that wraps different input fields */
        <fieldset className={ classNames("form-group", className) }>

            {/* The title for the section */}
            { title && title !== "" && <legend><span>{ title }</span></legend> }
            
            {/* Subtitle for the section */}
            { subtitle && subtitle !==  "" && <div className="form-desc">{ subtitle }</div> }
            
            { children }
        </fieldset>
    );
}