import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { ApiLogs } from '../../../entities/api-log.entity';
import { ApiLogsProtocol } from '../../../enums/api-log.enum';

interface Props {
    selectedApiLog: ApiLogs;
    reqData?: { userAgent: any, response: any, request: any }
    subtitle: string;
}
export function SelectedItemContent({ selectedApiLog, subtitle, reqData }: Props) {
    // const [doc, setDoc] = useState(new DOMParser().parseFromString(selectedApiLog.description, "text/xml"));
    const [date, setDate] = useState<string>();
    useEffect(() => {
        // console.log('Selected Ki Date: ', selectedApiLog.date);
        // const date: Date = new Date(selectedApiLog.date);
        // const month = ((date.getMonth() + 1) < 10) ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
        // const day = ((date.getDate()) < 10) ? `0${date.getDate()}` : `${date.getDate()}`
        // const formattedDate = `${date.getFullYear()}-${month}-${day}`;
        // setDate(formattedDate);
    }, [selectedApiLog]);

    const { t } = useTranslation('forms');
    const PrettyPrintJson = React.memo(({ data }: any) => (
        <div>
            <pre className="text-white">{JSON.stringify(data, null, 2)}
            </pre>
        </div>
    ));
    return (
        <ContentWrapper className="row">
            <ViewContent
                headerClassName={"mb-0 pb-0"}
                ecommerceClassName={"p-1"}
                className="col-sm"
                title={`${selectedApiLog.protocol == ApiLogsProtocol.Socket && selectedApiLog.method + ":"}${selectedApiLog.url}`}
                subtitle={subtitle}
            >
                <div className="row" style={{ justifyContent: 'space-around', marginTop: '-20px' }}>
                    <a className="element-box el-tablo" href="#stat" style={{ marginTop: '2rem', marginBottom: '1rem', padding: '1rem' }}>
                        <div className="label">{t("apilogs.ipAddress")}</div>
                        <div className="value">{selectedApiLog.requestIp}</div>
                    </a>
                    <a className="element-box el-tablo" href="#stat" style={{ padding: '1rem' }}>
                        <div className="label">{t("apilogs.duration")}</div>
                        <div className="value">{selectedApiLog.duration + " ms"}</div>
                    </a>
                    <a className="element-box el-tablo" href="#stat" style={{ padding: '1rem' }}>
                        <div className="label">{t("apilogs.travelTime")}</div>
                        <div className="value">{selectedApiLog.travelTime + " ms"}</div>
                    </a>
                    <a className="element-box el-tablo" href="#stat" style={{ padding: '1rem' }}>
                        <div className="label">{t("apilogs.requestSize")}</div>
                        <div className="value">{selectedApiLog.requestLength}</div>
                    </a>
                    <a className="element-box el-tablo" href="#stat" style={{ padding: '1rem' }}>
                        <div className="label">{t("apilogs.responseSize")}</div>
                        <div className="value">{selectedApiLog.responseLength}</div>
                    </a>
                </div>
                {reqData && (
                    <ViewContentItems >
                        <ViewContentItem title={t("apilogs.userAgent")} className="container-fluid bg-secondary p-1">
                            <PrettyPrintJson data={JSON.parse(reqData.userAgent)} />
                        </ViewContentItem>
                        <ViewContentItem title={t("apilogs.request")} className="container-fluid bg-secondary p-1">
                            <PrettyPrintJson data={JSON.parse(reqData.request)} />
                        </ViewContentItem>
                        <ViewContentItem title={t("apilogs.response")} className="container-fluid bg-secondary p-1">
                            <PrettyPrintJson data={JSON.parse(reqData.response)} />
                        </ViewContentItem>
                    </ViewContentItems>
                )}
            </ViewContent>
        </ContentWrapper>
    );
}