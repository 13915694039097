import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../../components/form/input/input.component";
import { PhoneInputs } from "../../../../../components/form/input/phoneNumber";
import { FormRadio } from "../../../../../components/form/input/radio.component";
import { FormSelect } from "../../../../../components/form/input/select.component";
import { FormSection } from "../../../../../components/form/section.component";
import { CorporateAccount } from "../../../../../entities/corporate-account.entity";
import { CorporateUser } from "../../../../../entities/corporate-user.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../../services/api.service";

interface Props {
  value: string;
  onChange: any
}

export function CorporateUserPostPersonal({ value, onChange }: Props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const [corporateAccount, setCorporateAccounts] = useState<CorporateAccount[]>([]);

  useEffect(() => { init(); }, [])

  const init = async () => {
    const corporateAccountList = await Api.get<IPaginationResponse<CorporateAccount>, IPaginationOptions>(Endpoint.CORPORATE_LIST);
    setCorporateAccounts(corporateAccountList.items);
  }
  return (
    <FormSection
      title={t("corporateBooking.personalSection.title")}
      subtitle={t("corporateBooking.personalSection.subTitle")}
    >
      <div className="row">
        <FormInput
          label={t("corporateUser.personalSection.name")}
          name="name"
          type="text"
          autoFocus={true}
          className="col-sm-4"
          validation={{ required: true }}
          {...formProps}
        />
      </div>
      <div className="row">
        <FormInput
          label={t("common.email")}
          name="email"
          type="email"
          className="col-sm-4"
          validation={{ required: true }}
          {...formProps}
        />
        <PhoneInputs
          placeholder={t("common.phone")}
          value={value}
          name={'phone'}
          onChange={onChange}
          validation={{required:true}}
          className="col-md-4"
          {...formProps}
        />
      </div>
      <div className="row">
        <FormSelect<{ id: string, name: string }>
          label={t("corporateUser.personalSection.role")}
          name="role"
          className="col-md-4"
          showLabel={true}
          multiple={false}
          validation={{ required: true }}
          items={[{ id: "1", name: 'admin' }, { id: '2', name: 'staff' }]}
          labelField="name"
          onChange={() => { }}
          {...formProps}
        />
        <FormSelect<CorporateAccount>
          label={t("corporateUser.personalSection.corporateAccount")}
          name="corporateAccount"
          className="col-md-4"
          showLabel={true}
          multiple={false}
          autoFocus={false}
          validation={{ required: true }}
          items={corporateAccount}
          labelField="companyName"
          onChange={(item) => { console.log("this is selected companyName", item) }}
          {...formProps}
        />
      </div>

    </FormSection>
  );

}
