import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../../components/form/input/input.component";
import { FormTextArea } from "../../../../../components/form/input/textarea.component";
import { FormSection } from "../../../../../components/form/section.component";
import { User } from "../../../../../entities/user.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../../services/api.service";

interface Props {
}

export default function IncidentPostBasic(props: Props) {

  const [ , setManagers ] = useState<User[]>([]);
  const [ , setUsers ] = useState<User[]>([]);

  const formProps = useFormContext();

  const { t } = useTranslation('forms');

  const loadUsers = useCallback(async () => {
    setUsers([]);
    setManagers([]);
    const { items } = await Api.get<IPaginationResponse<User>, IPaginationOptions>(Endpoint.USER_LIST, { page: 0, limit: 20 });
    setUsers(items);
    setManagers(items);
  }, []);

  useEffect(() => {
    loadUsers();
  }, [ loadUsers ]);

  return (
    <FormSection
      title={ t("incident.basicSection.title") }
      subtitle={ t("incident.basicSection.subTitle") }
    >
      <div className="row">
        <FormInput
          label={ t("incident.title") }
          name="title"
          type="text"
          autoFocus
          tabIndex={1}
          className="col-md-12"
          validation={{ required: true }}
          { ...formProps }
        />
      </div>
      <div className="row">
        <FormTextArea
          label={ t("incident.description") }
          name="description"
          tabIndex={2}
          className="col-md-12"
          validation={{ required: true }}
          { ...formProps }
        />
      </div>
      
      {/* <div className="row">
        <FormSelect<User>
          label={ t("incident.assingedTo") }
          name="assingedTo"
          validation={{}}
          multiple={ true }
          items={ users }
          labelField="assingedTo"
          valueField="id"
          className="col-sm-6"
          tabIndex={4}
          { ...formProps }
        />
      </div>
      <div className="row">
        <FormInput
          label={ t("incident.date") }
          name="date"
          type ="date"
           className="col-sm-6"
          tabIndex={4}
          { ...formProps }
        />
      
      </div> */}
       <div className="row">
       {/* <FormSelect<User>
          label={ t("incident.assingedTo") }
          name="assingedTo"
          validation={{}}
          multiple={ true }
          items={ users }
          labelField="assingedTo"
          valueField="id"
          className="col-md-6"
          tabIndex={4}
          { ...formProps }
        /> */}
        <FormInput
          label={ t("incident.date") }
          name="timestamp"
          type="date"
          tabIndex={3}
          className="col-md-6"
          validation={{ required: true }}
          { ...formProps }
        />
      </div>
      
    </FormSection>
  );

}