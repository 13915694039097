import { IEntityBase } from "../interfaces/entity.interface";
import { Driver } from './driver.entity';
import { IFile } from "./file.entity";
import { Passenger } from "./passenger.entity";
import { Protocol } from "./protocol.entity";
import { Trip } from "./trip.entity";
import { Vehicle } from './vehicle.entity';

export enum EmergencyStatus {
  New = 'new',
  Cancelled = 'cancelled',
  Taken = 'taken',
  Action = 'action',
  Completed = 'completed',
  TakenByWebAdmin = 'TakenByWebAdmin'

}

export interface Emergency extends IEntityBase {
  id: string;
  driverId: string,
  action: string | undefined,
  vehicleId: string,
  driver: Driver;
  vehicle: Vehicle;
  level: "high" | "medium" | "low",
  peerId: string,
  trip: Trip | null,
  passenger: Passenger,
  type: "PassengerApp" | "DriverApp",
  updateTime: string,
  createTime: string,
  status: EmergencyStatus,
  protocol: Protocol
  recordingFile?: IFile;
  location: any;
  visible: boolean;
}
