import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput } from '../../../../components/form/input/input.component';
import { FormSection } from '../../../../components/form/section.component';
import { Currency } from './../../../../entities/currency.entity';
import { Settings } from './../../../../entities/settings.entity';
import { SettingsService } from './../../../../services/settings.service';

export let startCharge: number = 0;
export let minimumCharge: number = 0;
export let appBookingCharge: number = 0;
export let dispatchBookingCharge: number = 0;
export let distanceChargePerThreshold: number = 0;
export let distanceThreshold: number = 0;
export let waitChargePerThreshold: number = 0;
export let waitThreshold: number = 0;
export let freeDistance: number = 0;

interface Props {
    onAppBookingCharge: (e: any) => void,
    onDispatchBookingCharge: (e: any) => void,
    onDistanceChargePerThreshold: (e: any) => void,
    onDistanceThreshold: (e: any) => void,
    onWaitChargePerThreshold: (e: any) => void,
    onWaitThreshold: (e: any) => void,
    onFreeDistance: (e: any) => void,
}


export function ChargesFarePost({
    onAppBookingCharge, onDispatchBookingCharge, onDistanceChargePerThreshold, onDistanceThreshold,
    onWaitChargePerThreshold, onWaitThreshold, onFreeDistance
}: Props) {

    const { t } = useTranslation(['forms', 'main']);
    const childProps = useFormContext();
    const settings: Settings | undefined = SettingsService.getSetting();
    const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).code : undefined;

    return (
        <FormSection
            title={t("fare.chargesSection.title")}
            subtitle={t("fare.chargesSection.subTitle")}
        >
            <div className="row">
                <FormInput
                    name="startCharge"
                    label={t("fare.chargesSection.startCharge")}
                    placeholder={currency}
                    type="number"
                    step={0.01}
                    className="col-sm-6"
                    tabIndex={5}
                    validation={{ required: true }}
                    {...childProps}
                />
                <FormInput
                    name="minimumCharge"
                    label={t("fare.chargesSection.minCharge")}
                    placeholder={currency}
                    type="number"
                    step={0.01}
                    className="col-sm-6"
                    tabIndex={6}
                    validation={{ required: true }}
                    {...childProps}
                />
            </div>
            <div className="row">
                <FormInput
                    name="appBookingCharge"
                    label={t("fare.chargesSection.appBookingFee")}
                    placeholder={currency}
                    type="number"
                    step={0.01}
                    className="col-sm-6"
                    onChange={onAppBookingCharge}
                    tabIndex={7}
                    validation={{ required: true }}
                    {...childProps}
                />
                <FormInput
                    name="dispatchBookingCharge"
                    label={t("fare.chargesSection.dispatchBookingFee")}
                    placeholder={currency}
                    type="number"
                    step={0.01}
                    className="col-sm-6"
                    onChange={onDispatchBookingCharge}
                    tabIndex={8}
                    validation={{ required: true }}
                    {...childProps}
                />
            </div>
            <div className="row">
                <FormInput
                    name="distanceChargePerThreshold"
                    label={t("fare.chargesSection.distanceCharge")}
                    placeholder={currency}
                    type="number"
                    step={0.001}
                    className="col-sm-6"
                    onChange={onDistanceChargePerThreshold}
                    tabIndex={9}
                    validation={{ required: true }}
                    {...childProps}
                />
                <FormInput
                    name="distanceThreshold"
                    label={t("fare.chargesSection.distanceThreshold")}
                    placeholder="Meters"
                    type="number"
                    step={0.01}
                    className="col-sm-6"
                    onChange={onDistanceThreshold}
                    tabIndex={10}
                    validation={{ required: true }}
                    {...childProps}
                />
            </div>
            <div className="row">
                <FormInput
                    name="waitChargePerThreshold"
                    label={t("fare.chargesSection.waitCharge")}
                    placeholder={currency}
                    type="number"
                    step={0.001}
                    className="col-sm-6"
                    onChange={onWaitChargePerThreshold}
                    tabIndex={11}
                    validation={{ required: true }}
                    {...childProps}
                />
                <FormInput
                    name="waitThreshold"
                    label={t("fare.chargesSection.waitThreshold")}
                    placeholder="Seconds"
                    type="number"
                    step={0.01}
                    className="col-sm-6"
                    onChange={onWaitThreshold}
                    tabIndex={12}
                    validation={{ required: true }}
                    {...childProps}
                />
            </div>
            <div className="row">
                <FormInput
                    name="freeDistance"
                    label={t("fare.chargesSection.freeDistance")}
                    placeholder="Meters"
                    type="number"
                    step={0.01}
                    className="col-sm-6"
                    onChange={onFreeDistance}
                    tabIndex={13}
                    validation={{ required: true }}
                    {...childProps}
                />
            </div>
        </FormSection>
    );
}
