import { IEntityBase, IEntityCreatedBy, IEntityTimestamp } from "../interfaces/entity.interface";
import { Address } from "./address.entity";
import { Passenger } from "./passenger.entity";
import { RequestCategory } from "./request-category.entity";
import { User } from "./user.entity";

export enum RequestStatus {
  New = 'new',
  Direct = 'direct',
  Assigned = 'assigned',
  Rejected = 'rejected',
  PassengerRejected = 'passenger-rejected',
  PassengerCompleted = 'passenger-completed',
  Completed = 'completed'
}

export interface Request extends IEntityBase, IEntityTimestamp, IEntityCreatedBy {
  [key: string]: any,
  code: string,
  message: string,
  category: RequestCategory | string,
  status: RequestStatus,
  passenger: Passenger | string,
  assignedBy: User | string,
  assignedTo: User | string,
  assignedAt?: Date | null,
  rejectedBy: User | string,
  completedBy: User | string,
  finalizedAt?: Date | null
}
