import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export enum SessionStatus {
  Active = "active",
  InActive = "inActive",
}

export interface Activity {
  code: string
  status: SessionStatus
  driver: string
  vehicle: string
  sessionTime: string | number
}

interface Props {
  title: string
  activities?: Array<Activity>
  color: string
}

export function DashboardSessionActivity(props: Props) {
  const { t } = useTranslation("dashboard");
  let history = useHistory();
  return (
    <div className="element-wrapper">
      <div className="element-box">
        <h6 className="element-header">
          <span className={`badge badge-${props.color}`}>{props?.activities?.length}</span> &nbsp;
          {props.title}
        </h6>
        <table className="table table-clean">
          <tbody>
            {props?.activities?.map(activity => (
              <tr onClick={() => {
                history.push(`/security/track-shift/${activity.code}`);
              }} style={{cursor:'pointer'}}>
                <td>
                  <div className="value">{t('session.driverCode')}</div>
                  <span className="sub-value">{activity.driver}</span>
                  <div className="value">{t('session.vehiclePlate')}</div>
                  <span className="sub-value">{activity.vehicle}</span>
                </td>
                <td className="text-right">
                  <div className="value">Session Time</div>
                  <span className="sub-value">{activity.sessionTime}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
