import React,{useState} from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Driver } from "../../../entities/driver.entity";
import { Group } from "../../../entities/group.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../enums/form.enum";
import { Endpoint } from '../../../services/api.service';
import { GroupRoute } from "../routes";
import { GroupPostBasic } from "./section/basic.component";

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: Group) => void;
}

/**
 * Add or update a group
 */
export function GroupPost({ style, cancel, formMode, entityId, onAfterSave }: Props) {
  const { t } = useTranslation('main');

  const [groupMembers, setGroupMembers] = useState <Driver[]>([]);
  const [leadDriver, setLeadDriver] = useState<Driver>();

  const onBeforeSave = (mode: FormMode, form: Group) => {
    console.log("Form On Before:",groupMembers,leadDriver)
    form.leadDriver = (leadDriver as Driver).id!;
    form.drivers = groupMembers.map((driver: any) => driver.id);

    delete form.driver; delete form.members;
  }
  const processData = (form: Group) => {
    console.log("Data Form: ", form, form.groupDrivers.filter((item: any) => {
      if (item.driver.id !== (form.leadDriver as Driver).id)
        return item.driver
    }).map((item: any) => item.driver));


    form.members = form.groupDrivers.filter((item: any) => {
      if (item.driver.id !== (form.leadDriver as Driver).id)
        return item.driver
    }).map((item: any) => item.driver);
    return form;
  }
  /**
 * Custom Header
 */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Title} title={t("entities.group")} />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Controls} />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={FormHeaderType.Title} title={t("entities.group")} />
    )
  );

  /**
   * Render
   */
  return (
    <Form<Group>
      endpoint={Endpoint.GROUP}
      returnLink={GroupRoute.List}
      cancel={cancel}
      formMode={formMode}
      entityId={entityId}
      processData={processData}
      onBeforeSave={onBeforeSave}
      onAfterSave={onAfterSave}
      className={style === FormStyle.Containerized && "ae-content-w"}
    >
      {style === FormStyle.Containerized && header()}
      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Title} title={t("entities.group")} />}
        <FormSections>
          <GroupPostBasic groupMembers={groupMembers} leadDriver={leadDriver} setLeadDriver={setLeadDriver} setGroupMembers={setGroupMembers}/>
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Controls} />}
      </SectionsWrapper>
    </Form>
  );
}
