import React from 'react';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { Subscriber } from '../../../entities/subscriber.entity';
import { useTranslation } from "react-i18next";

interface Props {
  selectedSubscriber: Subscriber
}


export function SelectedItemContent({
  selectedSubscriber
}: Props) {
  
  const { t } = useTranslation('forms');
  return(
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-12"
        title={ selectedSubscriber.title }
      >
        <ViewContentItems>
          <ViewContentItem title={ t("subscriber.basicSection.email") }>
            { selectedSubscriber.email }
          </ViewContentItem>
        </ViewContentItems>
      </ViewContent>
     
    </ContentWrapper>
  );
}