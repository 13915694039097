import React, { useCallback, useEffect, useState } from "react";
import { Loading } from "../../../../components/alerts/loading.component";
import { ListItem, ListItems } from "../../../../components/list/items.component";
import { List } from "../../../../components/list/wrapper.component";
import { View } from "../../../../components/view/wrapper.component";
import { Role } from "../../../../entities/role.entity";
import { FormMode, ModuleName } from "../../../../enums/core.enum";
import { FormStyle } from "../../../../enums/form.enum";
import { AclItem } from "../../../../interfaces/core.interface";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../../services/api.service';
import { UserRolePost } from "../post/post.component";
import { getAllAssignmentsGrouped } from "../role.util";
import { SelectedItemContent } from "./content.component";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "react-use";
import { useHistory } from "react-router-dom";

interface DisplayAssignment {
  id?: string;
  role?: Role,
  module: ModuleName,
  action: AclItem<unknown>,
  checked?: boolean,
  show?: boolean,
}

export type GroupedDisplayAssignment = { module: string, assignments: DisplayAssignment[] };

export function UserRoleList() {
  const { t } = useTranslation('list');
  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [roles, setRoles] = useState<Role[] | undefined>(undefined);
  const [selectedRole, setSelectedRole] = useState<Role>();
  const [selectedDisplayAssignments, setSelectedDisplayAssignments] = useState<GroupedDisplayAssignment[]>([]);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [size, setSize] = useState<number>()

  const refresh = useCallback(async () => {
    const { items, totalItems } = await Api.get<IPaginationResponse<Role>, IPaginationOptions>(Endpoint.USER_ROLE_LIST, { page: 0, limit, search });

    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items) {
      setRoles(items);
    }

    console.log('items: ', items)

    if (items.length > 0 && !selectedRole) {
      setSelectedRole(items[0]);
    }

    console.log('selected: ', selectedRole)

  }, [search, limit, selectedRole]);

  useEffect(() => { refresh(); }, []);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  useEffect(() => {
    if (!selectedRole || !selectedRole.assignments) {
      setSelectedDisplayAssignments([]);
      return;
    }
    const groupedAssignments = getAllAssignmentsGrouped();
    const groupedDisplayAssignments: GroupedDisplayAssignment[] = [];

    // @@TODO Group assignmetns by module
    // assignments.
    for (let module in groupedAssignments) {
      const assignments = groupedAssignments[module as ModuleName];
      if (!assignments) {
        continue;
      }
      const displayAssignments = [];
      for (let assignment of assignments) {
        for (let roleAssignment of selectedRole.assignments) {
          if (assignment.action.code === roleAssignment.action && module === roleAssignment.module) {
            assignment.show = true;
            assignment.checked = true;
            displayAssignments.push(assignment);
          }
        }
      }
      if (displayAssignments.length > 0) {
        groupedDisplayAssignments.push({
          module, assignments: displayAssignments,
        });
      }
    }

    setSelectedDisplayAssignments(groupedDisplayAssignments);
  }, [selectedRole]);

  const deleteRole = async () => {
    await Api.delete(Endpoint.USER_ROLE, { id: selectedRole?.id });
    setSelectedRole(undefined);
    refresh();
  }

  const toListItems = (role?: Role): ListItem<Role> | undefined => {
    if (!role) {
      return;
    }

    return {
      id: role.id as string,
      title: role.name,
      ref: role,
    };
  }

  const addRole = () => {
    setFormMode(FormMode.Adding);
  }

  const editRole = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
    refresh();
  }

  const onNext = () => {
    if (roles) {
      const currentRoleIndex = roles.findIndex(role => !!selectedRole && role.id === selectedRole.id);
      if (currentRoleIndex + 1 < roles.length && currentRoleIndex !== -1) {
        setSelectedRole(roles[currentRoleIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (roles) {
      const currentRoleIndex = roles.findIndex(role => !!selectedRole && role.id === selectedRole.id);
      if (currentRoleIndex - 1 > -1 && currentRoleIndex !== -1) {
        setSelectedRole(roles[currentRoleIndex - 1]);
      }
    }
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }


  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListItems
                items={roles?.map(toListItems) as ListItem<Role>[]}
                activeItem={toListItems(selectedRole)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setRoles(undefined); refresh(); }}
                onClick={(item: ListItem<Role>) => { setSelectedRole(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                roles !== undefined ? (
                  selectedRole ? (
                    selectedRole.isProtected === true ?
                      <View
                        componentName={"role"}
                        showAdd={true}
                        showEdit={true}
                        showNext={true}
                        showPrev={true}
                        // showAudit={true}
                        onAdd={addRole}
                        onEdit={editRole}
                        onPrev={onPrev}
                        onNext={onNext}
                      // onAudit={()=>checkAudit(selectedRole.id)}
                      >
                        <SelectedItemContent
                          selectedRole={selectedRole}
                          selectedDisplayAssignments={selectedDisplayAssignments}
                        />
                      </View> :
                      <View
                        componentName={"role"}
                        showAdd={true}
                        showEdit={true}
                        showDelete={true}
                        showAudit={true}
                        showNext={true}
                        showPrev={true}
                        onDelete={deleteRole}
                        onAdd={addRole}
                        onEdit={editRole}
                        onPrev={onPrev}
                        onNext={onNext}
                        onAudit={() => checkAudit(selectedRole.id)}
                      >

                        <SelectedItemContent
                          selectedRole={selectedRole}
                          selectedDisplayAssignments={selectedDisplayAssignments}
                        />
                      </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button onClick={addRole} className="btn btn-primary text-bold">{t("role.addRole")}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t("role.wait")}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedRole ? (
                  <UserRolePost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedRole.id}
                  />
                ) : (
                  <UserRolePost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListItems
            items={roles?.map(toListItems) as ListItem<Role>[]}
            activeItem={toListItems(selectedRole)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setRoles(undefined); refresh(); }}
            onClick={(item: ListItem<Role>) => { setSelectedRole(item.ref); }}
          />

          {formMode === undefined ? (
            roles !== undefined ? (
              selectedRole ? (
                selectedRole.isProtected === true ?
                  <View
                    componentName={"role"}
                    showAdd={true}
                    showEdit={true}
                    showNext={true}
                    showPrev={true}
                    // showAudit={true}
                    onAdd={addRole}
                    onEdit={editRole}
                    onPrev={onPrev}
                    onNext={onNext}
                  // onAudit={()=>checkAudit(selectedRole.id)}
                  >
                    <SelectedItemContent
                      selectedRole={selectedRole}
                      selectedDisplayAssignments={selectedDisplayAssignments}
                    />
                  </View> :
                  <View
                    componentName={"role"}
                    showAdd={true}
                    showEdit={true}
                    showDelete={true}
                    showAudit={true}
                    showNext={true}
                    showPrev={true}
                    onDelete={deleteRole}
                    onAdd={addRole}
                    onEdit={editRole}
                    onPrev={onPrev}
                    onNext={onNext}
                    onAudit={() => checkAudit(selectedRole.id)}
                  >

                    <SelectedItemContent
                      selectedRole={selectedRole}
                      selectedDisplayAssignments={selectedDisplayAssignments}
                    />
                  </View>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addRole} className="btn btn-primary text-bold">{t("role.addRole")}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t("role.wait")}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedRole ? (
              <UserRolePost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedRole.id}
              />
            ) : (
              <UserRolePost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
              />
            )
          )}
        </List>
      }
    </div>
  );

}
