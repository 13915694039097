import React from 'react';
import { CorporateUser } from '../../../../entities/corporate-user.entity';
import { ContentWrapper, ViewContent } from '../../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../../components/view/item.component';
import { Feature } from '../../../../entities/feature.entity';
import { capitalize } from '../../../../util/string.util';
import { useTranslation } from 'react-i18next';

interface Props {
  selectedCorporateUser: CorporateUser
}

export function SelectedItemContent({
  selectedCorporateUser
  
}: Props) {
  const { t } = useTranslation('forms');

  return(
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={ selectedCorporateUser.name }
      >
        <ViewContentItems>
        </ViewContentItems>
        <ViewContentItem title={t("corporateUser.personalSection.name")}>
                        {capitalize(selectedCorporateUser?.name)}
                    </ViewContentItem>

                    <ViewContentItem title={t("common.email")}>
                        <a href={`mailto:${selectedCorporateUser?.email}`}>{selectedCorporateUser?.email}</a>
                    </ViewContentItem>

                    <ViewContentItem title={t("common.phone")}>
                        <a href={`tel:${selectedCorporateUser?.phone}`}>{selectedCorporateUser?.phone}</a>
                    </ViewContentItem>

                    <ViewContentItem title={t("corporateUser.personalSection.corporateAccount")}>
                        {capitalize((selectedCorporateUser?.corporateAccount as any)?.companyName)}
                    </ViewContentItem>

                    <ViewContentItem title={t("corporateUser.personalSection.role")}>
                        {capitalize(selectedCorporateUser?.role)}
                    </ViewContentItem>

                 
      </ViewContent>
    </ContentWrapper>
  );
}