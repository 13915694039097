import { Acl } from "../../interfaces/core.interface";

export enum OperatorAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Approve = 'approve',
  Audit = 'audit'
}

export const OperatorAcl = [
  {
    code: OperatorAclCode.ListAll,
    title: 'operator.listAll.title',
    description: 'operator.listAll.subTitle',
  },
 
  {
    code: OperatorAclCode.ListOwn,
    title: 'operator.listOwn.title',
    description: 'operator.listOwn.subTitle',
  },
  {
    code: OperatorAclCode.Create,
    title: 'operator.create.title',
    description: 'operator.create.subTitle',
  },
  {
    code: OperatorAclCode.Update,
    title: 'operator.update.title',
    description: 'operator.update.subTitle',
  },
  {
    code: OperatorAclCode.Delete,
    title: 'operator.delete.title',
    description: 'operator.delete.subTitle',
  },
  {
    code: OperatorAclCode.Audit,
    title: 'operator.audit.title',
    description: 'operator.audit.subTitle',
  },
] as Acl<OperatorAclCode>;
