import { RouteProps } from "react-router-dom";
import { AuditList } from "./list/list.component";



export enum AuditRoute {
  Overview = '/audit/:id',
}

export const AuditRouteMap = [
  {
    path: AuditRoute.Overview,
    component: AuditList,
  }
] as RouteProps[];
