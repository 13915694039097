import { Currency } from "../entities/currency.entity";
import { Settings } from "../entities/settings.entity";
import { SettingsService } from "../services/settings.service";

export function toSubUnit (value: number, currency?:Currency): string{
  
  const settings: Settings | undefined = SettingsService.getSetting();
  let symbol = settings?.generalCurrency ? (settings.generalCurrency as Currency).symbol : ""
  let unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
    // if value is undefined return N/A
    if (value == undefined) {
      return "N/A";
    }

    if(currency){
      
      unit = currency ? (currency as Currency).subunits : 100;
      symbol = currency.symbol
    }
    
    // divide value by subunit
    let val: any = value / unit;
    val = Math.round((val + Number.EPSILON) * unit) / unit
    val = val.toFixed(Math.round((Math.log(unit) / Math.log(10))))
    
    // let nf = new Intl.NumberFormat('en-US');
    
    // Math.round((val + Number.EPSILON) * unit) / unit
    return `${symbol} ${val}`;
  }