import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Loading } from '../../../components/alerts/loading.component';
import { Fare } from '../../../entities/fare.entity';
import { Service } from '../../../entities/service.entity';
import { Zone } from '../../../entities/zone.entity';
import { IPaginationOptions, IPaginationResponse } from '../../../interfaces/paginate.interface';
import { Api, Endpoint } from '../../../services/api.service';
import { FareDashboardView } from './view.component';

interface Props {
  selectedZone: Zone | undefined,
  selectedService: Service | undefined,
  onChange?: (fareList: Fare[] | undefined) => void,
  onSelect?: (fare: Fare | undefined) => void,
}

interface FareListOptions {
  zone: Zone | string;
  service: Service | string;
}

/**
 * Displays fare list based on selected zones and services
 * Triggers onChange on fare list change
 * Triggers onSelect on fare selection change
 */
export function FareDashboardList({ selectedZone, selectedService, onChange, onSelect }: Props) {


  /**
   * List of loaded fares based on selected zone and service
   */
  const [ fareList, setFareList ] = useState<Fare[]>();

  /**
   * Selected fare
   */
  const [ selectedFare, setSelectedFare ] = useState<Fare>();

  /**
   * Determines if fare list is loading
   */
  const [ loading, setLoading ] = useState<boolean>(false);

  /**
   * Load fares
   */
  const loadFareList = useCallback(async () => {
    setLoading(true);
    const fareList = await Api.get<IPaginationResponse<Fare>, IPaginationOptions & FareListOptions>(Endpoint.FARE_LIST);
    setFareList(fareList.items);
    setLoading(false);
  }, [])
  
  /**
   * Refresh fare list upon zone or service change
   */
  useEffect(() => {
    loadFareList();
  }, [ loadFareList, selectedZone, selectedService ]);

  /**
   * Trigger onChange on fare list change
   */
  useEffect(() => {
    if (onChange) {
      onChange(fareList);
    }
  }, [ onChange, fareList ]);

  /**
   * Trigger onSelect when fare selection changes
   */
  useEffect(() => {
    if (onSelect) {
      onSelect(selectedFare);
    }
  }, [ onSelect, selectedFare ]);

  /**
   * When a fare is deleted, unselected current fare and refresh
   * fare list
   */
  const onDelete = () => {
    setSelectedFare(undefined);
    loadFareList();
  }

  /**
   * Render
   */

  const { t } = useTranslation('forms');
  if (!fareList || fareList.length === 0) {
    return (
      <div className="element-wrapper">
        <div className="element-box">
          <h2>{ t("fare.noFareSection.title") }</h2>
          <p>
            { t("fare.noFareSection.subTitle") }
            <Link to="/fare/post"> { t("fare.noFareSection.addNew") }</Link>
          </p>
        </div>
      </div>
    );
  }

  return (
    <ul className="ae-main-menu">
      <li className="header"><a href="fare-header"><span>{t("fare.title")}</span></a></li>
      <Loading className="loading" loading={ loading } />
      <FareDashboardView
        fare={ selectedFare }
        onDelete={ onDelete }
      />
      { fareList?.map(fare => (
        <li key={ fare.id } className={ classNames({ active: fare.id === selectedFare?.id })}>
          <a href="#fare" onClick={ () => { setSelectedFare(fare); }}>
            <i className="os-icon os-icon-arrow-right3" style={{ color: fare.color }}></i>
            <span>{ fare.title }</span>
          </a>
        </li>
      ))}
    </ul>
  );
}