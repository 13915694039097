import React from 'react';
import { useTranslation } from "react-i18next";
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { RequestCategory } from '../../../entities/request-category.entity';

interface Props {
  selectedRequestCategory: RequestCategory
}


export function SelectedItemContent({
  selectedRequestCategory
}: Props) {
  const { t } = useTranslation(['forms', 'dashboard']);

  return (
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={selectedRequestCategory.title}
      >
        <ViewContentItems>
          <ViewContentItem title={t("requestCategory.basicSection.title")}>
            {selectedRequestCategory.title}
          </ViewContentItem>
          <ViewContentItem title={t("requestCategory.basicSection.action")}>
            {selectedRequestCategory.action}
          </ViewContentItem>
          {selectedRequestCategory.target?<ViewContentItem title={t("requestCategory.basicSection.target")}>
            {selectedRequestCategory.target}
          </ViewContentItem>:null}    
          <ViewContentItem title={t("requestCategory.basicSection.position")}>
            {selectedRequestCategory.position}
          </ViewContentItem>
          <ViewContentItem title={t("requestCategory.basicSection.message")}>
            {selectedRequestCategory.message}
          </ViewContentItem>
        </ViewContentItems>
      </ViewContent>
    </ContentWrapper>
  );
}