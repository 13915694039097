import classNames from 'classnames';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

export enum ListItemStatus {
  Green = 'green',
  Yellow = 'yellow',
  Blue = 'blue',
  Red = 'red',
}

export interface ListItem<Entity> {
  id: string;
  title: string;
  imgSrc?: string;
  status?: ListItemStatus;
  ref: Entity;
  deleted?: any;
  onRemove?: () => void;
}

interface Props<Entity> {
  items?: ListItem<Entity>[];
  activeItem?: ListItem<Entity>;
  showSearch?: boolean;
  showRefresh?: boolean;
  hasMore?: boolean;
  fetchMoreData?: () => void
  onRefresh?: () => void,
  onSearchChange?: (search: string) => void,
  onClick?: (item: ListItem<Entity>) => void,
  onDoubleClick?: (item: ListItem<Entity>) => void,
  onSearchDoubleClick?: () => void;
  searchString?: string;
}

export function ListItems<Entity>({
  items, activeItem, showSearch, showRefresh, hasMore, searchString, fetchMoreData, onRefresh, onClick, onSearchChange, onSearchDoubleClick
}: Props<Entity>) {

  const search = useCallback(debounce((text: string) => onSearchChange && onSearchChange(text), 600), []);
  const { t } = useTranslation("main");
  const transalationList = useTranslation("list");

  return (
    <div className="ae-list-w">
      {(showRefresh || showSearch) && (
        <div className="ael-head ">
          {showSearch && (
            <div className="actions-left">
              <div className="input-search-w">
                <input
                  value={searchString}
                  className="form-control"
                  placeholder={`${t('entities.search')}...`}
                  type="search"
                  onChange={(e) => search((e.target as HTMLInputElement).value)}
                  onDoubleClick={onSearchDoubleClick}
                />
              </div>
            </div>
          )}
          {showRefresh && (
            <div className="actions-right">
              <a href="#refresh" onClick={onRefresh}><i className="os-icon os-icon-grid-18"></i></a>
            </div>
          )}
        </div>
      )}
      <div className="ae-list"
        style={{ height: 'auto' }}
      >
        {items && items?.length > 0 ?
          <InfiniteScroll
            dataLength={items.length}
            next={() => fetchMoreData && fetchMoreData()}
            hasMore={hasMore !== undefined && hasMore}
            loader={<h4>Loading...</h4>}
          >
            {items?.map(item => (
              <div key={item.id}
                className={classNames(
                  item?.deleted ? 'ae-item-deleted' : 'ae-item',
                  { active: activeItem?.id === item.id },
                  { 'with-status': !!item.status },
                  item.status ? `status-${item.status}` : null,
                )}
                onClick={() => { onClick && onClick(item); }}
              >
                {item.imgSrc && (
                  <div className="aei-image">
                    <div className="user-avatar-w"><img alt={item.title} src={item.imgSrc} /></div>
                  </div>
                )}
                <div className="flex-fill aei-content">
                  <h6 className="aei-title">{item.title}</h6>
                </div>
                {(item.onRemove && activeItem?.id == item.id) && (
                  <h6 onClick={item.onRemove} className='btn btn-secondary'>X</h6>
                )}
              </div>
            ))}</InfiniteScroll> : transalationList.t('common.noRecord')}
      </div>
    </div>
  );
}