import React from 'react';
import { useTranslation } from "react-i18next";
import { Form } from "../../../../components/form/form.component";
import { FormHeader } from "../../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../../components/form/sections.component";
import { Incident } from "../../../../entities/incident.entity";
import { FormMode } from "../../../../enums/core.enum";
import { FormHeaderType } from '../../../../enums/form.enum';
import { Endpoint } from '../../../../services/api.service';
import { SecurityRoute } from '../../routes';
import IncidentPostBasic from "./section/basic.component";

export enum FormStyle {
  Containerized = "Containerized",
}

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: Incident) => void;
}

/**
 * Add or update a incident
 */
export default function IncidentPost({
  style, cancel, formMode, entityId, onAfterSave
}: Props) {
  const { t } = useTranslation('main');

  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Title } title={ t("entities.incident") } />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Controls } />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={ FormHeaderType.Title } title={ t("entities.incident") } />
    )
  );

  return (
    <Form<Incident>
      endpoint={ Endpoint.INCIDENT }
      returnLink={ SecurityRoute.IncidentList }
      cancel={ cancel }
      formMode={ formMode }
      entityId={ entityId }
      onAfterSave={ onAfterSave }
      className={ style === FormStyle.Containerized && "ae-content-w" }
    >
      { style === FormStyle.Containerized && header() }
      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Title } title={ t("entities.incident")} /> }
        <FormSections>
          <IncidentPostBasic />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Controls } /> }
      </SectionsWrapper>
    </Form>
  );
}
