import { FieldError } from "react-hook-form";

export const getErrorMessage = (error:FieldError, label: string):string => {
  if (!error) {
    return '';
  }
  if (error.message) {
    return error.message as string;
  }
  switch (error.type) {
    case 'required':
      return `${label} is required`;
    case 'email':
      return 'Invalid email address';
  }
  return '';
}

const storageUrl = `${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}`;
export const getUrl = (path: string): string => {
  return `${storageUrl}${path}`;
}
