import React from 'react';
import bigImg from './../../../img/video-img.jpg';
import smallImg1 from './../../../img/video-img2.jpg';
import smallImg2 from './../../../img/video-img3.png';
import smallImg3 from './../../../img/video-img4.png';
import { MonitoringFilters } from './filters.component';

export function VideoMonitoring() {
  return (
    <>
      <div>
        <MonitoringFilters /> 
      </div>
      <div className="gallery">
        <figure className="gallery__item gallery__item--1">
          <img src={smallImg1} alt="Gallery 1" className="gallery__img" />
        </figure>
        <figure className="gallery__item gallery__item--2">
          <img src={bigImg} alt="Gallery 2" className="gallery__img" />
        </figure>
        <figure className="gallery__item gallery__item--3">
          <img src={smallImg2} alt="Gallery 3" className="gallery__img" />
        </figure>
        <figure className="gallery__item gallery__item--4">
          <img src={smallImg3} alt="Gallery 4" className="gallery__img" />
        </figure>
      </div>
    </>
  )
}


