import { RouteProps } from "react-router-dom";
import { DriverList } from "./list/list.component";
import { DriverPost } from "./post/post.component";

export enum DriverRoute {
  List = '/driver/list',
  Post = '/driver/post',
}

export const DriverRouteMap = [
  {
    path: DriverRoute.List,
    component: DriverList,
  },
  {
    path: DriverRoute.Post,
    component: DriverPost,
  },
] as RouteProps[];
