import { IEntityBase, IEntityCreatedBy, IEntityTimestamp } from "../interfaces/entity.interface";
import { Feature } from "./feature.entity";
import { IFile } from "./file.entity";
import { Fleet } from "./fleet.entity";
import { Inspection } from "./inspection.entity";
import { Network } from "./network.entity";
import { Service } from "./service.entity";
import { VehicleCheckList } from "./vehicleCheckList.entity copy";

export enum VehicleStatus {
  New = 'new',
  Pending = 'pending',
  Failed = 'failed',
  Active = 'active',
  Blocked = 'blocked',
}

export enum VehicleOwnership {
  Owned = 'owned',
  Leased = 'leased',
}

export enum VehicleFileType {
  Pic = 'plate',
  Profile = 'profile',
  Registration = 'registration',
  Insurance = 'insurance'
}

interface DropzoneFile extends File {
  dataUrl?: string;
}

export interface Vehicle extends IEntityBase, IEntityTimestamp, IEntityCreatedBy {
  [key: string]: any; 
  vin: string;
  plate: string;
  year: number;
  make: string;
  model: string;
  color: string;
  registrationExpiryDate?: Date | string;
  numPassengers: number;
  numLuggage: number;
  features: Feature[];
  fleets: Fleet[];
  networks: Network[];
  services: Service[];
  plateFile?: IFile;
  newPlateFile?: DropzoneFile;
  vehicleCheckList?:VehicleCheckList[];
  profileFiles?: IFile[];
  newProfileFiles?: DropzoneFile[];

  insuranceFiles?:IFile[];
  newInsuranceFiles?: DropzoneFile[];
 
  registrationFiles?:IFile[];
  newRegistrationFiles?: DropzoneFile[];
  deletedTime: Date;
  ownership: VehicleOwnership;
  status?: VehicleStatus;
}