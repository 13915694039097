import { ModuleName } from "../../../enums/core.enum";
import { AclCode } from "../../../interfaces/core.interface";
import { AclService } from "../../../services/acl.service";
import { DisplayAssignment } from "./role.interface";

export const getAllAssignments = (showModule?: ModuleName): DisplayAssignment[] => {
  const items: DisplayAssignment[] = [];
  const modules = AclService.getModules();
  for (let module of modules) {
    const actions = AclService.getModuleActions<AclCode>(module);
    if (!actions) {
      continue;
    }
    for (let action of actions) {
      items.push({
        role: undefined,
        module,
        action,
        checked: false,
        show: showModule ? module === showModule : true,
      });
    }
  }
  return items;
}

type GroupedAssignments = { [key in ModuleName]?: DisplayAssignment[] };
export const getAllAssignmentsGrouped = (showModule?: ModuleName): GroupedAssignments => {
  const assignments = getAllAssignments(showModule);
  const modules = AclService.getModules(); 
  let items: GroupedAssignments = {};

  for (let module of modules) {
    const moduleAssignments = [];
    for (let assignment of assignments)  {
      if (assignment.module === module) {
        moduleAssignments.push(assignment);
      }
    }
    items[module] = moduleAssignments;
  }

  return items;
}
