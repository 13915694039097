import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { SectionsWrapper } from "../../../components/form/sections.component";
import { Subscriber } from "../../../entities/subscriber.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../enums/form.enum";
import { Endpoint } from '../../../services/api.service';
import { SubscriberRoute } from "../routes";


interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: Subscriber) => void;
}

/**
 * Add or update a subscriber
 */
export function SubscriberPost({
  style, cancel, formMode, entityId, onAfterSave,
}: Props) {

  const { t } = useTranslation('main');

  /**
 * Custom Header
 */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Title} title={t("entities.subscriber")} />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Controls} />
        </SectionsWrapper>
      </>
    ) : (
        <FormHeader type={FormHeaderType.Title} title={t("entities.subscriber")} />
      )
  );

  /**
   * Render
   */
  return (
    <Form<Subscriber>
      endpoint={Endpoint.SUBSCRIBER}
      returnLink={SubscriberRoute.List}
      formMode={formMode}
      entityId={entityId}
      onAfterSave={onAfterSave}
      className={style === FormStyle.Containerized && "ae-content-w"}
    >
    </Form>
  );
}
