import React, { PropsWithChildren } from 'react';

export function List({ children }: PropsWithChildren<{}>) {
  return (
    <div className="app-email-w forse-show-content">
      <div className="app-email-i">
        { children }
      </div>
    </div>
  );
}
