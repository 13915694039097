import { Acl } from "../../interfaces/core.interface";

export enum GroupAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Approve = 'approve',
  Audit ='audit'
}

export const GroupAcl = [
  {
    code: GroupAclCode.ListAll,
    title: 'group.listAll.title',
    description: 'group.listAll.subTitle',
  },
  {
    code: GroupAclCode.ListOwn,
    title: 'group.listOwn.title',
    description: 'group.listOwn.subTitle',
  },
  {
    code: GroupAclCode.Create,
    title: 'group.create.title',
    description: 'group.create.subTitle',
  },
  {
    code: GroupAclCode.Update,
    title: 'group.update.title',
    description: 'group.update.subTitle',
  },
  {
    code: GroupAclCode.Delete,
    title: 'group.delete.title',
    description: 'group.delete.subTitle',
  },
  {
    code: GroupAclCode.Audit,
    title: 'group.audit.title',
    description: 'group.audit.subTitle',
  },
] as Acl<GroupAclCode>;
