import { RouteProps } from "react-router-dom";
import { NetworkList } from "./list/list.component";
import { NetworkPost } from "./post/post.component";

export enum NetworkRoute {
  List = '/network/list',
  Post = '/network/post',
}

export const NetworkRouteMap = [
  {
    path: NetworkRoute.List,
    component: NetworkList,
  },
  {
    path: NetworkRoute.Post,
    component: NetworkPost,
  },
] as RouteProps[];
