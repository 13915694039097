import {
  IEntityBase,
  IEntityCreatedBy,
  IEntityTimestamp,
} from '../interfaces/entity.interface';
import { Address } from './address.entity';
import { OperatorConsent } from './operatorConsent.entity';

export enum OperatorStatus {
  Active = 'active',
  Blocked = 'blocked',
}

export interface Operator
  extends IEntityBase,
    IEntityTimestamp,
    IEntityCreatedBy {
  [key: string]: any;
  title: string;
  password: string;
  contactPerson: string;
  contactPhone: string;
  contactEmail: string;
  consent: OperatorConsent;
  contactAddress: Address;
  businessRegistration: string;
  accreditionNumber?: string;
  bankSwiftCode?: string;
  status?: OperatorStatus;
}
