import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { ViewContent } from "../../../components/view/content.component";
import { ViewContentItem } from "../../../components/view/item.component";
import { Currency } from "../../../entities/currency.entity";
import { Fare } from "../../../entities/fare.entity";
import { Settings } from "../../../entities/settings.entity";
import { Zone } from "../../../entities/zone.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../enums/form.enum";
import { Api, Endpoint } from '../../../services/api.service';
import { SettingsService } from "../../../services/settings.service";
import { FareRoute } from "../routes";
import { BasicFarePost } from "./section/basic.component";
import { CalculatorFarePost } from "./section/calculator.component";
import { ChargesFarePost } from "./section/charges.component";

export interface RideType {
  id: string;
  name: string;
}

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: Fare) => void;
}
/**
 * Add or update a fare
 */
export function FarePost({
  style, cancel, formMode, entityId, onAfterSave,
}: Props) {

  let [calculateDistance, setCalculateDistance] = useState(0);
  let [calculateRideType, setCalculateRideType] = useState('Hail');
  let [calculateWaitTime, setCalculateWaitTime] = useState(0);
  const [appBookingCharge, setAppBookingCharge] = useState<any>(0);
  const [dispatchBookingCharge, setDispatchBookingCharge] = useState<any>(0);
  const [distanceChargePerThreshold, setDistanceChargePerThreshold] = useState(0);
  const [distanceThreshold, setDistanceThreshold] = useState(0);
  const [freeDistance, setFreeDistance] = useState(0);
  const [waitChargePerThreshold, setWaitChargePerThreshold] = useState(0);
  const [waitThreshold, setWaitThreshold] = useState(0);
  const [discount, setDiscount] = useState<any>(0)
  const [discountType, setDiscountType] = useState<string>('percentage')
  const [extraCharge, setExtraCharge] = useState<any>(0)
  const [selectedZone, setSelectedZone] = useState<Zone>(undefined!)
  const [subUnit, setSubUnit] = useState<number>()

  const settings: Settings | undefined = SettingsService.getSetting();


  const { t } = useTranslation(['forms', 'main']);

  const toSuperUnit = (value: number): number => {
    const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;

    return Math.round(value * unit);
  }

  const integerize = (value: string | number): number => {
    if (typeof value === 'number') {
      value = value.toString();
    }
    return parseInt(value, 10);
  }

  const toSubUnit = (value: number): number => {
    const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;

    return value / unit;

  }

  const onAppBookingCharge = (e: any) => {
    setAppBookingCharge(e.target.value)
  }

  const onDispatchBookingCharge = (e: any) => {
    setDispatchBookingCharge(e.target.value)
  }

  const onDistanceChargePerThreshold = (e: any) => {
    setDistanceChargePerThreshold(e.target.value)
  }

  const onDistanceThreshold = (e: any) => {
    setDistanceThreshold(e.target.value)
  }

  const onFreeDistance = (e: any) => {
    setFreeDistance(e.target.value)
  }

  const onWaitChargePerThreshold = (e: any) => {
    setWaitChargePerThreshold(e.target.value)
  }

  const onWaitThreshold = (e: any) => {
    setWaitThreshold(e.target.value)
  }

  const onDistanceChange = (e: any) => {
    setCalculateDistance(e.target.value)
  }

  const onWaitTimeChange = (e: any) => {
    setCalculateWaitTime(e.target.value)
  }

  const onRideTypeChange = (e: any) => {
    setCalculateRideType(e.target.value)
  }

  const onDiscountTypeChange = (e: any) => {
    setDiscountType(e.target.value)
  }

  const onDiscountChange = (e: any) => {
    setDiscount(e.target.value)
  }

  const onExtraChange = (e: any) => { 
    setExtraCharge(e.target.value)
  }

  const refresh = useCallback(async () => {
    const response = await Api.get<Zone, null>(Endpoint.ZONE_SELECTED)
    if (response) {
      setSelectedZone(response)
    }
  }, [])

  useEffect(()=>{
    refresh()
  },[])

  const calculateFare = (): number => {
    // console.log('selectedZone:', selectedZone?.meterVAT)
    if (!calculateRideType || !(calculateDistance || calculateWaitTime)) {
      return 0;
    }

    let netCalculateDistance = calculateDistance - freeDistance;
    if (netCalculateDistance < 0) {
      netCalculateDistance = 0;
    }

    let calculatedFare = 0;
    const meterTax = calculatedFare * ((selectedZone?.meterTax / 100) / 100) 
    const ccCharges = (calculatedFare + selectedZone?.meterTax) * selectedZone?.paymentCCChargeValue
    const vat = selectedZone?.meterVAT 

    calculatedFare = calculatedFare + meterTax + ccCharges + vat + (selectedZone?.levy)
    console.log('selectedZone:', selectedZone?.levy)
    if (calculateRideType === 'App') {
      calculatedFare = calculatedFare + parseInt(appBookingCharge);
    } else if (calculateRideType === 'Dispatch') {
      calculatedFare += parseInt(dispatchBookingCharge);
    }

    if (distanceChargePerThreshold && distanceThreshold) {
      calculatedFare += Math.floor(netCalculateDistance / distanceThreshold) * distanceChargePerThreshold;
    }

    if (waitChargePerThreshold && waitThreshold) {
      calculatedFare += Math.floor(calculateWaitTime / waitThreshold) * waitChargePerThreshold;
    }

    if (discount && discountType === 'amount') {
      calculatedFare = calculatedFare - parseInt(discount)
    }

    if (discount && discountType === 'percentage') {
      const percentageDiscount = (calculatedFare / 100) * parseInt(discount)
      calculatedFare = calculatedFare - percentageDiscount
    }

    if (extraCharge) {
      calculatedFare = calculatedFare + parseInt(extraCharge)
    } 

    return calculatedFare;
  }

  useEffect(() => {
    calculateFare()
  }, [calculateFare, calculateDistance, calculateWaitTime, freeDistance, calculateRideType, waitThreshold, waitChargePerThreshold, distanceThreshold, distanceChargePerThreshold, appBookingCharge, dispatchBookingCharge])

  const onBeforeSave = (mode: FormMode, form: Fare) => {
    form.id = form.id || undefined;
    form.startCharge = toSuperUnit(form.startCharge);
    form.minimumCharge = toSuperUnit(form.minimumCharge);
    form.appBookingCharge = toSuperUnit(form.appBookingCharge);
    form.dispatchBookingCharge = toSuperUnit(form.dispatchBookingCharge);
    form.distanceChargePerThreshold = toSuperUnit(form.distanceChargePerThreshold);
    form.distanceThreshold = integerize(form.distanceThreshold);
    form.waitChargePerThreshold = toSuperUnit(form.waitChargePerThreshold);
    form.waitThreshold = integerize(form.waitThreshold);
    form.freeDistance = integerize(form.freeDistance);
  }

  const processData = (fare: Fare): Fare => {
    fare.startCharge = toSubUnit(fare.startCharge);
    fare.minimumCharge = toSubUnit(fare.minimumCharge);
    fare.appBookingCharge = toSubUnit(fare.appBookingCharge);
    fare.dispatchBookingCharge = toSubUnit(fare.dispatchBookingCharge);
    fare.distanceChargePerThreshold = toSubUnit(fare.distanceChargePerThreshold);
    fare.waitChargePerThreshold = toSubUnit(fare.waitChargePerThreshold);
    setAppBookingCharge(fare.appBookingCharge);
    setWaitChargePerThreshold(fare.waitChargePerThreshold);
    setDistanceThreshold(fare.distanceThreshold);
    setDistanceChargePerThreshold(fare.distanceChargePerThreshold);
    setDispatchBookingCharge(fare.dispatchBookingCharge);
    setFreeDistance(fare.freeDistance);
    setWaitThreshold(fare.waitThreshold)
    return fare;
  }

  /**
   * Render
   */
  return (
    <Form<Fare>
      endpoint={Endpoint.FARE}
      returnLink={FareRoute.List}
      onBeforeSave={onBeforeSave}
      cancel={cancel}
      formMode={formMode}
      entityId={entityId}
      onAfterSave={onAfterSave}
      processData={processData}
      className={style === FormStyle.Containerized && "ae-content-w"}
    >
      <SectionsWrapper>
        <FormHeader type={FormHeaderType.Title} title={t("main:entities.fare")} />
        <FormSections>
          <BasicFarePost />
          <ChargesFarePost
            onAppBookingCharge={onAppBookingCharge}
            onDispatchBookingCharge={onDispatchBookingCharge}
            onDistanceChargePerThreshold={onDistanceChargePerThreshold}
            onDistanceThreshold={onDistanceThreshold}
            onFreeDistance={onFreeDistance}
            onWaitChargePerThreshold={onWaitChargePerThreshold}
            onWaitThreshold={onWaitThreshold}
          />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper>
        <FormHeader type={FormHeaderType.Controls} />
        <FormSections>
          <CalculatorFarePost
            rideType={calculateRideType}
            onDistanceChange={onDistanceChange}
            onRideTypeChange={onRideTypeChange}
            onWaitTimeChange={onWaitTimeChange}
            onDiscountChange={onDiscountChange}
            onDiscountTypeChange={onDiscountTypeChange}
            onExtraChange={onExtraChange}
          />
        </FormSections>
        <ViewContent
          className="col-md-6"
          title={''}
        >
          <ViewContentItem title={t('forms:fare.calculatorSection.calculateFare')}>
          {selectedZone?.generalCurrency.symbol} {calculateFare() / selectedZone?.generalCurrency.subunits}
          </ViewContentItem>
        </ViewContent>
      </SectionsWrapper>
    </Form>

  );
}