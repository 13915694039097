import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput } from '../../../../components/form/input/input.component';
import { FormSection } from '../../../../components/form/section.component';

export function BasicFarePost() {

    const { t } = useTranslation(['forms', 'main']);
    const childProps = useFormContext();

    return (
        <FormSection
            title={t("fare.basicSection.title")}
            subtitle={t("fare.basicSection.subTitle")}
        >
            <div className="row">
                <FormInput
                    name="title"
                    label={t("common.title")}
                    placeholder={t("fare.basicSection.titlePlaceholder")}
                    type="text"
                    maxLength={25}
                    className="col-sm-6"
                    tabIndex={1}
                    autoFocus
                    validation={{ required: true }}
                    {...childProps}
                />
                <FormInput
                    name="color"
                    label={t("common.color")}
                    type="color"
                    className="col-sm-6"
                    tabIndex={2}
                    validation={{ required: true }}
                    {...childProps}
                />
            </div>
        </FormSection>
    );
}