import { Group } from "react-dropdown";
import { Address } from "./address.entity";
import { CorporateAccount } from "./corporate-account.entity";
import { CorporateUser } from "./corporate-user.entity";
import { Driver } from "./driver.entity";
import { Feature } from "./feature.entity";
import { Passenger } from "./passenger.entity";
import { RepeatBooking } from "./repeatBooking.entity";
import { Service } from "./service.entity";
import { Session } from "./session.entity";
import { DiscountType, TripType } from "./trip.entity";
import { User } from "./user.entity";
import { Vehicle } from "./vehicle.entity";
import { Zone } from "./zone.entity";

export enum BookingType {
    App = "app",
    Dispatch = "dispatch",
    IVR = "ivr",
    Website = "website"
}
export enum BookingRate {
    Meter = "meter",
    Flat = "flat"
}
export enum BookingStatus {
    Pickup = 'pickup',
    Dropoff = 'dropoff',
    Bidding = 'bidding',
    Abandoned = 'abandoned',
    ScheduledPendingLastAttempt = 'scheduled-pending-last-attempt',
    ScheduledPending = 'scheduled-pending',
    ScheduledTaken = 'scheduled-taken',
    ScheduledDriverEnroute = 'scheduled-driver-enroute',
    ScheduledTakenCancelled = 'scheduled-taken-cancelled',
    DriverCancelled = 'driver-cancelled',
    MeterPaused = 'meter-paused',
    PassengerCancelled = 'passenger-cancelled',
    DispatchCancelled = 'dispatch-cancelled',
    NoDriver = 'no-driver',
    AwaitingAccept = 'awaiting-accept',
    NotAccepted = 'not-accepted',
    DriverEnroute = 'driver-enroute',
    DestinationEnroute = 'destination-enroute',
    Arrived = 'arrived',
    Completed = 'completed',
}

export enum DriverNotificationStatus {
    None = "none",
    Waiting = "waiting",
    BookingTaken = "booking-taken",
    DriverConfirmationSent = "driver-confirmation-sent",
    StartRideNotificationSent = "start-ride-notification-Sent"
}

export enum PassengerNotificationStatus {
    None = "none",
    Waiting = "waiting",
    PassengerConfirmationSent = "passenger-confirmation-sent"
}

export interface Booking {
  [key: string]: any;
  id: string;
  passenger: Passenger | string;
  service: Service | string;
  features: Feature[] | string[];
  pickupAddress: Address;
  dropOffAddress: Address;
  corporateAccount?: CorporateAccount | string;
  corporateUser?: CorporateUser | string;
  repeatBooking?: RepeatBooking | string;
  zone: Zone | string;
  estimate?: number;
  peerId?: string;
  tip: number;
  code: string;
  qrScanned: Boolean;
  isCorporate: Boolean;
  type: BookingType;
  rate: BookingRate;
  preferredGroup?: Group | string;
  contactedDrivers: Driver[];
  distance: number;
  duration: string;
  instruction?: string;
  status: BookingStatus;
  bookingType?: TripType;
  discountType?: DiscountType;
  fixedFare?: number;
  extra?: number;
  discount: number;
  driverNotificationStatus: DriverNotificationStatus;
  passengerNotificationStatus: PassengerNotificationStatus;
  scheduledTimestamp: Date;
  createTime: Date;
  updateTime: Date;
  createdBy: User | string;
  session: Session | string;
  reservedByDriver: Driver | string;
  driver: Driver | string;
  vehicle: Vehicle | string;
  owner: User | string;
  deletedTime: Date;
}
