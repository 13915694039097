import { Group } from "./group.entity";
import { User } from "./user.entity";

export enum PassengerStatus {
  Active = 'active',
  Blocked = 'blocked',
  Unverified = 'unverified',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
}

export interface Passenger {
  id: string;
  email: string;
  phone: string;
  password: string;
  firstName: string;
  lastName: string;
  gender: string;
  status: PassengerStatus;
  settingNotifyScheduleAccepted: Boolean;
  settingNotifyReceipt: Boolean;
  settingNotifyMeterStart: Boolean;
  settingNotifyDriverArriving: Boolean;
  settingNotifyDriverAccept: Boolean;
  settingNotifyDisableAll: Boolean;
  dateOfBirth: Date;
  googleID: string;
  facebookID: string;
  appleID: string;
  stripeCustomerID: string;
  defaultCard: string;
  recoveryCode: number;
  verificationCode: number;
  verificationCodeValidity: Date;
  createTime: Date;
  updateTime: Date;
  deletedTime: Date;
  createdBy: User | string;
  owner: User | string;
  groups: Group[];

  // COMMON ATTRIBUTES
  [key: string]: any
}
