import { Acl } from "../../interfaces/core.interface";

export enum ServiceAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete', 
  Audit = 'audit',
  setDefault = 'set-default'
}

export const ServiceAcl = [
  {
    code: ServiceAclCode.ListAll,
    title: 'service.listAll.title',
    description: 'service.listAll.subTitle',
  },
  {
    code: ServiceAclCode.ListOwn,
    title: 'service.listOwn.title',
    description: 'service.listOwn.subTitle',
  },
  {
    code: ServiceAclCode.Create,
    title: 'service.create.title',
    description: 'service.create.subTitle',
  },
  {
    code: ServiceAclCode.Update,
    title: 'service.update.title',
    description: 'service.update.subTitle',
  },
  {
    code: ServiceAclCode.Delete,
    title: 'service.delete.title',
    description: 'service.delete.subTitle',
  },
  {
    code: ServiceAclCode.Audit,
    title: 'service.audit.title',
    description: 'service.audit.subTitle',
  }, 
  {
    code: ServiceAclCode.setDefault,
    title: 'service.setDefault.title',
    description: 'service.setDefault.subTitle',
  },
] as Acl<ServiceAclCode>;
