import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormTextArea } from "../../../components/form/input/textarea.component";
import { FormSection } from "../../../components/form/section.component";

interface Props {
  shiftCheckListDescription:string|undefined
}
export function ShiftCheckListDescription(props: Props) {
  const { t } = useTranslation("forms");
  const formProps = useFormContext();

   const [shiftCheckListdescription, setShiftCheckListdescription] = useState<string>(props?.shiftCheckListDescription?props.shiftCheckListDescription:'');

  useEffect(
    () => {
      setShiftCheckListdescription(props?.shiftCheckListDescription?props?.shiftCheckListDescription:'')
    },
    [ props])

  return (
    <FormSection>
         <FormTextArea
            name={`shiftCheckListdescription`}
            label='Description'
            onchange={(e:any)=>setShiftCheckListdescription(e.target.value)}
            value={shiftCheckListdescription}
            validation={{ required: false }}
            rows={5}
            {...formProps}
          />
          </FormSection>
  );
}
