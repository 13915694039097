import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { default as EmergencyFunctions, default as HandlerRequests } from '../../components/emergency/emergency.functions';
import { Emergency, EmergencyStatus } from '../../entities/emergency.entity';
import cctv from '../../img/cctv.png';
import infoLogo from '../../img/info-emergency.png';
import { Loading } from '../alerts/loading.component';


interface Props {
    request: Emergency,
    marginBottom: number,
    type: "ADMIN SIDE" | "DRIVER SIDE",
    activateRequest: (id: string) => void;
}
let req;

const FloatingButton = ({type = "DRIVER SIDE", ...props}: Props) => {
    const { push: navigateTo } = useHistory();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation("main");

    return (
        <div style={{ display: props.request.level == "high" ? "block": props.request.visible ? "block": "none" }}>
            {type === "DRIVER SIDE" && (
                <>
                    {props.request.level === "high" && (
                        <div className="emergency-floating-button" style={{
                            bottom: props.marginBottom

                        }} onClick={() => props.activateRequest(props.request.id)}>
                            <div className="emergency-icon">
                                <img src={infoLogo} alt="info-emergency-logo" className="emergency-image" />
                            </div>
                            <div className="emergency-name">
                                EMERGENCY from { props.request.type}
                                <br></br>Level : EMERGENCY
                            </div>
                        </div>
                    )}
                    {props.request.level === "medium" && (
                        <div className="monitoring-floating-button" style={{
                            bottom: props.marginBottom
                        }} onClick={() => props.activateRequest(props.request.id)}>
                            <div className="emergency-icon">
                                <img src={infoLogo} alt="info-emergency-logo" className="emergency-image" />
                            </div>
                            <div className="emergency-name">
                                {t('mainMenu.security.monitor')} {props.request.type} 
                                <br></br>Level : {t('mainMenu.security.monitor')}
                            </div>
                        </div>
                    )}
                    {props.request.level === "low" && (
                        null
                    )}
                </>
            )}
            {type === "ADMIN SIDE" && 
            (
                <div className="monitoring-floating-button" style={{
                    bottom: props.marginBottom,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }} onClick={async () => {
                    setLoading(true);
                    const response = await HandlerRequests.postEmergencyRequest(props.request);
                    if (response) {
                        HandlerRequests.ActivateRequest(response as Emergency);       
                        if(!(window.location.pathname=='/emergency'))
                      {  console.log('Inside Emergency Navigation')
                        navigateTo(`/emergency`);}
                    }
                    setLoading(false);
                }}>
                    {loading && (
                        <Loading loading={true} />
                    )}
                    {!loading && (
                        <>
                            <div className="emergency-name" style={{ marginTop: '4%', fontSize: '15px' }}>
                            {t('mainMenu.security.monitor')} {t('mainMenu.security.adminSide')}
                                <p style={{ fontSize: '12px' }}>{props.request.driver.firstName}</p>
                            </div>
                            <div className="emergency-icon">
                                <img src={cctv} alt="info-emergency-logo" className="emergency-image" />
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}
export default FloatingButton;
