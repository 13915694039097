import { RouteProps } from "react-router-dom";
import { ServicePost } from "./post.component";
import { ServiceList } from "./list/list.component";

export enum ServiceRoute {
  Post = '/service/post',
  List = '/service/list'
}

export const ServiceRouteMap = [
  {
    path: ServiceRoute.List,
    component: ServiceList,
  },
  {
    path: ServiceRoute.Post,
    component: ServicePost,
  }
] as RouteProps[];
