import { ModuleName } from "../../enums/core.enum";
import { Module } from "../../interfaces/core.interface";
import { ZoneAcl, ZoneAclCode } from "./acl";
import { ZoneRouteMap } from "./routes";

export const ZoneModule = {
  name: ModuleName.Zone,
  acl: ZoneAcl,
  routes: ZoneRouteMap,
} as Module<ZoneAclCode>;
