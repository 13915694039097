import { Settings } from "../entities/settings.entity";
import { SettingsService } from "../services/settings.service";

export function to12HrFormat(value: number): string {
  let hour24str = String(value).padStart(2, '0');
  hour24str =  `${hour24str}:00:00`;
  return new Date(`1970-01-01T${hour24str}Z`)
    .toLocaleTimeString(undefined,
      {
        timeZone:'UTC',
        hour12:true,
        hour:'numeric',
        minute:'numeric',
      }
    );
}


export function dateFormate(date: any){
  let settings: Settings | undefined = SettingsService.getSetting();
  if (!settings) {
    return new Date(date).toLocaleString();
  }
  else {
    return new Date(date).toLocaleString(undefined, { timeZone: "UTC" });   
  }
}