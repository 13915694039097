import { Acl } from "../../interfaces/core.interface";

export enum DispatchAclCode {
  View = 'View',
}

export const DispatchAcl = [
  {
    code: DispatchAclCode.View,
    title: 'dispatch.view.title',
    description: 'dispatch.view.subTitle',
  },
] as Acl<DispatchAclCode>;
