import { RouteProps } from "react-router-dom";
import { ZonePost } from "./post.component";
import { ZoneList } from "./list/list.component";

export enum ZoneRoute {
  Post = "/zone/post",
  List = "/zone/list",
}

export const ZoneRouteMap = [
  {
    path: ZoneRoute.Post,
    component: ZonePost,
  },
  {
    path: ZoneRoute.List,
    component: ZoneList,
  },
] as RouteProps[];
