import { Acl } from "../../interfaces/core.interface";

export enum AuditAclCode {
  Overview = 'overview',
  
}

export const AuditAcl = [
  {
    code: AuditAclCode.Overview,
    title: 'audit.view.title',
    description: 'audit.view.subTitle',
  },
  
 
] as Acl<AuditAclCode>;
