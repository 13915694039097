import Peer, { MediaConnection } from "peerjs";
import { io, Socket } from "socket.io-client";
import { IceServers } from "../entities/ice.servers.entity";
import { CodecHandler } from "../util/codecHandler";
import { ACTIONS } from "./emergency.service";

enum CALL_EVENTS {
    CONNECT = "connect-call",
    RECONNECT = "reconnect-call",
    REMOVE = "remove-call"
}

type DISPATCH = (actions: ACTIONS) => void;

export class CallService {
    private SOCKET: Socket<any,any>;
    private PEER: Peer | undefined = undefined;
    private MEDIA_CONNECTION: MediaConnection | undefined = undefined;
    private DISPATCH: DISPATCH;
    private ICE_SERVERS: IceServers[];
    private MEDIA_STREAM: MediaStream | undefined = undefined;
    private BOOKING: string;

    constructor(booking: string, dispatch: DISPATCH, IceServers: IceServers[]) {
        this.DISPATCH = dispatch;
        this.BOOKING = booking;
        this.ICE_SERVERS = IceServers;
        this.SOCKET = io(process.env.REACT_APP_PEER_HOST!);
        this.AutoPeer();

        this.SOCKET.on(CALL_EVENTS.CONNECT, async (remotePeer:any) => {
            console.log("PASSENGER CONNECTION RECEIVED");
            if (this.PEER) {
                const stream = await this.getMediaStream();
                this.MEDIA_CONNECTION = this.PEER.call(remotePeer, stream, {
                    sdpTransform: (sdp: string) => {
                        sdp = CodecHandler.preferCodec(sdp, "h264");
                        return sdp;
                    }
                });
                this.MEDIA_CONNECTION.on("stream", (remoteStream) => {
                    console.log("CALL ESTABLISHED");
                    for (let track of remoteStream.getTracks()) {
                        console.log("call track = ", track);
                    }
                    this.DISPATCH(ACTIONS.CONNECTED);
                });
            }
        });

        this.SOCKET.on(CALL_EVENTS.RECONNECT, async (remotePeer:any) => {
            this.DISPATCH(ACTIONS.CONNECTING);
            console.log("RECONNCT EVENT!");
            if (this.PEER) {
                const stream = await this.getMediaStream();
                this.MEDIA_CONNECTION = this.PEER.call(remotePeer, stream, {
                    sdpTransform: (sdp: string) => {
                        sdp = CodecHandler.preferCodec(sdp, "h264");
                        return sdp;
                    }
                });
                this.MEDIA_CONNECTION.on('stream', (remoteStream) => {
                    for (let track of remoteStream.getTracks()) {
                        console.log("track reconnect = ", track);
                    }
                    this.DISPATCH(ACTIONS.CONNECTED);
                });
            }
        });
    }
    private peerConfigs() {
        const config = {
            debug: 0,
            secure: false,
            host: process.env.REACT_APP_PEER_HOST,
            port: 3002,
            iceServers: this.ICE_SERVERS,
            path: '/peerjs/'
        };
        if (process?.env?.REACT_APP_PEER_HOST?.includes("http://")) {
            config.secure = false;
            config.host = process.env.REACT_APP_PEER_HOST.replace("http://", "").replace(":3002", "");
            config.port = 3002;
            config.debug = 3;
        }
        else {
            config.secure = true;
            config.host = process?.env?.REACT_APP_PEER_HOST?.replace("https://", "");
            config.port = 443;
            config.debug = 0;
        }
        return config;
    }
    private sendConnectOrReconnect(peerID: string) {
        this.SOCKET.emit(CALL_EVENTS.CONNECT, { booking: this.BOOKING, type: "Driver", peerID: peerID });
    }
    private AutoPeer() {
        const config = this.peerConfigs();
        this.SOCKET.on('connect', () => {
            console.log("SOCKET CONNECTED!!!!");
            if (this.PEER) {
                if (this.PEER.disconnected) this.PEER.reconnect();
                console.log("PEER RECONNECTING = ", this.PEER.disconnected);
                this.sendConnectOrReconnect(this.PEER.id);
            }
            else {
                this.PEER = new Peer(undefined, config);
                this.PEER.on("open", (id) => {
                    console.log("PEER OPENED = ", id);
                    this.sendConnectOrReconnect(id);
                });
                this.PEER.on("error", (error) => {
                    console.log("PEER ERROR = ", error.type);
                    switch (error.type) {
                        case 'network':
                        case 'disconnected':
                            this.DISPATCH(ACTIONS.INTERNET_DOWN);
                            break;
                        case 'peer-unavailable':
                        case 'server-error':
                        case 'peer-lost':
                            this.endCall(true);
                            this.DISPATCH(ACTIONS.CALL_ENDED);
                            break;
                        case 'unavailable-id':
                            this.PEER = new Peer(undefined, config);
                            this.PEER.on('open', (id) => {
                                this.sendConnectOrReconnect(id);
                            });
                            break;
                    }
                });
                this.PEER.on("call", async (mediaConnection) => {
                    this.MEDIA_CONNECTION = mediaConnection;
                    const stream = await this.getMediaStream();
                    this.MEDIA_CONNECTION.answer(stream, {
                        sdpTransform: (sdp: string) => {
                            sdp = CodecHandler.preferCodec(sdp, "h264");
                            return sdp;
                        }
                    });
                    this.DISPATCH(ACTIONS.CONNECTED);
                });
            }
        });
    }
    private async getMediaStream(): Promise<MediaStream> {
        if (this.MEDIA_STREAM) return this.MEDIA_STREAM;
        else {
            this.MEDIA_STREAM = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: true,
            }) as MediaStream;

            this.MEDIA_STREAM.getVideoTracks().forEach((track) => {
                track.stop();
                track.enabled = false;
            });
            return this.MEDIA_STREAM;
        }
    }
    public async endCall(send: boolean) {
        if (send) this.SOCKET.emit(CALL_EVENTS.REMOVE, this.BOOKING);
        console.log("CLOSING PEER CONNECTION");
        this.MEDIA_CONNECTION?.close();
        this.PEER?.destroy();
        this.PEER = undefined;
        if (this.MEDIA_STREAM) {
            this.MEDIA_STREAM.getTracks().forEach((track) => {
                track.enabled = false;
                track.stop();
            });
        }
        this.MEDIA_STREAM = undefined;
        this.DISPATCH(ACTIONS.CALL_ENDED);
        this.SOCKET.close();
    }
    public toggleMic(state: boolean) {
        if (this.MEDIA_STREAM) {
            this.MEDIA_STREAM.getAudioTracks().forEach((track) => {
                track.enabled = state;
            });
        }
    }
}